/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types"
import React from "react"
import { List, Typography } from "antd"
import { useTranslation } from "react-i18next"
import "./FloatingInfoPanel.css"
import { FOOTPRINT_ATTRIBUTES_RANK, GEOMETRY_CATEGORY } from "helpers/constants"

const { Title, Paragraph } = Typography

/**
 *
 * Creates Floating InfoPanel when users have clicked on a Parcel or Building and then "Show More Information"
 *
 * @props {object}			data to be display
 * @props {function} 		function to handle the onClose behavior
 * @props {string}			geometry category following GEOMETRY_CATEGORY values
 *
 * @returns FloatingInfoPanel
 */
const FloatingInfoPanel = (props) => {
	const { data, onClose, geometryCategory } = props
	const { t } = useTranslation()
	const _data = Object.keys(data)
		.filter((key) => data[key].type) // display only keys who have been mapped Primary or Secondary
		.map((_content) => ({
			title: t(_content),
			type: data[_content].type,
			description: <Paragraph>{data[_content].label}</Paragraph>
		}))
	return (
		<div
			className="floatingInfoPanel__container"
			onClick={(event) => event.stopPropagation()}
		>
			<div className="floatingInfoPanel__header">
				<Title className="floatingInfoPanel__title" level={5}>
					{geometryCategory === GEOMETRY_CATEGORY.PARCEL
						? "Parcel"
						: "Building"}{" "}
					information
				</Title>
				<button
					type="button"
					className="floatingInfoPanel__closeButton"
					onClick={() => onClose()}
				>
					<svg
						width="18"
						height="18"
						viewBox="0 0 16 18"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M12.845 18.468 8 13.623l-4.845 4.845-1.623-1.623L6.377 12 1.532 7.155l1.623-1.623L8 10.377l4.845-4.845 1.623 1.623L9.623 12l4.845 4.845z"
							fill="#fff"
							fillRule="evenodd"
						/>
					</svg>
				</button>
			</div>
			<div className="floatingInfoPanel__dataContainer">
				<div>
					<List
						itemLayout="horizontal"
						dataSource={_data.filter(
							(entry) =>
								entry.type ===
								FOOTPRINT_ATTRIBUTES_RANK.SECONDARY
						)}
						renderItem={(item) => (
							<List.Item>
								<List.Item.Meta
									title={item.title}
									description={item.description}
								/>
							</List.Item>
						)}
					/>
				</div>
			</div>
		</div>
	)
}
FloatingInfoPanel.propTypes = {
	data: PropTypes.shape({}).isRequired,
	onClose: PropTypes.func.isRequired,
	geometryCategory: PropTypes.string.isRequired
}
export default FloatingInfoPanel
