import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Typography, Button, Table, Tag, Space, Tooltip } from "antd"
import moment from "moment"

import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons"

import { DoneIcon, NotReadyIcon, ToReviewIcon } from "assets/statusIcon"

import DownloadFloodLE from "assets/DownloadFloodLE"
import RemoveIcon from "assets/RemoveIcon"
import DownloadFileEmp from "assets/DownloadFileEmp"

import { DateTime } from "luxon"

import { disableJobDeletion } from "helpers/rulesHandler"
import {
	filteredJobsList,
	isFetching as jobsIsFetching,
	fetchJobsList,
	fetchResultsFileURLByJobId,
	isCreatingFileError as isCreatingFileErrorSlice,
	displayRefresh as displayRefreshSlice,
	setSelectedJob,
	deleteJobByJobId
} from "features/jobsSlice"
import {
	fetchResultsByJobId,
	clearJobResults,
	setSelectedJobId,
	setJobName,
	setCurrentLocationIndex,
	fetchResultsByJobIDController as fetchResultsByJobIDControllerSlice,
	setFetchResultsByJobIDController
} from "features/jobsResultsSlice"
import { user as userInfoSlice } from "features/authSlice"
import { displayAlert, setAlertPayload } from "features/alertsSlice"
import { ALERTS_TYPE, JOBS_STATUS_TYPE } from "helpers/constants"
import "styles/pages.css"
import WarningModal from "components/warningModal/WarningModal"

import { displayToast } from "components/alerts/Alerts"

import JobsHeader from "pages/jobs/components/JobsHeader"
import JobsProcessing from "pages/jobs/components/JobsProcessing"

import itemRender from "styles/tablePagination"
import DownloadAllData from "assets/DownloadAllData"

const { Title } = Typography
const { Column } = Table

const numberOfLocationsProcessedFragment = (resultProcessed = -1) => (
	<div>
		{`Location${
			resultProcessed > 1 ? "s" : ""
		} processed: ${resultProcessed}`}
	</div>
)
const JobsHandlerPage = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const isCreatingFileError = useSelector(isCreatingFileErrorSlice)
	const isJobsFetchingSelector = useSelector(jobsIsFetching)
	const filteredJobsListSelector = useSelector(filteredJobsList)
	const displayRefresh = useSelector(displayRefreshSlice)
	const userInfo = useSelector(userInfoSlice)
	const fetchResultsByJobIDController = useSelector(
		fetchResultsByJobIDControllerSlice
	)
	moment.locale(userInfo.locale)

	// Fetch list of jobs on component/page load
	useEffect(() => {
		if (!isJobsFetchingSelector) {
			dispatch(fetchJobsList())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch])

	useEffect(() => {
		if (isCreatingFileError) {
			dispatch(
				setAlertPayload({
					code: "ERROR_DOWNLOADING_MESSAGE",
					isMapAlert: false
				})
			)
			setTimeout(() => {
				dispatch(displayAlert(false))
			}, 2000)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCreatingFileError])

	const [isDeletingWarningVisible, setIsDeletingWarningVisible] =
		useState(false)

	const [jobIdToBeDelete, setJobIdToBeDelete] = useState("")

	return (
		<div className="page__container">
			<WarningModal
				title="Delete job"
				isWarningVisible={isDeletingWarningVisible}
				closable={false}
				maskClosable={false}
				actions={[
					<Button
						key="cancel"
						data-testid="warning-back-cancel-testid"
						className="axa-button--ghost"
						type="primary"
						size="large"
						onClick={() => setIsDeletingWarningVisible(false)}
					>
						Cancel
					</Button>,
					<Button
						key="submit"
						className="axa-button"
						type="primary"
						size="large"
						onClick={() => {
							dispatch(deleteJobByJobId(jobIdToBeDelete)).then(
								async (jobIdToBeDeleteData) => {
									setIsDeletingWarningVisible(false)
									if (
										jobIdToBeDeleteData.type ===
										deleteJobByJobId.fulfilled.type
									) {
										await displayToast(
											dispatch,
											"SUCCESS_DELETE_JOB",
											ALERTS_TYPE.SUCCESS,
											2000,
											false
										)
									} else {
										await displayToast(
											dispatch,
											"FAILED_DELETE_JOB",
											ALERTS_TYPE.ERROR,
											2000,
											false
										)
									}
									dispatch(fetchJobsList())
									setJobIdToBeDelete("")
								}
							)
						}}
					>
						Remove Job
					</Button>
				]}
			>
				<p>
					This action will remove the job and all modifications from
					the system. We suggest you to download the current version
					for safekeeping.
				</p>
			</WarningModal>
			<div className="page__titleActionContainer">
				<Title className="page__title">List of jobs</Title>
			</div>
			<JobsHeader />
			<Title className="page__subtitle" level={5}>
				Select a job to review
			</Title>
			{displayRefresh ? <JobsProcessing /> : null}
			<div className="page__dataTableContainer">
				<Table
					dataSource={filteredJobsListSelector}
					loading={isJobsFetchingSelector}
					rowKey="id"
					pagination={{
						itemRender,
						showSizeChanger: false,
						hideOnSinglePage: true
					}}
					rowClassName={(record, index) => {
						// workaround to have alternate row style
						const className = ["pages__table__row"]
						if (index % 2 === 0)
							className.push("pages__table__row--even")
						if (
							record.validation.toUpperCase() ===
								JOBS_STATUS_TYPE.IN_PROGRESS ||
							record.validation.toUpperCase() ===
								JOBS_STATUS_TYPE.UPLOADED ||
							record.validation.toUpperCase() ===
								JOBS_STATUS_TYPE.ERROR
						)
							className.push("pages__table__row--disabled")
						return className
					}}
					onRow={(record) => ({
						onClick: (event) => {
							event.stopPropagation()
							if (
								record.validation.toUpperCase() !==
									JOBS_STATUS_TYPE.IN_PROGRESS &&
								record.validation.toUpperCase() !==
									JOBS_STATUS_TYPE.UPLOADED &&
								record.validation.toUpperCase() !==
									JOBS_STATUS_TYPE.ERROR
							) {
								dispatch(clearJobResults())
								dispatch(
									setCurrentLocationIndex(
										record.current_review_position_index
									)
								)
								// Abort any other locations loading
								if (fetchResultsByJobIDController) {
									fetchResultsByJobIDController?.abort()
								}
								const currentfetchResultsByJobId = dispatch(
									fetchResultsByJobId(record.id)
								)
								// Set fetching controller to notify that a request is ongoing
								dispatch(
									setFetchResultsByJobIDController(
										currentfetchResultsByJobId
									)
								)
								navigate({
									pathname: "/location",
									search: `jobId=${record.id}`
								})

								dispatch(setSelectedJobId(record.id))
								dispatch(setJobName(record.account_name))
								dispatch(setSelectedJob(record))
							}
						} // click row
					})}
				>
					<Column
						title={t("ACCOUNT NAME")}
						dataIndex="account_name"
						key="account_name"
						ellipsis={{ showTitle: false }}
						render={(accountName) => accountName}
					/>

					<Column
						title={t("REFERENCE Nº")}
						dataIndex="account_reference"
						key="account_reference"
						className="table__title"
						ellipsis={{ showTitle: false }}
						render={(accountReference) => (
							<Tooltip
								placement="topLeft"
								title={accountReference}
							>
								{accountReference}
							</Tooltip>
						)}
					/>
					<Column
						title={t("STATUS")}
						dataIndex={["validation", "processed_locations"]}
						key="validation"
						render={(tag, record) => {
							let color
							let textColor
							let label
							let icon
							switch (record.validation.toUpperCase()) {
								case JOBS_STATUS_TYPE.VALIDATED:
									color = "#138636"
									textColor = "#F0F0F0"
									label = "Done"
									icon = <DoneIcon color="#F0F0F0" />
									break
								case JOBS_STATUS_TYPE.TO_BE_REVIEWED:
									color = "#FFBC11"
									textColor = "#343C3D"
									label = "To Be Reviewed"
									icon = <ToReviewIcon color="#343C3D" />
									break
								case JOBS_STATUS_TYPE.FILE_CREATION_IN_PROGRESS:
									color = "#b5d0ee"
									textColor = "#F0F0F0"
									label = "file creation"
									icon = <LoadingOutlined spin />
									break
								case JOBS_STATUS_TYPE.UPLOADED:
								case JOBS_STATUS_TYPE.IN_PROGRESS:
									color = "#C91432"
									textColor = "#F0F0F0"
									label = "Not ready"
									icon = <NotReadyIcon spin color="#F0F0F0" />
									break
								default:
									color = "#f07662"
									label = "error"
									textColor = "#343C3D"
									icon = <ExclamationCircleOutlined />
							}

							return record.validation.toUpperCase() ===
								JOBS_STATUS_TYPE.UPLOADED ||
								record.validation.toUpperCase() ===
									JOBS_STATUS_TYPE.IN_PROGRESS ? (
								<Tooltip
									placement="top"
									trigger="hover"
									title={numberOfLocationsProcessedFragment(
										record.processed_locations
									)}
								>
									<Tag
										style={{
											color: textColor,
											alignItems: "center"
										}}
										color={color}
										key={tag}
										icon={icon}
									>
										{label.toUpperCase()}
									</Tag>
								</Tooltip>
							) : (
								<Tag
									style={{ color: textColor }}
									color={color}
									key={tag}
									icon={icon}
								>
									{label.toUpperCase()}
								</Tag>
							)
						}}
					/>
					<Column
						title={t("Nº OF RECORDS")}
						dataIndex="total_number_records"
						key="total_number_records"
						sorter={(a, b) =>
							a.total_number_records - b.total_number_records
						}
						render={(text) => (
							<span data-testid="number-records-testid">
								{text >= 0 ? text : "-"}
							</span>
						)}
					/>
					<Column
						title={t("DATE UPLOADED")}
						dataIndex="upload_datetime"
						key="upload_datetime"
						sorter={(a, b) =>
							Date.parse(DateTime.fromISO(a.upload_datetime)) -
							Date.parse(DateTime.fromISO(b.upload_datetime))
						}
						render={(date) => (
							<span data-testid="upload-date-testid">
								{moment(date).format("DD/MM/YYYY")}
								<br />
								{moment(date).format("HH:mm")}
							</span>
						)}
					/>
					<Column
						title={t("PROCESSING TIME")}
						dataIndex="locations_processing_elapsed_time"
						key="processing_time"
						render={(time) => (
							<span data-testid="processing_time_testid">
								{time && time !== -1
									? moment(0).second(time).format("mm:ss")
									: "N/A"}
							</span>
						)}
					/>
					<Column
						title={t("LAST OPEN")}
						data-testid="last-opendate-column-testid"
						dataIndex="last_open_timestamp"
						key="last_open_timestamp"
						sorter={(a, b) =>
							Date.parse(
								DateTime.fromISO(a.last_open_timestamp)
							) -
							Date.parse(DateTime.fromISO(b.last_open_timestamp))
						}
						render={(date) => (
							<span data-testid="last-opendate-testid">
								{date ? (
									<>
										{moment(date).format("DD/MM/YYYY")}
										<br />
										{moment(date).format("HH:mm")}
									</>
								) : (
									"N/A"
								)}
							</span>
						)}
					/>
					<Column
						title={t("ACTIONS")}
						dataIndex="actions"
						key="actions"
						align="center"
						render={(text, record) => (
							<Space
								size="middle"
								style={{ alignItems: "center" }}
							>
								<Tooltip title="Download FloodLE format">
									<Button
										type="link"
										className="actions-footprint__button"
										data-testid="download-footprint-button"
										shape="circle"
										size="small"
										icon={
											<DownloadFloodLE
												classname={`actions-footprint__icon${
													record.validation.toUpperCase() ===
														JOBS_STATUS_TYPE.FILE_CREATION_IN_PROGRESS ||
													record.validation.toUpperCase() ===
														JOBS_STATUS_TYPE.UPLOADED ||
													record.validation.toUpperCase() ===
														JOBS_STATUS_TYPE.IN_PROGRESS ||
													record.validation.toUpperCase() ===
														JOBS_STATUS_TYPE.ERROR
														? "--disabled"
														: ""
												}`}
											/>
										}
										disabled={
											record.validation.toUpperCase() ===
												JOBS_STATUS_TYPE.FILE_CREATION_IN_PROGRESS ||
											record.validation.toUpperCase() ===
												JOBS_STATUS_TYPE.UPLOADED ||
											record.validation.toUpperCase() ===
												JOBS_STATUS_TYPE.IN_PROGRESS ||
											record.validation.toUpperCase() ===
												JOBS_STATUS_TYPE.ERROR
										}
										onClick={(e) => {
											e.stopPropagation()
											dispatch(
												setAlertPayload({
													code: "DOWNLOADING_MESSAGE",
													recordName:
														record.account_name,
													isMapAlert: false
												})
											)
											setTimeout(() => {
												dispatch(displayAlert(false))
											}, 3000)
											dispatch(
												fetchResultsFileURLByJobId({
													jobId: record.id,
													format: "flood_le"
												})
											).then((data) => {
												dispatch(fetchJobsList())
												window.open(
													data.payload.output_url_file
												)
												dispatch(
													setAlertPayload({
														code: "SUCCESS_DOWNLOADING_MESSAGE",
														isMapAlert: false
													})
												)
												setTimeout(() => {
													dispatch(
														displayAlert(false)
													)
												}, 3000)
											})
											e.currentTarget.blur()
										}}
									/>
								</Tooltip>
								<Tooltip title="Download EMP format">
									<Button
										type="link"
										className="actions-footprint__button"
										data-testid="download-footprint-button"
										shape="circle"
										size="small"
										icon={
											<DownloadFileEmp
												classname={`actions-footprint__icon${
													record.validation.toUpperCase() ===
														JOBS_STATUS_TYPE.FILE_CREATION_IN_PROGRESS ||
													record.validation.toUpperCase() ===
														JOBS_STATUS_TYPE.UPLOADED ||
													record.validation.toUpperCase() ===
														JOBS_STATUS_TYPE.IN_PROGRESS ||
													record.validation.toUpperCase() ===
														JOBS_STATUS_TYPE.ERROR
														? "--disabled"
														: ""
												}`}
											/>
										}
										disabled={
											record.validation.toUpperCase() ===
												JOBS_STATUS_TYPE.FILE_CREATION_IN_PROGRESS ||
											record.validation.toUpperCase() ===
												JOBS_STATUS_TYPE.UPLOADED ||
											record.validation.toUpperCase() ===
												JOBS_STATUS_TYPE.IN_PROGRESS ||
											record.validation.toUpperCase() ===
												JOBS_STATUS_TYPE.ERROR
										}
										onClick={(e) => {
											e.stopPropagation()
											dispatch(
												setAlertPayload({
													code: "DOWNLOADING_MESSAGE",
													recordName:
														record.account_name,
													isMapAlert: false
												})
											)
											setTimeout(() => {
												dispatch(displayAlert(false))
											}, 3000)
											dispatch(
												fetchResultsFileURLByJobId({
													jobId: record.id,
													format: ""
												})
											).then((data) => {
												dispatch(fetchJobsList())
												window.open(
													data.payload.output_url_file
												)
												dispatch(
													setAlertPayload({
														code: "SUCCESS_DOWNLOADING_MESSAGE",
														isMapAlert: false
													})
												)
												setTimeout(() => {
													dispatch(
														displayAlert(false)
													)
												}, 3000)
											})
											e.currentTarget.blur()
										}}
									/>
								</Tooltip>
								<Tooltip
									title={t("Download all data available")}
								>
									<Button
										type="link"
										className="actions-footprint__button"
										data-testid="download-footprint-button"
										shape="circle"
										size="small"
										icon={
											<DownloadAllData
												classname={`actions-footprint__icon${
													record.validation.toUpperCase() ===
														JOBS_STATUS_TYPE.FILE_CREATION_IN_PROGRESS ||
													record.validation.toUpperCase() ===
														JOBS_STATUS_TYPE.UPLOADED ||
													record.validation.toUpperCase() ===
														JOBS_STATUS_TYPE.IN_PROGRESS ||
													record.validation.toUpperCase() ===
														JOBS_STATUS_TYPE.ERROR
														? "--disabled"
														: ""
												}`}
											/>
										}
										disabled={
											record.validation.toUpperCase() ===
												JOBS_STATUS_TYPE.FILE_CREATION_IN_PROGRESS ||
											record.validation.toUpperCase() ===
												JOBS_STATUS_TYPE.UPLOADED ||
											record.validation.toUpperCase() ===
												JOBS_STATUS_TYPE.IN_PROGRESS ||
											record.validation.toUpperCase() ===
												JOBS_STATUS_TYPE.ERROR
										}
										onClick={(e) => {
											e.stopPropagation()
											dispatch(
												setAlertPayload({
													code: "DOWNLOADING_MESSAGE",
													recordName:
														record.account_name,
													isMapAlert: false
												})
											)
											setTimeout(() => {
												dispatch(displayAlert(false))
											}, 3000)
											dispatch(
												fetchResultsFileURLByJobId({
													jobId: record.id,
													format: "DATABASE_EXTRACT"
												})
											).then((data) => {
												dispatch(fetchJobsList())
												window.open(
													data.payload.output_url_file
												)

												dispatch(
													setAlertPayload({
														code: "SUCCESS_DOWNLOADING_MESSAGE",
														isMapAlert: false
													})
												)
												setTimeout(() => {
													dispatch(
														displayAlert(false)
													)
												}, 3000)
											})
											e.currentTarget.blur()
										}}
									/>
								</Tooltip>
								<Button
									type="link"
									className="actions-footprint__button"
									data-testid="delete-footprint-button"
									shape="circle"
									size="small"
									disabled={disableJobDeletion(
										record.validation,
										record.upload_datetime
									)}
									icon={
										<RemoveIcon
											classname={`actions-footprint__icon${
												disableJobDeletion(
													record.validation,
													record.upload_datetime
												)
													? "--disabled"
													: ""
											}`}
										/>
									}
									onClick={(e) => {
										e.stopPropagation()
										setIsDeletingWarningVisible(true)
										setJobIdToBeDelete(record.id)
										e.currentTarget.blur()
									}}
								/>
							</Space>
						)}
					/>
				</Table>
			</div>
		</div>
	)
}

export default JobsHandlerPage
