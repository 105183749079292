import { configureStore } from "@reduxjs/toolkit"
import { combineReducers, applyMiddleware } from "redux"
import { createBrowserHistory } from "history"
import { createReduxHistoryContext } from "redux-first-history"
import thunk from "redux-thunk"
import keplerGlReducer from "kepler.gl/reducers"
import { enhanceReduxMiddleware } from "kepler.gl/middleware"

import { taskMiddleware } from "react-palm/tasks"

import geocoderReducer from "features/footprintSlice"
import jobsReducer from "features/jobsSlice"
import jobsResultsReducer from "features/jobsResultsSlice"
import mapReducer from "features/mapSlice"
import alertsSliceReducer from "features/alertsSlice"
import authSliceReducer from "features/authSlice"
import backboneReducer from "features/backboneSlice"
import errorMiddleware from "helpers/errorMiddleware"

const { createReduxHistory, routerMiddleware, routerReducer } =
	createReduxHistoryContext({ history: createBrowserHistory() })

const reducer = combineReducers({
	keplerGl: keplerGlReducer.initialState({
		uiState: {
			readOnly: true,
			currentModal: null,
			activeSidePanel: null,
			mapControls: {
				visibleLayers: {
					show: false
				},
				mapLegend: {
					show: true,
					active: false
				},
				toggle3d: {
					show: false
				},
				splitMap: {
					show: false
				}
			}
		} // https://github.com/keplergl/kepler.gl/issues/702#issuecomment-575764668}),
	}),
	geocoder: geocoderReducer,
	jobs: jobsReducer,
	jobsResults: jobsResultsReducer,
	map: mapReducer,
	alerts: alertsSliceReducer,
	router: routerReducer,
	auth: authSliceReducer,
	backbone: backboneReducer
})

const middlewares = enhanceReduxMiddleware([
	thunk,
	taskMiddleware,
	errorMiddleware,
	routerMiddleware
])

const enhancers = [applyMiddleware(...middlewares)]

export const store = configureStore({
	reducer,
	enhancers,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			immutableCheck: false,
			actionsDenylist: [
				"@@kepler.gl/UPDATE_MAP",
				"@@kepler.gl/LAYER_HOVER",
				"@@kepler.gl/LAYER_CLICK",
				"@@kepler.gl/MOUSE_MOVE"
			]
		})
})

export const history = createReduxHistory(store)
