import React, { memo, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import useMediaQuery from "styles/useMediaQuery"

import { VectorMap } from "@react-jvectormap/core"
import map from "assets/worldMill.json"

import {
	isFetching as resultsIsFetching,
	breakdownByCountries as breakdownByCountriesSlice
} from "features/jobsResultsSlice"

import "./LocationInformations.css"

const QualityMap = () => {
	const isDesktop = useMediaQuery("(min-width: 1100px)")
	const [isShowMap, setIsShowMap] = useState(true)
	const isResultsFetching = useSelector(resultsIsFetching)
	const breakdownByCountries = useSelector(breakdownByCountriesSlice)
	const items =
		breakdownByCountries &&
		breakdownByCountries.reduce((acc, country) => {
			const { resolutionCountry, location } = country
			const number =
				(location === "US" && resolutionCountry > 80 && "#138636") ||
				(location !== "US" && resolutionCountry > 60 && "#138636") ||
				"#00008F"
			return Object.assign(acc, {
				[location]: number
			})
		}, {})

	useEffect(() => {
		setIsShowMap(false)
		setTimeout(() => {
			setIsShowMap(true)
		})
	}, [isDesktop])

	if (isResultsFetching) return <div className="grey_box" />
	return (
		isShowMap && (
			<VectorMap
				map={map}
				zoomOnScroll={false}
				backgroundColor="#7698CB"
				style={{
					maxWidth: "1034px",
					minHeight: "515px",
					maxHeight: "566px",
					height: "100%",
					width: "100%",
					transX: 0,
					transY: 0,
					scale: 1,
					baseTransX: 0,
					baseTransY: 0,
					baseScale: 1
				}}
				regionStyle={{
					initial: {
						fill: "#e4e4e4",
						"fill-opacity": 0.9,
						stroke: "none",
						"stroke-width": 0,
						"stroke-opacity": 0
					},
					hover: {
						cursor: "pointer",
						stroke: "#FFBC11",
						"stroke-width": 2,
						"stroke-opacity": 1
					}
				}}
				series={{
					regions: [
						{
							attributes: "fill",
							values: items
						}
					]
				}}
			/>
		)
	)
}

QualityMap.propTypes = {
	onHoverCountry: PropTypes.shape({
		location: PropTypes.string,
		color: PropTypes.string
	})
}

QualityMap.defaultProps = {
	onHoverCountry: {}
}

export default memo(QualityMap)
