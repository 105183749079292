import PropTypes from "prop-types"
import React, { memo } from "react"
import Icon from "assets/IconPinLocator"

/**
 *
 * Creates Pin component
 * Side panel on review mode
 *
 * @props {int} 			size of the pin
 * @props {string} 			color of the pin
 * @props {boolean} 		isClientPin: flag if the pin is "Coordinates"
 * @props {string} 			label of the pin
 *
 * @returns Pin
 */
const Pin = memo((props) => {
	const { size, color, isClientPin, label, index } = props

	return (
		<div
			style={{
				cursor: label ? "pointer" : "",
				height: "64px",
				display: "flex",
				justifyContent: "end"
			}}
		>
			{label ? (
				<div
					className="popPin"
					style={{
						display: "flex",
						flexDirection: "column",
						flexWrap: "nowrap",
						justifyContent: "center",
						alignItems: "center",
						alignContent: "center"
					}}
				>
					{index && <div className="bottom-arrow" />}
					<div
						className={
							index
								? "pin__label--index"
								: `pin__label${
										isClientPin && label
											? "--isClientPin"
											: ""
								  }`
						}
					>
						{label}
					</div>
					<Icon size={size} color={color} />
				</div>
			) : (
				<div style={{ display: "flex", alignSelf: "end" }}>
					<Icon size={size} color="#D68D77" />
				</div>
			)}
		</div>
	)
})

Pin.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
	isClientPin: PropTypes.bool,
	label: PropTypes.string,
	index: PropTypes.string
}

Pin.defaultProps = {
	size: 20,
	color: "#e28972",
	isClientPin: false,
	label: undefined,
	index: ""
}

export default Pin
