/* eslint-disable no-underscore-dangle */
import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Typography } from "antd"

import useMediaQuery from "styles/useMediaQuery"

import {
	jobResults,
	numberOfLocationAboveStreetLevel as numberOfLocationAboveStreetLevelSlice,
	numberOfLocationHighConfidence as numberOfLocationHighConfidenceSlice,
	numberOfLocationFootprinted as numberOfLocationFootprintedSlice,
	numberOfLocationValidated as numberOfLocationValidatedSlice
} from "features/jobsResultsSlice"
import BoxInfo from "./BoxInfo"
import "./LocationInformations.css"

const { Title } = Typography

const ProcessingInformations = () => {
	const isDesktop = useMediaQuery("(min-width: 1100px)")
	const jobResultsSelector = useSelector(jobResults)
	const numberOfLocationAboveStreetLevel = useSelector(
		numberOfLocationAboveStreetLevelSlice
	)
	const numberOfLocationHighConfidence = useSelector(
		numberOfLocationHighConfidenceSlice
	)
	const numberOfLocationFootprinted = useSelector(
		numberOfLocationFootprintedSlice
	)
	const numberOfLocationValidated = useSelector(
		numberOfLocationValidatedSlice
	)
	const { t } = useTranslation()

	return (
		<div className="info__container">
			<Title level={5} className="page__subtitle">
				{t("Processing information")}
			</Title>
			<div
				className={`header__information${isDesktop ? "" : "__mobile"}`}
			>
				<BoxInfo
					style
					title={t("High resolution locations")}
					tooltipTitle={t("Resolution at street-level or above")}
					text={
						<div>
							<span className="number">
								{numberOfLocationAboveStreetLevel}
							</span>
							/
							<span>
								{jobResultsSelector.length > 0
									? jobResultsSelector.length
									: "0"}
							</span>
						</div>
					}
				/>
				<BoxInfo
					title={t("High confidence locations")}
					tooltipTitle={t("Confidence score is 3 bars")}
					text={
						<div>
							<span className="number">
								{numberOfLocationHighConfidence}
							</span>
							/
							<span>
								{jobResultsSelector.length > 0
									? jobResultsSelector.length
									: "0"}
							</span>
						</div>
					}
				/>
				<BoxInfo
					title={t("Footprinted locations")}
					tooltipTitle={t(
						"Locations that contains building footprints (US & FR)"
					)}
					text={
						<div>
							<span className="number">
								{numberOfLocationFootprinted}
							</span>
							/
							<span>
								{jobResultsSelector.length > 0
									? jobResultsSelector.length
									: "0"}
							</span>
						</div>
					}
				/>
				<BoxInfo
					title={t("Validated locations")}
					tooltipTitle={t("Location manually validated by user")}
					text={
						<div>
							<span className="number">
								{numberOfLocationValidated}
							</span>
							/
							<span>
								{jobResultsSelector.length > 0
									? jobResultsSelector.length
									: "0"}
							</span>
						</div>
					}
				/>
			</div>
		</div>
	)
}

ProcessingInformations.propTypes = {}

ProcessingInformations.defaultProps = {}

export default ProcessingInformations
