import React from "react"
import PropTypes from "prop-types"

const DownloadFileEmp = ({ classname }) => (
	<svg
		width="17"
		height="21"
		viewBox="0 0 17 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={classname}
	>
		<path d="M16 19.5H1V1.5H11L16 6V19.5Z" fill="#00008F" />
		<path
			fillRule="evenodd"
			d="M0 1.5C0 0.947715 0.447715 0.5 1 0.5H11C11.247 0.5 11.4853 0.591444 11.669 0.756706L16.669 5.25671C16.8797 5.44635 17 5.71651 17 6V19.5C17 20.0523 16.5523 20.5 16 20.5H1C0.447715 20.5 0 20.0523 0 19.5V1.5ZM2 2.5V18.5H15V6.44536L10.6163 2.5H2Z"
			fill="#00008F"
		/>
		<path
			d="M3.92058 12.9338H2.35131C1.85313 12.9338 1.50233 12.8487 1.29891 12.6785C1.09964 12.5041 1 12.199 1 11.7631V8.5H3.92058V9.27218H1.86559V10.3121H3.30408L3.24181 11.0843H1.86559V11.7008C1.86559 11.8752 1.90503 11.9956 1.9839 12.062C2.06278 12.1284 2.20601 12.1616 2.41358 12.1616H3.92058V12.9338Z"
			fill="white"
		/>
		<path
			d="M8.04593 10.2872L7.24885 11.9686C7.1326 12.1928 6.9873 12.3048 6.81294 12.3048C6.64273 12.3048 6.4995 12.1865 6.38326 11.9499L5.57372 10.2872V12.9338H4.70813V8.5H5.56126L6.81294 11.0594L8.05839 8.5H8.91152V12.9338H8.04593V10.2872Z"
			fill="white"
		/>
		<path
			d="M12.6139 8.88609C12.8713 9.13933 13 9.51297 13 10.007C13 10.501 12.8713 10.8767 12.6139 11.1341C12.3607 11.3874 12.0016 11.514 11.5366 11.514H10.6835V12.9338H9.81788V8.5H11.5366C12.0016 8.5 12.3607 8.6287 12.6139 8.88609ZM12.1344 10.007C12.1344 9.51712 11.9206 9.27218 11.493 9.27218H10.6835V10.7356H11.493C11.7006 10.7356 11.8583 10.6795 11.9663 10.5674C12.0784 10.4554 12.1344 10.2685 12.1344 10.007Z"
			fill="white"
		/>
		<path
			d="M9 7.5H16V6.68477C16 6.56709 15.9504 6.45487 15.8633 6.37571L10.87 1.83634C10.6319 1.61992 10.3217 1.5 10 1.5H9V7.5Z"
			fill="white"
		/>
	</svg>
)

DownloadFileEmp.propTypes = {
	classname: PropTypes.string
}

DownloadFileEmp.defaultProps = {
	classname: ""
}

export default DownloadFileEmp
