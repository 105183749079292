import React from "react"
import PropTypes from "prop-types"

const DoneIcon = ({ color }) => (
	<svg
		width="14"
		height="15"
		viewBox="0 0 14 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7.00016 0.833336C3.32016 0.833336 0.333496 3.82 0.333496 7.5C0.333496 11.18 3.32016 14.1667 7.00016 14.1667C10.6802 14.1667 13.6668 11.18 13.6668 7.5C13.6668 3.82 10.6802 0.833336 7.00016 0.833336ZM5.66683 10.8333L2.3335 7.5L3.2735 6.56L5.66683 8.94667L10.7268 3.88667L11.6668 4.83334L5.66683 10.8333Z"
			fill={color}
		/>
	</svg>
)

DoneIcon.propTypes = {
	color: PropTypes.string
}

DoneIcon.defaultProps = {
	color: "white"
}

export default DoneIcon
