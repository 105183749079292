import React from "react"
import PropTypes from "prop-types"

const DownloadAllData = ({ classname }) => (
	<svg
		width="16"
		height="17"
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={classname}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.1378 3.81048C12.1378 5.63872 9.42066 7.1208 6.06888 7.1208C2.71711 7.1208 -4.6134e-05 5.63872 -4.6134e-05 3.81048C-4.6134e-05 1.98223 2.71711 0.500151 6.06888 0.500151C9.42066 0.500151 12.1378 1.98223 12.1378 3.81048ZM-4.6134e-05 10.6522C1.21374 11.866 3.42062 12.6384 6.06888 12.6384C6.2344 13.8522 6.73095 15.0108 7.55853 15.8384C7.06198 15.8935 6.56543 15.9487 6.06888 15.9487C2.64821 15.9487 -4.6134e-05 14.5142 -4.6134e-05 12.6384V10.6522ZM-4.6134e-05 6.23816V8.22436C-4.6134e-05 10.1002 2.64821 11.5347 6.06888 11.5347H6.12406C6.2344 10.2106 6.89647 8.99677 7.77922 8.11402C7.2275 8.16919 6.67578 8.22436 6.06888 8.22436C3.42062 8.22436 1.21374 7.45195 -4.6134e-05 6.23816ZM12.1382 12.4177V7.72804C14.2899 8.0039 16.0002 9.82458 16.0002 12.0866C16.0002 14.5142 14.014 16.5004 11.5865 16.5004C9.15889 16.5004 7.1727 14.5142 7.1727 12.0866C7.1727 9.87976 8.88303 8.0039 11.0347 7.72804V12.4177L10.3175 11.7004C10.0968 11.4797 9.76579 11.4797 9.5451 11.7004C9.32441 11.9211 9.32441 12.2522 9.5451 12.4728L11.2003 14.128C11.2278 14.1556 11.2554 14.1694 11.283 14.1832C11.3106 14.197 11.3382 14.2108 11.3658 14.2383C11.421 14.2935 11.5313 14.2935 11.5865 14.2935C11.6416 14.2935 11.752 14.2935 11.8072 14.2383C11.8623 14.2383 11.9175 14.1832 11.9727 14.128L13.6278 12.4728C13.8485 12.2522 13.8485 11.9211 13.6278 11.7004C13.4071 11.4797 13.0761 11.4797 12.8554 11.7004L12.1382 12.4177Z"
			fill="#00008F"
		/>
	</svg>
)

DownloadAllData.propTypes = {
	classname: PropTypes.string
}

DownloadAllData.defaultProps = {
	classname: ""
}

export default DownloadAllData
