/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types"
import React, { useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { List, Typography, Button } from "antd"

import { useTranslation } from "react-i18next"
import "components/infoTooltip/InfoTooltip.css"
import {
	geocodingIsSubmittingFootprintChecker as geocodingIsSubmittingFootprintCheckerSlice,
	isPostingResultEdit as isPostingResultEditSlice
} from "features/footprintSlice"

import { setIsClickingUnselected } from "features/mapSlice"

const { Title, Paragraph } = Typography
export const TOOLTIP_BOTTOM_OFFSET_PX = 20

const InfoTooltipPin = ({
	position,
	clickedPin,
	handleChangePin,
	closeInfoTooltipHandler,
	isSelectedPin
}) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const infoTooltipRef = useRef(null)
	const [displaySecondaryData, setDisplaySecondaryData] = useState(false)
	const isPostingResultEdit = useSelector(isPostingResultEditSlice)
	const isSubmittingFootprintChecker = useSelector(
		geocodingIsSubmittingFootprintCheckerSlice
	)
	const primaryContent = clickedPin.principal
	const secondaryContent = clickedPin.secondary
	const [top, setTop] = useState(position.y)

	const primaryData = Object.keys(primaryContent).map((item) => ({
		title: t(item),
		description: <Paragraph>{primaryContent[item]}</Paragraph>
	}))
	const secondaryData = Object.keys(secondaryContent).map((item) => ({
		title: t(item),
		description: <Paragraph>{secondaryContent[item]}</Paragraph>
	}))

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				infoTooltipRef.current &&
				!infoTooltipRef.current.contains(event.target)
			) {
				closeInfoTooltipHandler()
				setDisplaySecondaryData(false)
				dispatch(setIsClickingUnselected(false))
			}
		}
		document.addEventListener("click", handleClickOutside, true)
		return () => {
			document.removeEventListener("click", handleClickOutside, true)
		}
	}, [closeInfoTooltipHandler, dispatch])
	/* 
		Display plainly the tooltip
		check position of click and length of tooltip with height of screen
		if bigger set top to position of click minus difference between sum of position of click and length of tooltip minus height of screen
		added 20px offset to set the tooltip up 
		if lower position the tooltip at the click position
	*/
	useEffect(() => {
		if (
			position.y + infoTooltipRef?.current?.offsetHeight >
				window.innerHeight &&
			position.y < infoTooltipRef?.current?.offsetHeight
		)
			setTop(
				position.y -
					(position.y +
						infoTooltipRef?.current?.offsetHeight -
						window.innerHeight) -
					TOOLTIP_BOTTOM_OFFSET_PX
			)
		else if (
			position.y + infoTooltipRef?.current?.offsetHeight >
			window.innerHeight
		)
			setTop(position.y - infoTooltipRef?.current?.offsetHeight)
		else setTop(position.y)
	}, [position, infoTooltipRef, setTop])

	return (
		<div
			className="infoTooltip__container"
			data-testid="infoTooltip__container--testid"
			ref={infoTooltipRef}
			style={{
				left:
					position.x + infoTooltipRef?.current?.offsetWidth >
					window.innerWidth
						? position.x - infoTooltipRef?.current?.offsetWidth
						: position.x,
				top
			}}
		>
			<div className="infoTooltip_header">
				{displaySecondaryData && (
					<Button
						className="infoTooltip__goBackButton"
						onClick={() => {
							dispatch(setIsClickingUnselected(false))
							setDisplaySecondaryData(false)
						}}
					>
						<svg
							width="14"
							height="14"
							viewBox="0 0 14 14"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="m9.865 13 1.697-1.698L6.76 6.5l4.802-4.802L9.865 0l-6.5 6.5z"
								fill="#fff"
								fillRule="evenodd"
							/>
						</svg>
					</Button>
				)}
				<Title className="infoTooltip__title" level={5}>
					{clickedPin?.principal?.service === "Bing"
						? "Geocode information"
						: "POI information"}
				</Title>
				<Button
					className="infoTooltip__closeButton"
					onClick={closeInfoTooltipHandler}
				>
					<svg
						width="18"
						height="18"
						viewBox="0 0 16 18"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M12.845 18.468 8 13.623l-4.845 4.845-1.623-1.623L6.377 12 1.532 7.155l1.623-1.623L8 10.377l4.845-4.845 1.623 1.623L9.623 12l4.845 4.845z"
							fill="#fff"
							fillRule="evenodd"
						/>
					</svg>
				</Button>
			</div>
			<div className="infoTooltip__dataContainer">
				<List
					itemLayout="horizontal"
					dataSource={
						displaySecondaryData ? secondaryData : primaryData
					}
					renderItem={(item) => (
						<List.Item>
							<List.Item.Meta
								title={item.title}
								description={item.description}
							/>
						</List.Item>
					)}
				/>
			</div>
			{secondaryData.length > 0 && !displaySecondaryData && (
				<div
					className="infoTooltip__action__link"
					aria-hidden="true"
					onClick={() => {
						if (displaySecondaryData) setDisplaySecondaryData(false)
						else setDisplaySecondaryData(true)
					}}
				>
					{t(
						displaySecondaryData
							? "POI general data"
							: "POI quality sorting data"
					)}
				</div>
			)}
			{!isSelectedPin && (
				<div className="infoTooltip__action__container">
					<Button
						className="axa-button
					infoTooltip__action__container"
						disabled={
							isPostingResultEdit || isSubmittingFootprintChecker
						}
						type="primary"
						onClick={(e) => {
							e.currentTarget.blur()
							handleChangePin()
						}}
					>
						{t("SELECT LOCATION")}
					</Button>
				</div>
			)}
		</div>
	)
}

InfoTooltipPin.propTypes = {
	position: PropTypes.shape({
		x: PropTypes.number.isRequired,
		y: PropTypes.number.isRequired
	}).isRequired,
	clickedPin: PropTypes.shape({
		name: PropTypes.string,
		principal: PropTypes.shape({
			client: PropTypes.string,
			service: PropTypes.string,
			category: PropTypes.string,
			address: PropTypes.string
		}),
		secondary: PropTypes.shape({
			flag_quality: PropTypes.string,
			street_name_overall_smilarity: PropTypes.number,
			street_name_word_similarity: PropTypes.number,
			place_name_similarity_score: PropTypes.number
		})
	}).isRequired,
	handleChangePin: PropTypes.func.isRequired,
	closeInfoTooltipHandler: PropTypes.func.isRequired,
	isSelectedPin: PropTypes.bool
}

InfoTooltipPin.defaultProps = {
	isSelectedPin: false
}

export default InfoTooltipPin
