/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react"

import { Modal, Button } from "antd"
import illustration from "assets/data-privacy.png"
import "./DataPrivacyModal.css"
/**
 *
 * Creates Data Privacy notice modal
 *
 *
 * @returns DataPrivacyModal
 */

const DataPrivacyModal = () => {
	const [displayDataPrivacyModal, setDisplayDataPrivacyModal] = useState(true)

	useEffect(() => {
		if (JSON.parse(window.localStorage.getItem("privacyNoticeAgreement"))) {
			/* istanbul ignore next */
			setDisplayDataPrivacyModal(false)
		}
	}, [])

	const handleAction = () => {
		window.localStorage.setItem(
			"privacyNoticeAgreement",
			JSON.stringify(true)
		)
		setDisplayDataPrivacyModal(false)
	}
	return displayDataPrivacyModal ? (
		<Modal
			className="data_privacy_warning__container"
			title={
				<span className="data_privacy_warning_disclaimer__title">
					Data processing notice
				</span>
			}
			open={displayDataPrivacyModal}
			onCancel={/* istanbul ignore next */ () => handleAction()}
			centered
			closable
			mask={false}
			footer={null}
			destroyOnClose
		>
			<div className="data_privacy_warning_content__container">
				<div className="data_privacy_warning_illustration__container">
					<img
						className="data_privacy_warning_illustration"
						src={illustration}
						alt="man typing on keyboard"
					/>
				</div>
				<div className="data_privacy_warning_wrapper">
					<p className="data_privacy_warning_disclaimer__text">
						Within the AXA Location Footprinter (ALF) tool,
						geographical data is used to designate properties
						(buildings such as warehouses, manufacturing facilities,
						offices, stores) owned or leased by AXA XL corporate
						clients: postal addresses are used and converted in
						geocoded data (using Bing geocoding service). Such
						geographical data is then enriched with external
						location data sources (Lightbox, Bing Maps building
						footprints). Since the concerned customers are
						exclusively corporate clients, the addresses and
						geographical data used in ALF can&apos;t be used to
						identify any natural person. Therefore, this data is not
						considered as personal data in the sense of the European
						regulation.
					</p>
					<Button
						className="axa-button"
						type="primary"
						key="confirmButton"
						onClick={() => handleAction()}
					>
						Continue
					</Button>
				</div>
			</div>
		</Modal>
	) : null
}

export default DataPrivacyModal
