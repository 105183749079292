import {
	setUnselectedMarkers,
	setSelectedMarker,
	resetUnselectedMarkers,
	setMarker
} from "features/mapSlice"
import { SELECTION_KEY_DATA } from "helpers/constants"

import {
	addCircleTarget,
	getAdditionalParcels,
	addHocResult,
	getFootprintByCoordinates,
	clearFootprintData
} from "features/footprintSlice"

import {
	setCurrentFootprintMode,
	setCurrentModeDescription,
	setCurrentModeReasonKey
} from "features/jobsResultsSlice"

export const generateUnselectedPins = (geocodedDataRawSelector, dispatch) => {
	if (geocodedDataRawSelector.length > 0) {
		if (
			geocodedDataRawSelector[0].selection_key ===
			SELECTION_KEY_DATA.POI_UNSELECTED
		) {
			geocodedDataRawSelector[0].points_of_interest.forEach((data) => {
				dispatch(setUnselectedMarkers(data))
			})
			dispatch(
				setSelectedMarker(geocodedDataRawSelector[0].original_geocode)
			)
		} else if (
			geocodedDataRawSelector[0].selection_key ===
			SELECTION_KEY_DATA.POI_SELECTED
		) {
			dispatch(
				setUnselectedMarkers(
					geocodedDataRawSelector[0].original_geocode
				)
			)
			geocodedDataRawSelector[0].points_of_interest.forEach((data) =>
				dispatch(setUnselectedMarkers(data))
			)
			dispatch(
				setSelectedMarker({
					...geocodedDataRawSelector[0].poi_metadata,
					...geocodedDataRawSelector[0].poi,
					address: geocodedDataRawSelector[0].address,
					source: geocodedDataRawSelector[0].source,
					service: geocodedDataRawSelector[0].service,
					lat: geocodedDataRawSelector[0].lat,
					lon: geocodedDataRawSelector[0].lon
				})
			)
		}
	}
}

export const selectUnselectedPin = (
	newSelected,
	dispatch,
	oldSelectedData,
	unselectedMarkers
) => {
	dispatch(resetUnselectedMarkers())
	unselectedMarkers.map((data) => {
		if (newSelected?.data?.address.standard !== data?.address?.standard)
			return dispatch(setUnselectedMarkers(data))
		return dispatch(setUnselectedMarkers(oldSelectedData))
	})
	dispatch(setSelectedMarker(newSelected.data))
	dispatch(
		setMarker({
			lat: newSelected.data.lat,
			lon: newSelected.data.lon
		})
	)
	dispatch(clearFootprintData())
	dispatch(
		addCircleTarget({
			lat: newSelected.data.lat,
			lon: newSelected.data.lon
		})
	)
	dispatch(
		getFootprintByCoordinates({
			lon: parseFloat(newSelected.data.lon),
			lat: parseFloat(newSelected.data.lat)
		})
	).then(async (data) => {
		if (data.type === getAdditionalParcels.fulfilled.type) {
			dispatch(addHocResult(data[0].geo_data))
			dispatch(setCurrentFootprintMode(data[0].geo_data.processing_mode))
			dispatch(
				setCurrentModeDescription(
					data[0].geo_data.processing_mode_reason_key
				)
			)
			dispatch(
				setCurrentModeReasonKey(
					data[0].geo_data.processing_mode_reason_text
				)
			)
		}
	})
}
