/* eslint-disable no-underscore-dangle */
/**
 *
 * Convert MultiPolygon geometry feature to Polygons
 *
 * @param {array}   Features
 *
 * @returns {array} Features withouth MultiPolygon Geometry
 */

export default (features) => {
	const _features = JSON.parse(JSON.stringify(features))
	const multiPolygons = [
		...new Set(
			_features.filter(
				(feature) => feature.geometry.type === "MultiPolygon"
			)
		)
	]
	const newPolygons = multiPolygons
		.map((multiPolygon) => {
			const polygons = []
			multiPolygon.geometry.coordinates.forEach((coordinate) => {
				// Create new Polygons with unique Polygon geometry
				// Keeping the MultiPolygon source properties
				polygons.push({
					...multiPolygon,
					geometry: {
						coordinates: coordinate,
						type: "Polygon"
					}
				})
			})
			return polygons
		})
		.flat()
	// Returns an array with only original features with Polygon geometry and the new polygon
	return [
		..._features.filter((feature) => feature.geometry.type === "Polygon"),
		...newPolygons
	]
}
