import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Typography, Button } from "antd"

import { useSelector } from "react-redux"

import WarningModal from "components/warningModal/WarningModal"

import { geocodedDataRaw as geocodedDataRawSlice } from "features/footprintSlice"

const { Paragraph } = Typography

const MoreInfoDataSourceModal = ({
	onDismiss,
	isVisible,
	currentDataSourceDesc
}) => {
	const { t } = useTranslation()

	const currentGeocodedDataRaw = useSelector(geocodedDataRawSlice)

	return (
		<WarningModal
			title={t("Learn more about data")}
			isWarningVisible={isVisible}
			handleConfirm={() => {
				onDismiss()
			}}
			handleCancel={() => {
				onDismiss()
			}}
			actions={[
				<div key={1} className="popup_button">
					<Button
						key="cancel"
						className="axa-button"
						type="primary"
						size="large"
						onClick={() => onDismiss()}
					>
						{`${t("Go Back To").toUpperCase()} ${t(
							currentGeocodedDataRaw[0]?.processing_mode
						)}`}
					</Button>
				</div>
			]}
		>
			<Paragraph>
				{t(`data_source_desc_${currentDataSourceDesc}`)}
			</Paragraph>
		</WarningModal>
	)
}

MoreInfoDataSourceModal.propTypes = {
	onDismiss: PropTypes.func,
	isVisible: PropTypes.bool,
	currentDataSourceDesc: PropTypes.string
}

MoreInfoDataSourceModal.defaultProps = {
	onDismiss: () => {},
	isVisible: false,
	currentDataSourceDesc: ""
}

export default MoreInfoDataSourceModal
