/* eslint-disable no-underscore-dangle */
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Typography, Button, Skeleton } from "antd"
import { DownloadOutlined } from "@ant-design/icons"

import { displayAlert, setAlertPayload } from "features/alertsSlice"
import useMediaQuery from "styles/useMediaQuery"

import {
	jobResults,
	isFetching as resultsIsFetching,
	selectedJobId as selectedJobIdSlice,
	setSelectedRowKeys,
	fetchResultsByLocationIds
} from "features/jobsResultsSlice"
import { fetchJobsList, fetchResultsFileURLByJobId } from "features/jobsSlice"
import IconArrowDown from "assets/IconArrowDown"
import IconArrowUp from "assets/IconArrowUp"
import GeneralsInformations from "./GeneralsInformations"
import GeocodingInformations from "./GeocodingInformations"
import ByCountryInformations from "./ByCountryInformations"
import "./LocationInformations.css"
import QualityMap from "./QualityMap"
import ProcessingInformations from "./ProcessingInformations"

const { Title } = Typography

function areWeTestingWithJest() {
	return process.env.JEST_WORKER_ID !== undefined
}

const LocationInformations = ({
	setDisplayAllLocationsOnMapDrawer,
	jobName,
	selectedJobName
}) => {
	const isDesktop = useMediaQuery("(min-width: 1100px)")
	const dispatch = useDispatch()
	const [display, setDisplay] = useState(true)
	const [onHover, setOnHover] = useState(false)
	const isResultsFetching = useSelector(resultsIsFetching)
	const selectedJobId = useSelector(selectedJobIdSlice)
	const jobResultsSelector = useSelector(jobResults)
	const { t } = useTranslation()

	return (
		<div className="header__container_column">
			<div className="arrow_button__container">
				<Button
					className="arrow_button"
					type="text"
					onClick={() => setDisplay(!display)}
					icon={display ? <IconArrowUp /> : <IconArrowDown />}
				/>
			</div>
			<div className="header__content_row">
				<div className="title__container">
					<Title level={2} className="page__title">
						{isResultsFetching ? <Skeleton.Input /> : jobName}
					</Title>
					<Button
						className="locations_header__button"
						type="link"
						onClick={(e) => {
							e.stopPropagation()
							dispatch(
								setAlertPayload({
									code: "DOWNLOADING_MESSAGE",
									recordName: selectedJobName,
									isMapAlert: false
								})
							)
							setTimeout(() => {
								dispatch(displayAlert(false))
							}, 3000)
							dispatch(
								fetchResultsFileURLByJobId({
									jobId: selectedJobId,
									format: ""
								})
							).then((data) => {
								dispatch(fetchJobsList())
								window.open(data.payload.output_url_file)
								dispatch(
									setAlertPayload({
										code: "SUCCESS_DOWNLOADING_MESSAGE",
										isMapAlert: false
									})
								)
								setTimeout(() => {
									dispatch(displayAlert(false))
								}, 3000)
							})
							e.currentTarget.blur()
						}}
					>
						<span className="download__text">
							{t("Download file")}
						</span>
						<DownloadOutlined style={{ fontSize: "20px" }} />
					</Button>
				</div>
				<div className="title__container">
					{isResultsFetching ? (
						<Skeleton.Button />
					) : (
						<Button
							className="axa-button__secondary seeAllLocationsOnMap__button axa-btn-sm"
							data-testid="see-all-locations-on-map--testid"
							type="secondary"
							size="small"
							onMouseEnter={() => setOnHover(true)}
							onMouseLeave={() => setOnHover(false)}
							disabled={
								jobResultsSelector.length === 0 ||
								isResultsFetching
							}
							onClick={(e) => {
								e.currentTarget.blur()
								dispatch(
									setSelectedRowKeys(
										jobResultsSelector.map((loc) => loc.id)
									)
								)
								dispatch(fetchResultsByLocationIds()).then(
									(data) => {
										if (
											data.type ===
											fetchResultsByLocationIds.fulfilled
												.type
										) {
											dispatch(setSelectedRowKeys([]))
											setDisplayAllLocationsOnMapDrawer(
												true
											)
										}
										setOnHover(false)
									}
								)
							}}
						>
							See all locations on map
							<span
								disabled={
									jobResultsSelector.length === 0 ||
									isResultsFetching
								}
								className={`beta__badge${
									!onHover ? "__secondary" : ""
								}`}
							>
								{" "}
								beta
							</span>
						</Button>
					)}
				</div>
			</div>
			<div
				className={`header__content_${
					isDesktop ? "row" : "column__mobile"
				}`}
			>
				<div className="header__content_column">
					<GeneralsInformations />
					{display && isDesktop && (
						<div className="location-information-display">
							<div className="location-information-left-part">
								<GeocodingInformations
									isLoading={isResultsFetching}
								/>
								<ByCountryInformations
									isLoading={isResultsFetching}
								/>
							</div>
						</div>
					)}
				</div>
				<div>
					<ProcessingInformations />
					{display && isDesktop && (
						<div className="location-information-display">
							<div className="location-information-right-part">
								{!areWeTestingWithJest() && (
									<QualityMap isLoading={isResultsFetching} />
								)}
							</div>
						</div>
					)}
				</div>
				{!isDesktop && (
					<div>
						<GeocodingInformations isLoading={isResultsFetching} />
						{!areWeTestingWithJest() && (
							<QualityMap isLoading={isResultsFetching} />
						)}
						<ByCountryInformations isLoading={isResultsFetching} />
					</div>
				)}
			</div>
		</div>
	)
}

LocationInformations.propTypes = {
	setDisplayAllLocationsOnMapDrawer: PropTypes.func.isRequired,
	selectedJobName: PropTypes.string,
	jobName: PropTypes.string
}

LocationInformations.defaultProps = {
	selectedJobName: "",
	jobName: ""
}

export default LocationInformations
