import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Typography, Button } from "antd"

import { useSelector } from "react-redux"

import parse from "html-react-parser"

import WarningModal from "components/warningModal/WarningModal"

import { geocodedDataRaw as geocodedDataRawSlice } from "features/footprintSlice"

const { Paragraph, Text } = Typography

const MoreModeModal = ({ onDismiss, isVisible }) => {
	const { t } = useTranslation()

	const currentGeocodedDataRaw = useSelector(geocodedDataRawSlice)

	const locationTitle = ["USA", "FRA"].includes(
		currentGeocodedDataRaw && currentGeocodedDataRaw[0]?.address?.country
	)

	return (
		<WarningModal
			size="extralarge"
			title={
				t(
					`learn_more_mode_info_modal_${currentGeocodedDataRaw[0]?.backend_helper_mode}`
				) +
				(locationTitle
					? ` - ${currentGeocodedDataRaw[0]?.address?.country.slice(
							0,
							2
					  )} location`
					: "")
			}
			isWarningVisible={isVisible}
			handleConfirm={() => {
				onDismiss()
			}}
			handleCancel={() => {
				onDismiss()
			}}
			actions={[
				<div key={1} className="popup_button">
					<Button
						key="cancel"
						className="axa-button"
						type="primary"
						size="large"
						onClick={() => onDismiss()}
					>
						{t("Go Back To Geocoding Map").toUpperCase()}
					</Button>
				</div>
			]}
		>
			{currentGeocodedDataRaw.length > 0 &&
				currentGeocodedDataRaw[0]?.available_processing_modes?.map(
					(mode) => (
						<div key={mode} className="popup">
							<Paragraph className="popup_title">
								{t(mode)}
							</Paragraph>
							<Paragraph>
								{t(
									`${mode}.popup.subtitle${
										locationTitle && mode === "site"
											? `.${currentGeocodedDataRaw[0]?.address?.country.toLowerCase()}`
											: ""
									}`
								)}
							</Paragraph>
							<Text className="popup_actions">
								{parse(t(`${mode}.popup.action`))}
							</Text>
						</div>
					)
				)}
		</WarningModal>
	)
}

MoreModeModal.propTypes = {
	onDismiss: PropTypes.func,
	isVisible: PropTypes.bool
}

MoreModeModal.defaultProps = {
	onDismiss: () => {},
	isVisible: false
}

export default MoreModeModal
