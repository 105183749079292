import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import LocusEndpointURLBuilder from "helpers/LocusEndpointURLBuilder"
import { FilterAndSearchJobs, FilteringBy } from "helpers/FilterResultsHandler"
import { generateHeadersWithAuthorization } from "helpers/Auth"
import { postBulkService } from "features/footprintSlice"

const LOCUS_JOBS_ENDPOINT = process.env.REACT_APP_LOCUS_JOBS_ENDPOINT
const LOCUS_RESULTS_FILE_ENDPOINT =
	process.env.REACT_APP_LOCUS_RESULTS_FILE_ENDPOINT
const SLICE_PREFIX = "jobs"

export const INIT_STATE = {
	isCreatingFileError: false,
	isCreatingFileLink: false,
	isDeletingJob: false,
	isFetching: false,
	jobsList: [],
	filteredJobsList: [],
	selectedJob: undefined,
	displayRefresh: false,
	filteredBy: {
		filters: {
			onlyReadyToReview: false,
			onlyOpenAccounts: false
		},
		manual: ""
	}
}

// GET Fetch jobs @/jobs
export const fetchJobsList = createAsyncThunk(
	`${SLICE_PREFIX}/fetchJobsList`,
	async (data, { getState, fulfillWithValue, rejectWithValue }) => {
		const settings = {
			method: "GET",
			headers: generateHeadersWithAuthorization(getState())
		}
		try {
			const response = await fetch(
				LocusEndpointURLBuilder(LOCUS_JOBS_ENDPOINT),
				settings
			)
			if (response.status === 401) {
				return rejectWithValue(await response.json(), {
					status: response.status,
					location: { pathname: "/", search: "", hash: "", query: {} }
				})
			}
			if (response.status === 200 || response.status === 201) {
				return fulfillWithValue(await response.json(), {
					status: response.status
				})
			}
			throw response
		} catch (error) {
			return rejectWithValue(await error.json(), {
				status: error.status
			})
		}
	}
)

// GET A job download link @/jobs/output_file
export const fetchResultsFileURLByJobId = createAsyncThunk(
	`${SLICE_PREFIX}/fetchResultsFileByJobId`,
	async (
		{ jobId, format },
		{ getState, fulfillWithValue, rejectWithValue }
	) => {
		const settings = {
			method: "GET",
			headers: generateHeadersWithAuthorization(getState())
		}
		const url = new URL(
			LocusEndpointURLBuilder(
				`${LOCUS_JOBS_ENDPOINT}/${LOCUS_RESULTS_FILE_ENDPOINT}`
			)
		)

		const params = {
			job_id: jobId,
			webui: true,
			// format: format ? "flood_le" : ""
			format
		}
		Object.keys(params).forEach((key) =>
			url.searchParams.append(key, params[key])
		)

		try {
			const response = await fetch(url, settings)
			if (response.status === 401) {
				return rejectWithValue(await response.json(), {
					status: response.status,
					location: { pathname: "/", search: "", hash: "", query: {} }
				})
			}
			if (response.status === 200 || response.status === 201) {
				return fulfillWithValue(await response.json(), {
					status: response.status
				})
			}
			throw response
		} catch (error) {
			return rejectWithValue(await error.json(), { status: error.status })
		}
	}
)

// DELETE A job @/jobs
export const deleteJobByJobId = createAsyncThunk(
	`${SLICE_PREFIX}/deleteJobByJobId`,
	async (jobId, { getState, fulfillWithValue, rejectWithValue }) => {
		const settings = {
			method: "DELETE",
			headers: generateHeadersWithAuthorization(getState())
		}
		const url = new URL(LocusEndpointURLBuilder(`${LOCUS_JOBS_ENDPOINT}`))
		const params = {
			job_id: jobId,
			webui: true
		}
		Object.keys(params).forEach((key) =>
			url.searchParams.append(key, params[key])
		)

		try {
			const response = await fetch(url, settings)
			if (response.status === 401) {
				return rejectWithValue(await response.json(), {
					status: response.status,
					location: { pathname: "/", search: "", hash: "", query: {} }
				})
			}
			if (response.status === 403) {
				rejectWithValue({
					code: "JOB_E403",
					description:
						"The job doesn't belong to the requesting user or the user is not registered into the app.",
					details: []
				})
			}
			if (response.status === 409) {
				rejectWithValue({
					code: "JOB_E409",
					description: "The job status doesn't allow to be removed.s",
					details: []
				})
			}
			if (response.status === 204) {
				return fulfillWithValue(
					{},
					{
						status: response.status
					}
				)
			}
			throw response
		} catch (error) {
			return rejectWithValue(await error.json(), { status: error.status })
		}
	}
)

export const jobsSlice = createSlice({
	name: SLICE_PREFIX,
	initialState: INIT_STATE,
	reducers: {
		clearJobs: () => ({ ...INIT_STATE }),
		clearSelectedJob: (state) => ({
			...state,
			selectedJob: INIT_STATE.selectedJob
		}),
		setSelectedJob: (state, action) => ({
			...state,
			selectedJob: action.payload
		}),
		filterJobs: (state, action) => ({
			...state,
			filteredBy: {
				...state.filteredBy,
				manual: action.payload
			},
			filteredJobsList: FilteringBy(action.payload, state.jobsList, [
				"account_name",
				"account_reference"
			])
		}),
		filterJobsByManualInput: (state, action) => {
			const { filteredList } = FilterAndSearchJobs(
				JSON.parse(JSON.stringify(state.filteredBy)),
				JSON.parse(JSON.stringify(state.jobsList)),
				action.payload
			)
			return {
				...state,
				filteredBy: {
					...state.filteredBy,
					manual: action.payload
				},
				filteredJobsList: filteredList
			}
		},
		setFilterByOnlyReadyToReview: (state, action) => ({
			...state,
			filteredBy: {
				...state.filteredBy,
				filters: {
					onlyReadyToReview: action.payload,
					onlyOpenAccounts: false
				}
			}
		}),
		setFilterByOnlyOpenAccounts: (state, action) => ({
			...state,
			filteredBy: {
				...state.filteredBy,
				filters: {
					onlyReadyToReview: false,
					onlyOpenAccounts: action.payload
				}
			}
		}),
		filterJobsByFilters: (state) => {
			const { filteredList } = FilterAndSearchJobs(
				JSON.parse(JSON.stringify(state.filteredBy)),
				JSON.parse(JSON.stringify(state.jobsList)),
				state.filteredBy.manual
			)
			return {
				...state,
				filteredJobsList: filteredList
			}
		},
		resetQuickFilters: (state) => ({
			...state,
			filteredJobsList: state.jobsList
		})
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchJobsList.pending, (state) => {
				state.isFetching = true
			})
			.addCase(fetchJobsList.fulfilled, (state, action) => {
				state.isFetching = false
				state.jobsList = [].concat(action.payload.items)
				state.filteredJobsList = state.jobsList
				state.displayRefresh =
					state.filteredJobsList.filter(
						(f) =>
							f.validation === "UPLOADED" ||
							f.validation === "IN_PROGRESS"
					).length > 0
			})
			.addCase(fetchJobsList.rejected, (state) => {
				state.isFetching = false
			})
			.addCase(fetchResultsFileURLByJobId.pending, (state) => {
				state.isCreatingFileLink = true
				state.isCreatingFileError = false
			})
			.addCase(fetchResultsFileURLByJobId.fulfilled, (state) => {
				state.isCreatingFileLink = false
				state.isCreatingFileError = false
			})
			.addCase(fetchResultsFileURLByJobId.rejected, (state) => {
				state.isCreatingFileLink = false
				state.isCreatingFileError = true
			})
			.addCase(postBulkService.pending, (state) => {
				state.displayRefresh = true
			})
			.addCase(postBulkService.fulfilled, (state) => {
				state.displayRefresh = false
			})
			.addCase(postBulkService.rejected, (state) => {
				state.displayRefresh = false
			})
			.addCase(deleteJobByJobId.pending, (state) => {
				state.isDeletingJob = true
			})
			.addCase(deleteJobByJobId.fulfilled, (state) => {
				state.isDeletingJob = false
			})
			.addCase(deleteJobByJobId.rejected, (state) => {
				state.isDeletingJob = false
			})
	}
})

export const {
	clearJobs,
	clearSelectedJob,
	setSelectedJob,
	filterJobs,
	resetQuickFilters,
	setFilterByOnlyReadyToReview,
	setFilterByOnlyOpenAccounts,
	filterJobsByFilters,
	filterJobsByManualInput
} = jobsSlice.actions

/* istanbul ignore next */
export const isCreatingFileError = (state) => state.jobs.isCreatingFileError
/* istanbul ignore next */
export const isCreatingFileLink = (state) => state.jobs.isCreatingFileLink
/* istanbul ignore next */
export const isFetching = (state) => state.jobs.isFetching
/* istanbul ignore next */
export const jobsList = (state) => state.jobs.jobsList
/* istanbul ignore next */
export const filteredJobsList = (state) => state.jobs.filteredJobsList
/* istanbul ignore next */
export const selectedJob = (state) => state.jobs.selectedJob
/* istanbul ignore next */
export const displayRefresh = (state) => state.jobs.displayRefresh
/* istanbul ignore next */
export const filteredByOnlyReadyToReview = (state) =>
	state.jobs.filteredBy.filters.onlyReadyToReview
/* istanbul ignore next */
export const filteredByOpenAccounts = (state) =>
	state.jobs.filteredBy.filters.onlyOpenAccounts

export default jobsSlice.reducer
