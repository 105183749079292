import React from "react"
import PropTypes from "prop-types"
import { Button } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import { useSelector } from "react-redux"
import "./SkipFeedback.css"
import { VALIDATION_STATUS_TYPE } from "helpers/constants"
import { isPostingResultEdit as isPostingResultEditSlice } from "features/footprintSlice"

/**
 *
 * Creates SkipFeedback component
 * Popover appearing when user do not validate a location on review mode
 *
 * @props {function} 		onClose: handle the close of the popover
 * @props {function} 		saveFeedbackHandler: saving the reason why user has not validate the location
 *
 * @returns SkipFeedback
 */
const SkipFeedback = (props) => {
	const { onClose, saveFeedbackHandler } = props
	const isPostingResultEdit = useSelector(isPostingResultEditSlice)
	return (
		<div className="skipFeedback__container">
			<div className="skipFeedback__description__container">
				<span className="skipFeedback__description">
					The information shown in this location was…
				</span>
				<div className="skipFeedback__button__container">
					<Button
						className="axa-button skipFeedback__button--text"
						type="primary"
						disabled={isPostingResultEdit}
						onClick={() => {
							saveFeedbackHandler(
								VALIDATION_STATUS_TYPE.REVIEWED_WRONG
							)
						}}
					>
						Wrong
					</Button>
					<Button
						className="axa-button skipFeedback__button--text"
						type="primary"
						disabled={isPostingResultEdit}
						onClick={() => {
							saveFeedbackHandler(
								VALIDATION_STATUS_TYPE.REVIEWED_INCONCLUSIVE
							)
						}}
					>
						Inconclusive
					</Button>
				</div>
			</div>
			<CloseOutlined
				className="skipFeedback__close"
				data-testid="skipFeedback__close-testid"
				onClick={onClose}
			/>
		</div>
	)
}

SkipFeedback.propTypes = {
	onClose: PropTypes.func.isRequired,
	saveFeedbackHandler: PropTypes.func.isRequired
}

export default SkipFeedback
