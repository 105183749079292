import React, { useState, useEffect } from "react"

import { Typography } from "antd"

import "./LoaderComponent.css"

const LoaderComponent = () => {
	let dot = 1
	const [dotText, setDotText] = useState(".")

	useEffect(() => {
		const timer = setInterval(() => {
			setDotText(".".repeat(dot))
			dot %= 3
			dot += 1
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	return (
		<div className="box">
			<div className="loadingbox">
				<div className="loadingline">
					<div className="loadinglineactive" />
				</div>
			</div>
			<Typography className="text">Loading{dotText}</Typography>
		</div>
	)
}

export default LoaderComponent
