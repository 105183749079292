/* eslint-disable no-underscore-dangle */
import PropTypes from "prop-types"
import React, { useRef, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import InfoIcon from "assets/IconInfo"
import useMediaQuery from "styles/useMediaQuery"
import "./LocationInformations.css"

import { isFetching as resultsIsFetching } from "features/jobsResultsSlice"
import { Skeleton } from "antd"

const BoxInfo = ({ title, text, style, tooltipTitle }) => {
	const isResultsFetching = useSelector(resultsIsFetching)
	const isDesktop = useMediaQuery("(min-width: 1100px)")

	const ref = useRef(null)
	const [line, setLine] = useState(false)

	useEffect(() => {
		if (ref?.current?.offsetLeft < 70) setLine(true)
	}, [ref])

	return (
		<div
			ref={ref}
			className={`information__box${isDesktop ? "" : "__mobile"} ${
				line ? "line-left" : ""
			}`}
			style={{ paddingLeft: style ? "0" : "" }}
		>
			<div className="information__title">
				{title} {tooltipTitle ? <InfoIcon title={tooltipTitle} /> : ""}
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					fontSize: tooltipTitle ? "16px" : "24px"
				}}
			>
				{isResultsFetching ? <Skeleton.Input /> : text}
			</div>
			<div
				style={{
					border: "solid 0.5px #00008f",
					display: "flex",
					justifyContent: "center",
					alignItems: "flex-end"
				}}
			/>
		</div>
	)
}

BoxInfo.propTypes = {
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.object
	]).isRequired,
	title: PropTypes.string.isRequired,
	tooltipTitle: PropTypes.string,
	style: PropTypes.bool
}

BoxInfo.defaultProps = {
	style: false,
	tooltipTitle: ""
}

export default BoxInfo
