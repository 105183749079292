import { createSlice } from "@reduxjs/toolkit"
import { postChangeFootprintMode } from "features/footprintSlice"

export const INIT_STATE = {
	isFlyTo: false,
	marker: {},
	unselectedMarkers: [],
	selectedMarker: {},
	isMarkerMoving: false,
	onMarkerMoveCoords: {},
	isPosting: false,
	isClickingUnselected: false,
	displayClientCoordinatesPin: false,
	displayBannerDataSourceInfo: false,
	isLoadingMapData: false,
	isDisplayingToast: false
}

const SLICE_PREFIX = "map"

export const mapSlice = createSlice({
	name: SLICE_PREFIX,
	initialState: INIT_STATE,
	reducers: {
		triggerFlyTo: (state, action) => {
			if (typeof action.payload !== "boolean") {
				return {
					...state,
					isFlyTo: state.isFlyTo
				}
			}
			return {
				...state,
				isFlyTo: action.payload
			}
		},
		resetMarker: (state) => ({
			...state,
			marker: INIT_STATE.marker,
			selectedMarker: {}
		}),
		setMarker: (state, action) => ({
			...state,
			marker: action.payload
		}),
		setUnselectedMarkers: (state, action) => ({
			...state,
			unselectedMarkers: [...state.unselectedMarkers, action.payload]
		}),
		resetUnselectedMarkers: (state) => ({
			...state,
			unselectedMarkers: []
		}),
		setSelectedMarker: (state, action) => ({
			...state,
			selectedMarker: action.payload
		}),

		setMarkerMoving: (state, action) => {
			if (typeof action.payload !== "boolean") {
				return {
					...state,
					isMarkerMoving: state.isMarkerMoving
				}
			}
			return {
				...state,
				isMarkerMoving: action.payload
			}
		},
		setOnMarkerMoveCoords: (state, action) => ({
			...state,
			onMarkerMoveCoords: {
				...state.onMarkerMoveCoords,
				lngLat: action.payload
			}
		}),
		resetOnMarkerMoveCoords: (state) => ({
			...state,
			onMarkerMoveCoords: INIT_STATE.onMarkerMoveCoords
		}),
		setDisplayClientCoordinatesPin: (state) => ({
			...state,
			displayClientCoordinatesPin: !state.displayClientCoordinatesPin
		}),
		hideClientCoordinatesPin: (state) => ({
			...state,
			displayClientCoordinatesPin: false
		}),
		setIsClickingUnselected: (state, action) => ({
			...state,
			isClickingUnselected: action.payload
		}),
		setIsLoadingMapData: (state, action) => ({
			...state,
			isLoadingMapData: action.payload
		}),
		setIsDisplayingBannerDataSource: (state, action) => ({
			...state,
			displayBannerDataSourceInfo: action.payload
		}),
		setIsDisplayingToast: (state, action) => ({
			...state,
			isDisplayingToast: action.payload
		})
	},
	extraReducers: (builder) => {
		builder.addCase(postChangeFootprintMode.fulfilled, (state) => {
			if (state.isLoadingMapData !== true) {
				state.marker = INIT_STATE.marker
			}
			state.isLoadingMapData = false
		})
	}
})

export const {
	triggerFlyTo,
	resetMarker,
	setMarker,
	setUnselectedMarkers,
	resetUnselectedMarkers,
	setSelectedMarker,
	setMarkerMoving,
	setOnMarkerMoveCoords,
	resetOnMarkerMoveCoords,
	setDisplayClientCoordinatesPin,
	hideClientCoordinatesPin,
	setIsClickingUnselected,
	setIsLoadingMapData,
	setIsDisplayingToast,
	setIsDisplayingBannerDataSource
} = mapSlice.actions

/* istanbul ignore next */
export const isFlyTo = (state) => state.map.isFlyTo
/* istanbul ignore next */
export const marker = (state) => state.map.marker
/* istanbul ignore next */
export const unselectedMarkers = (state) => state.map.unselectedMarkers
/* istanbul ignore next */
export const selectedMarker = (state) => state.map.selectedMarker
/* istanbul ignore next */
export const isMarkerMoving = (state) => state.map.isMarkerMoving
/* istanbul ignore next */
export const onMarkerMoveCoords = (state) => state.map.onMarkerMoveCoords
/* istanbul ignore next */
export const displayClientCoordinatesPin = (state) =>
	state.map.displayClientCoordinatesPin
/* istanbul ignore next */
export const displayBannerDataSourceInfo = (state) =>
	state.map.displayBannerDataSourceInfo
/* istanbul ignore next */
export const isClickingUnselected = (state) => state.map.isClickingUnselected
/* istanbul ignore next */
export const isDisplayingToast = (state) => state.map.isDisplayingToast

export default mapSlice.reducer
