import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { generateHeadersWithAuthorization } from "helpers/Auth"
import LocusEndpointURLBuilder from "helpers/LocusEndpointURLBuilder"

/**
 * Slice responsible for handling backbone state:
 *  - Requesting Backend versions status (API, Engine)
 */

export const SLICE_PREFIX = "backbone"

export const INIT_STATE = {
	versions: {
		engine: undefined,
		isFetching: false
	}
}

export const fetchVersions = createAsyncThunk(
	`${SLICE_PREFIX}/fetchVersions`,
	async (values, { getState, fulfillWithValue, rejectWithValue }) => {
		const settings = {
			method: "GET",
			headers: generateHeadersWithAuthorization(getState())
		}
		const url = new URL(
			LocusEndpointURLBuilder(
				process.env.REACT_APP_LOCUS_VERSIONS_ENDPOINT
			)
		)
		try {
			const response = await fetch(url, settings)
			if (response.status === 401) {
				return rejectWithValue(await response.json(), {
					status: response.status,
					location: {
						pathname: "/",
						hash: "",
						query: {}
					}
				})
			}
			if (response.status === 200) {
				return fulfillWithValue(await response.json(), {
					status: response.status
				})
			}
			throw response
		} catch (error) {
			return rejectWithValue(await error.json(), { status: error.status })
		}
	}
)

export const backboneSlice = createSlice({
	name: SLICE_PREFIX,
	initialState: INIT_STATE,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchVersions.pending, (state) => {
				state.versions.isFetching = true
			})
			.addCase(fetchVersions.fulfilled, (state, action) => {
				state.versions.isFetching = false
				state.versions = { ...state.versions, ...action.payload }
			})
			.addCase(fetchVersions.rejected, (state) => {
				state.versions.isFetching = false
			})
	}
})

/* istanbul ignore next */
export const engineVersion = (state) => state.backbone.versions.engine
/* istanbul ignore next */
export const allVersions = (state) => state.backbone.versions

export default backboneSlice.reducer
