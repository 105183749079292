export const onClickMarker = (event, data, setHoverTooltip) => {
	const clickedPin = {
		principal: {
			poi_name:
				data.source !== "Bing" ? data?.poi?.place_name : undefined,
			name: data.source === "Bing" ? data?.poi?.place_name : undefined,
			client: data.poi_metadata?.location_name_norm ?? undefined,
			service:
				data.source === "FoursquarePlace"
					? "Foursquare"
					: data.source ?? undefined,
			category:
				data.poi?.categories?.length > 0
					? data.poi?.categories
							?.map((category, i) =>
								i > 0 ? ` ${category.name}` : category.name
							)
							.toString()
					: undefined,
			poi_address:
				data.source !== "Bing" ? data.address.standard : undefined,
			address: data.source === "Bing" ? data.address.standard : undefined
		},
		secondary: {
			flag_quality:
				data?.poi_metadata?.quality_flag?.toString() ?? undefined,
			street_name_overall_smilarity:
				data?.poi_metadata?.street_similarity_set ?? undefined,
			street_name_word_similarity:
				data?.poi_metadata?.place_name_similarity_word ?? undefined,
			place_name_similarity_score:
				data?.poi_metadata?.place_name_similarity_sort ?? undefined
		},
		data
	}
	setHoverTooltip({
		x: event.originalEvent.x,
		y: event.originalEvent.y,
		clickedPin
	})
}

export const onClickMarkerWithoutPoi = (event, data, setHoverTooltip) => {
	const clickedPin = {
		principal: {
			poi_name: data.source !== "Bing" ? data?.place_name : undefined,
			name: data.source === "Bing" ? data?.place_name : undefined,
			client: data.location_name_norm ?? undefined,
			service:
				data.source === "FoursquarePlace"
					? "Foursquare"
					: data.source ?? undefined,
			category:
				data.categories?.length > 0
					? data.categories
							?.map((category, i) =>
								i > 0 ? ` ${category.name}` : category.name
							)
							.toString()
					: undefined,
			poi_address:
				data.source !== "Bing" ? data.address.standard : undefined,
			address: data.source === "Bing" ? data.address.standard : undefined
		},
		secondary: {
			flag_quality: data?.quality_flag?.toString() ?? undefined,
			street_name_overall_smilarity:
				data?.street_similarity_set ?? undefined,
			street_name_word_similarity:
				data?.place_name_similarity_word ?? undefined,
			place_name_similarity_score:
				data?.place_name_similarity_sort ?? undefined
		},
		data
	}
	setHoverTooltip({
		x: event.originalEvent.x,
		y: event.originalEvent.y,
		clickedPin
	})
}
