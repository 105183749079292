/* eslint-disable no-underscore-dangle */
/* eslint-disable new-cap */
import Fuse from "fuse.js"
import { isEmpty } from "lodash"
import { CONFIDENCE_RANGE, JOBS_STATUS_TYPE } from "./constants"
/**
 *
 * Filter an array of objects
 *
 * @param {string}  query
 * @param {array}   inputList
 * @param {array}   location   Following https://fusejs.io/api/options.html#keys
 *
 * @returns {array} the filtered array
 */
export const FilteringBy = (query, inputList, location, threshold = 0.4) => {
	if (isEmpty(inputList)) return []
	if (isEmpty(location) || isEmpty(query)) return inputList

	const options = {
		threshold,
		useExtendedSearch: true,
		minMatchCharLength: 1,
		findAllMatches: true,
		keys: location
	}

	const fuse = new Fuse([...JSON.parse(JSON.stringify(inputList))], options)

	return fuse.search(query).map((result) => result.item)
}

/**
 *
 * Sorting an array of locations
 *
 * @param {array}   inputList
 * @param {array}   limit       (default: 10) limits the sorted output list length
 *
 * @returns {array} the sorted array
 */

export const SortingByTIVAndLimitTo = (inputList, limit = 10) => {
	if (isEmpty(inputList)) return []
	const _inputList = [...JSON.parse(JSON.stringify(inputList))]
	_inputList.sort((a, b) => {
		if (!a.input_data.insured_value) {
			return 1
		}
		if (!b.input_data.insured_value) {
			return 0
		}
		const numericValueA = Number(
			a.input_data.insured_value.replace(/[^0-9.-]+/g, "")
		)
		const numericValueB = Number(
			b.input_data.insured_value.replace(/[^0-9.-]+/g, "")
		)
		if (numericValueA <= numericValueB) {
			return 1
		}
		return -1
	})

	if (limit > 0) {
		return _inputList.slice(0, limit)
	}
	return _inputList
}

export const validationStatusPredicateBuilder = (status) =>
	status
		? {
				validation_status: status
		  }
		: undefined

export const confidencePredicateBuilder = (confidences) => {
	if (confidences && confidences.length === 0) {
		return { "geo_data.confidence": "" }
	}
	if (confidences && confidences.length >= 1 && Array.isArray(confidences)) {
		return {
			$or: [
				...confidences.flatMap((confidence) => {
					if (confidence === CONFIDENCE_RANGE.HIGH) {
						return [{ "geo_data.confidence": "5" }]
					}
					if (confidence === CONFIDENCE_RANGE.MEDIUM) {
						return [
							{ "geo_data.confidence": "3" },
							{ "geo_data.confidence": "4" }
						]
					}
					if (confidence === CONFIDENCE_RANGE.LOW) {
						return [
							{ "geo_data.confidence": "2" },
							{ "geo_data.confidence": "1" }
						]
					}
					return [{ "geo_data.confidence": "" }]
				})
			]
		}
	}
	return undefined
}

export const hasFootPrintPredicateBuilder = (hasFootPrints) => {
	if (hasFootPrints && hasFootPrints.length === 0) {
		return { "geo_data.has_footprints": "" }
	}
	if (
		hasFootPrints &&
		hasFootPrints.length >= 1 &&
		Array.isArray(hasFootPrints)
	) {
		return {
			$or: [
				...hasFootPrints.flatMap((hasFootPrint) => [
					{
						"geo_data.has_footprints": hasFootPrint
					}
				])
			]
		}
	}
	return undefined
}

export const multiCriteriaPredicateBuilder = (criterias, key) => {
	if (!key) return undefined
	if (criterias && criterias.length === 0) return { [key]: "" }
	if (criterias && criterias.length >= 1 && Array.isArray(criterias)) {
		return {
			$or: [
				...criterias.map((criteria) => ({
					[key]: criteria
				}))
			]
		}
	}
	return undefined
}

export const FilterAndSearch = (filteredBy, results, manInput) => {
	let tmp = []
	if (
		// filters: {"key": value}
		// item = ["key", "value"]
		// eslint-disable-next-line no-unused-vars
		Object.entries(filteredBy.filters).filter((item) => !!item[1])
			.length === 0
	) {
		tmp = results
	} else {
		tmp = FilteringBy(
			{
				$and: [
					multiCriteriaPredicateBuilder(
						filteredBy.filters.country,
						"geo_data.address.country_iso2"
					),
					multiCriteriaPredicateBuilder(
						filteredBy.filters.resolution,
						"geo_data.resolution"
					),
					confidencePredicateBuilder(filteredBy.filters.confidence),
					hasFootPrintPredicateBuilder(
						filteredBy.filters.has_footprints
					),
					multiCriteriaPredicateBuilder(
						filteredBy.filters.status,
						"validation_status"
					)
				].filter((predicate) => predicate)
			},
			results,
			[
				"geo_data.address.country_iso2",
				"geo_data.resolution",
				"validation_status",
				"geo_data.confidence",
				"geo_data.address",
				"geo_data.has_footprints",
				"geo_data.address.country"
			],
			-1 // Comments from https://stackoverflow.com/a/62627096/3900714
		)
	}
	if (manInput) {
		tmp = FilteringBy(manInput, JSON.parse(JSON.stringify(tmp)), [
			"input_data.customer",
			"input_data.address",
			"input_data.id",
			"geo_data.address.state",
			"geo_data.address.county",
			"geo_data.has_footprints",
			"geo_data.address.country_iso2"
		])
	}
	return { filteredList: tmp, manInput }
}

export const FilterAndSearchJobs = (filteredBy, results, manInput) => {
	let tmp = []
	if (
		// filters: {"key": value}
		// item = ["key", "value"]
		// eslint-disable-next-line no-unused-vars
		Object.entries(filteredBy.filters).filter((item) => !!item[1])
			.length === 0
	) {
		tmp = results
	} else {
		tmp = FilteringBy(
			{
				$and: [
					filteredBy.filters.onlyReadyToReview
						? {
								validation: JOBS_STATUS_TYPE.TO_BE_REVIEWED
						  }
						: undefined,
					filteredBy.filters.hideReviewedJobs
						? {
								validation: JOBS_STATUS_TYPE.IN_PROGRESS
						  }
						: undefined
				].filter((predicate) => predicate)
			},
			results,
			["validation"]
		)
	}
	if (manInput) {
		tmp = FilteringBy(manInput, JSON.parse(JSON.stringify(tmp)), [
			"account_name",
			"account_reference"
		])
	}
	return { filteredList: tmp, manInput }
}

export const FilterByResolutions = (resolutionsAvailable, results) => {
	let tmp = []
	if (!resolutionsAvailable || resolutionsAvailable.length === 0)
		return { filteredList: results }

	tmp = FilteringBy(
		{
			$and: [
				multiCriteriaPredicateBuilder(
					resolutionsAvailable,
					"geo_data.resolution"
				)
			].filter((predicate) => predicate)
		},
		results,
		["geo_data.resolution"],
		-1 // Comments from https://stackoverflow.com/a/62627096/3900714
	)
	return { filteredList: tmp, resolutionsAvailable }
}
