import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Typography, Input, Button } from "antd"

import { SearchOutlined, UploadOutlined } from "@ant-design/icons"

import { UploadPopover } from "components/uploadPopover/UploadPopover"
import {
	filterJobsByManualInput,
	setFilterByOnlyReadyToReview,
	filterJobsByFilters,
	filteredByOnlyReadyToReview as filteredByOnlyReadyToReviewSlice
} from "features/jobsSlice"
import "styles/pages.css"
import "pages/jobs/styles/jobs.css"
import QuickFilter from "components/quickFilter/QuickFilter"

import useMediaQuery from "styles/useMediaQuery"

const { Title } = Typography
const { Search } = Input

const JobsHeader = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const isDesktop = useMediaQuery("(min-width: 1100px)")

	const [searchingString, setSearchingString] = useState("")
	const filteredByOnlyReadyToReview = useSelector(
		filteredByOnlyReadyToReviewSlice
	)

	const prefix = (
		<SearchOutlined
			style={{
				fontSize: 16,
				color: "#00008F"
			}}
		/>
	)

	return (
		<div className="page__actionsContainer">
			<div className={`job__leftPart${!isDesktop ? "-column" : ""}`}>
				<div className="action__searchContainer">
					<Title className="page__subtitle" level={5}>
						{t("Search a file")}
					</Title>
					<div className="action__searchBar">
						<Search
							size="large"
							allowClear
							className="searchContainer__input--width"
							placeholder="Search by account name or reference"
							onSearch={(v) => {
								dispatch(filterJobsByManualInput(v))
							}}
							onChange={(e) => {
								setSearchingString(e.target.value)
							}}
							enterButton={() =>
								dispatch(
									filterJobsByManualInput(searchingString)
								)
							}
							addonAfter={undefined}
							prefix={prefix}
						/>
						<Button
							className="uploadPopover__button axa-button"
							type="primary"
							size="large"
							onClick={() =>
								dispatch(
									filterJobsByManualInput(searchingString)
								)
							}
						>
							{t("Search")}
						</Button>
					</div>
				</div>
				<QuickFilter
					onClickAction={() => {
						dispatch(
							setFilterByOnlyReadyToReview(
								!filteredByOnlyReadyToReview
							)
						)
						dispatch(filterJobsByFilters())
					}}
					filtered={filteredByOnlyReadyToReview}
					text="Show only ready to review"
					textFiltered="Display all jobs"
					width={isDesktop ? "50%" : ""}
				/>
			</div>
			<div className="page__actionContainer">
				<Title className="page__subtitle" level={5}>
					Upload a new file
				</Title>
				<UploadPopover>
					<Button
						className="uploadPopover__button axa-button"
						type="primary"
						size="large"
						icon={<UploadOutlined />}
					>
						Upload a new file
					</Button>
				</UploadPopover>
			</div>
		</div>
	)
}

export default JobsHeader
