/* eslint-disable no-underscore-dangle */
import PropTypes from "prop-types"
import React from "react"
import InfoIcon from "assets/IconInfo"
import "./LocationInformations.css"
import { Skeleton } from "antd"

const MetricInfo = ({
	title,
	text,
	tooltipTitle,
	center,
	color,
	isLoading
}) => (
	<div className="metrics__box">
		<div className="information__title">
			{title} {tooltipTitle ? <InfoIcon title={tooltipTitle} /> : ""}
		</div>
		{isLoading ? (
			<Skeleton.Input />
		) : (
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: center ? "center" : "",
					fontSize: tooltipTitle ? "16px" : "36px"
				}}
			>
				<div className="square__div">
					<div
						className="square"
						style={{
							background:
								parseInt(text, 10) > 1 ? color : "#e5e5e5"
						}}
					/>
					<div
						className="square"
						style={{
							background:
								parseInt(text, 10) >= 20 ? color : "#e5e5e5"
						}}
					/>
					<div
						className="square"
						style={{
							background:
								parseInt(text, 10) >= 40 ? color : "#e5e5e5"
						}}
					/>
					<div
						className="square"
						style={{
							background:
								parseInt(text, 10) >= 60 ? color : "#e5e5e5"
						}}
					/>
					<div
						className="square"
						style={{
							background:
								parseInt(text, 10) >= 80 ? color : "#e5e5e5"
						}}
					/>
				</div>
				<span
					style={{
						display: "flex"
					}}
				>
					{`${parseInt(text, 10)}%`}
				</span>
			</div>
		)}
	</div>
)

MetricInfo.propTypes = {
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.object
	]).isRequired,
	title: PropTypes.string.isRequired,
	tooltipTitle: PropTypes.string,
	center: PropTypes.bool,
	color: PropTypes.string,
	isLoading: PropTypes.bool
}

MetricInfo.defaultProps = {
	center: true,
	tooltipTitle: "",
	color: "#e5e5e5",
	isLoading: true
}

export default MetricInfo
