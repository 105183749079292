import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
	Button,
	Typography,
	List,
	Popover,
	Tooltip,
	Form,
	Input,
	Row,
	Col,
	Checkbox,
	Select,
	Switch
} from "antd"
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import { point, polygon, booleanPointInPolygon } from "@turf/turf"
import {
	clearFootprintData,
	addHocResult,
	isGeocoded as isGeocodedSlice,
	geocodingIsSubmittingFootprintChecker as geocodingIsSubmittingFootprintCheckerSlice,
	isPostingResultEdit as isPostingResultEditSlice,
	isLoadingAdditionalParcels as isLoadingAdditionalParcelsSlice,
	setIsGeocoding,
	postEditResult,
	patchAddressResult,
	setShowLoadingModalForFootprintModeSwitch,
	postChangeFootprintMode,
	FOOTPRINT_MODE,
	getFootprintByCoordinates,
	setFootprintIsPristine
} from "features/footprintSlice"
import {
	resetMarker,
	marker as markerSlice,
	resetOnMarkerMoveCoords,
	setDisplayClientCoordinatesPin,
	displayClientCoordinatesPin as displayClientCoordinatesPinSlice,
	setMarker,
	resetUnselectedMarkers,
	hideClientCoordinatesPin
} from "features/mapSlice"
import {
	selectedResults as selectedResultsSlice,
	setSelectedResultId,
	currentLocationIndex as currentLocationIndexSlice,
	locationIndexToZero,
	previousLocationIndex,
	nextLocationIndex,
	selectedJobId as selectedJobIdSlice,
	isSavingReviewState as isSavingReviewStateSlice,
	saveReviewState,
	resetFilteredByConfig,
	setCurrentFootprintMode,
	setCurrentModeDescription,
	setCurrentModeReasonKey,
	fetchResultsByJobId,
	setSaveReviewState,
	setCurrentLocationIndex,
	setSelectedResults,
	setSelectedRowKeys
} from "features/jobsResultsSlice"
import WarningModal from "components/warningModal/WarningModal"
import "./InfoControlPanel.css"
import { ALERTS_TYPE, VALIDATION_STATUS_TYPE } from "helpers/constants"
import LoadingModal from "components/loadingModal/LoadingModal"
import { toDisplayAsCurrencyValue } from "helpers/rulesHandler"
import currencyHandler from "helpers/currencyHandler"
import { generateCountriesCodeNameFlagByLocaleSortedByName } from "helpers/countriesHandler"
import { isValidCoordinates } from "helpers/Checkers"
import addressDisplayBuilder from "helpers/addressDisplayBuilder"
import { displayAlert } from "features/alertsSlice"
import { displayToast } from "components/alerts/Alerts"
import SkipFeedback from "./SkipFeedback"

const { Paragraph } = Typography
const { Option } = Select
/**
 *
 * Creates InfoControlPanel component
 * Side panel on review mode
 *
 * @props {function} 		onSaveHandler: handler for saving current review
 * @props {function} 		onHover: handler to hide InfoTooltip when Geocode outside of a selected parcel
 * @props {function} 		resetTooltip: handler to hide InfoTooltip when switching locations
 * @props {Array} 			drawnParcels: Array of locations (parcels)
 *
 * @returns InfoControlPanel
 */
const InfoControlPanel = (props) => {
	const {
		onSaveHandler,
		onHover,
		resetTooltip,
		drawnParcels,
		handleOkBackWarning
	} = props
	const [addressFormInstance] = Form.useForm()

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const selectedLocations = useSelector(selectedResultsSlice)
	const isSavingReviewState = useSelector(isSavingReviewStateSlice)
	const isGeocoded = useSelector(isGeocodedSlice)
	const currentLocationIndex = useSelector(currentLocationIndexSlice)
	const isSubmittingFootprintChecker = useSelector(
		geocodingIsSubmittingFootprintCheckerSlice
	)
	const isPostingResultEdit = useSelector(isPostingResultEditSlice)
	const isLoadingAdditionalParcels = useSelector(
		isLoadingAdditionalParcelsSlice
	)
	const selectedJobId = useSelector(selectedJobIdSlice)
	const [popoverVisible, setPopoverVisible] = useState(false)

	const marker = useSelector(markerSlice)
	const displayClientCoordinatesPin = useSelector(
		displayClientCoordinatesPinSlice
	)

	const renderExternalQueryLinks = (key, q) => {
		switch (key) {
			case "customer":
				if (selectedLocations) {
					// https://stackoverflow.com/a/47036373/3900714
					return (
						<a
							className="locationData__link"
							href={`https://www.google.com/maps/search/${encodeURIComponent(
								q
							)}/@${encodeURIComponent(
								selectedLocations[currentLocationIndex].geo_data
									.lat
							)},${encodeURIComponent(
								selectedLocations[currentLocationIndex].geo_data
									.lon
							)},16z`}
							target="_blank"
							rel="noopener noreferrer"
						>
							Look up in Google Places
						</a>
					)
				}
				/* istanbul ignore next */
				return null

			case "location":
				return (
					<a
						className="locationData__link"
						href={`https://maps.google.com/?q=${encodeURIComponent(
							q
						)}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						Look up in Google Maps
					</a>
				)
			case "address":
				return (
					<a
						className="locationData__link"
						href={`https://maps.google.com/?q=${encodeURIComponent(
							q
						)}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						Look up in Google Maps
					</a>
				)

			default:
				return null
		}
	}

	const resetBeforeMoveToNextLocation = () => {
		dispatch(clearFootprintData())
		dispatch(resetMarker())
		dispatch(resetUnselectedMarkers())
		dispatch(resetOnMarkerMoveCoords())
		resetTooltip(null)
	}
	const nextLocation = () => {
		resetBeforeMoveToNextLocation()
		if (currentLocationIndex + 1 >= selectedLocations.length) {
			dispatch(setSelectedResultId(undefined))
			dispatch(locationIndexToZero())
			dispatch(resetFilteredByConfig())
			dispatch(
				saveReviewState({
					selectedLocations: [],
					currentLocationIndex: 0,
					jobId: selectedJobId
				})
			).then((data) => {
				// If saving review is a success
				if (data.type === saveReviewState.fulfilled.type) {
					dispatch(setSelectedResults([]))
					dispatch(setSelectedRowKeys([]))
					dispatch(fetchResultsByJobId(selectedJobId)).then(
						(dataFetchResultsByJobId) => {
							if (
								dataFetchResultsByJobId.type ===
								fetchResultsByJobId.fulfilled.type
							) {
								dispatch(setSaveReviewState(false))
								navigate({
									pathname: "/location",
									search: `?jobId=${selectedJobId}`,
									hash: "",
									query: {}
								})
							}
						}
					)
				}
			})
		}
		dispatch(nextLocationIndex())
	}

	const previousLocation = () => {
		resetBeforeMoveToNextLocation()
		dispatch(previousLocationIndex())
	}

	const [isUndoWarningVisible, setIsUndoWarningVisible] = useState(false)
	const [isEditAddressModalVisible, setIsEditAddressModalVisible] =
		useState(false)

	useEffect(() => {
		if (selectedLocations?.length > 0) {
			if (currentLocationIndex <= selectedLocations?.length - 1) {
				navigate({
					pathname: "/map",
					search: `?${new URLSearchParams({
						locationId: selectedLocations[currentLocationIndex]?.id
					}).toString()}`
				})
				dispatch(
					addHocResult(
						selectedLocations[currentLocationIndex]?.geo_data
					)
				)
				dispatch(
					setSelectedResultId(
						selectedLocations[currentLocationIndex]?.id
					)
				)
				// Update current mode
				dispatch(
					setCurrentFootprintMode(
						selectedLocations[currentLocationIndex]?.geo_data
							.processing_mode
					)
				)
				dispatch(
					setCurrentModeDescription(
						selectedLocations[currentLocationIndex]?.geo_data
							.processing_mode_reason_text
					)
				)
				dispatch(
					setCurrentModeReasonKey(
						selectedLocations[currentLocationIndex]?.geo_data
							.processing_mode_reason_key
					)
				)
				dispatch(displayAlert(false))
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentLocationIndex])

	const contents = selectedLocations
		? selectedLocations[currentLocationIndex]?.input_data ?? {}
		: {}

	useEffect(() => {
		// https://stackoverflow.com/a/66789878/3900714
		// eslint-disable-next-line no-underscore-dangle
		if (addressFormInstance.__INTERNAL__.name) {
			addressFormInstance.setFieldsValue({
				streetAddress: contents?.street_name ?? "",
				city: contents?.city ?? "",
				pstlCode: contents?.postal_code ?? "",
				county: contents?.county ?? "",
				stateOrProvince: contents?.state ?? "",
				stateCode: contents?.state_code ?? "",
				country: contents?.country_code,
				toTriggerFootprint: false
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditAddressModalVisible, contents])

	const { t } = useTranslation()

	const infoToHide = [
		"id",
		"occupancy.code",
		"insured_value",
		"street_name",
		"city",
		"state",
		"state_code",
		"postal_code",
		"county",
		"country_code",
		"country",
		"raw_emp_attributes.OCCSCHEME",
		"raw_emp_attributes.OCCTYPE",
		"raw_emp_attributes.YEARBUILT",
		"raw_emp_attributes.NUMSTORIES",
		"raw_emp_attributes.CV2CVM",
		"raw_emp_attributes.CV9CVM",
		"raw_emp_attributes.NUMBLDGS",
		"raw_emp_attributes.FRSPRINKLERSYS",
		"raw_emp_attributes.SITECURRENCY",
		"raw_emp_attributes.LATITUDE",
		"raw_emp_attributes.LONGITUDE",
		"raw_emp_attributes.LOCNUM",
		"raw_emp_attributes.CV1VAL_USD",
		"raw_emp_attributes.CV2VAL_USD",
		"raw_emp_attributes.CV3VAL_USD",
		"raw_emp_attributes.CITY",
		"raw_emp_attributes.STREETNAME",
		"raw_emp_attributes.POSTALCODE",
		"raw_emp_attributes.TOTALVALUE"
	]

	const flattenContent = Object.assign(
		{},
		...Object.keys(contents)
			.map((item) => {
				if (contents[item] || contents[item] === 0) {
					if (typeof contents[item] === "object") {
						return Object.keys(contents[item]).map((subItem) => {
							if (contents[item][subItem]) {
								return {
									[`${item}.${subItem}`]:
										contents[item][subItem]
								}
							}
							/* istanbul ignore next */
							return null
						})
					}
					return { [item]: contents[item] }
				}
				return null
			})
			.flat()
			.filter((_content) => _content !== null)
	)
	const data = Object.keys(flattenContent)
		.filter((key) => !infoToHide.includes(key))
		.filter((key) => {
			// Hide amount === 0 || amount === ""
			if (toDisplayAsCurrencyValue(key)) {
				return flattenContent[key] !== "0" && flattenContent[key] !== ""
			}
			return true
		})
		.map((item) => {
			const linkContent = renderExternalQueryLinks(item, contents[item])
			if (item === "emp_lat_lon") {
				return {
					title: t(item),
					description: (
						<>
							<Paragraph
								copyable={{
									text: flattenContent[item]
								}}
							>
								<span className="locationData__coordinates">
									{flattenContent[item]}
								</span>
								{isValidCoordinates(flattenContent[item]) ? (
									<Switch
										data-testid="locationData__coordinates--switch--testid"
										className="locationData__coordinates--switch"
										size="small"
										checked={displayClientCoordinatesPin}
										checkedChildren={<EyeOutlined />}
										unCheckedChildren={
											<EyeInvisibleOutlined />
										}
										onChange={() =>
											dispatch(
												setDisplayClientCoordinatesPin()
											)
										}
									/>
								) : null}
							</Paragraph>
							{isValidCoordinates(flattenContent[item]) ? (
								<div>
									<div
										className="locationData__link locationData__coordinates--useClientCoordinates"
										data-testid="locationData__coordinates--useClientCoordinates--testid"
										role="button"
										tabIndex="-1"
										aria-hidden="true"
										onClick={(e) => {
											e.currentTarget.blur()
											dispatch(hideClientCoordinatesPin())
											dispatch(
												setMarker({
													lat: parseFloat(
														contents
															?.raw_emp_attributes
															?.LATITUDE
													),
													lon: parseFloat(
														contents
															?.raw_emp_attributes
															?.LONGITUDE
													)
												})
											)
											dispatch(
												setCurrentFootprintMode(
													FOOTPRINT_MODE.FOOTPRINT_BY_GEOCODE_DISPLAY_ONLY
												)
											)
											dispatch(
												getFootprintByCoordinates({
													lat: parseFloat(
														contents
															?.raw_emp_attributes
															?.LATITUDE
													),
													lon: parseFloat(
														contents
															?.raw_emp_attributes
															?.LONGITUDE
													)
												})
											).then(
												async (
													dataGetFootprintByCoordinates
												) => {
													if (
														dataGetFootprintByCoordinates.type ===
														getFootprintByCoordinates
															.fulfilled.type
													) {
														await displayToast(
															dispatch,
															"SUCCESS_MODIFIFY_GEOCODE",
															ALERTS_TYPE.SUCCESS,
															1000
														)
														dispatch(
															setFootprintIsPristine(
																false
															)
														)
													}
												}
											)
										}}
									>
										Choose this geocode
									</div>
								</div>
							) : null}
						</>
					)
				}
			}
			if (item === "address") {
				return {
					title: t(item),
					description: (
						<>
							<Paragraph
								style={{ whiteSpace: "pre-line" }}
								copyable={{
									text: addressDisplayBuilder(contents)
								}}
							>
								{addressDisplayBuilder(contents)}
								<Tooltip title="Edit">
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										xmlns="http://www.w3.org/2000/svg"
										className="edit-address-icon"
										data-testid="edit-address-icon-testid"
										onClick={() => {
											setIsEditAddressModalVisible(true)
										}}
									>
										<path
											d="M8.6 2.759 13.24 7.4l-8.437 8.437a.55.55 0 0 1-.39.162H.552A.552.552 0 0 1 0 15.448v-3.862a.55.55 0 0 1 .162-.39L8.599 2.76zM11.195.162a.551.551 0 0 1 .78 0l3.862 3.862a.551.551 0 0 1 0 .78L14.022 6.62 9.379 1.978z"
											fill="#00008F"
											fillRule="evenodd"
										/>
									</svg>
								</Tooltip>
							</Paragraph>
							{linkContent}
						</>
					)
				}
			}
			return {
				title: t(item),
				description: (
					<>
						<Paragraph copyable={item === "customer"}>
							{toDisplayAsCurrencyValue(item)
								? currencyHandler(
										flattenContent[item],
										flattenContent[
											"raw_emp_attributes.SITECURRENCY"
										]
								  )
								: flattenContent[item]}
						</Paragraph>
						{linkContent}
					</>
				)
			}
		})
	return (
		<div className="infoControlPanel" onMouseEnter={onHover}>
			<WarningModal
				title="Geocode outside of a selected parcel"
				isWarningVisible={isUndoWarningVisible}
				handleCancel={() => setIsUndoWarningVisible(false)}
				handleConfirm={async () => {
					onSaveHandler(VALIDATION_STATUS_TYPE.VALIDATED).then(
						async (onSaveHandlerResolve) => {
							setIsUndoWarningVisible(false)
							if (
								onSaveHandlerResolve.type ===
								postEditResult.fulfilled.type
							) {
								await displayToast(
									dispatch,
									"SUCCESS_VALIDATION_OR_NOT_LOCATION",
									ALERTS_TYPE.SUCCESS,
									400
								)
								nextLocation()
							}
						}
					)
				}}
			>
				<p>
					The geocode is outside of a selected parcel, do you want to
					continue and validate the location?
				</p>
			</WarningModal>
			<WarningModal
				title="Edit the address"
				size="large"
				handleCancel={() => {
					setIsEditAddressModalVisible(false)
					addressFormInstance.resetFields()
				}}
				isWarningVisible={isEditAddressModalVisible}
				closable
				maskClosable={false}
				actions={[]}
			>
				<div className="editAddress__container">
					<Form
						form={addressFormInstance}
						autoComplete="off"
						layout="vertical"
						className="editAddress__form"
						name="editAddressForm"
						initialValues={{
							streetAddress: contents?.street_name ?? "",
							city: contents?.city ?? "",
							pstlCode: contents?.postal_code ?? "",
							county: contents?.county ?? "",
							stateOrProvince: contents?.state ?? "",
							stateCode: contents?.state_code ?? "",
							country: contents?.country_code
						}}
						id="editAddressFormId"
						size="large"
						onFinish={(values) => {
							setIsEditAddressModalVisible(false)
							dispatch(
								patchAddressResult({
									addressToUpdate: {
										...values
									}
								})
							).then((editedAddressReq) => {
								if (
									editedAddressReq.type ===
									patchAddressResult.fulfilled.type
								) {
									if (values.toTriggerFootprint) {
										dispatch(
											setCurrentFootprintMode(
												`${FOOTPRINT_MODE.FORWARD}_footprint_retry`
											)
										)
										dispatch(
											setShowLoadingModalForFootprintModeSwitch(
												true
											)
										)
										dispatch(
											postChangeFootprintMode({
												rawEMPAttributes:
													selectedLocations[
														currentLocationIndex
													].input_data
														.raw_emp_attributes,
												triggerRetryMode: true,
												savePanelMode: true
											})
										).then(
											async (
												dataPostChangeFootprintMode
											) => {
												if (
													dataPostChangeFootprintMode.type ===
													postChangeFootprintMode
														.fulfilled.type
												) {
													dispatch(
														setFootprintIsPristine(
															false
														)
													)
													await displayToast(
														dispatch,
														"SUCCESS_MODIFIFY_GEOCODE",
														ALERTS_TYPE.SUCCESS,
														1000
													)
												}
											}
										)
									}
								}
							})
						}}
					>
						<Row gutter={[32, 48]}>
							<Col span={12}>
								<Form.Item
									label={
										<span className="editAddressForm__item__label">
											Street address
										</span>
									}
									className=""
									name="streetAddress"
								>
									<Input
										autoComplete="none"
										name="streetAddress"
										data-testid="editAddressForm__streetAddress__input-testid"
										className="editAddressForm__item__input"
										placeholder="Street Address"
									/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									label={
										<span className="editAddressForm__item__label">
											City
										</span>
									}
									className=""
									name="city"
								>
									<Input
										autoComplete="none"
										name="city"
										className="editAddressForm__item__input"
										data-testid="editAddressForm__city__input-testid"
										placeholder="City"
									/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									label={
										<span className="editAddressForm__item__label">
											Postal Code
										</span>
									}
									className=""
									name="pstlCode"
								>
									<Input
										autoComplete="none"
										name="pstlCode"
										className="editAddressForm__item__input"
										data-testid="editAddressForm__pstlCode__input-testid"
										placeholder="Postal Code"
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={[32, 48]}>
							<Col span={6}>
								<Form.Item
									label={
										<span className="editAddressForm__item__label">
											County
										</span>
									}
									className=""
									name="county"
								>
									<Input
										autoComplete="none"
										name="county"
										className="editAddressForm__item__input"
										data-testid="editAddressForm__county__input-testid"
										placeholder="County"
									/>
								</Form.Item>
							</Col>
							<Col span={5}>
								<Form.Item
									label={
										<span className="editAddressForm__item__label">
											State/Province
										</span>
									}
									className=""
									name="stateOrProvince"
								>
									<Input
										autoComplete="none"
										name="stateOrProvince"
										className="editAddressForm__item__input"
										data-testid="editAddressForm__stateOrProvince__input-testid"
										placeholder="State/Province"
									/>
								</Form.Item>
							</Col>
							<Col span={4}>
								<Form.Item
									label={
										<span className="editAddressForm__item__label">
											State Code
										</span>
									}
									className=""
									name="stateCode"
								>
									<Input
										autoComplete="none"
										name="stateCode"
										className="editAddressForm__item__input"
										data-testid="editAddressForm__stateCode__input-testid"
										placeholder="State Code"
									/>
								</Form.Item>
							</Col>
							<Col span={9}>
								<Form.Item
									label={
										<span className="editAddressForm__item__label">
											Country
										</span>
									}
									name="country"
								>
									<Select
										autoComplete="none"
										placeholder="Country"
										data-testid="edit_select_country--testid"
										className="edit_select_country"
										size="large"
										showSearch
										optionLabelProp="label"
										optionFilterProp="label"
										filterOption={(input, option) =>
											option.label
												.toLowerCase()
												.indexOf(input.toLowerCase()) >=
											0
										}
									>
										{generateCountriesCodeNameFlagByLocaleSortedByName().map(
											(countryObject) => (
												<Option
													className="editAddress__countrySelect"
													key={`${countryObject.code}-key`}
													data-testid={`select-${countryObject.code}--testid`}
													value={countryObject.code}
													label={countryObject.name}
												>
													<div className="countrySelect__content">
														<span
															role="img"
															aria-label={`${countryObject.flag} ${countryObject.name}`}
														>
															{`${countryObject.flag}`}
														</span>{" "}
														{`${countryObject.name}`}
													</div>
												</Option>
											)
										)}
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row
							gutter={[32, 48]}
							className="regenerateFootprintCheckbox__container"
						>
							<Col span={24}>
								<Form.Item
									name="toTriggerFootprint"
									valuePropName="checked"
								>
									<Checkbox data-testid="regenerateFootprintCheckbox--testid">
										Update the geocode
									</Checkbox>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={[32, 48]} justify="center" align="middle">
							<Col>
								<Form.Item shouldUpdate>
									{() => (
										<Button
											key="submit"
											form="editAddressFormId"
											htmlType="submit"
											className="axa-button"
											data-testid="save-edit-changes--testid"
											type="primary"
											size="large"
											disabled={
												addressFormInstance
													.getFieldsError()
													.filter(
														({ errors }) =>
															errors.length
													).length > 0
											}
										>
											Save changes
										</Button>
									)}
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
			</WarningModal>
			{isSavingReviewState ? <LoadingModal mode="" /> : ""}
			<div
				aria-hidden="true"
				className="infoControlPanel__goToPrevious__container"
				onClick={() => {
					dispatch(resetUnselectedMarkers())
					dispatch(resetMarker())
					handleOkBackWarning()
				}}
			>
				<svg
					width="14"
					height="14"
					viewBox="0 0 14 14"
					xmlns="http://www.w3.org/2000/svg"
					className={`infoControlPanel__goToPrevious__icon${
						isPostingResultEdit ||
						isLoadingAdditionalParcels ||
						isSubmittingFootprintChecker
							? "--disabled"
							: ""
					}`}
				>
					<path
						d="m9.865 13 1.697-1.698L6.76 6.5l4.802-4.802L9.865 0l-6.5 6.5z"
						fill="#00008F"
						fillRule="evenodd"
					/>
				</svg>
				<span
					className={`infoControlPanel__goToPrevious${
						isPostingResultEdit ||
						isLoadingAdditionalParcels ||
						isSubmittingFootprintChecker
							? "--disabled"
							: ""
					}`}
				>
					<Tooltip
						title="This option will save all previous work. You will be able to continue the validation selecting the file from the Job list.
 "
						placement="leftBottom"
						color="#00008F"
					>
						List of locations
					</Tooltip>
				</span>
			</div>
			<div className="infoControlPanel_counter__container">
				<span className="infoControlPanel_counter__label">
					Location details
				</span>
			</div>
			<div className="locationData__container">
				<div className="infoControlPanel_InformationTitle">
					Data from EMP
				</div>
				<List
					itemLayout="horizontal"
					dataSource={data}
					renderItem={(item) => (
						<List.Item>
							<List.Item.Meta
								title={
									<div className="infoControlPanel_item__title">
										{item.title}
									</div>
								}
								description={
									<div className="infoControlPanel_item__description">
										{item.description}
									</div>
								}
							/>
						</List.Item>
					)}
				/>
			</div>
			<div className="infoControlPanel_actionsContainer">
				<div className="infoControlPanel_locationsNavigator__container">
					<Button
						className="locationsNavigator_goToPrevious"
						data-testid="locationsNavigator_goToPrevious--testid"
						onClick={() => {
							if (selectedLocations.length > 1) {
								dispatch(hideClientCoordinatesPin())
								if (currentLocationIndex - 1 === -1) {
									resetBeforeMoveToNextLocation()
									dispatch(
										setCurrentLocationIndex(
											selectedLocations.length - 1
										)
									)
								} else {
									previousLocation()
								}
							}
						}}
					>
						<svg
							width="14"
							height="14"
							viewBox="0 0 14 14"
							xmlns="http://www.w3.org/2000/svg"
							className="locationsNavigator_goToPrevious__icon"
						>
							<path
								d="m9.865 13 1.697-1.698L6.76 6.5l4.802-4.802L9.865 0l-6.5 6.5z"
								fill="#00008F"
								fillRule="evenodd"
							/>
						</svg>
					</Button>

					<span className="locationsNavigator__label">
						Location{" "}
						{`${currentLocationIndex + 1} of ${
							selectedLocations?.length
						}`}
					</span>
					<Button
						className="locationsNavigator_goToNext"
						data-testid="locationsNavigator_goToNext--testid"
						onClick={() => {
							if (selectedLocations.length > 1) {
								dispatch(hideClientCoordinatesPin())
								if (
									currentLocationIndex >=
									selectedLocations?.length - 1
								) {
									resetBeforeMoveToNextLocation()
									dispatch(setCurrentLocationIndex(0))
								} else {
									nextLocation()
								}
							}
						}}
					>
						<svg
							width="14"
							height="14"
							viewBox="0 0 14 14"
							xmlns="http://www.w3.org/2000/svg"
							className="locationsNavigator_goToNext__icon"
						>
							<path
								d="m4.135 14-1.697-1.698L7.24 7.5 2.438 2.698 4.135 1l6.5 6.5z"
								fill="#00008F"
								fillRule="evenodd"
							/>
						</svg>
					</Button>
				</div>
				<Button
					className="axa-button--withIcon axa-button--validate"
					data-testid="validate-location"
					onClick={async (e) => {
						e.currentTarget.blur()
						if (
							currentLocationIndex <=
							selectedLocations?.length - 1
						) {
							const turfMarkerPoint = point([
								marker.lon,
								marker.lat
							])
							const selectedParcels = drawnParcels.filter(
								(feature) => feature.properties.selected
							)
							const noMarkerOnSelectedParcel = selectedParcels
								.map((parcel) =>
									booleanPointInPolygon(
										turfMarkerPoint,
										polygon(parcel.geometry.coordinates)
									)
								)
								.every((f) => !f)
							dispatch(hideClientCoordinatesPin())
							if (
								noMarkerOnSelectedParcel &&
								selectedParcels.length > 0
							) {
								setIsUndoWarningVisible(true)
							} else {
								onSaveHandler(
									VALIDATION_STATUS_TYPE.VALIDATED
								).then(async (onSaveHandlerResolve) => {
									if (
										onSaveHandlerResolve.type ===
										postEditResult.fulfilled.type
									) {
										await displayToast(
											dispatch,
											"SUCCESS_VALIDATION_OR_NOT_LOCATION",
											ALERTS_TYPE.SUCCESS,
											400
										)
										nextLocation()
									}
									setIsUndoWarningVisible(false)
								})
							}
						}
					}}
					disabled={
						selectedLocations?.length === 0 ||
						currentLocationIndex > selectedLocations?.length - 1 ||
						isGeocoded ||
						isSubmittingFootprintChecker ||
						isPostingResultEdit ||
						isLoadingAdditionalParcels
					}
					icon={
						<svg
							width="15"
							height="8"
							viewBox="0 0 15 8"
							xmlns="http://www.w3.org/2000/svg"
							className="axa-button--withLeftIcon"
						>
							<g fill="#fff" fillRule="nonzero">
								<path d="m8.896 0 1.451 1.49L4.005 8 .158 4.05 1.61 2.56l2.396 2.46zM12.707 0l1.451 1.49L7.817 8 6.088 6.22 7.54 4.73l.278.29z" />{" "}
							</g>
						</svg>
					}
				>
					Validate location
				</Button>
				<Popover
					// eslint-disable-next-line react/no-unstable-nested-components
					content={() => (
						<SkipFeedback
							saveFeedbackHandler={(feedback) => {
								setPopoverVisible(false)
								dispatch(setIsGeocoding(false))
								dispatch(hideClientCoordinatesPin())
								dispatch(
									addHocResult(
										selectedLocations[currentLocationIndex]
											?.geo_data
									)
								)
								onSaveHandler(feedback).then(
									async (onSaveHandlerResolve) => {
										if (
											onSaveHandlerResolve.type ===
											postEditResult.fulfilled.type
										) {
											await displayToast(
												dispatch,
												"SUCCESS_VALIDATION_OR_NOT_LOCATION",
												ALERTS_TYPE.SUCCESS,
												400
											)
											nextLocation()
										}
										setPopoverVisible(false)
									}
								)
							}}
							onClose={() => setPopoverVisible(false)}
						/>
					)}
					trigger="click"
					placement="rightBottom"
					open={popoverVisible}
					onOpenChange={(visible) => {
						if (
							selectedLocations?.length === 0 ||
							currentLocationIndex >
								selectedLocations?.length - 1 ||
							isGeocoded ||
							isSubmittingFootprintChecker ||
							isPostingResultEdit ||
							isLoadingAdditionalParcels
						) {
							return false
						}
						setPopoverVisible(visible)
						return true
					}}
				>
					<Button
						className="axa-button--withIcon axa-button--notValidate"
						data-testid="do-not-validate-location"
						onClick={(e) => {
							if (
								selectedLocations?.length === 0 ||
								currentLocationIndex >
									selectedLocations?.length - 1 ||
								isGeocoded ||
								isSubmittingFootprintChecker ||
								isPostingResultEdit ||
								isLoadingAdditionalParcels
							) {
								// disabling test coverage as it non user testable interaction
								/* istanbul ignore next */
								e.currentTarget.blur()
								/* istanbul ignore next */
								return true
							}
							return false
						}}
						disabled={
							selectedLocations?.length === 0 ||
							currentLocationIndex >
								selectedLocations?.length - 1 ||
							isGeocoded ||
							isSubmittingFootprintChecker ||
							isPostingResultEdit ||
							isLoadingAdditionalParcels
						}
						icon={
							<svg
								width="12px"
								height="12px"
								xmlns="http://www.w3.org/2000/svg"
								className="axa-button--withLeftIcon"
								viewBox="0 4 13 16"
							>
								<path
									d="M12.845 18.468 8 13.623l-4.845 4.845-1.623-1.623L6.377 12 1.532 7.155l1.623-1.623L8 10.377l4.845-4.845 1.623 1.623L9.623 12l4.845 4.845z"
									fill="#FFF"
									fillRule="evenodd"
								/>
							</svg>
						}
					>
						Do not validate location
					</Button>
				</Popover>
			</div>
		</div>
	)
}

InfoControlPanel.propTypes = {
	onSaveHandler: PropTypes.func.isRequired,
	onHover: PropTypes.func.isRequired,
	resetTooltip: PropTypes.func.isRequired,
	drawnParcels: PropTypes.PropTypes.instanceOf(Array).isRequired,
	handleOkBackWarning: PropTypes.func.isRequired
}

export default InfoControlPanel
