import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import "App.css"

import { Layout, Menu } from "antd"
import { UserOutlined, DownOutlined, LogoutOutlined } from "@ant-design/icons"

import {
	isLoggedIn as isLoggedInSlice,
	user as userSlice,
	startLogout
} from "features/authSlice"

const { Header } = Layout

const HeaderComponent = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const userInfo = useSelector(userSlice)
	const isLoggedIn = useSelector(isLoggedInSlice)

	return (
		<Header className="axa-header">
			<Link to="/" className="logo">
				<svg
					width="48"
					height="48"
					id="AXA_Logo_RGB"
					data-name="AXA Logo RGB"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 283.46698 283.46699"
				>
					<title>{t("AXA_Logo_Solid_RGB")}∫</title>
					<rect
						id="base"
						fill="#00008f"
						x="0.0025"
						y="0.0025"
						width="283.46448"
						height="283.46449"
					/>
					<polygon
						id="switch"
						fill="#ff1721"
						points="175.659 139.99 283.467 0 267.544 0 159.38 139.99 175.659 139.99"
					/>
					<path
						id="axa"
						fill="#fff"
						d="M216.597,202.461c4.941,13.823,15.122,49.795,19.1695,52.661H209.038a44.57134,44.57134,0,0,0-1.2545-9.4335c-1.1525-4.1115-10.8205-35.215-10.8205-35.215H154.507l-6.675,9.463s8.0175,25.0975,8.5155,26.328c.8645,2.217,4.6925,8.8575,4.6925,8.8575H135.425s-.664-3.833-.913-5.4295c-.2005-1.289-2.427-8.3495-2.427-8.3495s-5.8055,6.362-7.3875,9.3115c-1.597,2.944-2.305,4.4675-2.305,4.4675h-20.039s-.669-3.833-.918-5.4295c-.195-1.289-2.64652-8.916-2.64652-8.916s-5.61,6.8115-7.207,9.7555c-1.587,2.9495-2.2705,4.59-2.2705,4.59h-19.819s5.6005-5.332,7.5585-7.622c3.3005-3.882,15.6005-19.956,15.6005-19.956L87.72,210.47349H45.586s-24.0235,31.5675-24.97049,32.544c-.957.962-7.959,11.011-8.11551,12.1045H0v-7.949a5.9865,5.9865,0,0,1,.493-.4785c.386-.2835,18.213-22.4125,34.59-44.2335,14.717-19.0285,28.5255-37.5345,29.7365-39.297,2.9345-4.2625,7.163-13.4665,7.163-13.4665h21.782s.674,8.4665,1.309,10.522c.566,1.8165,13.8375,45.3615,14.15,45.8305l7.339-9.385-12.544-38.6135s-2.9395-7.2655-3.8965-8.354h25.4445a22.1658,22.1658,0,0,0,.83,6.9185c1.035,3.184,6.4895,22.8665,6.4895,22.8665s17.3535-21.753,18.369-23.3155a14.1,14.1,0,0,0,2.1435-6.4695h21.201s-3.8815,2.8365-10.6835,11.44c-2.285,2.8955-24.673,31.348-24.673,31.348s1.9535,6.66,2.9055,9.97552c.259.952.4395,1.5965.4395,1.665,0,.02948.493-.576,1.34252-1.665,5.77648-7.3195,32.051-41.7725,33.643-44.722,1.284-2.3825,3.1735-5.0925,4.282-8.0415H202.539s.4785,6.1765,1.1085,7.8855Zm-31.475-32.6125c-3.037,6.5335-20.913,28.296-20.913,28.296h28.3105s-5.488-16.8995-6.445-20.708a30.23266,30.23266,0,0,1-.557-7.4025C185.51748,169.6875,185.454,169.126,185.122,169.8485Zm-108.7745,0c-3.037,6.5335-20.913,28.296-20.913,28.296H83.745s-5.483-16.8995-6.44-20.708a30.23266,30.23266,0,0,1-.557-7.4025C76.748,169.6875,76.68,169.126,76.34749,169.8485ZM118.97,235.835l7.793-10.703c-.718-.77152-5.10748-14.082-5.10748-14.082l-7.53452,9.775Z"
						transform="translate(0 0)"
					/>
				</svg>
			</Link>
			<Menu
				className="axa-header__menu"
				mode="horizontal"
				defaultSelectedKeys={[""]}
				data-testid="user-email-data-testid"
				items={[
					{
						key: "1",
						icon: <UserOutlined />,
						disabled: !isLoggedIn,
						label: (
							<>
								{userInfo.email}
								&nbsp;&nbsp;&nbsp;
								<DownOutlined />
							</>
						),
						children: [
							{
								key: "11",
								onClick: () => {
									dispatch(startLogout())
								},
								label: (
									<b
										className="logout__link"
										data-testid="logout-data-testid"
									>
										<LogoutOutlined className="logout__link__icon" />
										{t("Sign Out")}
									</b>
								)
							}
						]
					}
				]}
			/>
		</Header>
	)
}

export default HeaderComponent
