/* eslint-disable no-underscore-dangle */
import React, {
	useState,
	useCallback,
	useEffect,
	useRef,
	useLayoutEffect,
	useMemo
} from "react"

import { createBrowserHistory } from "history"
import { useLocation, useNavigate } from "react-router-dom"
import { SyncOutlined, EnvironmentFilled } from "@ant-design/icons"
import MapGL, {
	Layer,
	Source,
	Marker,
	NavigationControl,
	ScaleControl
} from "react-map-gl"
// eslint-disable-next-line
import mapboxgl from 'mapbox-gl'
import WebMercatorViewport from "viewport-mercator-project"
import { useSelector, useDispatch } from "react-redux"
import { Spin, Button, Form, Input } from "antd"
import { isEmpty, isEqual } from "lodash"
import {
	polygon,
	centroid,
	getCoord,
	bbox,
	point,
	featureCollection
} from "@turf/turf"
import "./styles/Map.css"
import { isArrayEqual } from "helpers/Checkers"
import {
	geocodedDataRaw,
	getAdditionalParcels,
	geocodingIsFetching,
	postFootprintChecker,
	parcelsToTrace,
	highlightParcel,
	countyCodeISO3,
	unhighlightBuilding,
	highlightBuilding,
	geocodingIsSubmittingFootprintChecker,
	setClickedParcel,
	addHocResult,
	postEditResult,
	isGeocoded as isGeocodedSlice,
	isLoadingAdditionalParcels as isLoadingAdditionalParcelsSlice,
	postChangeFootprintMode,
	unHoverAllParcels,
	clearFootprintData,
	setIsGeocoding,
	addCircleTarget,
	removeCircleTarget,
	setShowLoadMoreParcelsCta,
	setShowLoadMoreBuildingsCta,
	showLoadMoreParcelsCta as showLoadMoreParcelsCtaSlice,
	showLoadMoreBuildingsCta as showLoadMoreBuildingsCtaSlice,
	getFootprintByCoordinates,
	FOOTPRINT_MODE,
	setHasUserManuallyEditGeocode,
	setFootprintIsPristine,
	setIsLoadingAdditionalBuildings,
	currentMode,
	isPostingResultEdit as isPostingResultEditSlice
} from "features/footprintSlice"
import {
	selectedResultId,
	fetchLocationById,
	selectedJobId as selectedJobIdSlice,
	locationDirectFetch as locationDirectFetchSlice,
	selectedResults as selectedResultsSlice,
	isFetchingLocationByID as isFetchingLocationByIDSlice,
	currentLocationIndex as currentLocationIndexSlice,
	setCurrentFootprintMode,
	setCurrentModeDescription,
	setCurrentModeReasonKey,
	setFetchingLocationByID,
	fetchResultsByJobId,
	setSaveReviewState,
	setSelectedResults,
	setSelectedRowKeys,
	saveReviewState
} from "features/jobsResultsSlice"
import {
	setAlertPayload,
	alertPayload as alertPayloadSlice,
	isDisplayAlert as isDisplayAlertSlice,
	displayAlert
} from "features/alertsSlice"
import Pin from "pages/map/components/pin"
import InfoTooltip from "components/infoTooltip/InfoTooltip"
import InfoControlPanel from "components/location_handlers/InfoControlPanel"
import Topbar from "pages/map/components/Topbar/Topbar"
import {
	isFlyTo,
	setMarker,
	setOnMarkerMoveCoords,
	marker as markerSlice,
	isMarkerMoving as isMarkerMovingSlice,
	onMarkerMoveCoords as onMarkerMoveCoordsSlice,
	displayClientCoordinatesPin as displayClientCoordinatesPinSlice,
	resetMarker,
	hideClientCoordinatesPin,
	isClickingUnselected as isClickingUnselectedSlice,
	setIsLoadingMapData,
	displayBannerDataSourceInfo,
	setIsDisplayingToast,
	isDisplayingToast as isDisplayingToastSlice
} from "features/mapSlice"
import WarningModal from "components/warningModal/WarningModal"
import FullscrenMapLoader from "components/fullscreenMapLoader/FullscreenMapLoader"
import Alerts, { displayToast } from "components/alerts/Alerts"
import { ALERTS_TYPE, VALIDATION_STATUS_TYPE } from "helpers/constants"
import { enableLoadMoreParcelsForSupportedCountries } from "helpers/rulesHandler"
import {
	buildingPredicate,
	isBuilding,
	parcelPredicate
} from "helpers/polygonFootprintHelper"
import MapLayersSwitcher from "pages/map/components/MapLayersSwitcher"
import { generateUnselectedPins } from "pages/map/utils/unselectedPointsOfInterest"
import UnselectedMarkers from "pages/map/components/UnselectedMarkers"
import SelectedMarker from "pages/map/components/SelectedMarker"
import BannerDataSource from "pages/map/components/BannerDataSource"
import "mapbox-gl/dist/mapbox-gl.css"

// source: https://stackoverflow.com/a/69489231/3900714
// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
if (process.env.NODE_ENV !== "test") {
	mapboxgl.workerClass =
		// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved, global-require
		require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default
}

const ZOOM_LEVEL = 18
const ZOOM_OFFSET = 1.5

const Map = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const history = createBrowserHistory()
	// Coordinates for center of USA.
	const [viewport, setViewport] = useState({
		latitude: 41.5515688,
		longitude: -110.262899,
		zoom: 3
	})

	// Default to "streets-v11" (https://www.mapbox.com/maps/streets) basemap
	const [mapLayer, setMapLayer] = useState({
		stringId: "satellite" // satellite-streets-v11
	})

	const [mapPointer, setMapPointer] = useState({
		style: "grab"
	})

	const [newGeocodeInputPristine, setNewGeocodeInputPristine] = useState(true)

	const [spinnerCoords, setSpinnerCoords] = useState({
		lon: 0.0,
		lat: 0.0
	})

	const [coordsAfterDrag, setcoordsAfterDrag] = useState({
		lon: 0.0,
		lat: 0.0
	})

	const [hoverTooltip, setHoverTooltip] = useState()

	const [isParcelsPristine, setParcelsPristine] = useState(true)

	const [isGeocodeModalVisible, setIsGeocodeModalVisible] = useState(false)
	const [isBackWarningModalVisible, setIsBackWarningModalVisible] =
		useState(false)

	const [localClickedParcelId, setLocalClickedParcelId] = useState("")
	const [disabledModifyGeocode, setDisabledModifyGeocode] = useState(true)
	const showModal = () => {
		setIsGeocodeModalVisible(true)
	}

	const handleOkGeocode = () => {
		setIsGeocodeModalVisible(false)
		setNewGeocodeInputPristine(true)
	}

	const handleCancelGeocode = () => {
		setIsGeocodeModalVisible(false)
		setNewGeocodeInputPristine(true)
	}

	const _onViewportChange = (vp) => {
		setViewport(vp)
	}

	let mapRefCenter = useRef(null)
	const mapParentRef = useRef(null)

	const [form] = Form.useForm()

	const [parcelToBeHovered, setParcelToBeHovered] = useState(null)
	const [buildingToBeHovered, setBuildingToBeHovered] = useState(null)

	const onFeatureHover = useCallback((evt) => {
		if (evt.features) {
			const currentHoveredParcel = evt.features.find(
				(feature) =>
					feature.layer.id === "parcels-layer" ||
					feature.layer.id === "building-parcel-layer" ||
					feature.layer.id === "building-layer-outline-hovered" ||
					feature.layer.id === "building-unselected-layer" ||
					feature.layer.id === "parcels-layer-hovered"
			)
			const currentHoveredBuilding = evt.features.find(
				(feature) =>
					feature.layer.id === "building-layer" ||
					feature.layer.id === "building-unselected-layer"
			)
			if (currentHoveredParcel) {
				setParcelToBeHovered({
					parcelid: currentHoveredParcel.properties.parcelid
				})
				setBuildingToBeHovered(null)
			}
			if (currentHoveredBuilding) {
				setBuildingToBeHovered({
					guid: currentHoveredBuilding.properties.guid
				})
				setParcelToBeHovered(null)
			}
			if (!currentHoveredBuilding && !currentHoveredParcel) {
				setBuildingToBeHovered(null)
				setParcelToBeHovered(null)
			}
		}
	}, [])

	const filterForHoveredParcel = useMemo(
		() => [
			"in",
			"parcelid",
			(parcelToBeHovered && parcelToBeHovered.parcelid) || ""
		],
		[parcelToBeHovered]
	)

	const filterForNotHoveredParcel = useMemo(
		() => [
			"!=",
			"parcelid",
			(parcelToBeHovered && parcelToBeHovered.parcelid) || ""
		],
		[parcelToBeHovered]
	)

	const filterForHoveredBuilding = useMemo(
		() => [
			"in",
			"guid",
			(buildingToBeHovered && buildingToBeHovered.guid) || ""
		],
		[buildingToBeHovered]
	)

	const _goToViewport = useCallback(
		({ lon, lat }, _isFlyTo, zoomLevel, selectedParcel) => {
			let vp = {
				width: "100%",
				height: "100%",
				latitude: lat,
				longitude: lon,
				zoom: 15
			}
			if (selectedParcel) {
				// Inspiration 1: https://github.com/visgl/react-map-gl/tree/master/examples/zoom-to-bounds
				// Inspiration 2: https://github.com/visgl/react-map-gl/issues/1237#issuecomment-821863433
				const { offsetHeight: height, offsetWidth: width } =
					mapParentRef.current

				const webMercatorVP = new WebMercatorViewport({
					height,
					width
				})
				const [minLng, minLat, maxLng, maxLat] = bbox(selectedParcel)
				let padding = 100 // hard-coded experienced based value

				// You could certainly improve on this, but I found it easiest to simply jump
				// ship and set the padding to nothing instead of recalculating what would fit.
				if (padding * 2 > height || padding * 2 > width) padding = 0

				const toBounds = webMercatorVP.fitBounds(
					[
						[minLng, minLat],
						[maxLng, maxLat]
					],
					{ padding }
				)
				vp = {
					...vp,
					latitude: toBounds.latitude,
					longitude: toBounds.longitude,
					zoom: toBounds.zoom - ZOOM_OFFSET
				}
			}
			_onViewportChange(vp)
		},
		[]
	)

	const onFinishInputCoordsFailed = () => {}

	const layout = {
		labelCol: {
			span: 12
		},
		wrapperCol: {
			span: 24
		}
	}

	const countyCodeISOSelector = useSelector(countyCodeISO3)
	const flyToSelector = useSelector(isFlyTo)
	const geocodingIsFetchingSelector = useSelector(geocodingIsFetching)
	const isGeocoded = useSelector(isGeocodedSlice)
	const isSubmittingFootprintChecker = useSelector(
		geocodingIsSubmittingFootprintChecker
	)
	const marker = useSelector(markerSlice)
	const onMarkerMoveCoords = useSelector(onMarkerMoveCoordsSlice)
	const isPostingResultEdit = useSelector(isPostingResultEditSlice)
	const parcels = useSelector(parcelsToTrace)
	const parcelsMemo = useMemo(() => parcels, [parcels])
	const selectedResultIdSelector = useSelector(selectedResultId)
	const selectedJobId = useSelector(selectedJobIdSlice)
	const geocodedDataRawSelector = useSelector(geocodedDataRaw)
	const locationDirectFetch = useSelector(locationDirectFetchSlice)
	const selectedResults = useSelector(selectedResultsSlice)
	const isFetchingLocationByID = useSelector(isFetchingLocationByIDSlice)
	const alertPayload = useSelector(alertPayloadSlice)
	const isDisplayAlert = useSelector(isDisplayAlertSlice)
	const isLoadingAdditionalParcels = useSelector(
		isLoadingAdditionalParcelsSlice
	)
	const isDisplayingBannerdataSource = useSelector(
		displayBannerDataSourceInfo
	)
	const showLoadMoreParcelsCta = useSelector(showLoadMoreParcelsCtaSlice)
	const showLoadMoreBuildingsCta = useSelector(showLoadMoreBuildingsCtaSlice)
	const currentLocationIndex = useSelector(currentLocationIndexSlice)
	const displayClientCoordinatesPin = useSelector(
		displayClientCoordinatesPinSlice
	)
	const isMarkerMoving = useSelector(isMarkerMovingSlice)
	const isClickingUnselected = useSelector(isClickingUnselectedSlice)
	const mode = useSelector(currentMode)

	const isDisplayingToast = useSelector(isDisplayingToastSlice)

	const handleOkBackWarning = () => {
		setIsBackWarningModalVisible(false)
		if (
			!isPostingResultEdit &&
			!isLoadingAdditionalParcels &&
			!isSubmittingFootprintChecker
		) {
			dispatch(hideClientCoordinatesPin())
			// Should not save anything location wise instead save review progress
			dispatch(
				saveReviewState({
					selectedLocations: [],
					currentLocationIndex,
					jobId: selectedJobId
				})
			).then((saveReviewStateDate) => {
				// If saving review is a success
				if (
					saveReviewStateDate.type === saveReviewState.fulfilled.type
				) {
					dispatch(fetchResultsByJobId(selectedJobId)).then(() => {
						dispatch(setSaveReviewState(false))
						dispatch(setSelectedResults([]))
						dispatch(setSelectedRowKeys([]))
						navigate({
							pathname: "/location",
							search: `?jobId=${selectedJobId}`,
							hash: "",
							query: {}
						})
					})
				}
			})
		}
	}

	const handleCancelBackWarning = () => {
		setIsBackWarningModalVisible(false)
	}

	// Check whether Parcels or Centroid state have changed
	const pristineChecker = useCallback(
		(oldParcels, newParcels, oldCentroid, newCentroid) =>
			setParcelsPristine(
				isArrayEqual(oldParcels, newParcels) &&
					isEqual(oldCentroid, newCentroid)
			),
		[]
	)
	/* CALLBACK HOOKS */

	/* EFFECT HOOKS */
	useEffect(() => {
		window.onbeforeunload = () => true
		history.listen(({ action }) => {
			if (action === "POP") {
				setIsBackWarningModalVisible(true)
				return false
			}
			return true
		})
	}, [history])

	// Loading Job's result on component direct load
	useEffect(() => {
		if (!selectedJobId && location.pathname === "/map") {
			const params = new URLSearchParams(location.search.substring(1))
			const locationId = params.get("locationId")
			dispatch(fetchLocationById({ locationId }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		generateUnselectedPins(geocodedDataRawSelector, dispatch)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geocodedDataRawSelector])

	useEffect(() => {
		if (locationDirectFetch) {
			dispatch(resetMarker())
			dispatch(clearFootprintData())
			dispatch(setIsGeocoding(false))
			if (
				selectedResults[0]?.validation_status !==
				VALIDATION_STATUS_TYPE.ERROR
			) {
				dispatch(addHocResult(selectedResults[0].geo_data))
				dispatch(
					setCurrentFootprintMode(
						selectedResults[0].geo_data.processing_mode
					)
				)
				dispatch(
					setCurrentModeDescription(
						selectedResults[0].geo_data.processing_mode_reason_key
					)
				)
				dispatch(
					setCurrentModeReasonKey(
						selectedResults[0].geo_data.processing_mode_reason_text
					)
				)
			} else {
				dispatch(
					setAlertPayload({
						...selectedResults[0].geo_data,
						isMapAlert: true
					})
				)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locationDirectFetch])

	useEffect(() => {
		if (geocodedDataRawSelector[0]) {
			setParcelsPristine(
				isArrayEqual(
					[].concat(
						geocodedDataRawSelector[0].buildings,
						geocodedDataRawSelector[0].parcels
					),
					parcels.features
				)
			)
			if (
				selectedResults[currentLocationIndex]?.validation_status ===
				VALIDATION_STATUS_TYPE.ERROR
			) {
				dispatch(
					setAlertPayload({
						...selectedResults[currentLocationIndex].geo_data,
						type: ALERTS_TYPE.ERROR,
						isMapAlert: true
					})
				)
			}
			if (isEmpty(marker)) {
				setHoverTooltip(null)
				dispatch(
					setMarker({
						lon: geocodedDataRawSelector[0]?.lon ?? 0.0,
						lat: geocodedDataRawSelector[0]?.lat ?? 0.0
					})
				)
				_goToViewport(
					{
						lon: geocodedDataRawSelector[0]?.lon ?? 0.0,
						lat: geocodedDataRawSelector[0]?.lat ?? 0.0
					},
					flyToSelector,
					ZOOM_LEVEL,
					parcels.features.find(
						(parcel) => parcel.properties.selected
					)
				)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		parcels,
		geocodedDataRawSelector,
		_goToViewport,
		flyToSelector,
		marker,
		dispatch
	])

	useEffect(() => {
		if (selectedResults.length > 0) {
			if (
				selectedResults[currentLocationIndex]?.validation_status ===
				VALIDATION_STATUS_TYPE.ERROR
			) {
				dispatch(resetMarker())
				dispatch(clearFootprintData())
				dispatch(setIsGeocoding(false))
				dispatch(
					setAlertPayload({
						...selectedResults[currentLocationIndex].geo_data,
						isMapAlert: true
					})
				)
				dispatch(setFetchingLocationByID(false))
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedResults])

	useEffect(() => {
		if (geocodedDataRawSelector[0] && !isEmpty(marker)) {
			pristineChecker(
				[].concat(
					geocodedDataRawSelector[0].buildings,
					geocodedDataRawSelector[0].parcels
				),
				parcels.features,
				{
					lon: geocodedDataRawSelector[0].lon,
					lat: geocodedDataRawSelector[0].lat
				},
				marker
			)
		}
	}, [parcels, geocodedDataRawSelector, pristineChecker, marker])

	useEffect(() => {
		// Reset Marker on new loads
		if (geocodingIsFetchingSelector) {
			dispatch(setMarker({}))
		}
	}, [geocodingIsFetchingSelector, dispatch])

	useEffect(() => {
		if (
			displayClientCoordinatesPin &&
			selectedResults[currentLocationIndex] &&
			marker.lat &&
			marker.lon &&
			!isMarkerMoving
		) {
			_goToViewport(
				{
					lon: marker.lon,
					lat: marker.lat
				},
				flyToSelector,
				ZOOM_LEVEL,
				featureCollection([
					point([parseFloat(marker.lon), parseFloat(marker.lat)]),
					point([
						parseFloat(
							selectedResults[currentLocationIndex].input_data
								.raw_emp_attributes.LONGITUDE
						),
						parseFloat(
							selectedResults[currentLocationIndex].input_data
								.raw_emp_attributes.LATITUDE
						)
					])
				])
			)
		} else if (
			displayClientCoordinatesPin &&
			selectedResults[currentLocationIndex] &&
			!marker.lat &&
			!marker.lon &&
			!isMarkerMoving
		) {
			_goToViewport(
				{
					lon: parseFloat(
						selectedResults[currentLocationIndex].input_data
							.raw_emp_attributes.LONGITUDE
					),
					lat: parseFloat(
						selectedResults[currentLocationIndex].input_data
							.raw_emp_attributes.LATITUDE
					)
				},
				flyToSelector,
				ZOOM_LEVEL
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [displayClientCoordinatesPin, marker])

	/* EFFECT HOOKS */

	// FlyTo new coordinates
	const onFinishInputCoords = (values) => {
		dispatch(setShowLoadMoreBuildingsCta(false))
		dispatch(setShowLoadMoreParcelsCta(false))
		setIsGeocodeModalVisible(false)
		setNewGeocodeInputPristine(true)
		setHoverTooltip(null)
		if (values.coords) {
			const _lon = values.coords.split(",")[1]
			const _lat = values.coords.split(",")[0]
			_goToViewport(
				{
					lon: parseFloat(_lon),
					lat: parseFloat(_lat)
				},
				flyToSelector,
				ZOOM_LEVEL
			)
			dispatch(
				setMarker({
					lon: parseFloat(_lon),
					lat: parseFloat(_lat)
				})
			)
			dispatch(setOnMarkerMoveCoords({ lon: _lon, lat: _lat }))
			dispatch(
				setCurrentFootprintMode(
					FOOTPRINT_MODE.FOOTPRINT_BY_GEOCODE_DISPLAY_ONLY
				)
			)
			dispatch(
				getFootprintByCoordinates({
					lon: parseFloat(_lon),
					lat: parseFloat(_lat)
				})
			).then(async (data) => {
				if (data.type === getFootprintByCoordinates.fulfilled.type) {
					dispatch(setFootprintIsPristine(false))
					dispatch(setIsDisplayingToast(true))
					await displayToast(
						dispatch,
						"SUCCESS_MODIFIFY_GEOCODE",
						ALERTS_TYPE.SUCCESS,
						1000
					)
					dispatch(setIsDisplayingToast(false))
					dispatch(setHasUserManuallyEditGeocode(true))
					setDisabledModifyGeocode(true)
				}
			})
		}
	}

	const BUILDING_COLOR = "#00008F"
	const BUILDING_OUTLINE_COLOR = "#00008F"
	const BUILDING_UNSELECTED_COLOR = "#00008F"
	const BUILDING_UNSELECTED_COLOR_OSM = "transparent"
	const PARCEL_COLOR = "#fafafa"
	const BUILDING_SELECTED_COLOR_OSM = "#00008F"
	const PARCEL_OUTLINE_HOVER_COLOR = "#dd7358"
	const PARCEL_FILL_HOVER_COLOR = "#dd7358"
	const PARCEL_UNSELECTED_COLOR = "#e28972"

	const circleTargetLayer = {
		id: "circle-target-layer",
		type: "fill",
		paint: {
			"fill-outline-color": "#bc9d45",
			"fill-color": "#bc9d45",
			"fill-opacity": 0.35
		},
		filter: ["==", "id", "circle-target"]
	}
	const circleTargetLayerOutline = {
		id: "circle-target-layer-outline",
		type: "line",
		layout: {
			"line-cap": "round"
		},
		paint: {
			"line-color": "#bc9d45",
			"line-width": 2,
			"line-opacity": 1,
			"line-dasharray": [2, 4]
		},
		filter: ["==", "id", "circle-target"]
	}

	const buildingLayer = {
		id: "building-layer",
		type: "fill",
		paint: {
			"fill-outline-color": BUILDING_COLOR,
			"fill-color": BUILDING_COLOR,
			"fill-opacity": 0.4
		},
		filter: ["==", "geometry_category", "building"]
	}

	const buildingLayerOSM = {
		id: "building-layer",
		type: "fill",
		paint: {
			"fill-outline-color": BUILDING_COLOR,
			"fill-color": BUILDING_UNSELECTED_COLOR_OSM
		},
		filter: ["==", "geometry_category", "building"]
	}

	const buildingLayerUnselected = {
		id: "building-unselected-layer",
		type: "fill",
		paint: {
			"fill-color": BUILDING_UNSELECTED_COLOR,
			"fill-opacity": 1,
			"fill-pattern": "pattern-packed-blue-dots"
		},
		filter: [
			"all",
			["in", "selected", false],
			["in", "highlight", true],
			["==", "geometry_category", "building"]
		]
	}

	const buildingLayerUnselectedOSM = {
		id: "building-unselected-layer",
		type: "fill",
		paint: {
			"fill-color": BUILDING_UNSELECTED_COLOR_OSM,
			"fill-opacity": 0
		},
		filter: [
			"all",
			["in", "selected", false],
			["in", "highlight", true],
			["==", "geometry_category", "building"]
		]
	}

	const buildingLayerUnselectedOutline = {
		id: "building-unselected-layer-outline",
		type: "line",
		paint: {
			"line-color": BUILDING_UNSELECTED_COLOR,
			"line-width": 2,
			"line-opacity": 1,
			"line-dasharray": [1, 3]
		},
		filter: [
			"all",
			["in", "selected", false],
			["==", "geometry_category", "building"]
		]
	}

	const buildingLayerOutlineHovered = {
		id: "building-layer-outline-hovered",
		type: "line",
		paint: {
			"line-color": BUILDING_COLOR,
			"line-width": 4
		},
		filter: ["in", "isHovered", true]
	}

	const buildingParcelLayer = {
		id: "building-parcel-layer",
		type: "fill",
		paint: {
			"fill-color": PARCEL_COLOR,
			"fill-opacity": 0.65
		},
		filter: ["in", "selected", true]
	}

	const buildingParcelLayerOSM = {
		id: "building-parcel-layer",
		type: "fill",
		paint: {
			"fill-color": BUILDING_SELECTED_COLOR_OSM,
			"fill-opacity": 0.65
		},
		filter: ["in", "selected", true]
	}

	const selectedParcelOutline = {
		id: "selected-parcel-layer-outline",
		type: "line",
		paint: {
			"line-color": BUILDING_OUTLINE_COLOR,
			"line-width": 2
		},
		filter: ["in", "selected", true]
	}

	const buildingParcelLayerOutlineHover = {
		id: "building-parcel-layer-outline-hovered",
		type: "line",
		paint: {
			"line-color": PARCEL_OUTLINE_HOVER_COLOR,
			"line-width": 4
		},
		filter: [
			"all",
			["in", "selected", false],
			["==", "geometry_category", "parcel"]
		]
	}

	const buildingParcelSelectedLayerOutlineHover = {
		id: "building-parcel-selected-layer-outline-hovered",
		type: "line",
		paint: {
			"line-color": BUILDING_OUTLINE_COLOR,
			"line-width": 4
		},
		filter: ["in", "selected", true]
	}

	// Parcels outlines
	const buildingParceLayerOutline = {
		id: "building-parcel-layer-outline",
		type: "line",
		layout: {
			"line-cap": "round"
		},
		paint: {
			"line-color": PARCEL_UNSELECTED_COLOR, // BUILDING_OUTLINE_COLOR,
			"line-width": 2,
			"line-opacity": 1,
			"line-dasharray": [1, 3]
		},
		filter: [
			"all",
			["in", "selected", false],
			["==", "geometry_category", "building"]
		]
	}

	const buildingParceLayerOutlineOSM = {
		id: "building-parcel-layer-outline",
		type: "line",
		layout: {
			"line-cap": "round"
		},
		paint: {
			"line-color": BUILDING_UNSELECTED_COLOR, // BUILDING_OUTLINE_COLOR,
			"line-width": 2,
			"line-opacity": 1,
			"line-dasharray": [1, 3]
		},
		filter: [
			"all",
			["in", "selected", false],
			["==", "geometry_category", "building"]
		]
	}

	const parcelsLayer = {
		id: "parcels-layer",
		type: "fill",
		paint: {
			"fill-opacity": 1,
			"fill-pattern": "pattern-packed-sienna-200-dots"
		},
		filter: [
			"all",
			["in", "selected", false],
			["==", "geometry_category", "parcel"]
		]
	}

	const parcelsLayerHovered = {
		id: "parcels-layer-hovered",
		type: "fill",
		paint: {
			"fill-color": PARCEL_FILL_HOVER_COLOR,
			"fill-opacity": 0.45
		},
		filter: [
			"all",
			["in", "selected", false],
			["==", "geometry_category", "parcel"]
		]
	}

	const parcelsLayerHoveredOSM = {
		id: "parcels-layer-hovered",
		type: "fill",
		paint: {
			"fill-color": "transparent"
		},
		filter: [
			"all",
			["in", "selected", false],
			["==", "geometry_category", "building"]
		]
	}

	const handleAddRemoveBuilding = (clickedBuilding) => {
		setHoverTooltip(null)
		if (geocodedDataRawSelector.length > 0 && clickedBuilding) {
			setSpinnerCoords({ ...clickedBuilding.properties.centroid })
			if (!clickedBuilding.properties.selected) {
				dispatch(highlightBuilding(clickedBuilding.properties.guid))
			} else {
				dispatch(unhighlightBuilding(clickedBuilding.properties.guid))
			}
		}
	}

	const handleAddRemoveParcel = (clickedParcel) => {
		setHoverTooltip(null)
		setLocalClickedParcelId("")
		if (geocodedDataRawSelector.length > 0 && clickedParcel) {
			setSpinnerCoords({
				lon: getCoord(
					centroid(polygon(clickedParcel.geometry.coordinates))
				)[0],
				lat: getCoord(
					centroid(polygon(clickedParcel.geometry.coordinates))
				)[1]
			})
			if (!clickedParcel.properties.selected) {
				dispatch(setClickedParcel(clickedParcel.properties.parcelid))
				dispatch(
					postFootprintChecker({
						geo_data: clickedParcel.geometry,
						parcelid: clickedParcel.properties.parcelid,
						countyCodeISO3: countyCodeISOSelector
					})
				).then(async (data) => {
					if (data.type === postFootprintChecker.fulfilled.type) {
						dispatch(setFootprintIsPristine(false))
						await dispatch(
							setAlertPayload({
								type: ALERTS_TYPE.SUCCESS,
								code: "SUCCESS_ADD_PARCELS",
								isMapAlert: true
							})
						)
						setTimeout(() => {
							dispatch(displayAlert(false))
						}, 2000)
						dispatch(setIsDisplayingToast(false))
					}
				})
			} else {
				dispatch(highlightParcel(JSON.stringify(clickedParcel)))
				dispatch(setFootprintIsPristine(false))
			}
		}
	}

	const onFloatingInfoPanelClose = () => {
		dispatch(setClickedParcel(""))
		setMapPointer({ style: "grab" })
		setHoverTooltip(null)
	}

	const _onClickParcel = (event) => {
		const clickedParcel =
			event.features &&
			geocodedDataRawSelector[0]?.backend_helper_mode === "osm"
				? event.features.find(
						(f) =>
							f.layer.id === "building-parcel-layer" ||
							f.layer.id === "building-layer" ||
							f.layer.id === "building-layer-outline-hovered" ||
							f.layer.id === "building-unselected-layer"
				  )
				: event.features.find(
						(f) =>
							f.layer.id === "parcels-layer" ||
							f.layer.id === "building-parcel-layer" ||
							f.layer.id === "building-layer" ||
							f.layer.id === "building-layer-outline-hovered" ||
							f.layer.id === "building-unselected-layer" ||
							f.layer.id === "parcels-layer-hovered"
				  )
		if (clickedParcel && isClickingUnselected === false) {
			if (isBuilding(clickedParcel.properties.geometry_category)) {
				setHoverTooltip({
					type: "BUILDING",
					x: event.originalEvent.x,
					y: event.originalEvent.y,
					clickedParcel
				})
				setBuildingToBeHovered({
					guid: clickedParcel.properties.parcelid
				})
				setParcelToBeHovered(null)
			} else {
				const clickedParcelFromStore = parcels.features.find((f) => {
					if (f.properties.parcelid) {
						return (
							f.properties.parcelid ===
							clickedParcel.properties.parcelid
						)
					}
					return null
				})
				setMapPointer({ style: "pointer" })
				setHoverTooltip(null)
				if (
					clickedParcel?.properties.parcelid === localClickedParcelId
				) {
					setLocalClickedParcelId("")
					dispatch(unHoverAllParcels())
				} else {
					clickedParcel.properties.selected =
						clickedParcelFromStore.properties.selected
					setLocalClickedParcelId(clickedParcel.properties.parcelid)
					setHoverTooltip({
						type: "PARCEL",
						x: event.originalEvent.x,
						y: event.originalEvent.y,
						clickedParcel
					})
					setParcelToBeHovered({
						parcelid: clickedParcel.properties.parcelid
					})
					setBuildingToBeHovered(null)
				}
			}
		} else {
			setMapPointer({ style: "grab" })
			setHoverTooltip(null)
		}
	}

	const _onSaveClick = (feedback) =>
		new Promise((resolve) => {
			dispatch(
				postEditResult({
					id: selectedResultIdSelector,
					feedback,
					buildings: parcels.features.filter(buildingPredicate),
					coords: marker,
					parcels: parcels.features.filter(parcelPredicate)
				})
			).then((data) => resolve(data))
		})

	const [dimensions, setDimensions] = useState({
		width: 0,
		height: 0
	})

	useLayoutEffect(() => {
		if (mapParentRef.current) {
			setDimensions({
				width: mapParentRef.current.offsetWidth,
				height: mapParentRef.current.offsetHeight
			})
		}
	}, [])

	// [LGO-1222] Select the geocode field when editing
	// Source: https://stackoverflow.com/a/72392048/3900714
	// Source: https://ant.design/components/input/#Input-Methods
	const coordsInputRef = useCallback(
		(ref) => {
			// Selecting all input only when value is pristine
			if (ref && newGeocodeInputPristine) {
				ref.focus({ cursor: "all" })
			}
		},
		[newGeocodeInputPristine]
	)
	return (
		<>
			{isGeocoded ? null : (
				<InfoControlPanel
					onSaveHandler={_onSaveClick}
					onHover={() => setHoverTooltip(null)}
					toSave={isParcelsPristine}
					resetTooltip={() => setHoverTooltip(null)}
					drawnParcels={parcels.features}
					handleOkBackWarning={handleOkBackWarning}
				/>
			)}
			<div className="map__superContainer" ref={mapParentRef}>
				<Topbar
					onEditGeocode={() => {
						showModal()
					}}
					coordinates={
						isEmpty(onMarkerMoveCoords.lngLat)
							? marker
							: {
									lon: parseFloat(
										onMarkerMoveCoords.lngLat.lon
									),
									lat: parseFloat(
										onMarkerMoveCoords.lngLat.lat
									)
							  }
					}
				/>
				<BannerDataSource />
				<WarningModal
					title="Back to the list of locations?"
					isWarningVisible={isBackWarningModalVisible}
					handleCancel={handleCancelBackWarning}
					actions={[
						<Button
							key="cancel"
							data-testid="warning-back-cancel-testid"
							className="axa-button--ghost geocode__submitButton"
							type="primary"
							size="large"
							onClick={() => handleCancelBackWarning()}
						>
							Cancel
						</Button>,
						<Button
							key="submit"
							className="axa-button geocode__submitButton"
							type="primary"
							size="large"
							onClick={() => handleOkBackWarning()}
						>
							Go to the full list of locations
						</Button>
					]}
				>
					<p>
						The back button will take you to the{" "}
						<span className="warning__description--bold">
							full List of locations
						</span>
						. If you just want to to take a step back to the
						previous location in the list, “
						<span className="warning__description--bold">
							Cancel
						</span>
						” this action and hit “
						<span className="warning__description--bold">
							Previous location
						</span>
						” on top of the map.
					</p>
				</WarningModal>
				<div className="map__container">
					<div
						className="load-more-parcels--container"
						style={{
							zIndex: 12,
							// eslint-disable-next-line
							marginTop: isDisplayingBannerdataSource
								? isDisplayingToast || isDisplayAlert
									? "84px"
									: "46px"
								: "0px"
						}}
					>
						{geocodedDataRawSelector[0]
							?.available_processing_modes &&
						geocodedDataRawSelector[0]?.available_processing_modes
							.length > 1 &&
						(showLoadMoreParcelsCta || showLoadMoreBuildingsCta) ? (
							<Button
								data-testid="loadmore-testid"
								className="load-more-parcels--button"
								type="primary"
								icon={
									<SyncOutlined className="load-more-parcels--loading" />
								}
								loading={isLoadingAdditionalParcels}
								size="medium"
								onMouseEnter={() => {
									const currentMapCenter = mapRefCenter
										?.getMap()
										?.getCenter()
									dispatch(
										addCircleTarget({
											lon: currentMapCenter?.lng,
											lat: currentMapCenter?.lat
										})
									)
								}}
								onMouseLeave={() => {
									if (!isLoadingAdditionalParcels) {
										dispatch(removeCircleTarget())
									}
								}}
								onClick={(e) => {
									e.currentTarget.blur()
									setHoverTooltip(null)
									dispatch(
										addCircleTarget({
											...coordsAfterDrag
										})
									)
									if (mode === "normal") {
										dispatch(
											getAdditionalParcels({
												...coordsAfterDrag,
												country: countyCodeISOSelector
											})
										).then(async (data) => {
											if (
												data.type ===
												getAdditionalParcels.fulfilled
													.type
											) {
												dispatch(
													setFootprintIsPristine(
														false
													)
												)
											}
										})
									} else {
										dispatch(setIsLoadingMapData(true))
										dispatch(
											setIsLoadingAdditionalBuildings(
												true
											)
										)
										dispatch(
											postChangeFootprintMode({
												...coordsAfterDrag,
												action: "buildings_around_point"
											})
										)
									}
								}}
							>
								{mode === "normal"
									? "Load more parcels"
									: "Load more buildings"}
							</Button>
						) : null}
					</div>
					{isDisplayAlert && alertPayload.isMapAlert ? (
						<Alerts
							alertCode={alertPayload?.code ?? "Title"}
							isMapAlert={alertPayload.isMapAlert}
							type={alertPayload.type}
						/>
					) : null}
					{isFetchingLocationByID ? null : (
						<MapLayersSwitcher
							setMapLayer={(value) => {
								setMapLayer({ stringId: value })
							}}
						/>
					)}
					{hoverTooltip ? (
						<InfoTooltip
							type={hoverTooltip.type}
							position={{
								x: hoverTooltip.x,
								y: hoverTooltip.y
							}}
							clickedParcel={JSON.parse(
								JSON.stringify(hoverTooltip.clickedParcel)
							)}
							handleAddRemoveParcel={handleAddRemoveParcel}
							handleAddRemoveBuilding={handleAddRemoveBuilding}
							closeInfoTooltipHandler={() =>
								setHoverTooltip(null)
							}
							onFloatingInfoPanelClose={() => {
								onFloatingInfoPanelClose()
								dispatch(unHoverAllParcels())
								setLocalClickedParcelId("")
							}}
						/>
					) : null}
					{isFetchingLocationByID ? (
						<FullscrenMapLoader />
					) : (
						<MapGL
							className="map__wrapper"
							interactiveLayerIds={[
								"parcels-layer",
								"building-parcel-layer",
								"building-layer",
								"building-layer-outline-hovered",
								"building-unselected-layer",
								"parcels-layer-hovered"
							]}
							// Load JSON styles which includes HERE POIs
							mapStyle={`../../map-style-${mapLayer.stringId}.json`}
							onViewportChange={_onViewportChange}
							onMove={(evt) => _onViewportChange(evt.viewState)}
							mapboxApiAccessToken={
								process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
							}
							ref={(map) => {
								mapRefCenter = map
							}}
							width={`${dimensions.width}px`}
							height={`${dimensions.height}px`}
							flyTo={_goToViewport}
							onClick={_onClickParcel}
							getCursor={() => mapPointer.style}
							onMouseMove={onFeatureHover}
							onDrag={(evt) => {
								evt.originalEvent.preventDefault()
								if (
									!isEmpty(marker) &&
									enableLoadMoreParcelsForSupportedCountries(
										countyCodeISOSelector
									)
								) {
									dispatch(setShowLoadMoreParcelsCta(true))
								}
								if (!isEmpty(marker) && mode === "osm") {
									dispatch(setShowLoadMoreBuildingsCta(true))
								}
								setcoordsAfterDrag({
									lat: evt.viewState.latitude,
									lon: evt.viewState.longitude
								})
							}}
							{...viewport}
						>
							<ScaleControl
								className="scale__container"
								style={{
									position: "absolute",
									// bottom: "10px",
									left: "120px"
								}}
								maxWidth={100}
								unit="metric"
							/>
							<div>
								<WarningModal
									title="Insert a new geocode"
									isWarningVisible={isGeocodeModalVisible}
									handleCancel={handleCancelGeocode}
									handleConfirm={handleOkGeocode}
									actions={[
										<Button
											key="submit"
											form="geocodeForm"
											className="axa-button geocode__submitButton"
											type="primary"
											size="large"
											onClick={undefined}
											htmlType="submit"
											disabled={
												disabledModifyGeocode ||
												typeof marker.lat !==
													"number" ||
												typeof marker.lon !== "number"
											}
										>
											Modify geocode
										</Button>
									]}
								>
									<div className="geocode__modalContainer">
										<p>
											<span className="warning__description--bold">
												Enter latitude and longitude
											</span>{" "}
											(in this order)
										</p>
										<Form
											layout="vertical"
											name="basic"
											form={form}
											onFieldsChange={() =>
												setDisabledModifyGeocode(
													form
														.getFieldsError()
														.some(
															(field) =>
																field.errors
																	.length > 0
														)
												)
											}
											initialValues={{
												coords: `${
													isEmpty(
														onMarkerMoveCoords.lngLat
													)
														? marker.lat
														: onMarkerMoveCoords
																.lngLat.lat
												}, ${
													isEmpty(
														onMarkerMoveCoords.lngLat
													)
														? marker.lon
														: onMarkerMoveCoords
																.lngLat.lon
												}`
											}}
											id="geocodeForm"
											size="small"
											onFinish={onFinishInputCoords}
											onFinishFailed={
												onFinishInputCoordsFailed
											}
											{...layout}
										>
											<Form.Item
												className="geocode__input__container"
												name="coords"
												rules={[
													{
														required: true,
														message:
															"Please input Latitude, Longitude!",
														pattern:
															/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/g
													}
												]}
											>
												<Input
													name="coords"
													ref={coordsInputRef}
													onChange={() =>
														setNewGeocodeInputPristine(
															false
														)
													}
													prefix={
														<EnvironmentFilled
															style={{
																fontSize:
																	"24px",
																color: "#00008F"
															}}
														/>
													}
												/>
											</Form.Item>
										</Form>
									</div>
								</WarningModal>
								<UnselectedMarkers />
								<SelectedMarker
									onMoveCoord={
										!isEmpty(marker)
											? // &&
											  // enableLoadMoreParcelsForSupportedCountries(
											  // 	countyCodeISOSelector
											  // )
											  onFinishInputCoords
											: () => {}
									}
								/>
								{selectedResults[currentLocationIndex] &&
								displayClientCoordinatesPin ? (
									<Marker
										anchor="center"
										offset={[0, 0]}
										latitude={parseFloat(
											selectedResults[
												currentLocationIndex
											]?.input_data?.raw_emp_attributes
												?.LATITUDE
										)}
										longitude={parseFloat(
											selectedResults[
												currentLocationIndex
											]?.input_data?.raw_emp_attributes
												?.LONGITUDE
										)}
										style={{ zIndex: 9 }}
									>
										<Pin
											size={34}
											color="#5B7B73"
											isClientPin={
												displayClientCoordinatesPin
											}
											label="Coordinates"
										/>
									</Marker>
								) : null}
							</div>

							{isSubmittingFootprintChecker ? (
								<Marker
									longitude={spinnerCoords.lon}
									latitude={spinnerCoords.lat}
									anchor="center"
								>
									<Spin
										size="large"
										className="parcel--loading"
									/>
								</Marker>
							) : null}
							{mode === "osm" && (
								<Source
									id="building-layer"
									type="geojson"
									data={parcelsMemo}
								>
									<Layer
										{...parcelsLayer}
										filter={[
											"all",
											["in", "selected", false],
											[
												"==",
												"geometry_category",
												"parcel"
											]
										]}
									/>
									<Layer {...buildingParcelLayerOSM} />
									<Layer {...buildingLayerUnselectedOSM} />

									<Layer
										{...buildingLayerUnselectedOutline}
									/>
									<Layer {...buildingParceLayerOutlineOSM} />
									<Layer
										{...buildingParcelLayerOutlineHover}
									/>
									<Layer
										{...buildingParcelSelectedLayerOutlineHover}
									/>
									<Layer {...buildingLayerOSM} />
									<Layer
										{...buildingLayerOutlineHovered}
										filter={filterForHoveredBuilding}
									/>
									<Layer {...parcelsLayerHoveredOSM} />
									<Layer {...circleTargetLayer} />
									<Layer {...circleTargetLayerOutline} />
								</Source>
							)}
							{mode === "normal" && (
								<Source
									id="building-layer"
									type="geojson"
									data={parcelsMemo}
								>
									<Layer
										{...parcelsLayer}
										filter={[
											"all",
											["in", "selected", false],
											filterForNotHoveredParcel
										]}
									/>
									<Layer {...buildingParcelLayer} />
									<Layer {...buildingLayerUnselected} />
									<Layer
										{...buildingLayerUnselectedOutline}
									/>
									<Layer {...buildingParceLayerOutline} />
									<Layer {...selectedParcelOutline} />
									<Layer {...buildingLayer} />
									<Layer
										{...buildingParcelLayerOutlineHover}
									/>
									<Layer
										{...buildingParcelSelectedLayerOutlineHover}
									/>
									<Layer
										{...buildingLayerOutlineHovered}
										filter={filterForHoveredBuilding}
									/>
									<Layer
										{...parcelsLayerHovered}
										filter={[
											"all",
											["in", "selected", false],
											filterForHoveredParcel
										]}
									/>
									<Layer {...circleTargetLayer} />
									<Layer {...circleTargetLayerOutline} />
								</Source>
							)}
							<NavigationControl
								showCompass={false}
								position="bottom-left"
								style={{
									position: "absolute",
									bottom: "10em",
									margin: "20px"
								}}
							/>
							{mode === "normal" && (
								<div className="legend__container">
									<div className="legend__row">
										<div className="legend__item legend__parcels--unselected" />
										<span className="legend_text">
											Unselected parcel
										</span>
									</div>
									<div className="legend__row">
										<div className="legend__item legend__buildingParcels--added" />
										<span className="legend_text">
											Parcel added to the location
											footprint
										</span>
									</div>
									<div className="legend__row">
										<div className="legend__item legend__building--added" />
										<span className="legend_text">
											Building border
										</span>
									</div>
								</div>
							)}
							{mode === "osm" && (
								<div className="legend__container">
									<div className="legend__row">
										<div className="legend__item legend__parcels--unselected" />
										<span className="legend_text">
											Pseudo parcel
										</span>
									</div>
									<div className="legend__row">
										<div className="legend__item legend__building--unselected--osm" />
										<span className="legend_text">
											Unselected buildings
										</span>
									</div>
									<div className="legend__row">
										<div className="legend__item legend__building--added--osm" />
										<span className="legend_text">
											Selected buildings
										</span>
									</div>
								</div>
							)}
						</MapGL>
					)}
				</div>
			</div>
		</>
	)
}

export default Map
