import React from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"

import { useDispatch } from "react-redux"

import WarningModal from "components/warningModal/WarningModal"

import {
	hideClientCoordinatesPin,
	resetMarker,
	setOnMarkerMoveCoords
} from "features/mapSlice"

import {
	addHocResult,
	setIsGeocoding,
	clearFootprintData,
	setHasUserManuallyEditGeocode,
	setFootprintIsPristine
} from "features/footprintSlice"

import { fetchResultsByLocationId } from "features/jobsResultsSlice"

const { Paragraph } = Typography

const UndoModal = ({ id, onDismiss, isVisible }) => {
	const dispatch = useDispatch()

	return (
		<WarningModal
			title="Undo all changes for current location"
			isWarningVisible={isVisible}
			handleCancel={() => onDismiss()}
			handleConfirm={() => {
				dispatch(setOnMarkerMoveCoords(undefined))
				dispatch(hideClientCoordinatesPin())
				dispatch(resetMarker())
				dispatch(clearFootprintData())
				dispatch(setIsGeocoding(false))
				dispatch(setHasUserManuallyEditGeocode(false))
				dispatch(fetchResultsByLocationId(id)).then((data) => {
					if (data.type === fetchResultsByLocationId.fulfilled.type) {
						dispatch(setFootprintIsPristine(true))
						dispatch(addHocResult(data.payload.items[0].geo_data))
					}
				})
				onDismiss()
			}}
		>
			<Paragraph>
				Confirm you want to undo all changes to this location, including
				added or removed parcels, and modified geo codes.
			</Paragraph>
		</WarningModal>
	)
}

UndoModal.propTypes = {
	id: PropTypes.string,
	onDismiss: PropTypes.func,
	isVisible: PropTypes.bool
}

UndoModal.defaultProps = {
	id: "",
	onDismiss: () => {},
	isVisible: false
}

export default UndoModal
