const latestExchangeDump = {
	motd: {
		msg: "If you or your company use this project or like what we doing, please consider backing us so we can continue maintaining and evolving this project.",
		url: "https://exchangerate.host/#/donate"
	},
	success: true,
	base: "USD",
	date: "2022-01-10",
	rates: {
		AED: 3.672476,
		AFN: 104.774852,
		ALL: 106.92661,
		AMD: 481.245223,
		ANG: 1.793414,
		AOA: 554.31763,
		ARS: 102.759851,
		AUD: 1.392364,
		AWG: 1.799989,
		AZN: 1.69988,
		BAM: 1.722304,
		BBD: 1.999971,
		BDT: 85.48977,
		BGN: 1.72431,
		BHD: 0.377273,
		BIF: 1989.091447,
		BMD: 0.999632,
		BND: 1.352244,
		BOB: 6.849765,
		BRL: 5.634533,
		BSD: 0.999996,
		BTC: 0.000024,
		BTN: 73.92302,
		BWP: 11.601793,
		BYN: 2.575998,
		BZD: 2.005312,
		CAD: 1.264828,
		CDF: 1998.001341,
		CHF: 0.918823,
		CLF: 0.030156,
		CLP: 829.675792,
		CNH: 6.380813,
		CNY: 6.376409,
		COP: 4016.115006,
		CRC: 638.84956,
		CUC: 0.999997,
		CUP: 25.742847,
		CVE: 97.474314,
		CZK: 21.571915,
		DJF: 177.225291,
		DKK: 6.550998,
		DOP: 57.186032,
		DZD: 139.463817,
		EGP: 15.722619,
		ERN: 14.997032,
		ETB: 49.342457,
		EUR: 0.881118,
		FJD: 2.135865,
		FKP: 0.736091,
		GBP: 0.735844,
		GEL: 3.094453,
		GGP: 0.73553,
		GHS: 6.154821,
		GIP: 0.736005,
		GMD: 52.786177,
		GNF: 9084.615684,
		GTQ: 7.681092,
		GYD: 208.136436,
		HKD: 7.796139,
		HNL: 24.432961,
		HRK: 6.619026,
		HTG: 99.460329,
		HUF: 316.005504,
		IDR: 14340.940135,
		ILS: 3.113796,
		IMP: 0.735537,
		INR: 74.236522,
		IQD: 1453.496842,
		IRR: 42238.664901,
		ISK: 128.487558,
		JEP: 0.7359,
		JMD: 153.635769,
		JOD: 0.709539,
		JPY: 115.618604,
		KES: 112.8153,
		KGS: 84.779084,
		KHR: 4057.892288,
		KMF: 433.384471,
		KPW: 899.758601,
		KRW: 1197.316338,
		KWD: 0.303447,
		KYD: 0.828948,
		KZT: 433.195991,
		LAK: 11204.659464,
		LBP: 1506.246325,
		LKR: 201.824814,
		LRD: 147.086403,
		LSL: 15.588041,
		LYD: 4.580031,
		MAD: 9.241556,
		MDL: 17.847311,
		MGA: 3956.28888,
		MKD: 54.223908,
		MMK: 1768.790424,
		MNT: 2859.90564,
		MOP: 7.992595,
		MRU: 36.307934,
		MUR: 43.691397,
		MVR: 15.446426,
		MWK: 813.195168,
		MXN: 20.383287,
		MYR: 4.208613,
		MZN: 63.825035,
		NAD: 15.631212,
		NGN: 410.863373,
		NIO: 35.321986,
		NOK: 8.840235,
		NPR: 118.276327,
		NZD: 1.476705,
		OMR: 0.385146,
		PAB: 1.000325,
		PEN: 3.950404,
		PGK: 3.502018,
		PHP: 51.314422,
		PKR: 175.938785,
		PLN: 4.003658,
		PYG: 6918.118763,
		QAR: 3.637354,
		RON: 4.355012,
		RSD: 103.473668,
		RUB: 75.523685,
		RWF: 1028.965189,
		SAR: 3.753795,
		SBD: 8.086245,
		SCR: 14.136982,
		SDG: 437.382663,
		SEK: 9.063068,
		SGD: 1.35687,
		SHP: 0.735969,
		SLL: 11319.462739,
		SOS: 578.861385,
		SRD: 21.257025,
		SSP: 130.226078,
		STD: 21161.261246,
		STN: 21.845124,
		SVC: 8.704771,
		SYP: 2511.826782,
		SZL: 15.557407,
		THB: 33.613439,
		TJS: 11.236335,
		TMT: 3.499768,
		TND: 2.880555,
		TOP: 2.284884,
		TRY: 13.792124,
		TTD: 6.752436,
		TWD: 27.611834,
		TZS: 2298.313877,
		UAH: 27.353673,
		UGX: 3526.641076,
		USD: 1,
		UYU: 44.603525,
		UZS: 10771.047794,
		VES: 4.629344,
		VND: 22685.086301,
		VUV: 113.156108,
		WST: 2.601069,
		XAF: 577.618734,
		XAG: 0.045581,
		XAU: 0.001173,
		XCD: 2.702059,
		XDR: 0.712295,
		XOF: 577.618259,
		XPD: 0.001744,
		XPF: 105.081014,
		XPT: 0.001023,
		YER: 250.183782,
		ZAR: 15.573683,
		ZMW: 16.768264,
		ZWL: 321.914639
	}
}

export const toUSD = (amount, currency = "USD") => {
	if (Number.isNaN(amount)) return NaN
	let useCurrency = "USD"
	if (currency !== "") useCurrency = currency
	if (typeof currency !== "string") return NaN
	if (!latestExchangeDump.rates[useCurrency]) return 0
	return amount * (1 / latestExchangeDump.rates[useCurrency])
}

export const fromUSDTo = (amount, currency = "USD") => {
	if (Number.isNaN(amount)) return NaN
	let useCurrency = "USD"
	if (currency !== "") useCurrency = currency
	if (typeof currency !== "string") return NaN
	if (!latestExchangeDump.rates[useCurrency]) return 0
	return amount * latestExchangeDump.rates[useCurrency]
}
