import React, { useState } from "react"
import PropTypes from "prop-types"

import { confidenceLabelBuilder } from "components/confidenceBars/ConfidenceBars"

import { useTranslation } from "react-i18next"

import { Select, Tag } from "antd"

import { HAS_FOOTPRINTS_MATCHING } from "helpers/constants"
import useMediaQuery from "styles/useMediaQuery"

const { Option } = Select

export const tagRenderer = ({ label, value, closable, onClose, id }) => (
	<Tag
		color={value}
		onMouseDown={
			/* istanbul ignore next */ (event) => {
				event.preventDefault()
				event.stopPropagation()
			}
		}
		closable={closable}
		onClose={onClose}
		closeIcon={
			<svg
				data-testid={`delete-${id}-${value}-filter-testid`}
				width="16"
				height="16"
				viewBox="0 0 16 16"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M12.845 14.468 8 9.623l-4.845 4.845-1.623-1.623L6.377 8 1.532 3.155l1.623-1.623L8 6.377l4.845-4.845 1.623 1.623L9.623 8l4.845 4.845z"
					fill="#00008F"
					fillRule="evenodd"
				/>
			</svg>
		}
		className="filter__tag"
	>
		{id !== "confidence"
			? label?.props?.children[0]
			: confidenceLabelBuilder(value)}
	</Tag>
)

const FilterAction = ({
	filter,
	title,
	placeholder,
	maxLenght,
	onChange,
	id,
	children,
	...props
}) => {
	const { t } = useTranslation()
	const [searchInput, setSearchInput] = useState("")
	const isDesktop = useMediaQuery("(min-width: 1100px)")

	return (
		<div className="filterAction__container--no-grow">
			<span className="filterAction__label">{t(title)}</span>
			<Select
				className="filtersActions__filtersContainer--selects multiFilter__container"
				placeholder={t(placeholder)}
				allowClear
				data-testid={`filter-select-${id}-testid`}
				showArrow
				searchValue={searchInput}
				onSearch={(value) => {
					if (value.length < searchInput.length) setSearchInput(value)
					if (searchInput.length >= maxLenght) return
					setSearchInput(value)
				}}
				mode="multiple"
				maxTagCount={1}
				maxTagPlaceholder={
					<span className="filter__tag--counter">{`+${
						filter.length - 1
					}`}</span>
				}
				tagRender={({ label, value, closable, onClose }) =>
					tagRenderer({
						label,
						value,
						closable,
						onClose,
						id
					})
				}
				filterOption={(input, option) => {
					if (id === "footprint")
						return (
							HAS_FOOTPRINTS_MATCHING[option.value]
								.toLowerCase()
								.indexOf(input?.toLowerCase()) >= 0
						)
					if (option["data-searchlabel"])
						return (
							option["data-searchlabel"]
								.toLowerCase()
								.indexOf(input?.toLowerCase()) >= 0
						)
					return (
						option.value
							.toLowerCase()
							.indexOf(input?.toLowerCase()) >= 0
					)
				}}
				onChange={(value) => {
					setSearchInput()
					onChange(value)
				}}
				value={filter || undefined}
				{...props}
			>
				{children}
			</Select>
			<div style={{ marginBottom: isDesktop ? "0" : "16px" }} />
		</div>
	)
}

FilterAction.propTypes = {
	onChange: PropTypes.func,
	filter: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string,
	placeholder: PropTypes.string,
	id: PropTypes.string,
	maxLenght: PropTypes.number,
	children: PropTypes.node
}

FilterAction.defaultProps = {
	onChange: () => {},
	filter: [],
	title: "",
	id: "",
	placeholder: "",
	maxLenght: 16,
	children: <Option value />
}

export default FilterAction
