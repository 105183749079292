/* eslint-disable no-underscore-dangle */
import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Typography, Tooltip, List, Skeleton } from "antd"
import { breakdownByCountries as breakdownByCountriesSlice } from "features/jobsResultsSlice"

import { getCountryNameByLocale } from "helpers/countriesHandler"
import { shortenTIV } from "helpers/currencyHandler"
import useMediaQuery from "styles/useMediaQuery"

import "./LocationInformations.css"

const { Title } = Typography

const ByCountryInformations = () => {
	const isDesktop = useMediaQuery("(min-width: 1100px)")
	const { t } = useTranslation()
	const breakdownByCountries = useSelector(breakdownByCountriesSlice)
	const items = breakdownByCountries && [...breakdownByCountries]

	const handleMouseEnter = (item) => {
		if (typeof item === "number") return
		const { location } = item
		const dataCode = document.querySelector(`[data-code=${location}]`)
		dataCode.setAttribute("stroke", "#ffbc11")
		dataCode.setAttribute("stroke-width", "2")
		dataCode.setAttribute("stroke-opacity", "1")
	}

	const handleMouseLeave = (item) => {
		if (typeof item === "number") return
		const { location } = item
		const dataCode = document.querySelector(`[data-code=${location}]`)
		dataCode.setAttribute("stroke", "none")
		dataCode.setAttribute("stroke-width", "0")
		dataCode.setAttribute("stroke-opacity", "0")
	}

	return (
		<div
			className={`country_table${isDesktop ? "" : "__mobile"}`}
			style={{ marginTop: isDesktop ? "0" : "24px" }}
		>
			{isDesktop && (
				<Title level={5} className="page__subtitle">
					{t("Broken down by country")}
				</Title>
			)}
			<div className="country_table_box">
				<div className="country__table__title">
					<div
						className="country__title"
						style={{
							width: "200px",
							justifyContent: "center"
						}}
					>
						{t("Country")}
					</div>
					<div
						className="country__title"
						style={{
							width: "200px",
							justifyContent: "center"
						}}
					>
						<Tooltip
							title={t("Total Insured Value")}
							color="#C3D1E7"
							overlayInnerStyle={{
								color: "#343c3d",
								padding: "12px"
							}}
						>
							{t("TIV")}
						</Tooltip>
					</div>
					<div
						className="country__title"
						style={{
							width: "200px",
							justifyContent: "center"
						}}
					>
						<Tooltip
							title={t("Street-level or above")}
							color="#C3D1E7"
							overlayInnerStyle={{
								color: "#343c3d",
								padding: "12px"
							}}
						>
							{t("High Resolution")}
						</Tooltip>
					</div>
				</div>
				<List
					className="country__table__list"
					height={280}
					dataSource={
						!breakdownByCountries
							? [...Array(5).keys()]
							: items?.sort((a, b) => {
									if (!a || !b) return -1
									return a.tivCountry > b.tivCountry ? -1 : 1
							  })
					}
					renderItem={(item) => (
						<List.Item
							className="country__table__item"
							onMouseEnter={() => handleMouseEnter(item)}
							onMouseLeave={() => handleMouseLeave(item)}
						>
							<div
								className="country__table__content"
								style={{
									fontWeight: 700
								}}
							>
								{!breakdownByCountries ? (
									<Skeleton.Input />
								) : (
									getCountryNameByLocale("en", item.location)
								)}
							</div>
							<div className="country__table__content">
								{!breakdownByCountries ? (
									<Skeleton.Input />
								) : (
									shortenTIV(item.tivCountry)
								)}
							</div>
							<div
								className="country__table__content"
								style={{
									fontWeight: 700
								}}
							>
								{!breakdownByCountries ? (
									<Skeleton.Input />
								) : (
									`${parseInt(item.resolutionCountry, 10)}%`
								)}
							</div>
						</List.Item>
					)}
				/>
			</div>
		</div>
	)
}

ByCountryInformations.propTypes = {}

ByCountryInformations.defaultProps = {}

export default ByCountryInformations
