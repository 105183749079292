import PropTypes from "prop-types"
import React from "react"
import { CONFIDENCE_RANGE } from "helpers/constants"

import "./ConfidenceBars.css"

export const confidenceLabelBuilder = (confidence) =>
	({ 1: "Low", 2: "Low", 3: "Medium", 4: "High", 5: "High" }[
		parseInt(confidence, 10)
	] ?? "N/A")

/**
 * Given
 *	confidencesAvalaible = {
 *		1: 1,
 *		2: 2,
 *		3: 42,
 *		4: 5,
 *		5: 17
 *	}
 *
 * @returns {
 *    5 : 17,
 *    1_2 : 2,
 *    3_4 : 5
 * }
 *
 */
export const reduceByConfidenceRangeWithCounter = (confidences) => {
	const confidenceRangeMatcher = (confidenceLevel) => {
		const rangeMatcher = {
			1: CONFIDENCE_RANGE.LOW,
			2: CONFIDENCE_RANGE.LOW,
			3: CONFIDENCE_RANGE.MEDIUM,
			4: CONFIDENCE_RANGE.MEDIUM,
			5: CONFIDENCE_RANGE.HIGH
		}
		return rangeMatcher[confidenceLevel]
	}

	return Object.entries(confidences).reduce(
		(acc, [k, v]) => ({
			...acc,
			[confidenceRangeMatcher(k)]: v
		}),
		{}
	)
}
/**
 *
 * Create Condifence bars
 *
 * @props {string/int}			confidence: confidence of a given footprint can be a string or an integer
 * @props {string}				label: confidence level's label
 * @props {boolean} 			toWhite: confidence bar should be display as white
 *
 * @returns ConfidenceBars
 */
const ConfidenceBars = (props) => {
	const { confidence, label, toWhite } = props
	let confidenceChart = {
		barOne: "bar-one-off",
		barTwo: "bar-two-off",
		barThree: "bar-three-off",
		barLabel: ""
	}
	switch (parseInt(confidence, 10)) {
		case 1:
		case 2:
			confidenceChart.barOne = "bar-one"
			confidenceChart.barTwo = "bar-two-off"
			confidenceChart.barThree = "bar-three-off"
			confidenceChart.barLabel = "Low"
			break
		case 3:
		case 4:
			confidenceChart.barOne = "bar-one"
			confidenceChart.barTwo = "bar-two"
			confidenceChart.barThree = "bar-three-off"
			confidenceChart.barLabel = "Medium"
			break

		case 5:
			confidenceChart.barOne = "bar-one"
			confidenceChart.barTwo = "bar-two"
			confidenceChart.barThree = "bar-three"
			confidenceChart.barLabel = "High"
			break
		default:
			confidenceChart = {
				barOne: "bar-one-off",
				barTwo: "bar-two-off",
				barThree: "bar-three-off",
				barLabel: "N/A"
			}
	}
	const bars = (
		<div className="bars">
			<div
				className={`bar ${confidenceChart.barOne}${
					toWhite ? "--white" : ""
				}`}
			/>
			<div
				className={`bar ${confidenceChart.barTwo}${
					toWhite ? "--white" : ""
				}`}
			/>
			<div
				className={`bar ${confidenceChart.barThree}${
					toWhite ? "--white" : ""
				}`}
			/>
		</div>
	)
	return label ? (
		<div className="bars__and__labels">
			{bars}
			<span className="confidence__labels">
				{confidenceChart.barLabel}
			</span>
		</div>
	) : (
		bars
	)
}

ConfidenceBars.propTypes = {
	confidence: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	label: PropTypes.bool.isRequired,
	toWhite: PropTypes.bool.isRequired
}

ConfidenceBars.defaultProps = {
	confidence: CONFIDENCE_RANGE.NONE
}

export default ConfidenceBars
