import React from "react"
import PropTypes from "prop-types"

const ToReviewIcon = ({ color, size }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 14 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M6.9935 0.833332C3.3135 0.833332 0.333496 3.82 0.333496 7.5C0.333496 11.18 3.3135 14.1667 6.9935 14.1667C10.6802 14.1667 13.6668 11.18 13.6668 7.5C13.6668 3.82 10.6802 0.833332 6.9935 0.833332ZM7.00016 12.8333C4.0535 12.8333 1.66683 10.4467 1.66683 7.5C1.66683 4.55333 4.0535 2.16667 7.00016 2.16667C9.94683 2.16667 12.3335 4.55333 12.3335 7.5C12.3335 10.4467 9.94683 12.8333 7.00016 12.8333Z"
			fill={color}
		/>
		<path
			d="M7.3335 4.16667H6.3335V8.16667L9.8335 10.2667L10.3335 9.44667L7.3335 7.66667V4.16667Z"
			fill={color}
		/>
	</svg>
)

ToReviewIcon.propTypes = {
	color: PropTypes.string,
	size: PropTypes.number
}

ToReviewIcon.defaultProps = {
	size: 16,
	color: "white"
}

export default ToReviewIcon
