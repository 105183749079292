import React from "react"
import PropTypes from "prop-types"

const NotReadyIcon = ({ color }) => (
	<svg
		width="16"
		height="11"
		viewBox="0 0 16 11"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.6665 2.83333L9.99984 5.5H11.9998C11.9998 7.70667 10.2065 9.5 7.99984 9.5C7.3265 9.5 6.6865 9.33333 6.13317 9.03333L5.15984 10.0067C5.97984 10.5267 6.95317 10.8333 7.99984 10.8333C10.9465 10.8333 13.3332 8.44667 13.3332 5.5H15.3332L12.6665 2.83333ZM3.99984 5.5C3.99984 3.29333 5.79317 1.5 7.99984 1.5C8.67317 1.5 9.31317 1.66667 9.8665 1.96667L10.8398 0.993333C10.0198 0.473333 9.0465 0.166666 7.99984 0.166666C5.05317 0.166666 2.6665 2.55333 2.6665 5.5H0.666504L3.33317 8.16667L5.99984 5.5H3.99984Z"
			fill={color}
		/>
	</svg>
)

NotReadyIcon.propTypes = {
	color: PropTypes.string
}

NotReadyIcon.defaultProps = {
	color: "white"
}

export default NotReadyIcon
