export default function itemRender(current, type, originalElement) {
	if (type === "prev") {
		// eslint-disable-next-line
		return <a className="pagination">PREVIOUS</a>
	}
	if (type === "next") {
		// eslint-disable-next-line
		return <a className="pagination">NEXT</a>
	}
	if (type === "page") {
		// eslint-disable-next-line
		return <a>{current}</a>
	}
	if (type === "jump-prev") {
		// eslint-disable-next-line
		return <a>...</a>
	}
	if (type === "jump-next") {
		// eslint-disable-next-line
		return <a className="pagination_ellipsis">...</a>
	}
	return originalElement
}
