import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Tooltip } from "antd"
import {
	engineVersion as engineVersionSlice,
	fetchVersions
} from "features/backboneSlice"
import { isLoggedIn as isLoggedInSlice } from "features/authSlice"
import "./Footer.css"

/**
 *
 * Create Footer component
 * Based on https://patterns.axa.ch/?path=/story/components-footer--footer
 *
 * @returns Footer
 */
const Footer = () => {
	// Creates a label with the Commit SHA (limit to 7 characters) as a prefix
	const buildLabel = `Version ${process.env.REACT_APP_VERSION}`
	const dispatch = useDispatch()
	const engineVersion = useSelector(engineVersionSlice)
	const isLoggedIn = useSelector(isLoggedInSlice)

	useEffect(() => {
		if (isLoggedIn) {
			dispatch(fetchVersions())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn])

	return (
		<div className="footer__container">
			<Tooltip title={`Engine: v${engineVersion}`} color="#00008F">
				<div className="footer__item">{buildLabel}</div>
			</Tooltip>
			<div className="footer__item">&copy; 2022 AXA Insurance Ltd.</div>
		</div>
	)
}

export default Footer
