import React from "react"

import "./FullscreenMapLoader.css"

const FullscreenMapLoader = () => (
	<div className="mapLoader__container">
		<div className="dot">
			<div className="centraldot" />
			<div className="wave" />
			<div className="wave2" />
		</div>
	</div>
)

export default FullscreenMapLoader
