import React from "react"

const IconArrowUpSmall = () => (
	<svg
		width="8"
		height="6"
		viewBox="0 0 8 6"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0.94 5.4693L4 2.41596L7.06 5.4693L8 4.5293L4 0.529297L0 4.5293L0.94 5.4693Z"
			fill="#00008F"
		/>
	</svg>
)

export default IconArrowUpSmall
