/* eslint-disable no-underscore-dangle */
import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Typography } from "antd"

import { shortenTIV } from "helpers/currencyHandler"

import useMediaQuery from "styles/useMediaQuery"

import {
	jobResults,
	totalTIV as totalTIVSlice
} from "features/jobsResultsSlice"
import BoxInfo from "./BoxInfo"
import "./LocationInformations.css"

const { Title } = Typography

const GeneralInformations = () => {
	const isDesktop = useMediaQuery("(min-width: 1100px)")
	const jobResultsSelector = useSelector(jobResults)
	const totalTIV = useSelector(totalTIVSlice)
	const { t } = useTranslation()

	return (
		<div
			className="info__container"
			style={{
				marginRight: "20px"
			}}
		>
			<Title level={5} className="page__subtitle">
				{t("General information")}
			</Title>
			<div
				className={`header__information${isDesktop ? "" : "__mobile"}`}
			>
				<BoxInfo
					style
					title={t("Total amount of locations")}
					text={
						jobResultsSelector.length > 0
							? jobResultsSelector.length
							: "0"
					}
				/>
				<BoxInfo
					title={t("Total sum of TIV")}
					text={shortenTIV(totalTIV)}
				/>
			</div>
		</div>
	)
}

GeneralInformations.propTypes = {}

GeneralInformations.defaultProps = {}

export default GeneralInformations
