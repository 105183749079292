/* eslint-disable no-underscore-dangle */
/**
 *
 * Extract coordinates from a string
 *
 * Exemple:
 *
 * ```
 * const coordinates: CoordinatesFromString("-31.248523, 102.342109")
 *
 * Output: { lat: -31.248523, lon: 102.342109 }
 *
 * ```
 *
 * @param {string} coordinatesString
 *
 * @returns {object} A coordinates object `{lat: <Float>, lon: <Float>}`
 */

export const extractor = (coordinatesString) => {
	const result = {
		lat: undefined,
		lon: undefined
	}
	if (coordinatesString && typeof coordinatesString === "string") {
		const _splittedInput = coordinatesString.split(",")
		if (_splittedInput && _splittedInput.length === 2) {
			result.lat = parseFloat(_splittedInput[0])
				? parseFloat(_splittedInput[0])
				: undefined
			result.lon = parseFloat(_splittedInput[1])
				? parseFloat(_splittedInput[1])
				: undefined
		}
	}

	return result
}

/**
 *
 * Scenario Outline: User check coordinates with especial <characters>
 *    And there is a location with especial <characters>
 *    When the user check the location
 * Then the coordinates are empty
 * Examples:
 *
 * characters
 * 0,0
 * NaN
 * 0
 * N/A
 *
 * ```
 *
 * @param {string} potentialLat
 * @param {string} potentialLon
 *
 * @returns {object} `{lat, lon}` or undefined
 */

export const checker = (potentialLat, potentialLon) => {
	if (potentialLat && potentialLon) {
		if (
			typeof potentialLat === "string" &&
			potentialLat.trim().length === 0 &&
			potentialLon.trim().length === 0
		)
			return undefined
		if (potentialLat === "N/A" && potentialLon === "N/A") return undefined
		const floatedPotentialLat = parseFloat(potentialLat)
		const floatedPotentialLon = parseFloat(potentialLon)
		if (floatedPotentialLat !== 0 && floatedPotentialLon !== 0) {
			return `${potentialLat}, ${potentialLon}`
		}
		return undefined
	}
	return undefined
}
