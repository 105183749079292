import PropTypes from "prop-types"

import "pages/locations/LocationsPage.css"

const SummaryCriteria = ({
	filtered,
	title,
	onFilter,
	semiColon,
	setSemiColon,
	index
}) => {
	if (filtered.length > 0) {
		let sc = false
		const list = semiColon.map((item, i) => {
			if (index > i && item === true) sc = true
			if (index === i) return true
			return item
		})
		if (!semiColon.every((val, i) => val === list[i])) setSemiColon(list)
		return `${sc ? "; " : ""}${title}: ${filtered.map(onFilter).join(", ")}`
	}
	const list = semiColon.map((item, i) => {
		if (index === i) return false
		return item
	})
	if (semiColon.length > 0 && !semiColon.every((val, i) => val === list[i]))
		setSemiColon(list)
	return null
}

SummaryCriteria.propTypes = {
	onFilter: PropTypes.func,
	filtered: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string)
	]),
	title: PropTypes.string,
	semiColon: PropTypes.arrayOf(PropTypes.bool),
	setSemiColon: PropTypes.func,
	index: PropTypes.number
}

SummaryCriteria.defaultProps = {
	onFilter: () => {},
	filtered: [],
	title: "",
	semiColon: [],
	setSemiColon: () => {},
	index: 0
}

export default SummaryCriteria
