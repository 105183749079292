export const LOGIN_BLOCK_MODE = {
	CALLBACK: "callback",
	LOGIN: "login"
}

// Use to determine a polygon category
export const GEOMETRY_CATEGORY = {
	BUILDING: "BUILDING",
	PARCEL: "PARCEL"
}

// Use to determine an alert type
export const ALERTS_TYPE = {
	SUCCESS: "success",
	WARNING: "warning",
	ERROR: "error",
	INFO: "info"
}

// Use to determine validation status
export const VALIDATION_STATUS_TYPE = {
	ERROR: "ERROR",
	REVIEWED_WRONG: "REVIEWED_WRONG",
	REVIEWED_INCONCLUSIVE: "REVIEWED_INCONCLUSIVE",
	VALIDATED: "VALIDATED",
	NON_REVIEWED: "NON_REVIEWED",
	REVIEWED_AND_VALIDATED: [
		"REVIEWED_WRONG",
		"REVIEWED_INCONCLUSIVE",
		"VALIDATED"
	]
}

// Use to determine confidence range
export const CONFIDENCE_RANGE = {
	HIGH: "5",
	MEDIUM: "3_4",
	LOW: "1_2",
	NONE: 0
}
// Inverted version of CONFIDENCE_RANGE
export const CONFIDENCE_RANGE_INVERTED = Object.fromEntries(
	Object.entries(CONFIDENCE_RANGE).map((a) => a.reverse())
)

// Use to determine match status key and label
export const STATUS_MATCHING = {
	VALIDATED: "Validated",
	NON_REVIEWED: "Non-reviewed",
	REVIEWED_INCONCLUSIVE: "Inconclusive",
	REVIEWED_WRONG: "Wrong data",
	ERROR: "Error"
}

// Use to determine match has footprint key and label
export const HAS_FOOTPRINTS_MATCHING = {
	Yes: "1_yes",
	No: "2_no",
	"N/A": "3_na"
}

// Use to determine jobs status type
export const JOBS_STATUS_TYPE = {
	UPLOADED: "UPLOADED",
	IN_PROGRESS: "IN_PROGRESS",
	TO_BE_REVIEWED: "TO_BE_REVIEWED",
	FILE_CREATION_IN_PROGRESS: "FILE_CREATION_IN_PROGRESS",
	VALIDATED: "VALIDATED",
	ERROR: "ERROR",
	DELETABLE: ["ERROR", "VALIDATED", "TO_BE_REVIEWED"]
}

// Use to determine Above/Below quality
export const RESOLUTION_QUALITY_RANGE = {
	ABOVE_STREET_LEVEL: [
		"VERIFIED",
		"ROOFTOP",
		"PARCEL_CENTROID",
		"STREET",
		"CLIENT_COORDINATES"
	],
	BELOW_STREET_LEVEL: [
		"STREET_CENTROID",
		"CITY",
		"ZIPCODE",
		"STATE",
		"COUNTRY",
		"NO_GEOCODE",
		"NOT_AVAILABLE"
	]
}

// Use to map resolutions and legend/ranking for LocationsVizer component
export const RESOLUTIONS_MAPPING_FOR_DATAVIZ = {
	VERIFIED: "11_VERIFIED",
	ROOFTOP: "10_ROOFTOP",
	PARCEL_CENTROID: "09_PARCEL_CENTROID",
	STREET: "08_STREET",
	CLIENT_COORDINATES: "07_CLIENT_COORDINATES",
	STREET_CENTROID: "06_STREET_CENTROID",
	CITY: "05_CITY",
	ZIPCODE: "04_ZIPCODE",
	STATE: "03_STATE",
	COUNTRY: "02_COUNTRY",
	NO_GEOCODE: "01_NO_GEOCODE",
	NOT_AVAILABLE: "00_NOT_AVAILABLE"
}

// Use to map resolutions to colors for LocationsVizer component
export const RESOLUTIONS_COLOR_MAPPING_FOR_DATAVIZ = {
	"11_VERIFIED": "#1a9850",
	"10_ROOFTOP": "#1a9850",
	"09_PARCEL_CENTROID": "#66BD63",
	"08_STREET": "#A6D96A",
	"07_CLIENT_COORDINATES": "#1a9850",
	"06_STREET_CENTROID": "#D9EF8B",
	"05_CITY": "#FFFFBF",
	"04_ZIPCODE": "#FEE08B",
	"03_STATE": "#FDAE61",
	"02_COUNTRY": "#D73027",
	"01_NO_GEOCODE": "#CCCCCC",
	"00_NOT_AVAILABLE": "#CCCCCC" // Locations w/o geocode should be filtered out - this a "in case of"
}

export const SOURCES_QUALITY_RANGE = {
	VERIFIED: "0_VERIFIED",
	Manual: "1_MANUAL",
	CLIENT_COORDINATES: "2_CLIENT",
	Bing: "3_BING",
	HerePlace: "4_HERE_PLACE",
	FoursquarePlace: "5_FOURSQUARE_PLACE"
}

export const SELECTION_KEY_DATA = {
	BING: "bing_used",
	CLIENT_COORDINATES: "client_coord_used",
	POI_SELECTED: "poi_response_with_selected_result",
	POI_UNSELECTED: "poi_response_without_selected_result",
	POI_NOT_FOUND: "poi_response_no_result"
}

export const SUPPORTED_COUNTRIES_ISO3_CODE = ["USA", "FRA"]

export const FOOTPRINT_ATTRIBUTES_RANK = {
	PRIMARY: "P",
	SECONDARY: "S"
}
export const BUILDING_ATTRIBUTES_RANK_MAPPING = {
	geometry_category: FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	guid: FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	name: FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"source.desc": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"source.creationdate": FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	"source.reviseddate": FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	"source.geometrycreationdate": FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	stories: FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	unitsnumber: FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	yearbuilt: FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"structure.type": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"structure.desc": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"structure.style": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"structure.use1": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"structure.use2": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"structure.light_building": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"structure.status": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"structure.wall_material": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"structure.roof": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	roof_shape: FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"address.streetaddress": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"address.additionalinfo": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"address.standard": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	surfacedesc: FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	area_m2: FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"centroid.lat": FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	"centroid.lon": FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	linked_parcels: FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	heightavgm: FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	groundelevationavgm: FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	groundelevationmin: FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	groundelevationmax: FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	roofelevationmin: FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	roofelevationmax: FOOTPRINT_ATTRIBUTES_RANK.SECONDARY
}

export const PARCEL_ATTRIBUTES_RANK_MAPPING = {
	geometry_category: FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	objectid: FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	"source.service": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"activity.usecodemunidesc": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	structsnumber: FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	totalparceldesc: FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	"owner.name": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"owneraddress.mailaddr": FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	"address.standard": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"address.country": FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	"address.locationname": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	buildingssurfacedesc: FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	"mainstructure.yearbuilt": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"mainstructure.rooftypedesc": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"mainstructure.constructioncodedesc": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"mainstructure.stories": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"mainstructure.unitsnumber": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"additionalinfo.owneroccupied": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"additionalinfo.companyflag": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY,
	"additionalinfo.associatepropertycount":
		FOOTPRINT_ATTRIBUTES_RANK.SECONDARY,
	"additionalinfo.code_insee": FOOTPRINT_ATTRIBUTES_RANK.PRIMARY
}

export const SHORTHEN_TIV = ["", "k", "M", "B", "T"]
