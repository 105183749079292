import React from "react"
import PropTypes from "prop-types"
import { Typography } from "antd"

import "./ModeAvailable.css"

const { Title, Paragraph } = Typography

const ModeAvailable = ({ src, title, subtitle }) => {
	const cutSrc = src.replace("osm_", "")

	return (
		<div className="container">
			<div className="icon">
				<img alt="icon" src={`img/${cutSrc}.svg`} />
			</div>
			<div className="div">
				<Title level={4}>{title}</Title>
				<Paragraph className="subtitle">{subtitle}</Paragraph>
			</div>
		</div>
	)
}

ModeAvailable.propTypes = {
	src: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string
}

ModeAvailable.defaultProps = {
	src: "",
	title: "",
	subtitle: ""
}

export default ModeAvailable
