/* eslint-disable no-underscore-dangle */
import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Typography } from "antd"
import useMediaQuery from "styles/useMediaQuery"

import {
	isFetching as resultsIsFetching,
	percentageLocationsAbove50MUSDEStreetLevel as percentageLocationsAbove50MUSDEStreetLevelSlice,
	percentageIntlLocationsAboveStreetLevel as percentageIntlLocationsAboveStreetLevelSlice,
	percentageUSLocationsAboveStreetLevel as percentageUSLocationsAboveStreetLevelSlice
} from "features/jobsResultsSlice"
import MetricInfo from "./MetricInfo"
import "./LocationInformations.css"

const { Title } = Typography

const GeocodingInformations = () => {
	const isResultsFetching = useSelector(resultsIsFetching)
	const isDesktop = useMediaQuery("(min-width: 1100px)")
	const percentageLocationsAbove50MUSDEStreetLevel = useSelector(
		percentageLocationsAbove50MUSDEStreetLevelSlice
	)
	const percentageIntlLocationsAboveStreetLevel = useSelector(
		percentageIntlLocationsAboveStreetLevelSlice
	)
	const percentageUSLocationsAboveStreetLevel = useSelector(
		percentageUSLocationsAboveStreetLevelSlice
	)
	const { t } = useTranslation()

	return (
		<div style={{ marginBottom: isDesktop ? "16px" : "24px" }}>
			<Title level={5} className="page__subtitle">
				{t("Geocoding Quality Metrics")}
			</Title>
			<div
				className={`header__information${isDesktop ? "" : "__mobile"}`}
				style={{ gap: "40px" }}
			>
				<MetricInfo
					title={t("TIV of US locations")}
					tooltipTitle={t(
						"Percentage of TIV geocoded at street-level or above in the US"
					)}
					text={percentageUSLocationsAboveStreetLevel}
					color={
						parseInt(percentageUSLocationsAboveStreetLevel, 10) > 80
							? "#138636"
							: "#00008F"
					}
					isLoading={isResultsFetching}
				/>
				<MetricInfo
					title={t("TIV of non US locations")}
					tooltipTitle={t(
						"Percentage of TIV geocoded at street-level or above outside of the US"
					)}
					text={percentageIntlLocationsAboveStreetLevel}
					color={
						parseInt(percentageIntlLocationsAboveStreetLevel, 10) >
						60
							? "#138636"
							: "#00008F"
					}
					isLoading={isResultsFetching}
				/>
				<MetricInfo
					title={t("TIV location above $50m")}
					tooltipTitle={t(
						"Percentage of TIV geocoded at street-level for all locations above $50M of TIV"
					)}
					text={percentageLocationsAbove50MUSDEStreetLevel}
					color={
						parseInt(
							percentageLocationsAbove50MUSDEStreetLevel,
							10
						) > 99
							? "#138636"
							: "#00008F"
					}
					isLoading={isResultsFetching}
				/>
			</div>
		</div>
	)
}

GeocodingInformations.propTypes = {}

GeocodingInformations.defaultProps = {}

export default GeocodingInformations
