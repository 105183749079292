import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Tooltip, Popconfirm, Typography, Button } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { WarningFilled, CopyOutlined, RedoOutlined } from "@ant-design/icons"
import {
	currentLocationIndex as currentLocationIndexSlice,
	selectedResults as selectedResultsSlice,
	setCurrentFootprintMode,
	isFetching as isFetchingSlice
} from "features/jobsResultsSlice"

import {
	isPostingResultEdit as isPostingResultEditSlice,
	isLoadingAdditionalParcels as isLoadingAdditionalParcelsSlice,
	geocodingIsSubmittingFootprintChecker as geocodingIsSubmittingFootprintCheckerSlice,
	FOOTPRINT_MODE,
	postChangeFootprintMode,
	setShowLoadingModalForFootprintModeSwitch,
	footprintIsPristine as footprintIsPristineSlice
} from "features/footprintSlice"

import "./Topbar.css"
import { VALIDATION_STATUS_TYPE } from "helpers/constants"
import ConfidenceBars from "components/confidenceBars/ConfidenceBars"
import generateValidationStatusLabels from "pages/locations/components/validateStatus"

import TopbarDropdown from "./Dropdown/TopbarDropdown"
import UndoModal from "./Modals/UndoModal"
import MoreModeModal from "./Modals/MoreModeModal"

const { Paragraph } = Typography

/**
 *
 * Creates Topbar component
 * Toolbar at the top on review mode
 *
 * @props {function} 		onEditGeocode: display the edit geocode modal from Map component
 * @props {object} 			coordinates ({lat: , lon: })
 *
 * @returns Topbar
 */
const Topbar = (props) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { onEditGeocode, coordinates } = props
	const [isLoading, setLoading] = useState(true)
	const [displayErrorPopover, toDisplayErrorPopover] = useState(false)

	// eslint-disable-next-line no-underscore-dangle
	const _coordinates = JSON.parse(JSON.stringify(coordinates))
	const [isUndoWarningVisible, setIsUndoWarningVisible] = useState(false)
	const [isMoreModeWarningVisible, setIsMoreModeWarningVisible] =
		useState(false)
	const isPostingResultEdit = useSelector(isPostingResultEditSlice)
	const isLoadingAdditionalParcels = useSelector(
		isLoadingAdditionalParcelsSlice
	)
	const currentLocationIndex = useSelector(currentLocationIndexSlice)
	const isFetching = useSelector(isFetchingSlice)
	const footprintIsPristine = useSelector(footprintIsPristineSlice)
	const selectedLocations = useSelector(selectedResultsSlice)
	const isSubmittingFootprintChecker = useSelector(
		geocodingIsSubmittingFootprintCheckerSlice
	)

	useEffect(() => {
		if (selectedLocations.length > 0) {
			setLoading(false)
		}
	}, [selectedLocations])

	if (isLoading) {
		return null
	}

	return (
		<div style={{ zIndex: 12 }} className="topbar__container">
			<UndoModal
				isVisible={isUndoWarningVisible}
				onDismiss={() => setIsUndoWarningVisible(false)}
				id={selectedLocations[currentLocationIndex]?.id}
			/>
			<MoreModeModal
				isVisible={isMoreModeWarningVisible}
				onDismiss={() => setIsMoreModeWarningVisible(false)}
			/>
			<div
				className={`topbar__footprintMode__container${
					selectedLocations[currentLocationIndex]
						?.validation_status === VALIDATION_STATUS_TYPE.ERROR
						? "--disabled"
						: ""
				}`}
				data-testid="topbar__footprintMode__container-testid"
			>
				<TopbarDropdown
					openModal={() => setIsMoreModeWarningVisible(true)}
				/>
				{selectedLocations[currentLocationIndex]?.geo_data
					.footprinter_error ? (
					<div className="footprint_error__container">
						<Popconfirm
							title={
								<div data-testid="footprint_error_wrapper--testid">
									<div
										className="footprint_error_header__container"
										data-testid="footprint_error_header__container--testid"
									>
										<span>Details</span>
										<svg
											width="16"
											height="16"
											viewBox="0 0 16 16"
											xmlns="http://www.w3.org/2000/svg"
											data-testid="footprint_error_close--testid"
											className="footprint_error_close--icon"
											onClick={() =>
												toDisplayErrorPopover(false)
											}
										>
											<path
												d="M12.845 14.468 8 9.623l-4.845 4.845-1.623-1.623L6.377 8 1.532 3.155l1.623-1.623L8 6.377l4.845-4.845 1.623 1.623L9.623 8l4.845 4.845z"
												fill="#fff"
												fillRule="evenodd"
											/>
										</svg>
									</div>
									<div className="footprint_error_content__container">
										<span>
											<b> Permanent : </b>
											{selectedLocations[
												currentLocationIndex
											].geo_data.footprinter_error
												.permanent
												? "yes"
												: "no"}
										</span>
										<span>
											<b>Message:</b>
										</span>
										<Paragraph
											className="footprint_error_description"
											data-testid="footprint_error_description--testid"
											ellipsis
											italic
										>
											{
												selectedLocations[
													currentLocationIndex
												].geo_data.footprinter_error
													.message
											}
										</Paragraph>
									</div>
								</div>
							}
							placement="bottomLeft"
							className="error_popconfirm_label__container"
							icon={null}
							open={displayErrorPopover}
							onOpenChange={(visible) =>
								toDisplayErrorPopover(visible)
							}
							okText="Copy stacktrace"
							okButtonProps={{
								className:
									"axa-button footprint_error_buttons footprint_error_copyButton",
								type: "primary",
								icon: <CopyOutlined />,
								disabled: !("clipboard" in navigator),
								"data-testid":
									"footprint_error_copyButton--testid"
							}}
							onConfirm={() =>
								new Promise((resolve) => {
									setTimeout(() => {
										navigator.clipboard.writeText(
											JSON.stringify(
												selectedLocations[
													currentLocationIndex
												].geo_data.footprinter_error,
												undefined,
												2
											)
												.replace(/(\\n)/gm, "\n")
												.replace(/(\\t)/gm, "\t")
										)
										resolve()
									}, 400)
								})
							}
							showCancel={
								selectedLocations[currentLocationIndex].geo_data
									.processing_mode_reason_key ===
								"geocode_exception_occurred"
							}
							cancelText="Try again"
							cancelButtonProps={{
								className:
									"axa-button--ghost footprint_error_buttons footprint_error_retryButton",
								icon: <RedoOutlined />,
								"data-testid":
									"footprint_error_retryButton--testid"
							}}
							onCancel={() => {
								dispatch(
									setCurrentFootprintMode(
										`${FOOTPRINT_MODE.FORWARD}_footprint_retry`
									)
								)
								dispatch(
									setShowLoadingModalForFootprintModeSwitch(
										true
									)
								)
								dispatch(
									postChangeFootprintMode({
										rawEMPAttributes:
											selectedLocations[
												currentLocationIndex
											].input_data.raw_emp_attributes,
										triggerRetryMode: true
									})
								)
							}}
						>
							<WarningFilled
								style={{
									color: "#D24723"
								}}
							/>
							<span
								className="footprint_error__label"
								data-testid="footprint_error__label--testid"
							>
								Failed to footprint
							</span>
						</Popconfirm>
					</div>
				) : null}
			</div>
			<div className="topbar__centerActions">
				<div
					className={`topbar__geocodeContainer${
						isPostingResultEdit ||
						isLoadingAdditionalParcels ||
						isSubmittingFootprintChecker
							? "--disabled"
							: ""
					}`}
				>
					<svg
						width="14"
						height="20"
						viewBox="0 0 14 20"
						xmlns="http://www.w3.org/2000/svg"
						className="geocode__icon"
					>
						<path
							d="M7 0C3.13 0 0 3.13 0 7c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z"
							fill="#00008F"
							fillRule="nonzero"
						/>
					</svg>
					<div
						className="geocode_value__container"
						data-testid="edit-geocode-manually-icon-testid"
						onClick={() => {
							// Prevent opening geocode modal while selecting geocode to copy
							if (getSelection().toString().length === 0) {
								if (
									!isPostingResultEdit &&
									!isLoadingAdditionalParcels &&
									!isSubmittingFootprintChecker
								)
									onEditGeocode()
							}
						}}
						aria-hidden="true"
					>
						<span className="geocode_value_label">
							{_coordinates.lat && _coordinates.lon
								? `${parseFloat(_coordinates.lat).toPrecision(
										9
								  )}, ${parseFloat(
										_coordinates.lon
								  ).toPrecision(9)}`
								: ""}
						</span>
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							xmlns="http://www.w3.org/2000/svg"
							className={`geocode__icon--edit--icon${
								isPostingResultEdit ||
								isLoadingAdditionalParcels ||
								isSubmittingFootprintChecker
									? "--disabled"
									: ""
							}`}
						>
							<path
								d="M8.6 2.759 13.24 7.4l-8.437 8.437a.55.55 0 0 1-.39.162H.552A.552.552 0 0 1 0 15.448v-3.862a.55.55 0 0 1 .162-.39L8.599 2.76zM11.195.162a.551.551 0 0 1 .78 0l3.862 3.862a.551.551 0 0 1 0 .78L14.022 6.62 9.379 1.978z"
								fill="#00008F"
								fillRule="evenodd"
							/>
						</svg>
					</div>
				</div>
				<div
					className={`confidence_resolution__container${
						selectedLocations[currentLocationIndex]
							?.validation_status === VALIDATION_STATUS_TYPE.ERROR
							? "--disabled"
							: ""
					}`}
				>
					<span className="resolution__label">
						{t(
							selectedLocations[currentLocationIndex]?.geo_data
								.resolution
						)}
					</span>
					<div className="confidence_bars__container">
						<ConfidenceBars
							confidence={
								selectedLocations[currentLocationIndex]
									?.geo_data.confidence
							}
							label={false}
							toWhite={false}
						/>
					</div>
				</div>
				{selectedLocations[currentLocationIndex] ? (
					<div className="topbar__validationStatus__container">
						{selectedLocations[currentLocationIndex]
							?.validation_status !==
						VALIDATION_STATUS_TYPE.NON_REVIEWED
							? generateValidationStatusLabels(
									selectedLocations[currentLocationIndex]
										?.validation_status
							  )
							: null}
					</div>
				) : null}
			</div>

			<div
				data-testid="undo__action--testid"
				className={`topbar__action${
					isPostingResultEdit ||
					isLoadingAdditionalParcels ||
					isSubmittingFootprintChecker
						? "--disabled"
						: ""
				}`}
				aria-hidden="true"
				onClick={(e) => {
					if (
						!isPostingResultEdit &&
						!isLoadingAdditionalParcels &&
						!isSubmittingFootprintChecker &&
						!footprintIsPristine
					) {
						e.currentTarget.blur()
						setIsUndoWarningVisible(true)
					}
				}}
			>
				<Tooltip
					title="This option will remove your changes and restore the original status of this location."
					placement="bottom"
					color="#00008F"
					trigger={
						!isPostingResultEdit &&
						!isLoadingAdditionalParcels &&
						!isSubmittingFootprintChecker &&
						!footprintIsPristine
							? "hover"
							: ""
					}
				>
					<Button
						key="submit"
						className="axa-button--withIcon axa-button__secondary"
						type="primary"
						size="large"
						disabled={
							isPostingResultEdit ||
							isLoadingAdditionalParcels ||
							isSubmittingFootprintChecker ||
							isFetching ||
							footprintIsPristine
						}
						icon={
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								xmlns="http://www.w3.org/2000/svg"
								className={`axa-button--withLeftIcon resetFootprint__button--icon${
									isPostingResultEdit ||
									isLoadingAdditionalParcels ||
									isSubmittingFootprintChecker ||
									isFetching ||
									footprintIsPristine
										? "--disabled"
										: ""
								}`}
							>
								<path
									d="M14.4 4.5v11c0 .276-.238.5-.533.5H2.133c-.295 0-.533-.224-.533-.5v-11h12.8zM11.2 7h-1.067v6.5H11.2V7zM8.533 7H7.467v6.5h1.066V7zM5.867 7H4.8v6.5h1.067V7zm4.8-7c.295 0 .533.224.533.5v2h4.267c.295 0 .533.224.533.5s-.238.5-.533.5H.533C.238 3.5 0 3.276 0 3s.238-.5.533-.5H4.8v-2c0-.276.238-.5.533-.5zm-.534 1H5.867v1.5h4.266V1z"
									fill="#00008F"
									fillRule="evenodd"
								/>
							</svg>
						}
					>
						undo changes
					</Button>
				</Tooltip>
			</div>
		</div>
	)
}

Topbar.propTypes = {
	onEditGeocode: PropTypes.func.isRequired,
	coordinates: PropTypes.shape({
		lon: PropTypes.number,
		lat: PropTypes.number
	})
}

Topbar.defaultProps = {
	coordinates: undefined
}

export default Topbar
