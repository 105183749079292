/* eslint-disable import/prefer-default-export */
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Formik } from "formik"
import { CloseOutlined } from "@ant-design/icons"

import { Button, Popover, Upload, Input, Row, Col, Form as AntForm } from "antd"
import {
	postBulkService,
	clearBulkFile,
	clearBulkMetadata,
	geocodingFormValues,
	clearFootprintData
} from "features/footprintSlice"
import { fetchJobsList } from "features/jobsSlice"
import "./UploadPopover.css"
import { displayAlert, setAlertPayload } from "features/alertsSlice"
import { ALERTS_TYPE } from "helpers/constants"
import { displayToast } from "components/alerts/Alerts"

const { Dragger } = Upload

/**
 *
 * Creates an UploadPopover
 *
 * @props {ReactNode} 		children: Set the component of the warning modal
 *
 * @returns UploadPopover
 */
export const UploadPopover = ({ children }) => {
	const dispatch = useDispatch()

	const [popoverVisible, setPopoverVisible] = useState(false)

	const formValuesSelector = useSelector(geocodingFormValues)

	const [form] = AntForm.useForm()

	const content = (submitForm, isSubmitting, setFieldValue, values) => (
		<div className="uploadPopover__wrapper">
			<div className="uploadPopover__title__container">
				<span className="uploadPopover__title">Upload a new file</span>
				<button
					type="button"
					className="uploadPopover__title__closeButton"
					data-testid="uploadPopover-close-button"
					onClick={() => setPopoverVisible(!popoverVisible)}
				>
					<CloseOutlined />
				</button>
			</div>
			<div className="uploadPopover__description__container">
				Complete the following fields as they appear in EMP. It will
				allow you to easily retrieve the right file in the job list.
			</div>
			<div className="uploadPopover__container">
				<AntForm
					form={form}
					className="queryFormUpload__container"
					initialValues={formValuesSelector}
					id="uploadMetadata"
				>
					<Row>
						<Col
							className="queryFormUpload__container__col--1"
							span={11}
						>
							<div>
								<div className="queryFromUpload__labels__container">
									<span className="queryFromUpload__label__title">
										Account name
									</span>
								</div>
								<AntForm.Item
									name="account_name"
									rules={[
										{
											required: true,
											message:
												"Please enter an Account Name up to 40 characters.",
											pattern: /^.{0,40}$/g
										}
									]}
								>
									<Input
										name="account_name"
										className="queryFormUpload__input"
										placeholder="Account name"
										onChange={({ target: { value } }) =>
											setFieldValue("account_name", value)
										}
									/>
								</AntForm.Item>
							</div>
						</Col>
						<Col span={11} offset={2}>
							<div className="">
								<div className="queryFromUpload__labels__container">
									<span className="queryFromUpload__label__title">
										Program reference
									</span>
								</div>
								<AntForm.Item
									name="account_reference"
									rules={[
										{
											required: true,
											message:
												"Please enter a program reference up to 20 characters.",
											pattern: /^.{0,20}$/g
										}
									]}
								>
									<Input
										name="account_reference"
										className="queryFormUpload__input"
										placeholder="Program reference"
										onChange={({ target: { value } }) =>
											setFieldValue(
												"account_reference",
												value
											)
										}
									/>
								</AntForm.Item>
							</div>
						</Col>
					</Row>
					<Dragger
						className="queryFormDragAndDrop__area"
						maxCount={1}
						accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						fileList={values?.bulkFile ?? []}
						data-testid="upload-dragger"
						onChange={(event) => {
							setFieldValue("bulkFile", event?.fileList)
						}}
						beforeUpload={() => false} // bypassing lib auto-upload
					>
						<p className="ant-upload-text">
							Drag and Drop or click to upload the new file
						</p>
					</Dragger>
					<div className="uploadPopover__upload--actions">
						<Button
							className="axa-button--ghost"
							type="primary"
							size="large"
							onClick={() => {
								setPopoverVisible(!popoverVisible)
							}}
						>
							Cancel
						</Button>
						<AntForm.Item shouldUpdate>
							{() => (
								<Button
									className="axa-button"
									type="primary"
									size="large"
									onClick={() => {
										setPopoverVisible(!popoverVisible)
										submitForm()
									}}
									disabled={
										Object.values(values).some(
											(x) =>
												x === null ||
												x === "" ||
												x.length === 0
										) ||
										form
											.getFieldsError()
											.filter(
												({ errors }) => errors.length
											).length > 0
									}
									loading={isSubmitting}
								>
									Upload
								</Button>
							)}
						</AntForm.Item>
					</div>
				</AntForm>
			</div>
		</div>
	)

	return (
		<Formik
			enableReinitialize
			initialValues={formValuesSelector}
			onSubmit={async (values, { setSubmitting, resetForm }) => {
				dispatch(clearFootprintData())
				dispatch(
					setAlertPayload({
						type: ALERTS_TYPE.INFO,
						code: "INFO_UPLOAD_NEW_FILE"
					})
				)

				dispatch(
					postBulkService({
						bulkFile: values.bulkFile[0],
						account_name: values.account_name,
						account_reference: values.account_reference
					})
				).then((data) => {
					if (data.type === postBulkService.fulfilled.type) {
						dispatch(fetchJobsList())
						dispatch(displayAlert(false))
					} else if (data.type === postBulkService.rejected.type) {
						displayToast(
							dispatch,
							data.payload.code,
							ALERTS_TYPE.ERROR,
							20000,
							data.payload.description,
							false
						)
					}
				})

				resetForm({})
				dispatch(clearBulkFile())
				dispatch(clearBulkMetadata())
				form.setFieldsValue({ account_name: "" })
				form.setFieldsValue({ account_reference: "" })
				setSubmitting(false)
			}}
		>
			{({ submitForm, isSubmitting, setFieldValue, values }) => (
				<Popover
					content={content(
						submitForm,
						isSubmitting,
						setFieldValue,
						values
					)}
					trigger="click"
					placement="leftBottom"
					open={popoverVisible}
					onOpenChange={(visible) => setPopoverVisible(visible)}
				>
					{children}
				</Popover>
			)}
		</Formik>
	)
}

UploadPopover.propTypes = {
	children: PropTypes.element.isRequired
}
