/* eslint-disable no-underscore-dangle */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import LocusEndpointURLBuilder from "helpers/LocusEndpointURLBuilder"
import {
	FilterAndSearch,
	SortingByTIVAndLimitTo
} from "helpers/FilterResultsHandler"
import { generateHeadersWithAuthorization } from "helpers/Auth"
import {
	RESOLUTION_QUALITY_RANGE,
	VALIDATION_STATUS_TYPE
} from "helpers/constants"
import {
	postChangeFootprintMode,
	patchAddressResult,
	postEditResult,
	getFootprintByCoordinates
} from "features/footprintSlice"
import {
	computePercentageLocationsAboveStreetLevel,
	computePercentageLocationsAboveTIVThreshold,
	countLocationsAboveStreetLevel,
	countLocationsHighConfidence,
	countLocationsFootprinted,
	countLocationsValidated,
	toEMPFileOrder
} from "helpers/rulesHandler"
import { SelectGeneratorWithCounter } from "helpers/SelectGenerator"
import { reduceByConfidenceRangeWithCounter } from "components/confidenceBars/ConfidenceBars"
import { setBreakdownByCountry } from "helpers/countriesHandler"

const LOCUS_RESULTS_ENDPOINT = process.env.REACT_APP_LOCUS_RESULTS_ENDPOINT
const LOCUS_JOBS_ENDPOINT = process.env.REACT_APP_LOCUS_JOBS_ENDPOINT
const LOCUS_SEARCH_RESULTS_ENDPOINT =
	process.env.REACT_APP_LOCUS_SEARCH_RESULTS_ENDPOINT

export const INIT_STATE = {
	isFetching: false,
	isSavingReviewState: false,
	locationDirectFetch: false,
	results: [],
	selectedJobId: undefined,
	selectedJobName: "",
	percentageReviewedLocation: 0.0,
	percentageUSLocationsAboveStreetLevel: 0.0,
	percentageIntlLocationsAboveStreetLevel: 0.0,
	percentageLocationsAbove50MUSDEStreetLevel: 0.0,
	fetchResultsByJobIDController: undefined,
	reviewedLocationsCount: 0,
	totalTIV: 0,
	numberOfLocationAboveStreetLevel: 0,
	numberOfLocationHighConfidence: 0,
	numberOfLocationFootprinted: 0,
	numberOfLocationValidated: 0,
	selectedResultId: undefined,
	filteredLocationsList: [],
	selectedResults: [],
	selectedRowKeys: [],
	currentLocationIndex: 0,
	isFetchingLocationByID: false,
	filteredBy: {
		filters: {
			confidence: "",
			resolution: "",
			country: "",
			status: "",
			has_footprints: ""
		},
		manual: ""
	},
	filterFootprints: "",
	footprintMode: "",
	footprintModeDescription: "",
	footprintModeReasonKey: "",
	availableProcessingModes: [],
	backendHelperMode: "",
	resolutionsAvailable: []
}

const SLICE_PREFIX = "jobsResults"
export const computeStartReviewLocationIndexHandler = (selectedResults) => {
	const idx = selectedResults.findIndex(
		(location) =>
			location.validation_status === VALIDATION_STATUS_TYPE.NON_REVIEWED
	)
	return idx !== -1 ? idx : 0
}

export const updateLocationAfterFootprintModeSwitch = (
	baseArray,
	resultId,
	newData
) => {
	let baseArrayClone = []
	if (!baseArray) return baseArrayClone
	baseArrayClone = JSON.parse(JSON.stringify(baseArray))
	if (!resultId || !newData) return baseArrayClone
	const index = baseArray.findIndex((location) => location.id === resultId)
	if (index !== -1) {
		baseArrayClone[index] = {
			...baseArrayClone[index],
			geo_data: {
				...newData
			}
		}
	}
	return baseArrayClone
}

const handleNewFootprint = (state, newFootprint) => {
	// Cherry picking new geo_data properties to be update
	const geoDataCherryPicked = {
		lat: newFootprint.lat,
		lon: newFootprint.lon,
		address: newFootprint.address,
		confidence: newFootprint.confidence,
		resolution: newFootprint.resolution,
		service: newFootprint.service,
		parcels: newFootprint.parcels,
		buildings: newFootprint.buildings,
		source: newFootprint.source,
		has_footprints: newFootprint.has_footprints,
		footprinter_error: newFootprint.footprinter_error,
		processing_mode: newFootprint.processing_mode,
		filter_footprints: newFootprint.has_footprints,
		available_processing_modes: newFootprint.available_processing_modes,
		backend_helper_mode: newFootprint.backend_helper_mode,
		processing_mode_reason_text:
			newFootprint.processing_mode_reason_text ||
			"processing_mode_reason_text",
		processing_mode_reason_key:
			newFootprint.processing_mode_reason_key ||
			"processing_mode_reason_key"
	}
	// Set new footprint mode and details
	state.footprintMode = newFootprint.processing_mode
	state.filterFootprints = newFootprint.filter_footprints
	state.footprintModeDescription =
		newFootprint.processing_mode_reason_text ||
		"processing_mode_reason_text"
	state.footprintModeReasonKey =
		newFootprint.processing_mode_reason_key || "processing_mode_reason_key"
	state.availableProcessingModes = newFootprint.available_processing_modes
	state.backendHelperMode = newFootprint.backend_helper_mode
	if (state.selectedResults[state.currentLocationIndex]) {
		state.selectedResults = updateLocationAfterFootprintModeSwitch(
			state.selectedResults,
			state.selectedResultId,
			geoDataCherryPicked
		)
	}
	if (state.filteredLocationsList[state.currentLocationIndex]) {
		state.filteredLocationsList = updateLocationAfterFootprintModeSwitch(
			state.filteredLocationsList,
			state.selectedResultId,
			geoDataCherryPicked
		)
	}
	if (state.results[state.currentLocationIndex]) {
		state.results = updateLocationAfterFootprintModeSwitch(
			state.results,
			state.selectedResultId,
			geoDataCherryPicked
		)
	}
}

/**
 *
 * Update results and selectedResults with the latest change
 * Reorder input_data for display
 *
 * @param {Redux state} 	state
 * @param {Object} 			updatedLocation
 * @returns {Ojbect}		{results, selectedResults}
 */
const handleLocationUpdate = (state, updatedLocation) => {
	const replacerData = {
		...updatedLocation,
		input_data: updatedLocation.input_data
			? Object.fromEntries(toEMPFileOrder(updatedLocation?.input_data))
			: undefined
	}
	const results = [...state.results]
	const selectedResults = [...state.selectedResults]

	results.splice(
		state.results.findIndex((r) => r.id === replacerData.id),
		1,
		replacerData
	)

	selectedResults.splice(
		state.selectedResults.findIndex((r) => r.id === replacerData.id),
		1,
		replacerData
	)
	return { results, selectedResults }
}

// GET locations by jobId @/results
export const fetchResultsByJobId = createAsyncThunk(
	`${SLICE_PREFIX}/fetchResultsByJobId`,
	async (jobId, { getState, fulfillWithValue, rejectWithValue }) => {
		if (!jobId) {
			return rejectWithValue({
				code: "LOC_E001",
				description: "All query parameters are missing",
				details: []
			})
		}
		const settings = {
			method: "GET",
			headers: generateHeadersWithAuthorization(getState())
		}
		const url = new URL(LocusEndpointURLBuilder(LOCUS_RESULTS_ENDPOINT))
		const params = {
			job_id: jobId,
			webui: true
		}
		Object.keys(params).forEach((key) =>
			url.searchParams.append(key, params[key])
		)

		try {
			const response = await fetch(url, settings)
			if (response.status === 401) {
				return rejectWithValue(await response.json(), {
					status: response.status,
					location: {
						pathname: "/location",
						search: `jobId=${jobId}`,
						hash: "",
						query: {}
					}
				})
			}
			if (response.status === 200 || response.status === 201) {
				return fulfillWithValue(await response.json(), {
					status: response.status
				})
			}
			throw response
		} catch (error) {
			return rejectWithValue(await error.json(), {
				status: error.status
			})
		}
	}
)

// POST Fetch locations by locationIds @/results
export const fetchResultsByLocationIds = createAsyncThunk(
	`${SLICE_PREFIX}/fetchResultsByLocationIds`,
	async (values, { getState, fulfillWithValue, rejectWithValue }) => {
		const url = new URL(
			LocusEndpointURLBuilder(LOCUS_SEARCH_RESULTS_ENDPOINT)
		)

		const body = {
			webui: true,
			geojson: false,
			result_ids: getState().jobsResults.selectedRowKeys
		}

		const settings = {
			method: "POST",
			headers: generateHeadersWithAuthorization(getState()),
			body: JSON.stringify(body)
		}

		try {
			const response = await fetch(url, settings)

			if (response.status === 401) {
				return rejectWithValue(await response.json(), {
					status: response.status,
					location: {
						pathname: "/location",
						search: `?jobId=${
							getState().jobsResults.selectedJobId
						}`,
						hash: "",
						query: {}
					}
				})
			}
			if (response.status === 200 || response.status === 201) {
				return fulfillWithValue(await response.json(), {
					status: response.status
				})
			}
			throw response
		} catch (error) {
			return rejectWithValue(await error.json(), {
				status: error.status
			})
		}
	}
)

// POST Fetch locations by a locationId @/results
export const fetchResultsByLocationId = createAsyncThunk(
	`${SLICE_PREFIX}/fetchResultsByLocationId`,
	async (id, { getState, fulfillWithValue, rejectWithValue }) => {
		const url = new URL(
			LocusEndpointURLBuilder(LOCUS_SEARCH_RESULTS_ENDPOINT)
		)
		const body = {
			webui: true,
			geojson: false,
			result_ids: [id]
		}

		const settings = {
			method: "POST",
			headers: generateHeadersWithAuthorization(getState()),
			body: JSON.stringify(body)
		}

		try {
			const response = await fetch(url, settings)

			if (response.status === 401) {
				return rejectWithValue(await response.json(), {
					status: response.status,
					location: {
						pathname: "/location",
						search: `?jobId=${
							getState().jobsResults.selectedJobId
						}`,
						hash: "",
						query: {}
					}
				})
			}
			if (response.status === 200 || response.status === 201) {
				return fulfillWithValue(await response.json(), {
					status: response.status
				})
			}
			throw response
		} catch (error) {
			return rejectWithValue(await error.json(), {
				status: error.status
			})
		}
	}
)

// GET job by jobId @/jobs
export const fetchJobById = createAsyncThunk(
	`${SLICE_PREFIX}/fetchJobById`,
	async (jobId, { getState, fulfillWithValue, rejectWithValue }) => {
		if (!jobId) {
			return rejectWithValue({
				code: "JOB_E001",
				description: "All query parameters are missing",
				details: []
			})
		}

		const settings = {
			method: "GET",
			headers: generateHeadersWithAuthorization(getState())
		}

		const url = new URL(LocusEndpointURLBuilder(LOCUS_JOBS_ENDPOINT))

		const params = {
			job_id: jobId,
			webui: true
		}
		Object.keys(params).forEach((key) =>
			url.searchParams.append(key, params[key])
		)

		try {
			const response = await fetch(url, settings)
			if (response.status === 401) {
				return rejectWithValue(await response.json(), {
					status: response.status,
					location: {
						pathname: "/location",
						search: `jobId=${jobId}`,
						hash: "",
						query: {}
					}
				})
			}
			if (response.status === 200 || response.status === 201) {
				return fulfillWithValue(await response.json(), {
					status: response.status
				})
			}
			throw response
		} catch (error) {
			return rejectWithValue(await error.json(), {
				status: error.status
			})
		}
	}
)

// GET location by locationId @/results
export const fetchLocationById = createAsyncThunk(
	`${SLICE_PREFIX}/fetchLocationById`,
	async (values, { getState, fulfillWithValue, rejectWithValue }) => {
		const settings = {
			method: "GET",
			headers: generateHeadersWithAuthorization(getState())
		}

		const url = new URL(LocusEndpointURLBuilder(LOCUS_RESULTS_ENDPOINT))
		if (!values) {
			return rejectWithValue({
				code: "LOC_E001",
				description: "All query parameters are missing",
				details: []
			})
		}
		if (!values.locationId) {
			return rejectWithValue({
				code: "LOC_E002",
				description: "Location ID parameter is missing",
				details: []
			})
		}

		const params = {
			result_id: values.locationId,
			webui: true
		}
		Object.keys(params).forEach((key) =>
			url.searchParams.append(key, params[key])
		)

		try {
			const response = await fetch(url, settings)
			if (response.status === 401) {
				return rejectWithValue(await response.json(), {
					status: response.status,
					location: {
						pathname: "/map",
						search: `?locationId=${values.locationId}`,
						hash: "",
						query: {}
					}
				})
			}
			if (response.status === 200 || response.status === 201) {
				return fulfillWithValue(await response.json(), {
					status: response.status
				})
			}
			throw response
		} catch (error) {
			return rejectWithValue(await error.json(), { status: error.status })
		}
	}
)

// PUT saving job @/jobs
export const saveReviewState = createAsyncThunk(
	`${SLICE_PREFIX}/saveReviewState`,
	async (values, { getState, fulfillWithValue, rejectWithValue }) => {
		try {
			if (!values) {
				return rejectWithValue({
					code: "LOC_E010",
					description: "All query parameters are missing",
					details: []
				})
			}
			if (!values.jobId) {
				return rejectWithValue({
					code: "LOC_E011",
					description: "jobId parameter is missing",
					details: []
				})
			}
			if (!values.selectedLocations) {
				return rejectWithValue({
					code: "LOC_E012",
					description: "selectedLocations parameter is missing",
					details: []
				})
			}
			if (
				!Number.isInteger(values.currentLocationIndex) ||
				values.currentLocationIndex === undefined ||
				values.currentLocationIndex === null
			) {
				return rejectWithValue({
					code: "LOC_E013",
					description: "currentLocationIndex parameter is missing",
					details: []
				})
			}

			const body = {
				job_id: values.jobId,
				selected_locations_ids: values.selectedLocations,
				current_review_position_index: values.currentLocationIndex,
				filtered_by: JSON.stringify(getState().jobsResults.filteredBy)
			}

			const settings = {
				method: "PUT",
				body: JSON.stringify(body),
				headers: generateHeadersWithAuthorization(getState())
			}

			const url = new URL(LocusEndpointURLBuilder(LOCUS_JOBS_ENDPOINT))

			const response = await fetch(url, settings)
			if (response.status === 401) {
				return rejectWithValue(await response.json(), {
					status: response.status,
					location: {
						pathname: "/map",
						search: `?locationId=${values.selectedLocations[0]}`,
						hash: "",
						query: {}
					}
				})
			}
			if (response.status === 204) {
				return fulfillWithValue(
					{},
					{
						status: response.status
					}
				)
			}
			throw response
		} catch (error) {
			return rejectWithValue(await error.json(), { status: error.status })
		}
	}
)

export const jobsResultsSlice = createSlice({
	name: SLICE_PREFIX,
	initialState: INIT_STATE,
	reducers: {
		clearJobResults: () => ({ ...INIT_STATE }),
		setManualSearchInputValue: (state, action) => ({
			...state,
			filteredBy: {
				...state.filteredBy,
				manual: action.payload
			}
		}),
		setSelectedRowKeys: (state, action) => ({
			...state,
			selectedRowKeys: action.payload || []
		}),
		setSelectedJobId: (state, action) => ({
			...state,
			selectedJobId: action.payload
		}),
		setSelectedResultId: (state, action) => ({
			...state,
			selectedResultId: action.payload
		}),
		setJobName: (state, action) => ({
			...state,
			selectedJobName: action.payload
		}),
		setFetchingLocationByID: (state, action) => ({
			...state,
			isFetchingLocationByID:
				typeof action.payload === "boolean" ? action.payload : false
		}),
		setSelectedResults: (state, action) => ({
			...state,
			selectedResults: action.payload.sort((a, b) => {
				const locationsCurrentOrder = state.filteredLocationsList.map(
					(location) => location.id
				)
				return (
					locationsCurrentOrder.indexOf(a.id) -
					locationsCurrentOrder.indexOf(b.id)
				)
			}),
			currentLocationIndex: action.payload.findIndex(
				(location) =>
					location.validation_status ===
					VALIDATION_STATUS_TYPE.NON_REVIEWED
			)
		}),
		filterLocationsByManualInput: (state, action) => {
			const { filteredList, manInput } = FilterAndSearch(
				state.filteredBy,
				state.results,
				action.payload
			)
			return {
				...state,
				filteredBy: {
					...state.filteredBy,
					manual: manInput
				},
				filteredLocationsList: filteredList
			}
		},
		filterToTop10TIVs: (state) => ({
			...state,
			filteredLocationsList: SortingByTIVAndLimitTo(state.results)
		}),
		setFilteredByCountry: (state, action) => ({
			...state,
			filteredBy: {
				...state.filteredBy,
				filters: {
					...state.filteredBy.filters,
					country: action.payload
				}
			}
		}),
		setFilteredByResolution: (state, action) => ({
			...state,
			filteredBy: {
				...state.filteredBy,
				filters: {
					...state.filteredBy.filters,
					resolution: action.payload
				}
			}
		}),
		setFilteredByResolutionBelowStreetLevel: (state) => {
			// https://jira.axa.com/jira/browse/LGO-1021
			// Below street-level AND current filters are applied
			const mergeResolutions = [
				...new Set([
					...JSON.parse(
						JSON.stringify(state.filteredBy.filters?.resolution)
					),
					...RESOLUTION_QUALITY_RANGE.BELOW_STREET_LEVEL.filter(
						(resolution) =>
							Object.keys(state.resolutionsAvailable).includes(
								resolution
							)
					) // belowSteetLevelResolutionsAvailable
				])
			]
			const { filteredList } = FilterAndSearch(
				{
					...state.filteredBy,
					filters: {
						...state.filteredBy.filters,
						resolution: mergeResolutions
					}
				},
				JSON.parse(JSON.stringify(state.results)),
				JSON.parse(JSON.stringify(state.filteredBy.manual))
			)
			return {
				...state,
				filteredLocationsList: filteredList,
				filteredBy: {
					...state.filteredBy,
					filters: {
						...state.filteredBy.filters,
						resolution: mergeResolutions
					}
				}
			}
		},
		resetFilteredByResolution: (state) => ({
			...state,
			filteredBy: {
				...state.filteredBy,
				filters: {
					...state.filteredBy.filters,
					resolution: ""
				}
			}
		}),
		setFilteredByConfidence: (state, action) => ({
			...state,
			filteredBy: {
				...state.filteredBy,
				filters: {
					...state.filteredBy.filters,
					confidence: action.payload
				}
			}
		}),
		setFilteredByStatus: (state, action) => ({
			...state,
			filteredBy: {
				...state.filteredBy,
				filters: {
					...state.filteredBy.filters,
					status: action.payload
				}
			}
		}),
		setFilteredByHasFootprints: (state, action) => ({
			...state,
			filteredBy: {
				...state.filteredBy,
				filters: {
					...state.filteredBy.filters,
					has_footprints: action.payload
				}
			}
		}),
		setFilteredByConfig: (state, action) => {
			const { filteredList } = FilterAndSearch(
				JSON.parse(JSON.stringify(action.payload)),
				JSON.parse(JSON.stringify(state.results)),
				action.payload.manual
			)
			return {
				...state,
				filteredLocationsList: filteredList,
				filteredBy: action.payload
			}
		},
		resetFilteredByConfig: (state) => ({
			...state,
			filteredBy: INIT_STATE.filteredBy
		}),
		resetFilteredLocationsList: (state) => ({
			...state,
			filteredLocationsList: state.results
		}),
		filterLocationsByFilters: (state) => {
			const { filteredList } = FilterAndSearch(
				JSON.parse(JSON.stringify(state.filteredBy)),
				JSON.parse(JSON.stringify(state.results)),
				state.filteredBy.manual
			)
			return {
				...state,
				filteredLocationsList: filteredList
			}
		},
		resetQuickFilters: (state) => ({
			...state,
			filteredBy: {
				manual: state.filteredBy.manual || "",
				filters: {
					...INIT_STATE.filteredBy.filters
				}
			},
			filteredLocationsList: state.results
		}),
		locationIndexToZero: (state) => ({
			...state,
			currentLocationIndex: 0
		}),
		setCurrentLocationIndex: (state, action) => ({
			...state,
			currentLocationIndex: action.payload || 0
		}),
		resetLocationIndex: (state) => ({
			...state,
			currentLocationIndex: -1
		}),
		nextLocationIndex: (state) => ({
			...state,
			currentLocationIndex: state.currentLocationIndex + 1
		}),
		previousLocationIndex: (state) => ({
			...state,
			currentLocationIndex: state.currentLocationIndex - 1
		}),
		resetSelectedLocations: (state) => ({
			...state,
			selectedResults: [],
			currentLocationIndex: 0
		}),
		computeStartReviewLocationIndex: (state) => ({
			...state,
			currentLocationIndex: computeStartReviewLocationIndexHandler(
				state.selectedResults
			)
		}),
		setCurrentFootprintMode: (state, action) => ({
			...state,
			footprintMode: action.payload
		}),
		setCurrentModeDescription: (state, action) => ({
			...state,
			footprintModeDescription: action.payload
		}),
		setCurrentModeReasonKey: (state, action) => ({
			...state,
			footprintModeReasonKey: action.payload
		}),
		setSaveReviewState: (state, action) => ({
			...state,
			isSavingReviewState: action.payload
		}),
		setFetchResultsByJobIDController: (state, action) => ({
			...state,
			fetchResultsByJobIDController: action.payload
		})
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchResultsByJobId.pending, (state) => {
				state.isFetching = true
			})

			.addCase(fetchResultsByJobId.fulfilled, (state, action) => {
				state.isFetching = false
				state.results = action.payload?.items?.map((item) => {
					const { input_data: inputData } = item
					const resolution = item?.geo_data?.resolution
					if (!resolution) {
						// eslint-disable-next-line no-param-reassign
						item = {
							...item,
							geo_data: {
								...item.geo_data,
								resolution: "NO_GEOCODE"
							}
						}
					}
					return {
						...item,
						input_data: inputData
							? Object.fromEntries(toEMPFileOrder(inputData))
							: undefined
					}
				})
				state.resolutionsAvailable = SelectGeneratorWithCounter(
					state.results,
					(location) =>
						location.geo_data?.resolution ?? undefined
							? location.geo_data.resolution
							: null
				)
				state.confidencesAvailable = reduceByConfidenceRangeWithCounter(
					SelectGeneratorWithCounter(state.results, (location) =>
						location.geo_data?.confidence ?? undefined
							? location.geo_data.confidence
							: null
					)
				)
				state.statusAvailable = SelectGeneratorWithCounter(
					state.results,
					(location) =>
						location?.validation_status ?? undefined
							? location?.validation_status
							: null
				)
				state.countriesAvailable = SelectGeneratorWithCounter(
					state.results,
					(location) =>
						location.geo_data?.address?.country_iso2 ?? undefined
							? location.geo_data.address.country_iso2
							: null
				)
				state.breakdownByCountries = setBreakdownByCountry(
					state.results,
					state.countriesAvailable
				)
				state.hasFootprintsAvailable = SelectGeneratorWithCounter(
					state.results,
					(location) =>
						!!(location.geo_data?.has_footprints ?? undefined)
				)
				state.filteredLocationsList = state.results
				state.filterFootprints = SelectGeneratorWithCounter(
					state.results,
					(location) => location.geo_data?.has_footprints
				)
				if (state.results.length > 0) {
					state.reviewedLocationsCount = state.results.filter(
						(location) =>
							VALIDATION_STATUS_TYPE.REVIEWED_AND_VALIDATED.includes(
								location.validation_status
							)
					).length
					state.percentageReviewedLocation =
						(state.reviewedLocationsCount / state.results.length) *
						100

					state.percentageUSLocationsAboveStreetLevel =
						computePercentageLocationsAboveStreetLevel(
							state.results.filter(
								(location) =>
									location.input_data?.country_code === "US"
							)
						)

					state.percentageIntlLocationsAboveStreetLevel =
						computePercentageLocationsAboveStreetLevel(
							state.results.filter(
								(location) =>
									location.input_data?.country_code !== "US"
							)
						)
					state.percentageLocationsAbove50MUSDEStreetLevel =
						computePercentageLocationsAboveTIVThreshold(
							state.results,
							50000000
						)
					state.totalTIV = state.results
						.map(
							(location) =>
								location.input_data?.raw_emp_attributes
									?.TOTALVALUE
						)
						.reduce(
							(prev, curr) =>
								parseFloat(prev, 10) + parseFloat(curr, 10),
							0
						)
					state.numberOfLocationAboveStreetLevel =
						countLocationsAboveStreetLevel(state.results)
					state.numberOfLocationHighConfidence =
						countLocationsHighConfidence(state.results)
					state.numberOfLocationFootprinted =
						countLocationsFootprinted(state.results)
					state.numberOfLocationValidated = countLocationsValidated(
						state.results
					)
				}
				const { filteredList } = FilterAndSearch(
					JSON.parse(JSON.stringify(state.filteredBy)),
					JSON.parse(JSON.stringify(state.results)),
					state.filteredBy.manual
				)
				state.filteredLocationsList = filteredList
				state.selectedResults = state.filteredLocationsList
				state.selectedResults = state.filteredLocationsList.filter(
					(location) => state.selectedRowKeys.includes(location.id)
				)
				if (state.selectedResults.length > 0) {
					state.currentLocationIndex =
						computeStartReviewLocationIndexHandler(
							state.selectedResults
						)
					state.footprintMode =
						state.selectedResults[
							state.currentLocationIndex
						].geo_data.processing_mode
					state.footprintModeDescription =
						state.selectedResults[
							state.currentLocationIndex
						].geo_data.processing_mode_reason_text
					state.footprintModeReasonKey =
						state.selectedResults[
							state.currentLocationIndex
						].geo_data.processing_mode_reason_key
					state.availableProcessingModes =
						state.selectedResults[
							state.currentLocationIndex
						].geo_data.available_processing_modes
					state.backendHelperMode =
						state.selectedResults[
							state.currentLocationIndex
						].geo_data.backend_helper_mode
				} else {
					state.currentLocationIndex = 0
					state.footprintMode =
						state.filteredLocationsList[
							state.currentLocationIndex
						].geo_data.processing_mode
					state.footprintModeDescription =
						state.filteredLocationsList[
							state.currentLocationIndex
						].geo_data.processing_mode_reason_text
					state.footprintModeReasonKey =
						state.filteredLocationsList[
							state.currentLocationIndex
						].geo_data.processing_mode_reason_key
				}
			})
			.addCase(fetchResultsByJobId.rejected, (state) => {
				state.isFetching = false
			})
			.addCase(fetchResultsByLocationIds.pending, (state) => {
				state.isFetching = true
			})
			.addCase(fetchResultsByLocationIds.fulfilled, (state, action) => {
				state.isFetching = false
				state.selectedResults = action.payload?.items?.map((item) => ({
					...item,
					input_data: item.input_data
						? Object.fromEntries(toEMPFileOrder(item.input_data))
						: undefined
				}))
				if (state.selectedResults.length > 0) {
					state.currentLocationIndex =
						computeStartReviewLocationIndexHandler(
							state.selectedResults
						)
					state.footprintMode =
						state.selectedResults[
							state.currentLocationIndex
						].geo_data.processing_mode
					state.footprintModeDescription =
						state.selectedResults[
							state.currentLocationIndex
						].geo_data.processing_mode_reason_text
					state.footprintModeReasonKey =
						state.selectedResults[
							state.currentLocationIndex
						].geo_data.processing_mode_reason_key
					state.availableProcessingModes =
						state.selectedResults[
							state.currentLocationIndex
						].geo_data.available_processing_modes
					state.backendHelperMode =
						state.selectedResults[
							state.currentLocationIndex
						].geo_data.backend_helper_mode
				}
			})
			.addCase(fetchResultsByLocationIds.rejected, (state) => {
				state.isFetching = false
			})
			.addCase(fetchResultsByLocationId.pending, (state) => {
				state.isFetching = true
			})
			.addCase(fetchResultsByLocationId.fulfilled, (state, action) => {
				state.isFetching = false
				const result = action.payload?.items?.map((item) => ({
					...item,
					input_data: item.input_data
						? Object.fromEntries(toEMPFileOrder(item.input_data))
						: undefined
				}))[0]
				// eslint-disable-next-line prefer-destructuring
				state.selectedResults[state.currentLocationIndex] = result

				if (state.selectedResults.length > 0) {
					state.footprintMode =
						state.selectedResults[
							state.currentLocationIndex
						].geo_data.processing_mode
					state.footprintModeDescription =
						state.selectedResults[
							state.currentLocationIndex
						].geo_data.processing_mode_reason_text
					state.footprintModeReasonKey =
						state.selectedResults[
							state.currentLocationIndex
						].geo_data.processing_mode_reason_key
					state.availableProcessingModes =
						state.selectedResults[
							state.currentLocationIndex
						].geo_data.available_processing_modes
					state.backendHelperMode =
						state.selectedResults[
							state.currentLocationIndex
						].geo_data.backend_helper_mode
				}
			})
			.addCase(fetchResultsByLocationId.rejected, (state) => {
				state.isFetching = false
			})
			.addCase(fetchJobById.pending, (state) => state)
			.addCase(fetchJobById.fulfilled, (state, action) => {
				state.selectedJobName = action.payload?.account_name ?? ""
				state.currentLocationIndex =
					action.payload.current_review_position_index
				state.filteredBy = action.payload.filtered_by
					? JSON.parse(action.payload.filtered_by)
					: INIT_STATE.filteredBy
			})
			.addCase(fetchJobById.rejected, (state) => state)
			.addCase(fetchLocationById.pending, (state) => {
				state.isFetchingLocationByID = true
			})
			.addCase(fetchLocationById.fulfilled, (state, action) => {
				state.isFetchingLocationByID = false
				const inputData = action.payload.input_data
					? JSON.parse(JSON.stringify(action.payload.input_data))
					: undefined
				state.results.push({
					...action.payload,
					input_data: inputData
						? Object.fromEntries(toEMPFileOrder(inputData))
						: undefined
				})
				state.selectedResults = state.results
				state.selectedJobId = action.payload.job_id
				state.selectedResultId = action.payload.id
				state.locationDirectFetch = true
			})
			.addCase(fetchLocationById.rejected, (state) => {
				state.isFetchingLocationByID = false
			})
			.addCase(saveReviewState.pending, (state) => {
				state.isSavingReviewState = true
			})
			.addCase(saveReviewState.fulfilled, (state) => {
				state.isSavingReviewState = true
			})
			.addCase(saveReviewState.rejected, (state) => {
				state.isSavingReviewState = false
			})
			.addCase(postChangeFootprintMode.fulfilled, (state, action) => {
				if (!Array.isArray(action.payload)) {
					handleNewFootprint(state, action.payload)
				}
			})
			.addCase(getFootprintByCoordinates.fulfilled, (state, action) => {
				if (state.selectedResults[state.currentLocationIndex]) {
					state.selectedResults[state.currentLocationIndex].geo_data =
						{}
				}
				if (state.filteredLocationsList[state.currentLocationIndex]) {
					state.filteredLocationsList[
						state.currentLocationIndex
					].geo_data = {}
				}
				if (state.results[state.currentLocationIndex]) {
					state.results[state.currentLocationIndex].geo_data = {}
				}
				state.selectedResults[
					state.currentLocationIndex
				].validation_status = VALIDATION_STATUS_TYPE.NON_REVIEWED
				handleNewFootprint(state, {
					...action.payload,
					source: "CLIENT_COORDINATES"
				})
			})
			.addCase(patchAddressResult.pending, () => {})
			.addCase(patchAddressResult.fulfilled, (state, action) => {
				const { results, selectedResults } = handleLocationUpdate(
					state,
					action.payload
				)

				state.results = results
				state.selectedResults = selectedResults
				state.filteredLocationsList = results
			})
			.addCase(patchAddressResult.rejected, () => {})
			.addCase(postEditResult.pending, () => {})
			.addCase(postEditResult.fulfilled, (state, action) => {
				const { results, selectedResults } = handleLocationUpdate(
					state,
					action.payload
				)
				state.results = results
				state.selectedResults = selectedResults
				state.filteredLocationsList = results
			})
			.addCase(postEditResult.rejected, () => {})
	}
})

export const {
	clearJobResults,
	setSelectedJobId,
	setJobName,
	setSelectedRowKeys,
	setSelectedResultId,
	setSelectedResults,
	filterLocationsByManualInput,
	filterToTop10TIVs,
	filterByConfidence,
	filterByCountry,
	filterLocationsByFilters,
	locationIndexToZero,
	nextLocationIndex,
	previousLocationIndex,
	resetQuickFilters,
	resetLocationIndex,
	resetSelectedLocations,
	resetFilteredByConfig,
	resetFilteredLocationsList,
	setFilteredByCountry,
	setFilteredByResolution,
	setFilteredByConfidence,
	setFilteredByStatus,
	setFilteredByConfig,
	setFilteredByHasFootprints,
	setManualSearchInputValue,
	setCurrentLocationIndex,
	computeStartReviewLocationIndex,
	setFetchingLocationByID,
	setCurrentFootprintMode,
	setCurrentModeDescription,
	setCurrentModeReasonKey,
	setSaveReviewState,
	setFetchResultsByJobIDController,
	setFilteredByResolutionBelowStreetLevel,
	resetFilteredByResolution
} = jobsResultsSlice.actions

/* istanbul ignore next */
export const isFetching = (state) => state.jobsResults.isFetching
/* istanbul ignore next */
export const jobResults = (state) => state.jobsResults.results
/* istanbul ignore next */
export const selectedJobId = (state) => state.jobsResults.selectedJobId
/* istanbul ignore next */
export const selectedJobName = (state) => state.jobsResults.selectedJobName
/* istanbul ignore next */
export const selectedResultId = (state) => state.jobsResults.selectedResultId
/* istanbul ignore next */
export const selectedResults = (state) => state.jobsResults.selectedResults
/* istanbul ignore next */
export const filteredLocationsList = (state) =>
	state.jobsResults.filteredLocationsList
/* istanbul ignore next */
export const percentageReviewedLocation = (state) =>
	state.jobsResults.percentageReviewedLocation
/* istanbul ignore next */
export const percentageUSLocationsAboveStreetLevel = (state) =>
	state.jobsResults.percentageUSLocationsAboveStreetLevel
/* istanbul ignore next */
export const percentageIntlLocationsAboveStreetLevel = (state) =>
	state.jobsResults.percentageIntlLocationsAboveStreetLevel
/* istanbul ignore next */
export const totalTIV = (state) => state.jobsResults.totalTIV
/* istanbul ignore next */
export const numberOfLocationAboveStreetLevel = (state) =>
	state.jobsResults.numberOfLocationAboveStreetLevel
/* istanbul ignore next */
export const numberOfLocationHighConfidence = (state) =>
	state.jobsResults.numberOfLocationHighConfidence
/* istanbul ignore next */
export const numberOfLocationFootprinted = (state) =>
	state.jobsResults.numberOfLocationFootprinted
/* istanbul ignore next */
export const numberOfLocationValidated = (state) =>
	state.jobsResults.numberOfLocationValidated
/* istanbul ignore next */
export const percentageLocationsAbove50MUSDEStreetLevel = (state) =>
	state.jobsResults.percentageLocationsAbove50MUSDEStreetLevel
/* istanbul ignore next */
export const currentLocationIndex = (state) =>
	state.jobsResults.currentLocationIndex
/* istanbul ignore next */
export const jobResultsLength = (state) => state.jobsResults.results.length
/* istanbul ignore next */
export const reviewedLocationsCount = (state) =>
	state.jobsResults.reviewedLocationsCount
/* istanbul ignore next */
export const locationDirectFetch = (state) =>
	state.jobsResults.locationDirectFetch
/* istanbul ignore next */
export const isFetchingLocationByID = (state) =>
	state.jobsResults.isFetchingLocationByID
/* istanbul ignore next */
export const selectedRowKeys = (state) => state.jobsResults.selectedRowKeys
/* istanbul ignore next */
export const filteredBy = (state) => state.jobsResults.filteredBy
/* istanbul ignore next */
export const footprintMode = (state) => state.jobsResults.footprintMode
/* istanbul ignore next */
export const footprintModeDescription = (state) =>
	state.jobsResults.footprintModeDescription
/* istanbul ignore next */
export const footprintModeReasonKey = (state) =>
	state.jobsResults.footprintModeReasonKey
/* istanbul ignore next */
export const isSavingReviewState = (state) =>
	state.jobsResults.isSavingReviewState
/* istanbul ignore next */
export const fetchResultsByJobIDController = (state) =>
	state.jobsResults.fetchResultsByJobIDController
/* istanbul ignore next */
export const resolutionsAvailable = (state) =>
	state.jobsResults.resolutionsAvailable
/* istanbul ignore next */
export const confidencesAvailable = (state) =>
	state.jobsResults.confidencesAvailable
/* istanbul ignore next */
export const statusAvailable = (state) => state.jobsResults.statusAvailable
/* istanbul ignore next */
export const countriesAvailable = (state) =>
	state.jobsResults.countriesAvailable
/* istanbul ignore next */
export const breakdownByCountries = (state) =>
	state.jobsResults.breakdownByCountries
export const hasFootprintsAvailable = (state) =>
	state.jobsResults.hasFootprintsAvailable
export const filterFootprints = (state) => state.jobsResults.filterFootprints
export default jobsResultsSlice.reducer
