import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
// eslint-disable-next-line import/no-extraneous-dependencies
import "mapbox-gl/dist/mapbox-gl.css"
import { Layout } from "antd"
import { Route, Routes } from "react-router" // react-router v4/v5
import { useLocation, useNavigate } from "react-router-dom"

import Map from "pages/map/Map"
import JobsPage from "pages/jobs/JobsPage"
import LocationsPage from "pages/locations/LocationsPage"
import LoginPage from "pages/login/LoginPage"
import NotFoundPage from "pages/notFound/NotFoundPage"

import Alerts from "components/alerts/Alerts"
import {
	alertPayload as payloadSlice,
	isDisplayAlert as isDisplayAlertSlice
} from "features/alertsSlice"
import {
	REQUESTED_PATH_KEY,
	authorizeURL as authorizeURLSlice,
	createAuthorizationRequest,
	isLoggedIn as isLoggedInSlice,
	isLoggedOut as isLoggedOutSlice,
	logoutURI as logoutURISlice,
	fetchAccessToken,
	setUserLocale
} from "features/authSlice"
import { footprintMode as footprintModeSlice } from "features/jobsResultsSlice"
import "antd/dist/antd.min.css"
import "App.css"
import Footer from "components/footer/Footer"
import Header from "components/header/Header"
import DataPrivacyModal from "components/dataPrivacyModal/DataPrivacyModal"
import LoadingModal from "components/loadingModal/LoadingModal"
import { LOGIN_BLOCK_MODE } from "helpers/constants"

const { Content } = Layout

const App = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const currentPathname = useSelector(() => window.location.pathname)
	const currentLocation = useLocation()
	const payload = useSelector(payloadSlice)
	const toDisplayAlert = useSelector(isDisplayAlertSlice)
	const authorizeURL = useSelector(authorizeURLSlice)
	const isLoggedIn = useSelector(isLoggedInSlice)
	const isLoggedOut = useSelector(isLoggedOutSlice)
	const logoutURI = useSelector(logoutURISlice)
	const currentFootprintMode = useSelector(footprintModeSlice)

	// Triggers the creation of an AuthZ request i.e start of the OAuth process
	useEffect(() => {
		if (
			!isLoggedIn &&
			!authorizeURL &&
			currentPathname !== "/callback" &&
			!isLoggedOut
		) {
			dispatch(createAuthorizationRequest(currentLocation))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch])

	// Trigger OAuth request
	useEffect(() => {
		if (!toDisplayAlert) {
			if (authorizeURL && currentPathname !== "/callback")
				window.location.href = authorizeURL
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authorizeURL])

	// Trigger logout process. Basically redirects to PassAXA logout page
	useEffect(() => {
		if (logoutURI) {
			window.location.href = logoutURI
		}
	}, [logoutURI])

	// OAuth callback
	// If no prior successful login => request Access Token (i.e follow OAuth process)
	// If already logged in then => trigger redirection
	useEffect(() => {
		if (
			currentPathname === "/callback" &&
			!toDisplayAlert &&
			currentPathname !== "/loggedout"
		) {
			if (!isLoggedIn) {
				const params = new URLSearchParams(currentLocation.search)
				dispatch(
					fetchAccessToken({
						code: params.get("code"),
						state: params.get("state")
					})
				)
			} else {
				dispatch(setUserLocale(navigator && navigator.language))
				navigate(
					JSON.parse(
						window.sessionStorage.getItem(REQUESTED_PATH_KEY)
					)
				)
			}
		}
	}, [
		currentPathname,
		isLoggedIn,
		toDisplayAlert,
		navigate,
		currentLocation,
		dispatch
	])

	return (
		<Layout style={{ minHeight: "100vh" }}>
			{currentPathname === "/map" ? null : <Header />}
			{isLoggedIn ? (
				<Content className="content__container">
					{toDisplayAlert && !payload.isMapAlert ? (
						<Alerts
							alertCode={payload?.code ?? "Title"}
							isMapAlert={payload.isMapAlert}
							interpolation={{ recordName: payload.recordName }}
							type={payload.type}
							message={payload.message}
						/>
					) : null}
					<DataPrivacyModal />
					<LoadingModal mode={currentFootprintMode} />
					<Routes>
						<Route exact path="/" element={<JobsPage />} />
						<Route
							exact
							path="/location"
							element={<LocationsPage />}
						/>
						<Route exact path="/map" element={<Map />} />
						<Route
							exact
							path="/callback"
							element={
								<LoginPage type={LOGIN_BLOCK_MODE.CALLBACK} />
							}
						/>
						<Route
							exact
							path="/login"
							element={
								<LoginPage type={LOGIN_BLOCK_MODE.LOGIN} />
							}
						/>
						<Route path="*" element={<NotFoundPage />} />
					</Routes>
				</Content>
			) : (
				<Content className="content__container">
					<LoginPage type={LOGIN_BLOCK_MODE.CALLBACK} />
				</Content>
			)}
			{currentPathname === "/map" ? null : <Footer />}
		</Layout>
	)
}

export default App
