import { createSlice } from "@reduxjs/toolkit"
import { ALERTS_TYPE } from "helpers/constants"
import { TO_BE_GENERIC } from "helpers/ErrorCodesMapping"

export const genericErrorHandler = (code) =>
	TO_BE_GENERIC.includes(code) || !code ? "GEN_E100" : code

export const INIT_STATE = {
	isDisplayAlert: false,
	payload: {
		type: ALERTS_TYPE.INFO,
		isMapAlert: false,
		status: 418
	}
}

/**
 * Slice responsible for the Alert state:
 *  - Show/Hide display an Alert
 *  - Display an Alert with a given payload
 */
export const alertsSlice = createSlice({
	name: "alerts",
	initialState: INIT_STATE,
	reducers: {
		displayAlert: (state, action) => {
			if (typeof action.payload !== "boolean") {
				return {
					...state,
					isDisplayAlert: state.isDisplayAlert
				}
			}
			return {
				...state,
				isDisplayAlert: action.payload
			}
		},
		setAlertPayload: (state, action) => ({
			...state,
			isDisplayAlert: true,
			payload: {
				...action.payload,
				code: genericErrorHandler(action.payload.code)
			}
		})
	}
})

export const { displayAlert, setAlertPayload } = alertsSlice.actions

/* istanbul ignore next */
export const isDisplayAlert = (state) => state.alerts.isDisplayAlert
/* istanbul ignore next */
export const alertPayload = (state) => state.alerts.payload

export default alertsSlice.reducer
