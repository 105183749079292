/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types"
import React, { useState, useRef, useEffect } from "react"
import { useSelector } from "react-redux"
import { List, Typography, Button } from "antd"
import { PlusOutlined, MinusOutlined } from "@ant-design/icons"

import { useTranslation } from "react-i18next"
import { isJSONString } from "helpers/Checkers"
import "./InfoTooltip.css"
import FloatingInfoPanel from "components/floatingInfoPanel/FloatingInfoPanel"
import {
	geocodingIsSubmittingFootprintChecker as geocodingIsSubmittingFootprintCheckerSlice,
	isPostingResultEdit as isPostingResultEditSlice
} from "features/footprintSlice"
import {
	BUILDING_ATTRIBUTES_RANK_MAPPING,
	FOOTPRINT_ATTRIBUTES_RANK,
	GEOMETRY_CATEGORY,
	PARCEL_ATTRIBUTES_RANK_MAPPING
} from "helpers/constants"

const { Title, Paragraph } = Typography
export const TOOLTIP_BOTTOM_OFFSET_PX = 20

/**
 *
 * Creates InfoPanel when users have clicked on a Parcel or Building"
 *
 * @props {string}			type: geometry category following GEOMETRY_CATEGORY values
 * @props {object}			position: {x, y} coordinates where the component should be display at
 * @props {object} 			clickedParcel: clicked parcel object
 * @props {object} 			clickedParcel: clicked parcel object
 * @props {function} 		handleAddRemoveParcel: function for UI to handle the addition/removal parcel
 * @props {function} 		clickedParcel: function for UI to handle the removal parcel
 * @props {function} 		onFloatingInfoPanelClose: function for UI to handle the removal of the tooltip
 *
 * @returns InfoTooltip
 */
const InfoTooltip = (props) => {
	const {
		type,
		position,
		clickedParcel,
		handleAddRemoveParcel,
		handleAddRemoveBuilding,
		onFloatingInfoPanelClose,
		closeInfoTooltipHandler
	} = props
	const { t } = useTranslation()
	const infoTooltipRef = useRef(null)
	const [displayFloatingPanel, setDisplayFloatingPanel] = useState(false)
	const isPostingResultEdit = useSelector(isPostingResultEditSlice)
	const isSubmittingFootprintChecker = useSelector(
		geocodingIsSubmittingFootprintCheckerSlice
	)
	const content = clickedParcel.properties
	const [top, setTop] = useState(position.y)

	/* 
		Build a flatten content Object
		From {foo : "{\"alice\":\"bob\"}", bar: 42}
		To {
			foo.alice: "bob",
			bar: 42
		}
	*/
	const flattenContent = Object.assign(
		{},
		...Object.keys(content)
			.map((item) => {
				if (content[item]) {
					// if item is stringified
					if (isJSONString(content[item])) {
						if (
							item === "linked_parcels" &&
							JSON.parse(content[item]).length > 0
						) {
							return {
								linked_parcels: {
									label: JSON.parse(content[item]).map(
										(parcel, index) => {
											if (
												index + 1 ===
												JSON.parse(content[item]).length
											) {
												return parcel
											}
											return `${parcel}, `
										}
									),
									type: BUILDING_ATTRIBUTES_RANK_MAPPING[item]
								}
							}
						}
						return Object.keys(JSON.parse(content[item])).map(
							(subItem) => {
								if (
									((`${item}.${subItem}` ===
										"structure.wall_material" &&
										JSON.parse(content[item])[subItem] ===
											"00") ||
										`${item}.${subItem}` ===
											"structure.roof") &&
									Number(
										JSON.parse(content[item])[subItem]
									) === 0
								) {
									return null
								}
								if (JSON.parse(content[item])[subItem]) {
									return {
										[`${item}.${subItem}`]: {
											label: JSON.parse(content[item])[
												subItem
											],
											type:
												type ===
												GEOMETRY_CATEGORY.PARCEL
													? PARCEL_ATTRIBUTES_RANK_MAPPING[
															`${item}.${subItem}`
													  ]
													: BUILDING_ATTRIBUTES_RANK_MAPPING[
															`${item}.${subItem}`
													  ]
										}
									}
								}
								/* istanbul ignore next */
								return null
							}
						)
					}
					return {
						[item]: {
							label: content[item],
							type:
								type === GEOMETRY_CATEGORY.PARCEL
									? PARCEL_ATTRIBUTES_RANK_MAPPING[item]
									: BUILDING_ATTRIBUTES_RANK_MAPPING[item]
						}
					}
				}
				return null
			})
			.flat()
			.filter((_content) => _content !== null)
	)
	/* 
		Filter only allowed keys
		infoToDisplay: foo.alice
		From {
			foo.alice: "bob",
			bar: 42
		}
		To {
			foo.alice: "bob",
		}
	*/
	const data = Object.keys(flattenContent)
		.filter((key) => flattenContent[key].type) // display only keys who have been mapped Primary or Secondary
		.map((_content) => ({
			title: t(_content),
			type: flattenContent[_content].type,
			description: (
				<Paragraph copyable={t(_content) === "Address"}>
					{flattenContent[_content].label}
				</Paragraph>
			)
		}))

	const parcelActionComponent = (
		<Button
			className="axa-button infoTooltip__action__container"
			disabled={isPostingResultEdit || isSubmittingFootprintChecker}
			type="primary"
			onClick={(e) => {
				e.currentTarget.blur()
				if (type.toUpperCase() === GEOMETRY_CATEGORY.PARCEL)
					handleAddRemoveParcel(
						JSON.parse(JSON.stringify(clickedParcel))
					)
				else {
					handleAddRemoveBuilding(
						JSON.parse(JSON.stringify(clickedParcel))
					)
				}
			}}
		>
			{clickedParcel.properties.selected ? (
				<div className="infotooltip__action__wrapper">
					<MinusOutlined className="infoTooltip__action__icon" />
					REMOVE
				</div>
			) : (
				<div className="infotooltip__action__wrapper">
					<PlusOutlined className="infoTooltip__action__icon" />
					ADD
				</div>
			)}
		</Button>
	)

	/* 
		Display plainly the tooltip
		check position of click and length of tooltip with height of screen
		if bigger set top to position of click minus difference between sum of position of click and length of tooltip minus height of screen
		added 20px offset to set the tooltip up 
		if lower position the tooltip at the click position
	*/
	useEffect(() => {
		if (
			position.y + infoTooltipRef?.current?.offsetHeight >
				window.innerHeight &&
			position.y < infoTooltipRef?.current?.offsetHeight
		)
			setTop(
				position.y -
					(position.y +
						infoTooltipRef?.current?.offsetHeight -
						window.innerHeight) -
					TOOLTIP_BOTTOM_OFFSET_PX
			)
		else if (
			position.y + infoTooltipRef?.current?.offsetHeight >
			window.innerHeight
		)
			setTop(position.y - infoTooltipRef?.current?.offsetHeight)
		else setTop(position.y)
	}, [position, infoTooltipRef, setTop])

	return (
		<div>
			{displayFloatingPanel ? (
				<FloatingInfoPanel
					data={flattenContent}
					geometryCategory={type}
					onClose={() => {
						onFloatingInfoPanelClose()
						setDisplayFloatingPanel(false)
					}}
				/>
			) : (
				<div
					className="infoTooltip__container"
					data-testid="infoTooltip__container--testid"
					ref={infoTooltipRef}
					style={{
						left:
							position.x + infoTooltipRef?.current?.offsetWidth >
							window.innerWidth
								? position.x -
								  infoTooltipRef?.current?.offsetWidth
								: position.x,
						top
					}}
					onClick={(event) => event.stopPropagation()}
				>
					<div className="infoTooltip_header">
						<Title className="infoTooltip__title" level={5}>
							{`${
								type === GEOMETRY_CATEGORY.BUILDING
									? "Building"
									: "Parcel"
							} information`}
						</Title>
						<Button
							className="infoTooltip__closeButton"
							onClick={closeInfoTooltipHandler}
						>
							<svg
								width="18"
								height="18"
								viewBox="0 0 16 18"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M12.845 18.468 8 13.623l-4.845 4.845-1.623-1.623L6.377 12 1.532 7.155l1.623-1.623L8 10.377l4.845-4.845 1.623 1.623L9.623 12l4.845 4.845z"
									fill="#fff"
									fillRule="evenodd"
								/>
							</svg>
						</Button>
					</div>
					<div className="infoTooltip__dataContainer">
						<List
							itemLayout="horizontal"
							dataSource={data.filter(
								(entry) =>
									entry.type ===
									FOOTPRINT_ATTRIBUTES_RANK.PRIMARY
							)}
							renderItem={(item) => (
								<List.Item>
									<List.Item.Meta
										title={item.title}
										description={item.description}
									/>
								</List.Item>
							)}
						/>
					</div>
					{data.length > 0 && (
						<div
							className="infoTooltip__action__link"
							aria-hidden="true"
							onClick={() => {
								setDisplayFloatingPanel(true)
							}}
						>
							More information
						</div>
					)}
					<div className="infoTooltip__action__container">
						{parcelActionComponent}
					</div>
				</div>
			)}
		</div>
	)
}
InfoTooltip.propTypes = {
	type: PropTypes.string.isRequired,
	position: PropTypes.shape({
		x: PropTypes.number.isRequired,
		y: PropTypes.number.isRequired
	}).isRequired,
	clickedParcel: PropTypes.shape({
		properties: PropTypes.shape({
			selected: PropTypes.bool,
			highlight: PropTypes.bool,
			title: PropTypes.string,
			parcelid: PropTypes.string,
			source: PropTypes.string,
			guid: PropTypes.string
		}),
		geometry: PropTypes.shape({
			coordinates: PropTypes.PropTypes.instanceOf(Array).isRequired,
			type: PropTypes.string.isRequired
		})
	}).isRequired,
	handleAddRemoveParcel: PropTypes.func.isRequired,
	handleAddRemoveBuilding: PropTypes.func.isRequired,
	onFloatingInfoPanelClose: PropTypes.func.isRequired,
	closeInfoTooltipHandler: PropTypes.func.isRequired
}

export default InfoTooltip
