import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import KeplerGl from "kepler.gl"
import { addDataToMap } from "kepler.gl/actions"
import { processGeojson, processRowObject } from "kepler.gl/dist/processors"
import { useSelector, useDispatch, ReactReduxContext } from "react-redux"
import { Drawer } from "antd"
import { featureCollection, point } from "@turf/turf"

import { formattedInputDataWithHiddenData } from "helpers/inputDataFormatter"
import { selectedResults as selectedResultsSlice } from "features/jobsResultsSlice"

import { addHocResultHandler } from "features/footprintSlice"
import currencyHandler from "helpers/currencyHandler"
import LocationsVizerConfig from "components/datavizers/LocationsVizerConfig"
import {
	RESOLUTIONS_MAPPING_FOR_DATAVIZ,
	RESOLUTIONS_COLOR_MAPPING_FOR_DATAVIZ
} from "helpers/constants"

import "./LocationsVizer.css"
import { SelectGeneratorWithCounter } from "helpers/SelectGenerator"
import { sortResolutionsOption } from "helpers/rulesHandler"

/**
 *
 * Creates LocationsVizer component
 *
 * @props {function} 		onCloseHandler: Set LocationsVizer close function
 * @props {boolean} 		isVisible: Set LocationsVizer visibility
 *
 * @returns LocationsVizer
 */
const LocationsVizer = (props) => {
	const { onCloseHandler, isVisible } = props
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const { store } = useContext(ReactReduxContext)
	let selectedResults = useSelector(selectedResultsSlice)
	// [LGO-1191] Filtering out locations with TIV = 0
	// [LGO-1198] Filtering out locations with NO_GEOCODE
	selectedResults = selectedResults.filter(
		(location) =>
			parseFloat(
				location?.input_data?.raw_emp_attributes.TOTALVALUE
			).toFixed(0) > 0 && location?.geo_data?.resolution !== "NO_GEOCODE"
	)
	const localConfig = {
		...LocationsVizerConfig
	}

	const resolutionsAvailableWithTIV = sortResolutionsOption(
		Object.keys(
			SelectGeneratorWithCounter(selectedResults, (location) =>
				location.geo_data?.resolution ?? undefined
					? location.geo_data.resolution
					: null
			)
		)
	)

	const colors =
		resolutionsAvailableWithTIV.length > 0
			? resolutionsAvailableWithTIV.map(
					(resolutionKey) =>
						RESOLUTIONS_COLOR_MAPPING_FOR_DATAVIZ[
							RESOLUTIONS_MAPPING_FOR_DATAVIZ[resolutionKey]
						]
			  )
			: []
	localConfig.config.visState.layers[0].config.visConfig.colorRange.colors =
		colors.reverse()

	useEffect(() => {
		if (isVisible) {
			const footprints = []
			const geocodesByTIVAndResolutionsData = []
			selectedResults.forEach((location) => {
				footprints.push(
					addHocResultHandler([], location.geo_data)
						.features.filter(
							(feature) =>
								feature.properties.selected ||
								feature.properties.highlight
						)
						.map((feature) => ({
							...feature,
							properties: {
								...feature.properties,
								fillColor: [0, 0, 143],
								lineWidth: 0.1,
								Review: `${window.location.origin}/map?locationId=${location.id}`
							}
						}))
				)

				if (location.geo_data.lon && location.geo_data.lat) {
					footprints.push([
						{
							...point([
								parseFloat(location.geo_data.lon),
								parseFloat(location.geo_data.lat)
							]),
							properties: {
								fillColor: [240, 118, 98],
								radius: 0.1,
								lineWidth: 0.5
							}
						}
					])
				}
				const tooltipData = formattedInputDataWithHiddenData(
					location.input_data
				)
				localConfig.config.visState.interactionConfig.tooltip.fieldsToShow.fd4ee5m =
					[
						{
							name: "Resolution",
							format: null
						},
						...Object.keys(tooltipData).map((field) => ({
							name: t(field),
							format: null
						})),
						{
							name: "Review",
							format: null
						}
					]
				geocodesByTIVAndResolutionsData.push({
					TOTALVALUE_STD:
						(parseFloat(
							location.input_data.raw_emp_attributes.TOTALVALUE
						).toFixed(0) *
							9999999999.99) /
						10 ** 15, // this is to overcome Kepler.GL casting every float value above 99999999.99 to TIME type
					TOTALVALUE:
						currencyHandler(
							location?.input_data.raw_emp_attributes?.TOTALVALUE,
							location?.input_data.raw_emp_attributes
								?.SITECURRENCY
						) === "NaN"
							? "N/A"
							: currencyHandler(
									location?.input_data.raw_emp_attributes
										?.TOTALVALUE,
									location?.input_data.raw_emp_attributes
										?.SITECURRENCY
							  ),
					Resolution:
						RESOLUTIONS_MAPPING_FOR_DATAVIZ[
							location.geo_data.resolution
						],
					footprint_computed_longitude: location.geo_data.lon,
					footprint_computed_latitude: location.geo_data.lat,
					...tooltipData,
					Review: `${window.location.origin}/map?locationId=${location.id}`
				})
			})
			dispatch(
				addDataToMap({
					datasets: [
						{
							info: {
								label: "Geocoding quality",
								id: "fd4ee5m"
							},
							data:
								geocodesByTIVAndResolutionsData.length > 0
									? processRowObject(
											geocodesByTIVAndResolutionsData
									  )
									: undefined
						},
						{
							info: {
								label: "footprint layer",
								id: "fd4ee5m222"
							},
							data: processGeojson(
								featureCollection(
									footprints
										.filter((f) => f.length > 0)
										.flat()
								)
							)
						}
					],

					options: {
						centerMap: true,
						readOnly: false
					},
					info: {
						title: "Taro and Blue",
						description: "This is my map"
					},
					config: localConfig
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible])

	return (
		<Drawer
			placement="right"
			destroyOnClose
			data-testid="locationVizer__wrapper--testid"
			getContainer={false}
			onClose={onCloseHandler}
			open={isVisible}
			width="90%"
			zIndex={998} // Not overlapping Kepler tooltip which has a zIndex of 999
			title={null}
		>
			<div data-testid="locationVizer__wrapper--testid">
				<KeplerGl
					store={store}
					id="LocationsVizer"
					mapboxApiAccessToken={
						process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
					}
				/>
			</div>
		</Drawer>
	)
}

LocationsVizer.propTypes = {
	onCloseHandler: PropTypes.func.isRequired,
	isVisible: PropTypes.bool
}

LocationsVizer.defaultProps = {
	isVisible: false
}

export default LocationsVizer
