/* eslint-disable no-underscore-dangle */
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import { Modal, Button } from "antd"

import "./WarningModal.css"
/**
 *
 * Creates an alert
 *
 * @props {ReactNode} 		children: Set the component of the warning modal
 * @props {string} 			title: Human readable description text
 * @props {boolean} 		isWarningVisible: Set modal visibility
 * @props {function} 		handleCancel: Set modal cancel button functions
 * @props {function} 		handleConfirm: Set modal confirm button functions
 * @props {ReactNode} 		actions: Set the component of the warning modal footer actions
 * @props {boolean} 		closable: Set modal closability
 * @props {boolean} 		maskClosable: Set modal mask visibility
 * @props {string} 			size: Set modal size
 *
 * @returns Alert
 */

const WarningModal = (props) => {
	const {
		children,
		title,
		isWarningVisible,
		handleCancel,
		handleConfirm,
		actions,
		closable,
		maskClosable,
		size
	} = props
	const { t } = useTranslation()
	const _actions = actions || [
		<Button
			className="axa-button--ghost"
			type="primary"
			key="cancelButton"
			onClick={handleCancel}
		>
			Cancel
		</Button>,
		<Button
			className="axa-button"
			type="primary"
			key="confirmButton"
			onClick={handleConfirm}
		>
			Confirm
		</Button>
	]

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{children ? (
				<Modal
					className={`warning_container ${
						size.length > 0 ? `warning_container--${size}` : ""
					}`}
					closeIcon={
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							xmlns="http://www.w3.org/2000/svg"
							data-testid="warning_container_close--testid"
						>
							<path
								d="M12.845 14.468 8 9.623l-4.845 4.845-1.623-1.623L6.377 8 1.532 3.155l1.623-1.623L8 6.377l4.845-4.845 1.623 1.623L9.623 8l4.845 4.845z"
								fill="#00008F"
								fillRule="evenodd"
							/>
						</svg>
					}
					title={t(title)}
					open={isWarningVisible}
					onCancel={handleCancel}
					centered
					closable={closable}
					maskClosable={maskClosable}
					footer={_actions}
					destroyOnClose
				>
					{children}
				</Modal>
			) : null}
		</>
	)
}

WarningModal.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
	isWarningVisible: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func,
	handleConfirm: PropTypes.func,
	actions: PropTypes.PropTypes.instanceOf(Array),
	closable: PropTypes.bool,
	maskClosable: PropTypes.bool,
	size: PropTypes.string
}

WarningModal.defaultProps = {
	title: "",
	actions: undefined,
	handleCancel: undefined,
	handleConfirm: undefined,
	closable: true,
	maskClosable: true,
	size: ""
}

export default WarningModal
