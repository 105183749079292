/**
 *
 * Get JWT Token from state
 *
 * @param {Object} state
 *
 * @returns {string} JWT Token
 */
export const getAccessToken = (state) => state?.auth?.access_token ?? ""

/**
 *
 * Generate the common header to reach Locus API with authN and authZ
 *
 * @param {Object} state
 *
 * @returns {string} JWT Token
 */

export const generateHeadersWithAuthorization = (state) => ({
	"content-type": "application/json",
	"Ocp-Apim-Subscription-Key": process.env.REACT_APP_LOCUS_API_KEY,
	Authorization: `Bearer ${getAccessToken(state)}`
})

export default { getAccessToken, generateHeadersWithAuthorization }
