import React from "react"
import { useDispatch } from "react-redux"
import { push } from "redux-first-history"
import { Result, Button } from "antd"
import { useTranslation } from "react-i18next"

/**
 *
 * Creates an Not Found Block
 * As per https://ant.design/components/result/#components-result-demo-404
 *
 *
 * @returns NotFoundBlock
 */

const NotFoundBlock = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	return (
		<div style={{ width: "100%" }}>
			<Result
				status="404"
				title="404"
				subTitle={t(
					"Sorry, the page you have requested does not exist."
				)}
				extra={
					<Button
						className="axa-button"
						onClick={() => {
							dispatch(push("/"))
						}}
						size="large"
					>
						{t("Go Back Jobs list")}
					</Button>
				}
			/>
		</div>
	)
}

export default NotFoundBlock
