import PropTypes from "prop-types"
import React, { useState } from "react"

import "../styles/MapLayersSwitcher.css"

/**
 *
 * Creates an MapLayersSwitcher
 *
 * @param {function} setMapLayer        Human readable description text
 * *
 * @returns MapLayersSwitcher
 */

const MapLayersSwitcher = (props) => {
	const { setMapLayer } = props
	const LAYERS_TYPE = ["satellite", "streets-v11"]
	const [currentLayer, setCurrentLayer] = useState(0)
	return (
		<div
			aria-hidden="true"
			data-testid="map-layerl-switch-testid"
			className="map__layerSelector"
			onClick={() => {
				setMapLayer(LAYERS_TYPE[Number(!currentLayer)])
				setCurrentLayer(!currentLayer)
			}}
		>
			{LAYERS_TYPE[Number(currentLayer)] !== LAYERS_TYPE[0] ? (
				<div className="map__layerSelector__layerLabel">
					<div
						className="basemap__switcher__illustration basemap__switcher__illustration--satellite"
						alt=""
					>
						<div className="map__layerSelector__layerLabel__description">
							<div className="map__layerSelector__icon--white" />
							<span className="map__layerSelector__layerLabelText--satellite">
								Satellite
							</span>
						</div>
					</div>
				</div>
			) : (
				<div className="map__layerSelector__layerLabel">
					<div
						className="basemap__switcher__illustration basemap__switcher__illustration--road"
						alt=""
					>
						<div className="map__layerSelector__layerLabel__description">
							<div className="map__layerSelector__icon" />
							<span className="map__layerSelector__layerLabelText">
								Road
							</span>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

MapLayersSwitcher.propTypes = {
	setMapLayer: PropTypes.func.isRequired
}

export default MapLayersSwitcher
