/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Typography, Input, Button, Table, Tooltip } from "antd"
import { SearchOutlined } from "@ant-design/icons"

import {
	// ALERTS_TYPE,
	RESOLUTIONS_MAPPING_FOR_DATAVIZ,
	SOURCES_QUALITY_RANGE,
	HAS_FOOTPRINTS_MATCHING,
	ALERTS_TYPE
} from "helpers/constants"

import { useNavigate, useLocation, Link } from "react-router-dom"
import currencyHandler from "helpers/currencyHandler"

import { displayToast } from "components/alerts/Alerts"

import {
	jobResults,
	isFetching as resultsIsFetching,
	selectedResults as selectedResultsSlice,
	selectedJobId as selectedJobIdSlice,
	setSelectedResults,
	fetchResultsByJobId,
	setSelectedJobId,
	filteredLocationsList,
	filterLocationsByManualInput,
	fetchJobById,
	selectedJobName as selectedJobNameSlice,
	setSelectedRowKeys,
	selectedRowKeys as selectedRowKeysSlice,
	currentLocationIndex as currentLocationIndexSlice,
	filteredBy as filteredBySlice,
	computeStartReviewLocationIndex,
	fetchResultsByLocationIds,
	resetSelectedLocations
} from "features/jobsResultsSlice"
import { setIsGeocoding, clearFootprintData } from "features/footprintSlice"
import { resetMarker } from "features/mapSlice"
import "styles/pages.css"
import "./LocationsPage.css"
import ConfidenceBars from "components/confidenceBars/ConfidenceBars"
import LocationsVizer from "components/datavizers/LocationsVizer"

import FilterWrapper from "components/filterAction/FilterWrapper"
import LoaderComponent from "components/Loader/LoaderComponent"

import LocationInformations from "pages/locations/components/LocationInformations"
import generateValidationStatusLabels from "pages/locations/components/validateStatus"

import { addressFragmented } from "helpers/inputDataFormatter"

import itemRender from "styles/tablePagination"
import LocationGridSkeleton from "./LocationPage.loading"

// import InformationAlerts from "components/informationAlerts/InformationAlerts"

const { Title, Text } = Typography
const { Search } = Input
const { Column } = Table

export const titleFragmentLeft = (title) => (
	<div className="table__title--toLeft" data-testid="column-header-id-testid">
		{title}
	</div>
)

const LocationsHandlerPage = () => {
	const dispatch = useDispatch()
	const selectedJobId = useSelector(selectedJobIdSlice)
	const navigate = useNavigate()
	const loc = useLocation()
	const { t } = useTranslation()

	const [tableIsAtTop, setTableIsAtTop] = useState(false)
	const [filteredbyResolution, setFilteredbyResolution] = useState(false)
	const [displayAllLocationsOnMapDrawer, setDisplayAllLocationsOnMapDrawer] =
		useState(false)

	const tableContainerRef = useRef(null)

	// Check and set whethever table is reaching top
	const handleScroll = () => {
		if (tableContainerRef.current) {
			setTableIsAtTop(
				tableContainerRef.current.getBoundingClientRect().top < 0
			)
		}
	}

	useEffect(() => {
		window.addEventListener("scroll", handleScroll)
	})

	// const [searchingString, setSearchingString] = useState("")

	const isResultsFetching = useSelector(resultsIsFetching)
	const jobResultsSelector = useSelector(jobResults)
	const filteredLocationsListSelector = useSelector(filteredLocationsList)
	const selectedResults = useSelector(selectedResultsSlice)
	const selectedJobName = useSelector(selectedJobNameSlice)
	const selectedRowKeys = useSelector(selectedRowKeysSlice)
	const currentLocationIndex = useSelector(currentLocationIndexSlice)
	const filteredBy = useSelector(filteredBySlice)
	const [jobName, setJobName] = useState("CLIENT_NAME")

	const locationsWithBadResolution = filteredLocationsListSelector.filter(
		(location) =>
			location.geo_data.resolution === "NO_GEOCODE" ||
			location.geo_data.resolution === "NOT_AVAILABLE"
	)

	// Loading Job's result on component direct load
	useEffect(() => {
		// If not selectedJobId means direct load
		if (!selectedJobId || selectedJobName.length === 0) {
			const params = new URLSearchParams(loc?.search?.substring(1))
			const jobId = params?.get("jobId")
			dispatch(setSelectedJobId(jobId))
			dispatch(fetchJobById(jobId))
			dispatch(fetchResultsByJobId(jobId))
		}
		// Set JobName Locally
		setJobName(selectedJobName)
		dispatch(computeStartReviewLocationIndex())

		if (locationsWithBadResolution.length > 0) {
			dispatch(
				setSelectedRowKeys(
					locationsWithBadResolution.map((location) => location.id)
				)
			)
			dispatch(setSelectedResults(locationsWithBadResolution))
			displayToast(
				dispatch,
				"GEOCODING_FAILURE",
				ALERTS_TYPE.ERROR,
				20000,
				`Processing failed for ${locationsWithBadResolution.length} of your locations`,
				false
			)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedJobName, filteredLocationsListSelector])

	const rowSelection = {
		selectedRowKeys,
		onChange: (selectedRKs, selectedRows) => {
			dispatch(setSelectedRowKeys(selectedRKs))
			dispatch(setSelectedResults(selectedRows))
			dispatch(computeStartReviewLocationIndex())
		},
		getCheckboxProps: (record) => ({
			...record,
			"data-testid": "locations-checkbox-data-testid"
		})
	}

	const onRowClicked = (record) => {
		const _selectedRowKeys = [...selectedRowKeys]
		const _selectedRows = [...selectedResults]

		if (_selectedRowKeys.indexOf(record.id) >= 0) {
			_selectedRowKeys.splice(_selectedRowKeys.indexOf(record.id), 1)
			_selectedRows.splice(
				_selectedRows
					.map((location) => JSON.stringify(location))
					.indexOf(JSON.stringify(record)),
				1
			) // remove js object deep link
		} else {
			_selectedRowKeys.push(record.id)
			_selectedRows.push(record)
		}
		dispatch(setSelectedRowKeys(_selectedRowKeys))
		dispatch(setSelectedResults(_selectedRows))
		dispatch(computeStartReviewLocationIndex())
	}
	const prefix = (
		<SearchOutlined
			style={{
				fontSize: 16,
				color: "#00008F"
			}}
		/>
	)

	return (
		<div
			className={`page__container round-lg location ${
				displayAllLocationsOnMapDrawer ? "stop-scrolling" : ""
			}`}
			data-testid="locationPageContainer-testid"
		>
			{isResultsFetching && jobResultsSelector.length === 0 && (
				<LoaderComponent />
			)}
			{/* isResultsFetching && (
				<InformationAlerts
					icon={ALERTS_TYPE.INFO}
					text={t("Please wait,loading may take up to 2:00 min")}
					color="#4976ba"
				/>
			) */}
			<div className="pageBreadcrumb">
				<Link to="/" className="pageBreadcrumb__link">
					List of jobs
				</Link>{" "}
				/ <span className="file_name">{jobName}</span>
			</div>
			<LocationInformations
				setDisplayAllLocationsOnMapDrawer={
					setDisplayAllLocationsOnMapDrawer
				}
				jobName={jobName}
				selectedJobName={selectedJobName}
				isLoading={isResultsFetching}
			/>
			<div className="page__actionsContainer-column">
				<FilterWrapper
					filteredbyResolution={filteredbyResolution}
					setFilteredbyResolution={setFilteredbyResolution}
				/>
			</div>
			<div
				className={`page__separatorContainer ${
					tableIsAtTop ? "page__separatorContainer--fullscreen" : ""
				}`}
			>
				<div className="separator__titleContainer">
					<Title className="page__subtitle" level={5}>
						{`${selectedResults?.length}/${jobResultsSelector?.length} selected locations`}
					</Title>
					<div className="page__actionContainer action__searchContainer searchContainer--locations">
						<div className="filterAction__container">
							<Search
								size="large"
								allowClear
								className="searchContainer__input--withLabel"
								placeholder="Search a location by its name or reference…"
								onSearch={(v) => {
									dispatch(filterLocationsByManualInput(v))
								}}
								onChange={(e) => {
									dispatch(
										filterLocationsByManualInput(
											e.target.value
										)
									)
								}}
								onBlur={(e) => {
									dispatch(
										filterLocationsByManualInput(
											e.target.value
										)
									)
								}}
								enterButton={
									/* istanbul ignore next */
									() => null
								}
								addonAfter={undefined}
								prefix={prefix}
								defaultValue={filteredBy?.manual || ""}
							/>
						</div>
					</div>
				</div>
				<Button
					className="axa-button axa-btn-sm"
					type="primary"
					size="large"
					onClick={() => {
						dispatch(resetMarker())
						dispatch(clearFootprintData())
						dispatch(setIsGeocoding(false))
						dispatch(fetchResultsByLocationIds()).then((data) => {
							if (
								data.type ===
								fetchResultsByLocationIds.fulfilled.type
							) {
								dispatch(resetMarker())
								dispatch(clearFootprintData())
								dispatch(setIsGeocoding(false))
								navigate({
									pathname: "/map",
									search: `locationId=${selectedResults[currentLocationIndex]?.id}`
								})
							}
						})
					}}
					disabled={selectedResults?.length === 0}
				>
					Review selected locations
				</Button>
			</div>
			{jobResultsSelector ? (
				<LocationsVizer
					onCloseHandler={() => {
						setDisplayAllLocationsOnMapDrawer(false)
						dispatch(resetSelectedLocations())
					}}
					isVisible={displayAllLocationsOnMapDrawer}
				/>
			) : null}

			<div
				className="page__dataTableContainer"
				ref={tableContainerRef}
				data-testid="page__dataTableContainer-testid"
			>
				{isResultsFetching ? (
					<LocationGridSkeleton />
				) : (
					<Table
						dataSource={filteredLocationsListSelector}
						rowKey="id"
						rowSelection={rowSelection}
						rowClassName={(record, index) =>
							index % 2 === 0
								? "locationRow pages__table__row--even"
								: "locationRow"
						}
						preserveSelectedRowKeys
						pagination={{
							itemRender,
							showSizeChanger: false,
							hideOnSinglePage: true,
							position: "bottomRight",
							defaultPageSize: 50
						}}
						onRow={(record) => ({
							onClick: () => {
								onRowClicked(record)
							}
						})}
					>
						<Column
							title={
								<Tooltip
									title={t(
										"The ID number attributed to the location in the source file"
									)}
								>
									{titleFragmentLeft("ID")}
								</Tooltip>
							}
							showSorterTooltip={false}
							dataIndex={[
								"input_data",
								"raw_emp_attributes",
								"LOCNUM"
							]}
							align="left"
							width="8%"
							sorter={(a, b) => {
								if (!a?.input_data?.id) return 1
								if (!b?.input_data?.id) return -1
								return (
									Number(b.input_data.id) -
									Number(a.input_data.id)
								)
							}}
							render={(id) => (
								<span data-testid="id-testid">{id}</span>
							)}
						/>
						<Column
							title={
								<Tooltip
									title={t(
										"The name provided by the client in the source file"
									)}
								>
									LOCATION NAME
								</Tooltip>
							}
							dataIndex={["input_data", "validation_status"]}
							width="20%"
							render={(inputData, record) => (
								<div
									className="locationName__container"
									data-testid="location-name-testid"
								>
									<span className="locationName__label">
										{record?.input_data?.customer ?? "N/A"}
									</span>
									{generateValidationStatusLabels(
										record.validation_status
									)}
								</div>
							)}
						/>
						<Column
							title={
								<Tooltip
									title={t(
										"The address provided by the client in the source file"
									)}
								>
									ADDRESS
								</Tooltip>
							}
							dataIndex="input_data"
							width="30%"
							render={(inputData) => (
								<div data-testid="address-testid">
									{!inputData?.address
										? "N/A"
										: addressFragmented(inputData.address)}
								</div>
							)}
						/>
						<Column
							title={
								<Tooltip
									title={t(
										"The country code provided by the geocoder"
									)}
								>
									COUNTRY
								</Tooltip>
							}
							showSorterTooltip={false}
							dataIndex="geo_data"
							ellipsis
							width="10%"
							sorter={(a, b) =>
								a.geo_data?.address?.country.localeCompare(
									b.geo_data?.address?.country
								)
							}
							render={(geoData) => (
								<div data-testid="country-testid">
									{geoData?.address?.country ?? "N/A"}
								</div>
							)}
						/>
						<Column
							title={
								<Tooltip
									title={t(
										"The resolution of the location provided by the geocoder"
									)}
								>
									RESOLUTION
								</Tooltip>
							}
							showSorterTooltip={false}
							dataIndex={["geo_data", "resolution"]}
							ellipsis
							width="15%"
							defaultSortOrder={
								locationsWithBadResolution.length > 0
									? "descend"
									: null
							}
							sorter={(a, b) => {
								if (!a?.geo_data?.resolution) return 1
								if (!b?.geo_data?.resolution) return -1
								return (
									RESOLUTIONS_MAPPING_FOR_DATAVIZ[
										b.geo_data.resolution
									].substr(0, 2) -
									RESOLUTIONS_MAPPING_FOR_DATAVIZ[
										a.geo_data.resolution
									].substr(0, 2)
								)
							}}
							render={(resolution) => (
								<span data-testid="resolution-testid">
									{t(resolution)}
								</span>
							)}
						/>
						<Column
							title={
								<Tooltip
									title={t(
										"Our own confidence about the resolution based on the geocoder data"
									)}
								>
									CONFIDENCE
								</Tooltip>
							}
							showSorterTooltip={false}
							dataIndex={["geo_data", "confidence"]}
							width="12%"
							sorter={(a, b) =>
								b.geo_data.confidence - a.geo_data.confidence
							}
							render={(confidence) => (
								<div data-testid="confidence-testid">
									<ConfidenceBars
										confidence={confidence}
										label={false}
										toWhite={false}
									/>
								</div>
							)}
						/>
						<Column
							title={
								<Tooltip title={t("The source of the geocode")}>
									SOURCE
								</Tooltip>
							}
							showSorterTooltip={false}
							dataIndex={["geo_data", "source"]}
							width="12%"
							sorter={(a, b) => {
								if (!a?.geo_data?.source) return 1
								if (!b?.geo_data?.source) return -1
								return (
									SOURCES_QUALITY_RANGE[
										a?.geo_data?.source
									][0] -
									SOURCES_QUALITY_RANGE[
										b?.geo_data?.source
									][0]
								)
							}}
							render={(source) => (
								<div data-testid="source-testid">
									{t(source) || "N/A"}
								</div>
							)}
						/>
						<Column
							title={
								<Tooltip
									title={
										<Text style={{ color: "white" }}>
											It indicates if the location has a
											footprint.{" "}
										</Text>
									}
								>
									FOOTPRINT
								</Tooltip>
							}
							showSorterTooltip={false}
							dataIndex={["geo_data"]}
							ellipsis
							align="left"
							width="10%"
							sorter={(a, b) => {
								if (!a?.geo_data?.has_footprints) return 1
								if (!b?.geo_data?.has_footprints) return -1
								return (
									HAS_FOOTPRINTS_MATCHING[
										a?.geo_data?.has_footprints
									][0] -
									HAS_FOOTPRINTS_MATCHING[
										b?.geo_data?.has_footprints
									][0]
								)
							}}
							render={(geoData) => (
								<div data-testid="footprint-testid">
									{t(geoData.has_footprints)}
								</div>
							)}
						/>
						<Column
							title={
								<Tooltip title={t("Total insured value")}>
									<div data-testid="tiv-title-testid">
										TIV
									</div>
								</Tooltip>
							}
							showSorterTooltip={false}
							align="left"
							dataIndex="input_data"
							width="15%"
							render={(inputData) => (
								<span data-testid="tiv-testid">
									{currencyHandler(
										inputData?.raw_emp_attributes
											?.TOTALVALUE,
										inputData?.raw_emp_attributes
											?.SITECURRENCY
									) === "NaN"
										? "N/A"
										: currencyHandler(
												inputData?.raw_emp_attributes
													?.TOTALVALUE,
												inputData?.raw_emp_attributes
													?.SITECURRENCY
										  )}
								</span>
							)}
							sorter={(a, b) => {
								if (!a?.input_data?.insured_value) return 1
								if (!b?.input_data?.insured_value) return -1
								return (
									Number(
										b.input_data.insured_value.replace(
											/[^0-9.-]+/g,
											""
										)
									) -
									Number(
										a.input_data.insured_value.replace(
											/[^0-9.-]+/g,
											""
										)
									)
								)
							}}
						/>
					</Table>
				)}
			</div>
		</div>
	)
}

export default LocationsHandlerPage
