/* eslint-disable no-underscore-dangle */
import PropTypes from "prop-types"
import React from "react"
import { Modal } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import parse from "html-react-parser"
import {
	setShowLoadingModalForFootprintModeSwitch,
	showLoadingModalForFootprintModeSwitch as showLoadingModalForFootprintModeSwitchSlice,
	isPostingResultEdit as isPostingResultEditSlice,
	FOOTPRINT_MODE
} from "features/footprintSlice"
import {
	isSavingReviewState as isSavingReviewStateSlice,
	isFetchingLocationByID as isFetchingLocationByIDSlice
} from "features/jobsResultsSlice"

import "./LoadingModal.css"
/**
 *
 * Creates an Loading Modal
 *
 * @props {string} 		mode: Following FOOTPRINT_MODE values
 *
 * @returns LoadingModal
 */

const LoadingModal = (props) => {
	const { mode } = props
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const showLoadingModalForFootprintModeSwitch = useSelector(
		showLoadingModalForFootprintModeSwitchSlice
	)
	const isPostingResultEdit = useSelector(isPostingResultEditSlice)
	const isSavingReviewState = useSelector(isSavingReviewStateSlice)
	const isFetchingLocationByID = useSelector(isFetchingLocationByIDSlice)

	let loadingTitle = t(`${mode}.title`)
	let loadingStyle = ""
	if (isPostingResultEdit) {
		loadingTitle = "Saving location details"
	} else if (isSavingReviewState) {
		loadingTitle = "Closing"
	} else if (isFetchingLocationByID) {
		loadingTitle = "Loading the location"
	}
	if (mode === "osm_site" || mode === "osm_building") {
		loadingStyle = "_main"
	}

	return (
		<Modal
			className="loading_container"
			title={<div className="loading_content__title">{loadingTitle}</div>}
			open={
				showLoadingModalForFootprintModeSwitch ||
				isPostingResultEdit ||
				isSavingReviewState ||
				isFetchingLocationByID
			}
			onCancel={
				/* istanbul ignore next */ () =>
					dispatch(setShowLoadingModalForFootprintModeSwitch(false))
			}
			centered
			closable={false}
			maskClosable={false}
			footer={null}
			destroyOnClose
		>
			<div
				className={`loading_content__container${
					isPostingResultEdit ||
					isSavingReviewState ||
					isFetchingLocationByID
						? "--small"
						: ""
				}`}
			>
				{!isPostingResultEdit &&
				!isSavingReviewState &&
				!isFetchingLocationByID ? (
					<div
						className={`loading_content_description__container${loadingStyle}`}
					>
						{parse(t(`${mode}.description`))}
					</div>
				) : null}
				<div className="loading_animation__container">
					<div className="waveBar" />
					<div className="waveBar" />
					<div className="waveBar" />
					<div className="waveBar" />
					<div className="waveBar" />
					<div className="waveBar" />
					<div className="waveBar" />
					<div className="waveBar" />
					<div className="waveBar" />
					<div className="waveBar" />
					<div className="waveBar" />
					<div className="waveBar" />
					<div className="waveBar" />
					<div className="waveBar" />
				</div>
			</div>
		</Modal>
	)
}

LoadingModal.propTypes = {
	mode: PropTypes.string
}

LoadingModal.defaultProps = {
	mode: FOOTPRINT_MODE.GEOCODE
}

export default LoadingModal
