/* istanbul ignore file */

// Configuration output from Kepler.GL export
// ⚠️ for dataId
export default {
	version: "v1",
	config: {
		visState: {
			filters: [],
			layers: [
				{
					id: "bkai8i",
					type: "point",
					config: {
						dataId: "fd4ee5m",
						label: "Geocoding quality",
						color: [18, 147, 154],
						highlightColor: [252, 242, 26, 255],
						columns: {
							lat: "footprint_computed_latitude",
							lng: "footprint_computed_longitude",
							altitude: null
						},
						isVisible: true,
						visConfig: {
							radius: 10,
							fixedRadius: false,
							opacity: 1,
							outline: false,
							thickness: 2,
							strokeColor: null,
							colorRange: {
								name: "ColorBrewer RdYlGn-8",
								type: "diverging",
								category: "ColorBrewer",
								colors: [
									"#d73027",
									"#fc8d59",
									"#fee08b",
									"#ffffbf",
									"#d9ef8b",
									"#91cf60",
									"#1a9850",
									"#1a9850"
								],
								reversed: false
							},
							strokeColorRange: {
								name: "Global Warming",
								type: "sequential",
								category: "Uber",
								colors: [
									"#5A1846",
									"#900C3F",
									"#C70039",
									"#E3611C",
									"#F1920E",
									"#FFC300"
								]
							},
							radiusRange: [0, 200],
							filled: true
						},
						hidden: false,
						textLabel: [
							{
								field: null,
								color: [255, 255, 255],
								size: 18,
								offset: [0, 0],
								anchor: "start",
								alignment: "center"
							}
						]
					},
					visualChannels: {
						colorField: { name: "Resolution", type: "integer" },
						colorScale: "quantile",
						strokeColorField: null,
						strokeColorScale: "quantile",
						sizeField: { name: "TOTALVALUE_STD", type: "real" },
						sizeScale: "sqrt"
					}
				},
				{
					id: "g1peuig",
					type: "heatmap",
					config: {
						dataId: "fd4ee5m",
						label: "Insured value heatmap",
						color: [136, 87, 44],
						highlightColor: [252, 242, 26, 255],
						columns: {
							lat: "footprint_computed_latitude",
							lng: "footprint_computed_longitude"
						},
						isVisible: true,
						visConfig: {
							opacity: 0.8,
							colorRange: {
								name: "Global Warming",
								type: "sequential",
								category: "Uber",
								colors: [
									"#5A1846",
									"#900C3F",
									"#C70039",
									"#E3611C",
									"#F1920E",
									"#FFC300"
								]
							},
							radius: 100
						},
						hidden: false,
						textLabel: [
							{
								field: null,
								color: [255, 255, 255],
								size: 18,
								offset: [0, 0],
								anchor: "start",
								alignment: "center"
							}
						]
					},
					visualChannels: {
						weightField: { name: "TOTALVALUE_STD", type: "real" },
						weightScale: "linear"
					}
				},
				{
					id: "vir4bt4",
					type: "geojson",
					config: {
						dataId: "fd4ee5m222",
						label: "Footprints",
						color: [82, 151, 218],
						highlightColor: [252, 242, 26, 255],
						columns: { geojson: "_geojson" },
						isVisible: true,
						visConfig: {
							opacity: 1,
							strokeOpacity: 0.8,
							thickness: 2,
							strokeColor: [252, 211, 133],
							colorRange: {
								name: "Custom Palette",
								type: "custom",
								category: "Custom",
								colors: ["#FFFFFF", "#00008F"]
							},
							strokeColorRange: {
								name: "Global Warming",
								type: "sequential",
								category: "Uber",
								colors: [
									"#5A1846",
									"#900C3F",
									"#C70039",
									"#E3611C",
									"#F1920E",
									"#FFC300"
								]
							},
							radius: 10,
							sizeRange: [0, 10],
							radiusRange: [0, 50],
							heightRange: [0, 500],
							elevationScale: 5,
							enableElevationZoomFactor: true,
							stroked: true,
							filled: true,
							enable3d: false,
							wireframe: false
						},
						hidden: false,
						textLabel: [
							{
								field: null,
								color: [255, 255, 255],
								size: 18,
								offset: [0, 0],
								anchor: "start",
								alignment: "center"
							}
						]
					},
					visualChannels: {
						colorField: null,
						colorScale: "quantile",
						strokeColorField: null,
						strokeColorScale: "quantile",
						sizeField: null,
						sizeScale: "linear",
						heightField: null,
						heightScale: "linear",
						radiusField: null,
						radiusScale: "linear"
					}
				}
			],
			interactionConfig: {
				tooltip: {
					fieldsToShow: {
						fd4ee5m: [
							{ name: "resolution", format: null },
							{ name: "TOTALVALUE", format: null }
						],
						fd4ee5m222: [{ name: "Review", format: null }]
					},
					compareMode: false,
					compareType: "absolute",
					enabled: true
				},
				brush: {
					size: 0.5,
					enabled: false
				},
				geocoder: {
					enabled: false
				},
				coordinate: {
					enabled: false
				}
			},
			layerBlending: "normal",
			splitMaps: [],
			animationConfig: {
				currentTime: null,
				speed: 1
			}
		},
		mapState: {
			bearing: 0,
			dragRotate: false,
			latitude: 33.43656982445611,
			longitude: -86.7311065195223,
			pitch: 0,
			zoom: 14.771558404695716,
			isSplit: false
		},
		mapStyle: {
			styleType: "muted",
			topLayerGroups: {},
			visibleLayerGroups: {
				label: true,
				road: true,
				border: false,
				building: true,
				water: true,
				land: true,
				"3d building": false
			},
			threeDBuildingColor: [
				9.665468314072013, 17.18305478057247, 31.1442867897876
			],
			mapStyles: {}
		}
	}
}
