import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import { Marker } from "react-map-gl"

import InfoTooltipPin from "pages/map/components/InfoTooltipPin"
import Pin from "pages/map/components/pin"
import {
	unselectedMarkers as unselectedMarkersSlice,
	selectedMarker as selectedMarkerSlice,
	setIsClickingUnselected
} from "features/mapSlice"

import { selectUnselectedPin } from "pages/map/utils/unselectedPointsOfInterest"
import {
	onClickMarker,
	onClickMarkerWithoutPoi
} from "pages/map/utils/handleMarker"
import { uuid4 } from "uuid4"

const UnselectedPin = () => {
	const dispatch = useDispatch()
	const unselectedMarkers = useSelector(unselectedMarkersSlice)
	const selectedMarker = useSelector(selectedMarkerSlice)
	const [hoverTooltip, setHoverTooltip] = useState()

	return (
		<div>
			{hoverTooltip ? (
				<InfoTooltipPin
					type={hoverTooltip.type}
					position={{
						x: hoverTooltip.x,
						y: hoverTooltip.y
					}}
					clickedPin={JSON.parse(
						JSON.stringify(hoverTooltip.clickedPin)
					)}
					handleChangePin={() => {
						selectUnselectedPin(
							hoverTooltip.clickedPin,
							dispatch,
							selectedMarker,
							unselectedMarkers
						)
						setHoverTooltip(null)
					}}
					closeInfoTooltipHandler={() => setHoverTooltip(null)}
				/>
			) : null}
			{unselectedMarkers.length > 0 &&
				[...unselectedMarkers].reverse().map((data, i) => {
					const source =
						data.source.toLowerCase() === "foursquareplace"
							? "Foursquare"
							: data.source
					const idx = data?.poi_metadata?.custom_sort_idx
						? data?.poi_metadata?.custom_sort_idx
						: data?.custom_sort_idx ?? undefined

					const onClick = data.poi
						? onClickMarker
						: onClickMarkerWithoutPoi
					return (
						<Marker
							key={uuid4()}
							anchor="bottom"
							offset={[0, 0]}
							data-testid={`pin-testid-${i}`}
							longitude={data.lon ?? 0.0}
							latitude={data.lat ?? 0.0}
							onClick={(event) => {
								dispatch(setIsClickingUnselected(true))
								onClick(event, data, setHoverTooltip)
							}}
						>
							<Pin
								size={34}
								color="#f3f3f3"
								index={i.toString()}
								label={
									idx
										? `${idx} - ${source}`.toUpperCase()
										: source.toUpperCase()
								}
							/>
						</Marker>
					)
				})}
		</div>
	)
}
UnselectedPin.propTypes = {}

export default React.memo(UnselectedPin)
