import React from "react"

const IconArrowDownSmall = () => (
	<svg
		width="8"
		height="6"
		viewBox="0 0 8 6"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0.94 0.529297L4 3.58263L7.06 0.529297L8 1.4693L4 5.4693L0 1.4693L0.94 0.529297Z"
			fill="#343C3D"
		/>
	</svg>
)

export default IconArrowDownSmall
