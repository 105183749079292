import { getCountryNameByLocale } from "./countriesHandler"
/**
 *
 * Follow https://jira.axa.com/jira/browse/LGO-666
 *
 */
export default (contents) => {
	const line1 = contents.street_name ? contents.street_name : ""
	let line2
	let line3
	line2 = contents.city ? contents.city : ""
	line2 = `${
		line2
			? `${line2}${contents.state ? `, ${contents.state}` : ""}`
			: `${contents.state ? `${contents.state}` : ""}`
	}`
	line3 = contents.postal_code ? contents.postal_code : ""
	line3 = `${
		line3
			? `${line3}${
					contents.country_code
						? `, ${getCountryNameByLocale(
								"en",
								contents?.country_code
						  )}`
						: ""
			  }`
			: `${
					contents.country_code
						? `${getCountryNameByLocale(
								"en",
								contents?.country_code
						  )}`
						: ""
			  }`
	}`
	return `${line1}
    ${line2}
    ${line3}
    `
}
