import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import { FilterFilled, FilterOutlined } from "@ant-design/icons"

import { Typography, Button } from "antd"

const { Title } = Typography

const QuickFilter = ({
	onClickAction,
	filtered,
	text,
	textFiltered,
	gap,
	disabled,
	width
}) => {
	const { t } = useTranslation()

	return (
		<div className="action__quickFiltersContainer" style={{ width }}>
			<div
				className="filters__ctas__title"
				style={{ paddingBottom: gap ? "51px" : "0" }}
			>
				<Title className="page__subtitle" level={5}>
					{t("Quick Filters")}
				</Title>
			</div>
			<div className="filters__ctas__container">
				<Button
					data-testid="quickFilter__action--resolutionsBelowStreetLevel--testid"
					type="primary"
					shape="round"
					className="axa-button__secondary"
					disabled={disabled}
					onClick={(e) => {
						onClickAction()
						e.currentTarget.blur()
					}}
					icon={filtered ? <FilterFilled /> : <FilterOutlined />}
				>
					{filtered ? t(textFiltered) : t(text)}
				</Button>
			</div>
		</div>
	)
}

QuickFilter.propTypes = {
	onClickAction: PropTypes.func,
	filtered: PropTypes.bool,
	gap: PropTypes.bool,
	disabled: PropTypes.bool,
	text: PropTypes.string,
	textFiltered: PropTypes.string,
	width: PropTypes.string
}

QuickFilter.defaultProps = {
	onClickAction: () => {},
	filtered: false,
	gap: false,
	disabled: false,
	text: "",
	textFiltered: "",
	width: "100%"
}

export default QuickFilter
