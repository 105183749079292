/* eslint-disable import/prefer-default-export */
import { DateTime } from "luxon"
import {
	ALERTS_TYPE,
	JOBS_STATUS_TYPE,
	RESOLUTION_QUALITY_RANGE,
	CONFIDENCE_RANGE,
	VALIDATION_STATUS_TYPE,
	SUPPORTED_COUNTRIES_ISO3_CODE
} from "helpers/constants"
import { toUSD } from "helpers/currencyConverter"
import { checker } from "helpers/CoordinatesFromString"

/**
 *
 * Enable or Disable Job deletion according to the following criteria
 * Status
 *  - ERROR
 *  - TO_BE_REVIEWED
 *  - DONE
 *
 * Upload date (whatever the status)
 *  - all the jobs older than 1 day
 *
 * @param {string} jobStatus        status of the job to be deleted
 * @param {string} jobUploadDate    upload date of the job to be deleted
 *
 * @return {Boolean} Boolean        should enable or disable job deletion
 *
 */
export const disableJobDeletion = (jobStatus, jobUploadDate) => {
	if (!jobStatus || !jobUploadDate) return true
	if (JOBS_STATUS_TYPE.DELETABLE.includes(jobStatus.toUpperCase())) {
		return false
	}
	if (DateTime.now() > DateTime.fromISO(jobUploadDate).plus({ days: 1 })) {
		return false
	}
	return true
}

/**
 *
 * Order input_data object to EMP standards
 *
 * @param {Object} inputData    input_data object with data on parcel/building
 *
 * @returns {array} 			Ordered input_data following EMP standards
 */
export const toEMPFileOrder = (inputData) => {
	if (!inputData) return undefined
	if (Object.entries(inputData).length === 0) return []
	// Reference LGO-95
	const INPUT_DATA_EMP_ORDER = [
		"emp_loc_num",
		"id",
		"customer",
		"address",
		"emp_lat_lon",
		"occupancy",
		"construction_material",
		"year_built",
		"surface_area",
		"number_of_stories",
		"floor_occupancy",
		"number_of_buildings",
		"insured_value",
		"raw_emp_attributes"
	]

	// Reference LGO-619
	const INPUT_DATA_RAW_ATTRIBUTES_EMP_ORDER = [
		"TOTALVALUE",
		"CV1VAL",
		"CV2VAL",
		"CV3VAL",
		"CV9VAL",
		"OCCSCHEME",
		"OCCTYPE",
		"YEARBUILT",
		"NUMSTORIES",
		"CV9CVM",
		"LATITUDE",
		"LONGITUDE"
	]
	const orderInputDataByEMPSorter = (a, b, orderToFollow) => {
		const aIndex = orderToFollow.indexOf(a.key)
		const bIndex = orderToFollow.indexOf(b.key)
		if (aIndex === -1 && bIndex === -1) {
			return 0
		}
		if (aIndex === -1) {
			return 1
		}
		if (bIndex === -1) {
			return -1
		}
		return aIndex - bIndex
	}
	const inputDataCopy = {
		...inputData,
		emp_loc_num: inputData?.raw_emp_attributes?.LOCNUM,
		emp_lat_lon: checker(
			inputData?.raw_emp_attributes?.LATITUDE,
			inputData?.raw_emp_attributes?.LONGITUDE
		)
	}

	// map from {foo:bar, bob: {alice: ana}} to [{key: foo, value: bar}, {key: bob, value: {key: alice: value: ana}}]
	// sort (https://www.mickpatterson.com.au/blog/sorting-an-array-of-javascript-objects-in-a-specific-order/)
	// reduce [[key: foo, value: bar}, {key: bob, value: {key: alice: value: ana}}] to [[ [ 'foo' ], 'bar' ],[["raw_emp_attributes"], {alice: ana}]]
	// Put no listed item to the end inspired by https://stackoverflow.com/a/27038137/3900714
	return Object.keys(inputDataCopy)
		.map((i) => {
			if (i === "raw_emp_attributes") {
				return {
					key: i,
					value: Object.keys(inputDataCopy[i])
						.map((attribute) => ({
							key: attribute,
							value: inputDataCopy[i][attribute]
						}))
						.sort((a, b) =>
							orderInputDataByEMPSorter(
								a,
								b,
								INPUT_DATA_RAW_ATTRIBUTES_EMP_ORDER
							)
						)
						.reduce(
							(obj, item) => ({
								...obj,
								[item.key]: item.value
							}),
							{}
						)
				}
			}
			return { key: i, value: inputDataCopy[i] }
		})
		.sort((a, b) => orderInputDataByEMPSorter(a, b, INPUT_DATA_EMP_ORDER))
		.reduce((obj, item) => [...obj, [[item.key], item.value]], [])
}

/**
 *
 * Order resolutions to a given order
 *
 * @param {array} inputData   array of current job resolutions
 *
 * @returns {array} 			Ordered resolutions
 */
export const sortResolutionsOption = (resolutions) => {
	if (!resolutions) return []
	const RESOLUTIONS_ORDER = [
		"VERIFIED",
		"ROOFTOP",
		"PARCEL_CENTROID",
		"STREET",
		"CLIENT_COORDINATES",
		"STREET_CENTROID",
		"CITY",
		"ZIPCODE",
		"STATE",
		"COUNTRY",
		"NO_GEOCODE",
		"NOT_AVAILABLE"
	]

	const resolutionsCopy = JSON.parse(JSON.stringify(resolutions))
	const sortInputDataByEMPOrder = (order) =>
		order.reduce(
			(obj, item, index) => ({
				...obj,
				[item]: index
			}),
			{}
		)

	return resolutionsCopy
		.map((i) => ({ value: i }))
		.sort(
			(a, b) =>
				sortInputDataByEMPOrder(RESOLUTIONS_ORDER)[a.value] -
				sortInputDataByEMPOrder(RESOLUTIONS_ORDER)[b.value]
		)
		.reduce((obj, item) => [...obj, item.value], [])
}

/**
 * Decided wherever an item should be display as a currency
 *
 * @param {string} item		item's key
 *
 * @returns {boolean}		wherever a given item's key belong to the list
 */
export const toDisplayAsCurrencyValue = (item) =>
	[
		"insured_value",
		"raw_emp_attributes.TOTALVALUE",
		"raw_emp_attributes.CV1VAL",
		"raw_emp_attributes.CV2VAL",
		"raw_emp_attributes.CV3VAL",
		"raw_emp_attributes.CV9VAL"
	].includes(item)

export const totalValueReducePredicate = (previousValue, currentValue) => {
	if (!currentValue.input_data?.raw_emp_attributes) return previousValue
	if (currentValue.input_data?.raw_emp_attributes.TOTALVALUE) {
		if (currentValue.input_data?.raw_emp_attributes.TOTALVALUE !== "") {
			return (
				previousValue +
				toUSD(
					parseFloat(
						currentValue.input_data.raw_emp_attributes.TOTALVALUE
					),
					currentValue.input_data.raw_emp_attributes.SITECURRENCY
				)
			)
		}
	}
	return previousValue
}

const totalValueReducePredicateAboveThereshold = (
	previousValue,
	currentValue,
	thresholdInUSD
) => {
	if (!currentValue.input_data?.raw_emp_attributes) return previousValue
	if (currentValue.input_data?.raw_emp_attributes.TOTALVALUE) {
		if (currentValue.input_data?.raw_emp_attributes.TOTALVALUE !== "") {
			if (
				toUSD(
					parseFloat(
						currentValue.input_data.raw_emp_attributes.TOTALVALUE
					),
					currentValue.input_data.raw_emp_attributes.SITECURRENCY
				) >= thresholdInUSD
			) {
				return (
					previousValue +
					toUSD(
						parseFloat(
							currentValue.input_data.raw_emp_attributes
								.TOTALVALUE
						),
						currentValue.input_data.raw_emp_attributes.SITECURRENCY
					)
				)
			}
			return previousValue
		}
	}
	return previousValue
}

/**
 * Computes % of insured value geocoded at street-level or above (includes Rooftop, Parcel centroid, Street) for a given list of locations
 * (
 *	 (Sum of all TIVs of Locations at street-level or above (includes Rooftop, Parcel centroid, Street) )
 *	 /
 *	 (Sum of all TIVs of US Locations)
 *	) * 100
 *
 * @param {array}  list of locations
 * @returns {float}
 */
export const computePercentageLocationsAboveStreetLevel = (locations) => {
	if (!locations) return 0
	if (!Array.isArray(locations)) return 0
	if (locations.length === 0) return 0
	if (locations.reduce(totalValueReducePredicate, 0.0) === 0) return 0
	return (
		(locations
			.filter((location) =>
				RESOLUTION_QUALITY_RANGE.ABOVE_STREET_LEVEL.includes(
					location.geo_data.resolution
				)
			)
			.reduce(totalValueReducePredicate, 0.0) /
			locations.reduce(totalValueReducePredicate, 0.0)) *
		100
	)
}

export const countLocationsAboveStreetLevel = (locations) => {
	if (!locations) return NaN
	if (!Array.isArray(locations)) return NaN
	if (locations.length === 0) return NaN
	return locations.filter((location) =>
		RESOLUTION_QUALITY_RANGE.ABOVE_STREET_LEVEL.includes(
			location.geo_data.resolution
		)
	).length
}

export const countLocationsHighConfidence = (locations) => {
	if (!locations) return NaN
	if (!Array.isArray(locations)) return NaN
	if (locations.length === 0) return NaN
	return locations.filter((location) =>
		CONFIDENCE_RANGE.HIGH.includes(location.geo_data.confidence)
	).length
}

export const countLocationsFootprinted = (locations) => {
	if (!locations) return NaN
	if (!Array.isArray(locations)) return NaN
	if (locations.length === 0) return NaN
	return locations.filter((location) => location.geo_data.has_footprints)
		.length
}

export const countLocationsValidated = (locations) => {
	if (!locations) return NaN
	if (!Array.isArray(locations)) return NaN
	if (locations.length === 0) return NaN
	return locations.filter((location) =>
		VALIDATION_STATUS_TYPE.REVIEWED_AND_VALIDATED.includes(
			location.validation_status
		)
	).length
}

/**
 * Computes % of insured value geocoded at street-level or above (includes Rooftop, Parcel centroid, Street)
 * AND above a TIV threshold for a given list of locations
 *
 * (
 *	 (Sum of all TIVs of Locations at street-level or above (includes Rooftop, Parcel centroid, Street) ) >= a given TIV threshold
 *	 /
 *	 (Sum of all TIVs of US Locations)
 *	) * 100
 *
 * @param {array}  	list of locations
 * @param {int}		TIV thresholold in USD
 * @returns {float}
 */
export const computePercentageLocationsAboveTIVThreshold = (
	locations,
	thresholdInUSD = 50000000
) => {
	if (!locations) return 0
	if (!Array.isArray(locations)) return 0
	if (locations.length === 0) return 0
	if (
		locations.reduce(
			(previousValue, currentValue) =>
				totalValueReducePredicateAboveThereshold(
					previousValue,
					currentValue,
					thresholdInUSD
				),
			0.0
		) === 0
	)
		return 0
	return (
		(locations
			.filter((location) =>
				RESOLUTION_QUALITY_RANGE.ABOVE_STREET_LEVEL.includes(
					location.geo_data.resolution
				)
			)
			.reduce(
				(previousValue, currentValue) =>
					totalValueReducePredicateAboveThereshold(
						previousValue,
						currentValue,
						thresholdInUSD
					),
				0.0
			) /
			locations.reduce(
				(previousValue, currentValue) =>
					totalValueReducePredicateAboveThereshold(
						previousValue,
						currentValue,
						thresholdInUSD
					),
				0.0
			)) *
		100
	)
}

/**
 * Wrapper returning if a given ISO3 country code is supported
 *
 * @param {string}  	ISO3 Country code
 * @returns {Boolean}
 */
export const enableLoadMoreParcelsForSupportedCountries = (iso3CountryCode) => {
	if (!iso3CountryCode) return false
	return SUPPORTED_COUNTRIES_ISO3_CODE.includes(iso3CountryCode.toUpperCase())
}

/**
 * Logic to determine if an Alert is not toastable (should not disappear after x milliseconds)
 *
 * @param {string}			Alert Code
 *
 * @returns {Boolean}
 */
export const isNotToastableAlert = (code) =>
	![
		"INFO_UPLOAD_NEW_FILE",
		"SUCCESS_VALIDATION_OR_NOT_LOCATION",
		"SUCCESS_MODIFIFY_GEOCODE",
		"SUCCESS_ADD_PARCELS",
		"DOWNLOADING_MESSAGE",
		"SUCCESS_DOWNLOADING_MESSAGE",
		"ERROR_DOWNLOADING_MESSAGE"
	].includes(code)

/**
 * Logic to determine an Alert type for given Alert code
 *
 * @param {string}			Alert code
 * @returns {string}		error/success/info/warning
 */
export const alertTypeByCode = (code) => {
	const SUCCESS_TYPE = [
		"SUCCESS_ADD_PARCELS",
		"SUCCESS_DELETE_JOB",
		"SUCCESS_VALIDATION_OR_NOT_LOCATION",
		"SUCCESS_DOWNLOADING_MESSAGE",
		"SUCCESS_MODIFIFY_GEOCODE"
	]
	const ERROR_TYPE = [
		"BLD_E503",
		"FPT_E520",
		"FPT_E521",
		"GEN_E500",
		"PCL_E204",
		"AUT_004",
		"UPF_E409",
		"UPF_E462",
		"UPF_E463",
		"UPF_E464",
		"ERROR_DOWNLOADING_MESSAGE",
		"GEOCODING_FAILURE"
	]
	const INFO_TYPE = ["GEN_E403", "DOWNLOADING_MESSAGE", "AUT_E401"]
	const WARNING_TYPE = [
		"UPF_E001",
		"UPF_E002",
		"UPF_E004",
		"UPF_E005",
		"UPF_E007",
		"UPF_E460"
	]
	if (ERROR_TYPE.includes(code)) {
		return ALERTS_TYPE.ERROR
	}
	if (INFO_TYPE.includes(code)) {
		return ALERTS_TYPE.INFO
	}
	if (WARNING_TYPE.includes(code)) {
		return ALERTS_TYPE.WARNING
	}
	if (SUCCESS_TYPE.includes(code)) {
		return ALERTS_TYPE.SUCCESS
	}
	return "info"
}
