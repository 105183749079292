import { SHORTHEN_TIV } from "helpers/constants"

/**
 *
 * Format display for currency values given a currency and a locale
 *
 * @param {float} 		value
 * @param {string} 		ISO currency code
 * @param {string} 		ISO country code
 *
 * @returns {string} 	Formatted string with value displayed in a given currency for a given locale
 */
export default (value, currencyCode = "USD", locale = "en-US") => {
	let useCurrencyCode = "USD"
	if (currencyCode !== "") useCurrencyCode = currencyCode
	const res = NaN
	if (Number.isNaN(parseFloat(value))) return res.toString()
	if (value === 0 || parseFloat(value) === 0.0) return res.toString()
	try {
		return Intl.NumberFormat(locale, {
			style: "currency",
			currency: useCurrencyCode,
			maximumFractionDigits: 0,
			minimumFractionDigits: 0
		}).format(parseFloat(value))
	} catch (error) {
		return res.toString()
	}
}

const reversedNum = (num) => num.toString().split("").reverse().join("")
const raiseNumberUp = (str) => {
	if (str[1] > 4) return parseInt(str[2], 10) + 1
	return str[2]
}

export const shortenTIV = (value) => {
	const intValue = parseInt(value, 10)
	const arrSliped = reversedNum(intValue)
		.toString()
		.split(/(?<=^(?:.{3})+)(?!$)/)

	if (arrSliped.length >= 4) {
		return `$ ${reversedNum(
			arrSliped[arrSliped.length - 1]
		)}.${raiseNumberUp(arrSliped[arrSliped.length - 2])} ${
			SHORTHEN_TIV[arrSliped.length - 1]
		}`
	}
	return `$ ${reversedNum(arrSliped[arrSliped.length - 1])} ${
		SHORTHEN_TIV[arrSliped.length - 1]
	}`
}
