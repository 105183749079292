import { isRejectedWithValue } from "@reduxjs/toolkit"
import { push } from "redux-first-history"
import { ALERTS_TYPE } from "helpers/constants"
import { REQUESTED_PATH_KEY } from "features/authSlice"
import { setAlertPayload } from "features/alertsSlice"

/**
 * Log a warning and show a toast!
 */
// RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
const errorMiddleware =
	({ dispatch }) =>
	(next) =>
	(action) => {
		if (isRejectedWithValue(action)) {
			// AUT_E401 is backend error code triggered when Token is not valid anymore
			if (action.payload.code === "AUT_E401") {
				window.sessionStorage.setItem(
					REQUESTED_PATH_KEY,
					JSON.stringify(action.meta.location)
				)
				dispatch(push("/"))
				dispatch(
					setAlertPayload({
						type: ALERTS_TYPE.INFO,
						status: action.meta.status,
						...action.payload
					})
				)
			} else {
				dispatch(
					setAlertPayload({
						type: ALERTS_TYPE.INFO,
						status: action.meta.status,
						payload: {
							isMapAlert: false,
							...action.payload
						}
					})
				)
			}
		}
		return next(action)
	}

export default errorMiddleware
