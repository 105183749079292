import React, { useEffect, useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import { Select, Typography } from "antd"

import {
	resolutionsAvailable as resolutionsAvailableSlice,
	confidencesAvailable as confidencesAvailableSlice,
	countriesAvailable as countriesAvailableSlice,
	statusAvailable as statusAvailableSlice,
	filterFootprints as filterFootprintsSlice,
	setFilteredByCountry,
	setFilteredByResolution,
	setFilteredByConfidence,
	filterLocationsByFilters,
	setFilteredByStatus,
	setFilteredByHasFootprints,
	filteredBy as filteredBySlice,
	setFilteredByResolutionBelowStreetLevel,
	resetFilteredByResolution
} from "features/jobsResultsSlice"

import ConfidenceBars from "components/confidenceBars/ConfidenceBars"
import QuickFilter from "components/quickFilter/QuickFilter"
import FilterAction from "components/filterAction/FilterAction"
import SummaryFilter from "components/summaryCriteria/SummaryFilter"

import {
	generateSpecificCountriesCodeNameFlagByLocaleSortedByName,
	getCountryNameByLocale
} from "helpers/countriesHandler"
import {
	CONFIDENCE_RANGE_INVERTED,
	STATUS_MATCHING,
	RESOLUTION_QUALITY_RANGE
} from "helpers/constants"
import { sortResolutionsOption } from "helpers/rulesHandler"

import useMediaQuery from "styles/useMediaQuery"

const { Option } = Select
const { Title } = Typography

const FilterWrapper = ({ filteredbyResolution, setFilteredbyResolution }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const filteredBy = useSelector(filteredBySlice)
	const resolutionsAvailable = useSelector(resolutionsAvailableSlice)
	const confidencesAvailable = useSelector(confidencesAvailableSlice)
	const countriesAvailable = useSelector(countriesAvailableSlice)
	const statusAvailable = useSelector(statusAvailableSlice)
	const filterFootprints = useSelector(filterFootprintsSlice)

	const isDesktop = useMediaQuery("(min-width: 1100px)")
	const [width, setWidth] = useState(0)
	const ref = useRef(null)

	const handleResize = () => {
		setWidth(ref && ref?.current?.offsetWidth)
	}
	useEffect(() => {
		window.addEventListener("resize", handleResize, false)
	}, [])

	return (
		<div
			ref={ref}
			className={`filters__wrapper${!isDesktop ? "-column" : ""}`}
		>
			<div className="filters__ctas filter__locations">
				<div
					className={`filters__ctas__title actions__title${
						!isDesktop ? "-column" : ""
					}`}
				>
					<Title className="page__subtitle" level={5}>
						{t("Filter locations")}
					</Title>
				</div>
				<div className="actions__wrapper">
					<div
						className={`page__actionContainer action__filtersContainer filtersActions--locations {
`}
						style={{ marginBottom: isDesktop ? "24px" : "8px" }}
					>
						<div
							className="filtersActions__filtersContainer"
							style={{
								width: isDesktop ? width - 314 - 64 : "auto"
							}}
						>
							<FilterAction
								filter={
									filteredBy?.filters?.resolution?.length > 0
										? filteredBy.filters.resolution
										: []
								}
								title="Resolution"
								placeholder="Resolution level"
								maxLenght={15}
								onChange={(value) => {
									dispatch(
										setFilteredByResolution(
											value.length === 0 ? "" : value
										),
										filteredbyResolution &&
											setFilteredbyResolution(false)
									)
									dispatch(filterLocationsByFilters())
								}}
								id="resolution"
							>
								{sortResolutionsOption(
									Object.keys(resolutionsAvailable ?? [])
								).map((select) => (
									<Option
										key={btoa(select)}
										disabled={false}
										value={select}
										className="filter_option"
										data-testid={`select-resolution-${select}-testid`}
									>
										<div className="selectResolution__children">
											<span>{t(select)}</span>
											<span className="selectResolution__counter">
												{resolutionsAvailable[select]}
											</span>
										</div>
									</Option>
								))}
							</FilterAction>
							<FilterAction
								filter={
									filteredBy?.filters?.confidence?.length > 0
										? filteredBy.filters.confidence
										: []
								}
								title="Level of confidence"
								placeholder="Confidence level"
								maxLenght={6}
								onChange={(value) => {
									dispatch(
										setFilteredByConfidence(
											value.length === 0 ? "" : value
										)
									)
									dispatch(filterLocationsByFilters())
								}}
								id="confidence"
							>
								{confidencesAvailable &&
									Object.keys(confidencesAvailable)
										.sort()
										.reverse()
										.filter((range) => range !== "0")
										.map((range) => (
											<Option
												className="filter_option filter_option--confidence"
												key={range}
												value={range}
												data-testid={`select-confidence-${range}-testid`}
												data-searchlabel={
													CONFIDENCE_RANGE_INVERTED[
														range
													]
												}
											>
												<div className="selectResolution__children">
													<ConfidenceBars
														confidence={range}
														label
														toWhite={false}
													/>
													<span className="selectResolution__counter">
														{
															confidencesAvailable[
																range
															]
														}
													</span>
												</div>
											</Option>
										))}
							</FilterAction>
							<FilterAction
								filter={
									filteredBy?.filters?.country?.length > 0
										? filteredBy.filters.country
										: []
								}
								title="Country"
								placeholder="Select a country"
								maxLenght={16}
								onChange={(value) => {
									dispatch(
										setFilteredByCountry(
											value.length === 0 ? "" : value
										)
									)
									dispatch(filterLocationsByFilters())
								}}
								id="country"
								filterOption={
									/* istanbul ignore next */ (
										input,
										option
									) => {
										if (
											input.toUpperCase() === option.value
										) {
											return true
										}
										return (
											getCountryNameByLocale(
												undefined,
												option.value
											)
												.toLowerCase()
												.indexOf(input.toLowerCase()) >=
											0
										)
									}
								}
							>
								{countriesAvailable &&
									generateSpecificCountriesCodeNameFlagByLocaleSortedByName(
										undefined,
										Object.keys(countriesAvailable)
									).map((countryObject) => (
										<Option
											className="filter_option"
											key={`${countryObject.code}-key`}
											data-testid={`select-country-${countryObject.code}-testid`}
											value={countryObject.code}
											label={countryObject.name}
										>
											<div className="selectResolution__children">
												<div className="filter_countrySelect__content">
													<span
														role="img"
														aria-label={`${countryObject.flag} ${countryObject.name}`}
													>
														{`${countryObject.flag}`}
													</span>
													<div className="filter_countrySelect__country--text">{`${countryObject.name}`}</div>
												</div>
												<span className="selectResolution__counter">
													{
														countriesAvailable[
															countryObject.code
														]
													}
												</span>
											</div>
										</Option>
									))}
							</FilterAction>
							<FilterAction
								filter={
									filteredBy?.filters?.status?.length > 0
										? filteredBy.filters.status
										: []
								}
								title="Status"
								placeholder="Select status"
								maxLenght={12}
								onChange={(value) => {
									dispatch(
										setFilteredByStatus(
											value.length === 0 ? "" : value
										)
									)
									dispatch(filterLocationsByFilters())
								}}
								id="status"
							>
								{statusAvailable &&
									Object.keys(statusAvailable).map(
										(select) => (
											<Option
												key={btoa(select)}
												value={select}
												data-testid={`select-status-${select}-testid`}
												className="filter_option"
											>
												<div className="selectResolution__children">
													<span>
														{
															STATUS_MATCHING[
																select
															]
														}
													</span>
													<span className="selectResolution__counter">
														{
															statusAvailable[
																select
															]
														}
													</span>
												</div>
											</Option>
										)
									)}
							</FilterAction>
							<FilterAction
								filter={
									filteredBy?.filters?.has_footprints
										?.length > 0
										? filteredBy.filters.has_footprints
										: []
								}
								title="Footprint"
								placeholder="Select an option"
								maxLenght={3}
								onChange={(value) => {
									dispatch(
										setFilteredByHasFootprints(
											value.length === 0 ? "" : value
										)
									)
									dispatch(filterLocationsByFilters())
								}}
								id="footprint"
							>
								{filterFootprints &&
									Object.keys(filterFootprints).map(
										(select) => (
											<Option
												key={btoa(select)}
												value={select}
												data-testid={`select-footprint-${select}-testid`}
												className="filter_option"
											>
												<div className="selectResolution__children">
													<span>{select}</span>
													<span className="selectResolution__counter">
														{
															filterFootprints[
																select
															]
														}
													</span>
												</div>
											</Option>
										)
									)}
							</FilterAction>
						</div>
					</div>
				</div>
				<SummaryFilter filteredbyResolution={filteredbyResolution} />
			</div>
			<QuickFilter
				onClickAction={() => {
					setFilteredbyResolution(!filteredbyResolution)
					dispatch(
						filteredbyResolution
							? resetFilteredByResolution()
							: setFilteredByResolutionBelowStreetLevel()
					)
					dispatch(filterLocationsByFilters())
				}}
				filtered={filteredbyResolution}
				text="Show low resolution geocodes"
				textFiltered="Display all resolution geocodes"
				disabled={Object.keys(resolutionsAvailable ?? [])?.every(
					(resolution) =>
						RESOLUTION_QUALITY_RANGE.ABOVE_STREET_LEVEL.includes(
							resolution
						)
				)}
				width={isDesktop ? "314px" : ""}
				gap={isDesktop}
			/>
		</div>
	)
}

FilterWrapper.propTypes = {
	filteredbyResolution: PropTypes.bool,
	setFilteredbyResolution: PropTypes.func
}

FilterWrapper.defaultProps = {
	filteredbyResolution: false,
	setFilteredbyResolution: () => {}
}

export default FilterWrapper
