import React from "react"
import PropTypes from "prop-types"

const RemoveIcon = ({ classname }) => (
	<svg
		width="14"
		height="19"
		viewBox="0 0 14 19"
		fill="none"
		className={classname}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1 16.5C1 17.6 1.9 18.5 3 18.5H11C12.1 18.5 13 17.6 13 16.5V4.5H1V16.5ZM14 1.5H10.5L9.5 0.5H4.5L3.5 1.5H0V3.5H14V1.5Z"
			fill="#00008F"
		/>
	</svg>
)

RemoveIcon.propTypes = {
	classname: PropTypes.string
}

RemoveIcon.defaultProps = {
	classname: ""
}

export default RemoveIcon
