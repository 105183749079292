import React from "react"
import { VALIDATION_STATUS_TYPE } from "helpers/constants"

const generateValidationStatusLabels = (status) => {
	switch (status.toUpperCase()) {
		case VALIDATION_STATUS_TYPE.REVIEWED_INCONCLUSIVE:
			return (
				<span className="validationStatus__label validationStatus__label--reviewed">
					Inconclusive search
				</span>
			)
		case VALIDATION_STATUS_TYPE.REVIEWED_WRONG:
			return (
				<span className="validationStatus__label validationStatus__label--reviewed">
					Wrong data
				</span>
			)
		case VALIDATION_STATUS_TYPE.VALIDATED:
			return (
				<span className="validationStatus__label validationStatus__label--validated">
					Verified
				</span>
			)
		case VALIDATION_STATUS_TYPE.NON_REVIEWED:
			return null
		default:
			return null
	}
}
export default generateValidationStatusLabels
