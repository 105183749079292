// eslint-disable-next-line import/prefer-default-export
export const TO_BE_GENERIC = [
	"BLD_E001",
	"BLD_E002",
	"BLD_E003",
	"BLD_E004",
	"BLD_E400",
	"DWV_E400",
	"FPT_E001",
	"FPT_E002",
	"FPT_E400",
	"GEN_E405",
	"GEN_E600",
	"JOB_E001",
	"JOB_E400",
	"JOB_E403",
	"JOB_E404",
	"JOB_E409",
	"LOC_E400",
	"LOC_E404",
	"LOC_E404",
	"LOC_E001",
	"LOC_E002",
	"PCL_E001",
	"PCL_E002",
	"PCL_E003",
	"PCL_E004",
	"PCL_E400",
	"UFP_E001",
	"UFP_E002",
	"UFP_E003",
	"UFP_E004",
	"UFP_E005",
	"UFP_E006",
	"UFP_E400",
	"UPF_E003",
	"UPF_E006",
	"LOC_E001",
	"AUT_001",
	"AUT_002",
	"AUT_003",
	"AUT_005",
	"AUT_006"
]
