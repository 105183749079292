import { isEmpty } from "lodash"

export default (endpoint = "") => {
	const protocol = process.env.REACT_APP_LOCUS_SCHEME
	const host = process.env.REACT_APP_LOCUS_API_HOST
	const port = process.env.REACT_APP_LOCUS_PORT
		? `:${process.env.REACT_APP_LOCUS_PORT}`
		: ""
	const version = process.env.REACT_APP_LOCUS_API_VERSION

	let cleanseEndpoint = endpoint
	// Removing leading slash if any
	cleanseEndpoint = cleanseEndpoint.startsWith("/")
		? cleanseEndpoint.substr(1)
		: cleanseEndpoint
	// Removing trailong slash if any
	cleanseEndpoint = cleanseEndpoint.endsWith("/")
		? cleanseEndpoint.substr(0, cleanseEndpoint.length - 1)
		: cleanseEndpoint

	if (
		process.env.REACT_APP_LOCUS_SCHEME === "undefined" ||
		process.env.REACT_APP_LOCUS_SCHEME === "null" ||
		process.env.REACT_APP_LOCUS_API_HOST === "undefined" ||
		process.env.REACT_APP_LOCUS_API_HOST === "null" ||
		process.env.REACT_APP_LOCUS_PORT === "undefined" ||
		process.env.REACT_APP_LOCUS_PORT === "null" ||
		isEmpty(protocol) ||
		isEmpty(host)
	) {
		return undefined
	}
	let uri = `${protocol}://${host}${port}/${version}/${cleanseEndpoint}`
	if (!cleanseEndpoint) {
		// removing trailing slash with uri ending by .../api/
		uri = uri.substr(0, uri.length - 1)
	}
	return uri
}
