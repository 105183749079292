import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import {
	filteredBy as filteredBySlice,
	resetQuickFilters
} from "features/jobsResultsSlice"

import { STATUS_MATCHING } from "helpers/constants"
import { getCountryNameByLocale } from "helpers/countriesHandler"
import { confidenceLabelBuilder } from "components/confidenceBars/ConfidenceBars"

import { Button } from "antd"
import SummaryCriteria from "./SummaryCriteria"

const SummaryFilter = ({ filteredbyResolution }) => {
	const [semiColon, setSemiColon] = useState(
		Array.from({ length: 5 }, () => false)
	)
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const filteredBy = useSelector(filteredBySlice)

	if (
		filteredBy?.filters &&
		Object.values(filteredBy?.filters).every(
			(filter, key) =>
				filter?.length === 0 ||
				!filter ||
				(filteredbyResolution && key === 1 && filter?.length === 3)
		)
	)
		return <div />

	return (
		<div className="filters_summary__container">
			<span className="filters__summary__title">
				Summary of filters applied
			</span>
			<div className="filters_summary_list__container">
				<div className="filters_input">
					<SummaryCriteria
						filtered={
							filteredbyResolution &&
							filteredBy.filters?.resolution?.length === 3
								? []
								: filteredBy.filters.resolution
						}
						title="Resolution"
						semiColon={semiColon}
						onFilter={(item) => t(item)}
						setSemiColon={(value) => setSemiColon(value)}
						index={0}
					/>
					<SummaryCriteria
						filtered={filteredBy.filters.confidence}
						title="Confidence"
						semiColon={semiColon}
						onFilter={(item) => confidenceLabelBuilder(item)}
						setSemiColon={(value) => setSemiColon(value)}
						index={1}
					/>
					<SummaryCriteria
						filtered={filteredBy.filters.country}
						title="Country"
						semiColon={semiColon}
						onFilter={(country) =>
							getCountryNameByLocale(undefined, country)
						}
						setSemiColon={(value) => setSemiColon(value)}
						index={2}
					/>
					<SummaryCriteria
						filtered={filteredBy.filters.status}
						title="Status"
						semiColon={semiColon}
						onFilter={(item) => STATUS_MATCHING[item]}
						setSemiColon={(value) => setSemiColon(value)}
						index={3}
					/>
					<SummaryCriteria
						filtered={filteredBy.filters.has_footprints}
						title="Footprint"
						semiColon={semiColon}
						onFilter={(item) => item}
						index={4}
					/>
				</div>
				<Button
					type="primary"
					className="axa-button"
					data-testid="actions-footprint__button--testid"
					onClick={(e) => {
						e.currentTarget.blur()
						dispatch(resetQuickFilters())
					}}
					icon={
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							xmlns="http://www.w3.org/2000/svg"
							className="axa-button--withLeftIcon"
						>
							<path
								d="M14.4 4.5v11c0 .276-.238.5-.533.5H2.133c-.295 0-.533-.224-.533-.5v-11h12.8zM11.2 7h-1.067v6.5H11.2V7zM8.533 7H7.467v6.5h1.066V7zM5.867 7H4.8v6.5h1.067V7zm4.8-7c.295 0 .533.224.533.5v2h4.267c.295 0 .533.224.533.5s-.238.5-.533.5H.533C.238 3.5 0 3.276 0 3s.238-.5.533-.5H4.8v-2c0-.276.238-.5.533-.5zm-.534 1H5.867v1.5h4.266V1z"
								fill="#fff"
								fillRule="evenodd"
							/>
						</svg>
					}
				>
					Remove all filters
					{/* <div className="filters_deletion_action" /> */}
				</Button>
			</div>
		</div>
	)
}

SummaryFilter.propTypes = {
	filteredbyResolution: PropTypes.bool
}

SummaryFilter.defaultProps = {
	filteredbyResolution: false
}

export default SummaryFilter
