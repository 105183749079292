import React from "react"

const IconArrowDown = () => (
	<svg
		width="16px"
		height="16px"
		viewBox="0 0 16 16"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g
			id="Symbols"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g id="Icons/Functional/icon-caret-down" fill="#00008F">
				<polygon
					id="Shape"
					points="16 5.08939786 13.9106021 3 8 8.91060214 2.08939786 3 0 5.08939786 8 13.0893979"
				/>
			</g>
		</g>
	</svg>
)

export default IconArrowDown
