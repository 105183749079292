import PropTypes from "prop-types"
import React from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Result, Button } from "antd"
import { SafetyCertificateOutlined } from "@ant-design/icons"
import {
	createAuthorizationRequest,
	REQUESTED_PATH_KEY
} from "features/authSlice"

import "pages/login/LoginPage.css"
import { LOGIN_BLOCK_MODE } from "helpers/constants"

/**
 *
 * Creates an Login
 * As per https://ant.design/components/result/#components-result-demo-404
 *
 * @props {string} 		type: Following FOOTPRINT_MODE values
 *
 * @returns Login
 */

const LoginComponent = (props) => {
	const { type } = props
	const dispatch = useDispatch()
	const navigate = useNavigate()
	return (
		<div className="loginBlock__container">
			<Result
				icon={
					<SafetyCertificateOutlined style={{ color: "#00008F" }} />
				}
				title={
					type === LOGIN_BLOCK_MODE.CALLBACK ? "Logging in" : "Oops."
				}
				subTitle={
					type === LOGIN_BLOCK_MODE.CALLBACK
						? "We are currently authenticating you."
						: "Looks like your session expired, please click on the login button below"
				}
				extra={
					<Button
						disabled={type === LOGIN_BLOCK_MODE.CALLBACK}
						className="axa-button"
						onClick={() => {
							dispatch(
								createAuthorizationRequest(
									JSON.parse(
										window.sessionStorage.getItem(
											REQUESTED_PATH_KEY
										)
									)
								)
							)
							navigate("/")
						}}
						size="large"
					>
						{type === LOGIN_BLOCK_MODE.CALLBACK
							? "Go Back Jobs list"
							: "Login"}
					</Button>
				}
			/>
		</div>
	)
}

LoginComponent.propTypes = {
	type: PropTypes.string.isRequired
}

export default LoginComponent
