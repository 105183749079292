import React from "react"
import PropTypes from "prop-types"
import { Tooltip } from "antd"
import "./IconInfo.css"

const Info = ({ title }) => (
	<Tooltip
		title={title}
		color="#C3D1E7"
		overlayInnerStyle={{
			color: "#343c3d",
			padding: "12px"
		}}
	>
		<svg
			width="18px"
			height="19px"
			viewBox="0 0 18 19"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g
				id="TIV-panel-(in-progress)"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="[LGO]-1582-TIV-dashboard-|-Geocoding-Quality-Country-panel"
					transform="translate(-999.000000, -581.000000)"
				>
					<g
						id="icon/flag"
						transform="translate(999.000000, 581.000000)"
					>
						<ellipse
							id="Oval"
							fill="#4976BA"
							cx="9"
							cy="9.62283737"
							rx="9"
							ry="8.96885813"
						/>
						<text
							id="i"
							fontFamily="SourceSansPro-Regular, Source Sans Pro"
							fontSize="12"
							fontWeight="normal"
							fill="#FFFFFF"
							className="noselect"
						>
							<tspan className="noselect" x="7.36363636" y="13.5">
								i
							</tspan>
						</text>
					</g>
				</g>
			</g>
		</svg>
	</Tooltip>
)

Info.propTypes = {
	title: PropTypes.string.isRequired
}

export default Info
