import React from "react"
import i18n from "i18next"
import { toDisplayAsCurrencyValue } from "helpers/rulesHandler"
import currencyHandler from "./currencyHandler"

const infoToHide = [
	"id",
	"occupancy.code",
	"insured_value",
	"street_name",
	"city",
	"state",
	"state_code",
	"postal_code",
	"county",
	"country_code",
	"country",
	"raw_emp_attributes.OCCSCHEME",
	"raw_emp_attributes.OCCTYPE",
	"raw_emp_attributes.YEARBUILT",
	"raw_emp_attributes.NUMSTORIES",
	"raw_emp_attributes.CV2CVM",
	"raw_emp_attributes.CV9CVM",
	"raw_emp_attributes.NUMBLDGS",
	"raw_emp_attributes.FRSPRINKLERSYS",
	"raw_emp_attributes.SITECURRENCY",
	"raw_emp_attributes.LATITUDE",
	"raw_emp_attributes.LONGITUDE",
	"raw_emp_attributes.LOCNUM"
]

export const flattentContent = (contents) =>
	contents && typeof contents === "object"
		? Object.assign(
				{},
				...Object.keys(contents)
					.map((item) => {
						if (contents[item] || contents[item] === 0) {
							if (typeof contents[item] === "object") {
								return Object.keys(contents[item]).map(
									(subItem) => {
										if (contents[item][subItem]) {
											return {
												[`${item}.${subItem}`]:
													contents[item][subItem]
											}
										}
										/* istanbul ignore next */
										return null
									}
								)
							}
							return { [item]: contents[item] }
						}
						return null
					})
					.flat()
					.filter((_content) => _content !== null)
		  )
		: {}

export const inputDataKeysWithHiddenData = (flattenContent) =>
	Object.keys(flattenContent)
		.filter((key) => !infoToHide.includes(key))
		.filter((key) => {
			// Hide amount === 0 || amount === ""
			if (toDisplayAsCurrencyValue(key)) {
				return flattenContent[key] !== "0" && flattenContent[key] !== ""
			}
			return true
		})

export const formattedInputDataWithHiddenData = (contents) => {
	const myFlattentContent = flattentContent(contents)

	return Object.assign(
		{},
		...inputDataKeysWithHiddenData(myFlattentContent).map((data) => ({
			[i18n.t(data)]: toDisplayAsCurrencyValue(data)
				? currencyHandler(
						myFlattentContent[data],
						myFlattentContent["raw_emp_attributes.SITECURRENCY"]
				  )
				: myFlattentContent[data]
		}))
	)
}

export const addressFragmented = (address) => {
	let lines = address?.split(",")
	lines = lines.length > 1 ? lines.slice(0, -1) : lines
	return lines?.map((line) => (
		<span key={line}>
			{line}
			<br />
		</span>
	))
}
