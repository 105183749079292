import IconInfoBanner from "assets/IconInfoBanner"
import React, { useState } from "react"
import { geocodedDataRaw as geocodedDataRawSlice } from "features/footprintSlice"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { setIsDisplayingBannerDataSource } from "features/mapSlice"
import MoreInfoDataSourceModal from "./Topbar/Modals/MoreInfoDataSourceModal"

const BannerDataSource = () => {
	const currentGeocodedDataRaw = useSelector(geocodedDataRawSlice)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const [showDescriptionSourceModal, setShowDescriptionSourceModal] =
		useState(false)

	let currentModeDisplay = ""
	if (
		currentGeocodedDataRaw[0]?.available_processing_modes &&
		currentGeocodedDataRaw[0]?.available_processing_modes.length > 1 &&
		currentGeocodedDataRaw[0]?.address?.country &&
		currentGeocodedDataRaw[0]?.address?.country === "USA"
	) {
		currentModeDisplay = "us"
		dispatch(setIsDisplayingBannerDataSource(true))
	} else if (
		currentGeocodedDataRaw[0]?.available_processing_modes &&
		currentGeocodedDataRaw[0]?.available_processing_modes.length > 1 &&
		currentGeocodedDataRaw[0]?.address?.country &&
		currentGeocodedDataRaw[0]?.address?.country === "FRA"
	) {
		currentModeDisplay = "fr"
		dispatch(setIsDisplayingBannerDataSource(true))
	} else if (
		currentGeocodedDataRaw[0]?.available_processing_modes &&
		currentGeocodedDataRaw[0]?.available_processing_modes.length > 1
	) {
		currentModeDisplay = "osm"
		dispatch(setIsDisplayingBannerDataSource(true))
	} else {
		dispatch(setIsDisplayingBannerDataSource(false))
	}

	return currentModeDisplay ? (
		<div className="banner__container">
			<MoreInfoDataSourceModal
				isVisible={showDescriptionSourceModal}
				onDismiss={() => setShowDescriptionSourceModal(false)}
				currentDataSourceDesc={currentModeDisplay}
			/>
			<p className="banner__text">
				<IconInfoBanner classname="banner__info__logo" />
				{`${t(`data_source_${currentModeDisplay}`)}`}
				{/* eslint-disable-next-line */}
				<a
					className="banner__link"
					onClick={() => setShowDescriptionSourceModal(true)}
				>
					Click here for more info.
				</a>
			</p>
		</div>
	) : (
		<div />
	)
}

export default BannerDataSource
