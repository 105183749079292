/* eslint-disable no-undef */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { decodeToken } from "react-jwt"

/**
 * Slice responsible for handling the OAuth process:
 *  - Creates the AuthZ request
 *  - Request the Access Token
 */

// Generate a Random String
/* istanbul ignore next */
const getRandomString = (length = 28) => {
	const randomItems = new Uint32Array(length)
	crypto.getRandomValues(randomItems)
	const binaryStringItems = randomItems.map(
		(dec) => `0${dec.toString(16).substr(-2)}`
	)
	return binaryStringItems.reduce((acc, item) => `${acc}${item}`, "")
}

// Encrypt a String with SHA256
/* istanbul ignore next */
const encryptStringWithSHA256 = async (str) => {
	const PROTOCOL = "SHA-256"
	const textEncoder = new TextEncoder()
	const encodedData = textEncoder.encode(str)
	return crypto.subtle.digest(PROTOCOL, encodedData)
}

// Convert Hash to Base64-URL
/* istanbul ignore next */
const hashToBase64url = (arrayBuffer) => {
	const items = new Uint8Array(arrayBuffer)
	const stringifiedArrayHash = items.reduce(
		(acc, i) => `${acc}${String.fromCharCode(i)}`,
		""
	)
	const decodedHash = btoa(stringifiedArrayHash)
	const base64URL = decodedHash
		.replace(/\+/g, "-")
		.replace(/\//g, "_")
		.replace(/=+$/, "")
	return base64URL
}

export const SLICE_PREFIX = "auth"
export const PKCE_STATE_KEY = "pkce_state"
export const CODE_VERIFIER_KEY = "code_verifier"
export const REQUESTED_PATH_KEY = "requested_path"

const MAAM_AUTHZ_ENDPOINT = process.env.REACT_APP_MAAM_AUTHZ_ENDPOINT
const MAAM_TOKEN_ENDPOINT = process.env.REACT_APP_MAAM_TOKEN_ENDPOINT
const MAAM_LOGOUT_ENDPOINT = process.env.REACT_APP_MAAM_LOGOUT_ENDPOINT
const APP_CLIENT_ID = process.env.REACT_APP_APP_CLIENT_ID
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI

export const INIT_STATE = {
	isLoggingIn: false,
	isLoggedIn: false,
	isLoggingOut: false,
	isLoggedOut: false,
	id_token: "",
	json_id_token: "",
	access_token: "",
	refresh_token: "",
	authorize_URL: "",
	logoutURI: "",
	userInfo: {
		fullname: "",
		email: "",
		locale: "en"
	}
}

const OAUTH_SERVER_HEADERS = new Headers({
	"content-type": "application/x-www-form-urlencoded"
})

export const createAuthorizationRequest = createAsyncThunk(
	`${SLICE_PREFIX}/createAuthorizationRequest`,
	async (data, { fulfillWithValue, rejectWithValue }) => {
		sessionStorage.setItem(REQUESTED_PATH_KEY, JSON.stringify(data))
		const pkceState = getRandomString()
		sessionStorage.setItem(PKCE_STATE_KEY, pkceState)
		const codeVerifier = getRandomString(43)
		sessionStorage.setItem(CODE_VERIFIER_KEY, codeVerifier)
		const codeVerifierHash = await encryptStringWithSHA256(codeVerifier)
		/* istanbul ignore next */
		if (codeVerifierHash) {
			return fulfillWithValue({
				pkceState,
				codeVerifierHash
			})
		}
		/* istanbul ignore next */
		return rejectWithValue({
			code: "AUT_E006",
			description: "Authorization Internal Error",
			details: []
		})
	}
)

export const fetchAccessToken = createAsyncThunk(
	`${SLICE_PREFIX}/fetchAuthentication`,
	async (parameters, { fulfillWithValue, rejectWithValue }) => {
		const settings = {
			method: "POST",
			headers: OAUTH_SERVER_HEADERS,
			credentials: "same-origin"
		}
		if (!parameters) {
			return rejectWithValue({
				code: "AUT_E001",
				description: "All query parameters are missing",
				details: []
			})
		}

		if (!parameters.code) {
			return rejectWithValue({
				code: "AUT_E002",
				description: "code parameter is missing",
				details: []
			})
		}

		if (!parameters.state) {
			return rejectWithValue({
				code: "AUT_E003",
				description: "state parameter is missing",
				details: []
			})
		}
		const pkceStateFromSessionStorage =
			sessionStorage.getItem(PKCE_STATE_KEY)
		const codeVerifierFromStorage =
			sessionStorage.getItem(CODE_VERIFIER_KEY)

		if (!pkceStateFromSessionStorage || !codeVerifierFromStorage) {
			return rejectWithValue({
				code: "AUT_E004",
				description:
					"Something went wrong during authentication process",
				details: []
			})
		}
		if (pkceStateFromSessionStorage !== parameters.state) {
			return rejectWithValue({
				code: "AUT_E005",
				description: "You are not authorized",
				details: []
			})
		}
		const details = {
			grant_type: "authorization_code",
			redirect_uri: REDIRECT_URI,
			client_id: APP_CLIENT_ID,
			code_verifier: codeVerifierFromStorage,
			code: parameters.code
		}
		const formBody = Object.keys(details)
			.map(
				(key) =>
					`${encodeURIComponent(key)}=${encodeURIComponent(
						details[key]
					)}`
			)
			.join("&")

		try {
			const response = await fetch(MAAM_TOKEN_ENDPOINT, {
				...settings,
				body: formBody
			})
			if (response.status === 200 || response.status === 301) {
				return fulfillWithValue(await response.json(), {
					status: response.status
				})
			}
			throw response
		} catch (error) {
			return rejectWithValue(await error.json(), { status: error.status })
		}
	}
)

export const authSlice = createSlice({
	name: SLICE_PREFIX,
	initialState: INIT_STATE,
	reducers: {
		startLogout: (state) => ({
			...INIT_STATE,
			isLoggedOut: true,
			logoutURI: `${MAAM_LOGOUT_ENDPOINT}?client_id=${APP_CLIENT_ID}&id_token_hint=${state.jwt_token}`
		}),
		setUserLocale: (state, action) => ({
			...state,
			userInfo: {
				...state.userInfo,
				locale: action.payload || INIT_STATE.userInfo.locale
			}
		})
	},
	extraReducers: (builder) => {
		builder
			.addCase(createAuthorizationRequest.pending, (state) => {
				state.isLoggingIn = true
				sessionStorage.removeItem(REQUESTED_PATH_KEY)
			})
			.addCase(createAuthorizationRequest.fulfilled, (state, action) => {
				state.isLoggingIn = true
				const codeChallenge = hashToBase64url(
					action.payload.codeVerifierHash
				)
				state.authorize_URL = `${MAAM_AUTHZ_ENDPOINT}?client_id=${APP_CLIENT_ID}&response_type=code&scope=openid+profile+email&state=${action.payload.pkceState}&code_challenge_method=S256&code_challenge=${codeChallenge}&redirect_uri=${REDIRECT_URI}`
			})
			.addCase(createAuthorizationRequest.rejected, (state) => {
				state.isLoggingIn = false
				sessionStorage.removeItem(PKCE_STATE_KEY)
				sessionStorage.removeItem(CODE_VERIFIER_KEY)
				sessionStorage.removeItem(REQUESTED_PATH_KEY)
			})
			.addCase(fetchAccessToken.pending, (state) => {
				state.isLoggingIn = true
				state.authorize_URL = ""
			})
			.addCase(fetchAccessToken.fulfilled, (state, action) => {
				state.isLoggingIn = false
				state.jwt_id_token = action.payload.id_token
				state.access_token = action.payload.access_token
				state.refresh_token = action.payload.refresh_token
				state.id_token = decodeToken(action.payload.id_token)
				state.isLoggedIn = true
				state.userInfo.fullname = state.id_token.name
				state.userInfo.email = state.id_token.email
				sessionStorage.removeItem(PKCE_STATE_KEY)
				sessionStorage.removeItem(CODE_VERIFIER_KEY)
				state.isLoggedOut = false
			})
			.addCase(fetchAccessToken.rejected, (state) => {
				state.isLoggingIn = false
				sessionStorage.removeItem(PKCE_STATE_KEY)
				sessionStorage.removeItem(CODE_VERIFIER_KEY)
			})
	}
})

export const { startLogout, setUserLocale } = authSlice.actions

/* istanbul ignore next */
export const authorizeURL = (state) => state.auth.authorize_URL
/* istanbul ignore next */
export const logoutURI = (state) => state.auth.logoutURI
/* istanbul ignore next */
export const isLoggedIn = (state) => state.auth.isLoggedIn
/* istanbul ignore next */
export const isLoggedOut = (state) => state.auth.isLoggedOut
/* istanbul ignore next */
export const user = (state) => state.auth.userInfo

export default authSlice.reducer
