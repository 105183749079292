const BUILDING_ALIAS = "building"
const PARCEL_ALIAS = "parcel"

export const isParcel = (geometryCategory) =>
	geometryCategory?.toLowerCase() === PARCEL_ALIAS
export const isBuilding = (geometryCategory) =>
	geometryCategory?.toLowerCase() === BUILDING_ALIAS

export const buildingPredicate = (feature) =>
	isBuilding(
		JSON.parse(JSON.stringify(feature))?.properties?.geometry_category
	)
export const parcelPredicate = (feature) =>
	isParcel(JSON.parse(JSON.stringify(feature))?.properties?.geometry_category)
