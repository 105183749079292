import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "antd"

import { geocodingIsSubmittingBulkFile as geocodingIsSubmittingBulkFileSlice } from "features/footprintSlice"
import { fetchJobsList } from "features/jobsSlice"
import "styles/pages.css"

const JobsHandlerPage = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const isSubmittingBulkFile = useSelector(geocodingIsSubmittingBulkFileSlice)

	return (
		<div className="page_refreshActionsContainer">
			<img
				src="img/eclipse-spinner-animated.svg"
				className="refreshActions_icon"
				alt=""
			/>
			<div className="refreshActions_disclaimer">
				<p className="eclipse-spinner-animated">
					We are processing the locations contained in your file. Keep
					refreshing the status until your file is shown as ready to
					review.
				</p>
				<div className="ballpark__container">
					<svg
						className="ballpark__icon"
						width="17"
						height="16"
						viewBox="0 0 17 16"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M8.244 0a7.988 7.988 0 0 1 8 8 8 8 0 1 1-8-8zm0 1.607a6.393 6.393 0 1 0 0 12.786 6.393 6.393 0 0 0 0-12.786zm.396 2.399v4.198l3.598 2.135-.6.983-4.197-2.518V4.006h1.2z"
							fill="#00008F"
							fillRule="evenodd"
						/>
					</svg>

					<p className="ballpark-description">
						{t(
							"It usually takes from 20 to 70 seconds for every 100 locations"
						)}
					</p>
				</div>
			</div>
			<div className="refreshActions_cta">
				<Button
					className="axa-button"
					disabled={isSubmittingBulkFile}
					type="primary"
					size="large"
					onClick={(e) => {
						dispatch(fetchJobsList())
						e.currentTarget.blur()
					}}
				>
					{t("refresh the job status")}
				</Button>
			</div>
		</div>
	)
}

export default JobsHandlerPage
