/**
 *
 * For a given predicate (filter function) returns an array of unique items
 *
 * @param {function} 		filter function
 * @param {array} 			input array
 *
 * @returns {string} 		array of unique items
 */
export const SelectGenerator = (inputList, predicate) => {
	if (!predicate || typeof predicate !== "function") return []
	let results = []
	results = [...new Set(inputList.map(predicate))].filter((i) => i !== null)
	return results
}

/**
 *
 * For a given predicate (filter function) returns an array of unique items with counter
 *
 * @param {function} 		filter function
 * @param {array} 			input array
 *
 * @returns {string} 		array of unique items
 */
export const SelectGeneratorWithCounter = (inputList, predicate) => {
	if (!predicate || typeof predicate !== "function") return {}
	return inputList
		.map(predicate)
		.filter((i) => i !== null)
		.reduce((acc, curr) => {
			const copyAcc = acc
			if (copyAcc[curr]) {
				copyAcc[curr] += 1
			} else {
				copyAcc[curr] = 1
			}
			return acc
		}, {})
}
