import { isEmpty, isEqual, xorWith } from "lodash"

/**
 *
 * Check whether the input is a valid JSON String
 *
 * @param {string} str
 *
 * @returns {Boolean} Boolean
 */
export const isJSONString = (str) => {
	try {
		return typeof JSON.parse(str) === "object"
	} catch (e) {
		return false
	}
}

/**
 *
 * Compares two arrays equality
 *
 * @param {Array} array1
 * @param {Array} array2
 *
 * @returns {Boolean} Boolean
 */
export const isArrayEqual = (array1, array2) =>
	isEmpty(xorWith(array1, array2, isEqual))

/**
 *
 * Test whether a given input string is valid coordinates
 * Source: https://stackoverflow.com/a/38302732/3900714
 *
 * @param {String} coords following the intl. format latitude, longitude
 *
 * @returns {Boolean} Boolean
 */
export const isValidCoordinates = (coords) => {
	const args = coords.split(/,\s+/)

	const lat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/
	const lon = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/

	return (
		lat.test(args[0].trim()) === true && lon.test(args[1].trim()) === true
	)
}
