import React, { useState, useCallback, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import { Marker } from "react-map-gl"

import InfoTooltipPin from "pages/map/components/InfoTooltipPin"
import Pin from "pages/map/components/pin"
import { isEmpty } from "lodash"
import {
	marker as markerSlice,
	setMarker,
	setMarkerMoving,
	setOnMarkerMoveCoords,
	unselectedMarkers as unselectedMarkersSlice,
	selectedMarker as selectedMarkerSlice,
	displayClientCoordinatesPin as displayClientCoordinatesPinSlice,
	setIsClickingUnselected
} from "features/mapSlice"

import {
	setHasUserManuallyEditGeocode,
	setFootprintIsPristine
} from "features/footprintSlice"

import {
	selectedResults as selectedResultsSlice,
	currentLocationIndex as currentLocationIndexSlice
} from "features/jobsResultsSlice"

import { selectUnselectedPin } from "pages/map/utils/unselectedPointsOfInterest"
import {
	onClickMarkerWithoutPoi,
	onClickMarker
} from "pages/map/utils/handleMarker"

const SelectedPin = ({ onMoveCoord }) => {
	const marker = useSelector(markerSlice)
	const dispatch = useDispatch()
	const unselectedMarkers = useSelector(unselectedMarkersSlice)
	const selectedMarker = useSelector(selectedMarkerSlice)
	const selectedResults = useSelector(selectedResultsSlice)
	const currentLocationIndex = useSelector(currentLocationIndexSlice)
	const displayClientCoordinatesPin = useSelector(
		displayClientCoordinatesPinSlice
	)
	const [hoverTooltip, setHoverTooltip] = useState()
	const [source, setSource] = useState("")
	const onClick = selectedMarker?.poi
		? onClickMarker
		: onClickMarkerWithoutPoi

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onDragStart = useCallback(() => {
		dispatch(setMarkerMoving(true))
		dispatch(setFootprintIsPristine(false))
	})

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onMarkerDragEnd = useCallback((event) => {
		dispatch(setMarker({ lon: event.lngLat.lng, lat: event.lngLat.lat }))
		setTimeout(() => {
			dispatch(setOnMarkerMoveCoords({}))
			onMoveCoord({
				coords: `${event.lngLat.lat}, ${event.lngLat.lng}`
			})
		}, 1000)
		dispatch(setMarkerMoving(false))
		dispatch(setHasUserManuallyEditGeocode(true))
	})

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onMarkerDragMoves = useCallback((event) => {
		setHoverTooltip(null)
		dispatch(
			setOnMarkerMoveCoords({
				lon: event.lngLat.lng,
				lat: event.lngLat.lat
			})
		)
		dispatch(setMarker({ lon: event.lngLat.lng, lat: event.lngLat.lat }))
	})
	useEffect(() => {
		setSource(
			selectedMarker?.source?.toLowerCase() === "foursquareplace"
				? "Foursquare"
				: selectedMarker?.source ?? undefined
		)
	}, [selectedMarker, source])

	return (
		<div>
			{!isEmpty(selectedMarker) && hoverTooltip ? (
				<InfoTooltipPin
					type={hoverTooltip.type}
					position={{
						x: hoverTooltip.x,
						y: hoverTooltip.y
					}}
					clickedPin={JSON.parse(
						JSON.stringify(hoverTooltip.clickedPin)
					)}
					handleChangePin={() => {
						selectUnselectedPin(
							hoverTooltip.clickedPin,
							dispatch,
							selectedMarker,
							unselectedMarkers
						)
						setHoverTooltip(null)
					}}
					closeInfoTooltipHandler={() => setHoverTooltip(null)}
					isSelectedPin
				/>
			) : null}
			<Marker
				data-testid="pin-testid"
				longitude={marker.lon ?? 0.0}
				latitude={marker.lat ?? 0.0}
				anchor="bottom"
				className="marker__container"
				draggable
				onDragStart={onDragStart}
				onDragEnd={onMarkerDragEnd}
				onDrag={onMarkerDragMoves}
				onClick={(event) => {
					if (!isEmpty(selectedMarker)) {
						dispatch(setIsClickingUnselected(true))
						onClick(event, selectedMarker, setHoverTooltip)
					}
				}}
				style={{ zIndex: 10 }}
			>
				<Pin
					size={34}
					label={
						selectedResults[currentLocationIndex] &&
						displayClientCoordinatesPin
							? "ALF"
							: source?.toUpperCase() ?? undefined
					}
				/>
			</Marker>
		</div>
	)
}
SelectedPin.propTypes = {
	onMoveCoord: PropTypes.func.isRequired
}

export default SelectedPin
