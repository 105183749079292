import { initReactI18next } from "react-i18next"
import i18n from "i18next"
import common from "i18n/common.json"
import errors from "i18n/errors.json"

const resources = {
	en: {
		common,
		errors
	}
}

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		defaultNS: "common",
		// we do not use keys in form messages.welcome
		interpolation: {
			escapeValue: false // react already safes from xss
		},
		keySeparator: false,
		lng: "en",
		ns: ["common", "errors"],
		resources
	})

export default i18n
