/* eslint-disable no-shadow */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { isEmpty } from "lodash"
import { circle } from "@turf/turf"
import LocusEndpointURLBuilder from "helpers/LocusEndpointURLBuilder"
import MultiPolygonToPolygons from "helpers/MultipolygonToPolygons"
import { generateHeadersWithAuthorization } from "helpers/Auth"
import { ALERTS_TYPE } from "helpers/constants"
import { getCountryNameByLocale } from "helpers/countriesHandler"
import { enableLoadMoreParcelsForSupportedCountries } from "helpers/rulesHandler"
import {
	buildingPredicate,
	isBuilding,
	parcelPredicate
} from "helpers/polygonFootprintHelper"

const LOCUS_BULK_API_ENDPOINT = process.env.REACT_APP_LOCUS_BULK_API_ENDPOINT
const LOCUS_JOBS_ENDPOINT = process.env.REACT_APP_LOCUS_JOBS_ENDPOINT
const LOCUS_LOCATIONS_FOOTPRINT_ENDPOINT =
	process.env.REACT_APP_LOCUS_LOCATIONS_FOOTPRINT_ENDPOINT
const LOCUS_RESULTS_ENDPOINT = process.env.REACT_APP_LOCUS_RESULTS_ENDPOINT
const LOCUS_LOCATIONS_MANAGER = process.env.REACT_APP_LOCUS_LOCATIONS_MANAGER
const SLICE_PREFIX = "footprint"

export const FOOTPRINT_MODE = {
	BUILDINGS: "buildings",
	OSM_BUILDINGS: "buildings_around_point",
	BUILDING: "forward_footprint_building",
	GEOCODE: "forward_footprint_geocode",
	SITE: "forward_footprint_site",
	FORWARD: "forward",
	REVERSE_NO_FOOTPRINTS: "reverse_no_footprints",
	REVERSE: "reverse",
	FOOTPRINT_BY_GEOCODE_DISPLAY_ONLY: "footprint_by_geocode"
}

export const INIT_STATE = {
	form: {
		bulkFile: [],
		account_name: "",
		account_reference: ""
	},
	geocodedDataRaw: [],
	countyCodeISO3: "",
	isFetching: false,
	isSubmittingBulkFile: false,
	isSubmittingFootprintChecker: false,
	isPostingResultEdit: false,
	isGeocoded: false,
	parcelToTrace: {
		type: "FeatureCollection",
		features: []
	},
	clickedParcelId: "",
	isBulkSubDrawerVisible: false,
	editedResultBuffer: undefined,
	toClearForm: false,
	isLoadingAdditionalParcels: false,
	isLoadingAdditionalBuildings: false,
	showLoadMoreBuildingsCta: false,
	showLoadMoreParcelsCta: false,
	showLoadingModalForFootprintModeSwitch: false,
	hasUserManuallyEditGeocode: false,
	footprintIsPristine: true
}

const removeDuplicates = (inputArray) =>
	Array.from(new Set(inputArray.map(JSON.stringify))).map(JSON.parse)

export const addingNewParcelsHandler = (oldFeatures, newFeatures) => {
	if (newFeatures && Array.isArray(newFeatures) && newFeatures.length > 0) {
		/* Handling new parcels to be add */
		const _newParcels = newFeatures.filter(parcelPredicate) // get only parcels
		const oldParcelsIds = removeDuplicates(
			oldFeatures
				.filter(parcelPredicate) // get only parcels
				.map((parcel) => parcel.properties.parcelid) // get an array of parcel ids
		)
		const parcelsIdsToBeAdd = _newParcels
			.map((parcel) => parcel.properties.parcelid) // get an array of parcel ids
			.filter((parcel) => !oldParcelsIds.includes(parcel))
		let parcelsToBeAdd = _newParcels.filter((parcel) =>
			parcelsIdsToBeAdd.includes(parcel.properties.parcelid)
		)
		// Handle MultiPolygon use-case
		parcelsToBeAdd = MultiPolygonToPolygons(
			JSON.parse(JSON.stringify(parcelsToBeAdd))
		)
		return [...oldFeatures, ...parcelsToBeAdd]
	}
	return oldFeatures
}

export const addBuildingsAndParcels = (
	oldFeatures,
	newBuildings,
	newParcels,
	isLoadingMoreBuildings
) => {
	let _features = [...oldFeatures]
	const _newBuildings = newBuildings ? [...newBuildings] : []

	const oldBuildingsIds = removeDuplicates(
		oldFeatures
			.filter(buildingPredicate) // get only buidings
			.map((building) => building.properties.guid) // get an array of buildings ids
	)
	const buildingsIdsToBeAdd = _newBuildings
		.map((building) => building.properties.guid) // get an array of buildings ids
		.filter((building) => !oldBuildingsIds.includes(building))
	let buildingsToBeAdd = _newBuildings.filter((building) =>
		buildingsIdsToBeAdd.includes(building.properties.guid)
	)
	buildingsToBeAdd = buildingsToBeAdd.map((building) => ({
		...building,
		properties: {
			...building.properties,
			selected:
				building.properties.selected || isLoadingMoreBuildings
					? building.properties.selected
					: newParcels.map((parcel) => parcel.properties.parcelid),
			highlight: true
		}
	}))
	buildingsToBeAdd = MultiPolygonToPolygons(buildingsToBeAdd)
	const parcelsToBeAdd = addingNewParcelsHandler(
		oldFeatures.filter(parcelPredicate), // get old parcels
		newParcels
	)
	// Merge parcels and highligthed buildings
	_features = _features
		.concat(buildingsToBeAdd, parcelsToBeAdd)
		.filter((x) => x != null)
	return _features
}

/**
 *
 * Unhighlight a building for a given parcelid
 *
 * @param {array}   features     	an array of featues (parcels & buildings)
 * @param {string}  buildingGUID     ID of the building to be removed
 *
 * @returns {array}              features: all parcels & buildings to be traced
 */
export const unhighlightBuildingHandler = (features, buildingGUID) => {
	if (isEmpty(features)) return []

	let _features = [...JSON.parse(JSON.stringify(features))]
	if (buildingGUID && typeof buildingGUID === "string") {
		_features = _features
			.map((feature) => {
				if (feature.properties.guid === buildingGUID) {
					const newFeature = feature
					newFeature.properties.selected = false
					return newFeature
				}
				return feature
			})
			.filter((b) => b !== null)
		return _features
	}
	return features
}

/**
 *
 * Highlight a building for a given parcelid
 *
 * @param {array}   features     	an array of featues (parcels & buildings)
 * @param {string}  buildingGUID     ID of the building to be removed
 *
 * @returns {array}              features: all parcels & buildings to be traced
 */
export const highlightBuildingHandler = (features, buildingGUID) => {
	if (isEmpty(features)) return []

	let _features = [...JSON.parse(JSON.stringify(features))]
	if (buildingGUID && typeof buildingGUID === "string") {
		_features = _features
			.map((feature) => {
				if (feature.properties.guid === buildingGUID) {
					const newFeature = feature
					newFeature.properties.selected = true
					return newFeature
				}
				return feature
			})
			.filter((b) => b !== null)
		return _features
	}
	return features
}

/**
 *
 * Finds a parcel by parcelid and sets the selected property
 *
 * @param {array} features     an array of featues (parcels & buildings)
 * @param {string} parcelid     ID of the parcel to be highlighted
 *
 * @returns {array}            features: all parcels to be traced
 */
export const setParcelSelection = (features, parcelid) => {
	if (isEmpty(features)) return []
	let _features = [...JSON.parse(JSON.stringify(features))]
	if (parcelid && typeof parcelid === "string") {
		// If a building has parcelid in its linked_parcels
		// Remove it from the features
		// Don't touch other linked_parcels
		_features = _features
			.map((feature) => {
				if (isBuilding(feature?.properties?.geometry_category)) {
					if (
						feature.properties?.linked_parcels?.includes(parcelid)
					) {
						return null
					}
					return feature
				}
				return feature
			})
			.filter((building) => building !== null)

		_features = _features.map((feature) => {
			if (feature.properties.parcelid === parcelid) {
				return {
					...feature,
					properties: {
						...feature.properties,
						selected: !feature.properties.selected
					}
				}
			}
			return feature
		})
		return _features
	}
	return features
}

/**
 *
 * Add new buildings features and highlights associated parcels
 *
 * @param {array}   prevFeatures        Original features
 * @param {array}   newFeatures         New features to be added
 * @param {string}  clickedParcelId     Clicked Parcel ID --- Workaround
 *
 * @returns {array} features: all parcels to be traced
 */
export const addNewBuildingFootprintHandler = (
	prevFeatures,
	newFeatures,
	clickedParcelId
) => {
	if (newFeatures && Array.isArray(newFeatures)) {
		const existingBuildingsGuids = prevFeatures
			.map((feature) => {
				if (isBuilding(feature?.properties?.geometry_category)) {
					return feature.properties.guid
				}
				return null
			})
			.filter((guid) => guid !== null)

		const newBuildings = [...newFeatures]
		const newItemsToAdd = newBuildings
			.map((building) => {
				// if the building already exists extract the linked_parcels
				if (existingBuildingsGuids.includes(building.properties.guid)) {
					return building.properties.linked_parcels
				}
				return building
			})
			.filter((item) => item)

		const allNewLinkedParcelsId = newItemsToAdd
			.filter((b) => Array.isArray(b))
			.flat()
		const allOldLinkedParcelsId = prevFeatures
			.filter(buildingPredicate)
			.map((building) => building.properties.selected)
			.flat()
		let parcelsToBeSelected = allOldLinkedParcelsId
			.filter((x) => !allNewLinkedParcelsId.includes(x))
			.concat(
				allNewLinkedParcelsId.filter(
					(x) => !allOldLinkedParcelsId.includes(x)
				)
			)

		if (!parcelsToBeSelected.includes(clickedParcelId))
			parcelsToBeSelected.push(clickedParcelId)

		parcelsToBeSelected = [...new Set(parcelsToBeSelected)].filter(
			(parcel) => parcel !== undefined
		)

		let newBuildingsToAdd = newItemsToAdd.filter((b) => !Array.isArray(b))

		let _features = [...JSON.parse(JSON.stringify(prevFeatures))]

		if (newBuildings.length > 0) {
			// Highlight new buildings
			newBuildingsToAdd = newBuildingsToAdd.map((building) => ({
				...building,
				properties: {
					...building.properties,
					highlight: true
				}
			}))
			newBuildingsToAdd = addBuildingsAndParcels(
				_features.filter((feature) =>
					newBuildingsToAdd
						.map((b) => b.properties.guid)
						.includes(feature.properties.guid)
				), // trick addBuildingsAndParcels to use only "Buildings" features
				newBuildingsToAdd,
				[
					{
						geometry: {},
						properties: {
							parcelid: clickedParcelId
						}
					}
				] // trick addBuildingsAndParcels to use a dummy newParcels which has the same parcelid has the clicked one preventing it to be addeded as well.
			).filter(buildingPredicate)
			newBuildingsToAdd = removeDuplicates(newBuildingsToAdd)
		}
		_features = MultiPolygonToPolygons(_features)

		_features = _features.map((feature) => {
			if (parcelsToBeSelected.includes(feature.properties.parcelid)) {
				return {
					...feature,
					properties: {
						...feature.properties,
						selected: true
					}
				}
			}
			return feature
		})
		_features = _features
			.concat(newBuildingsToAdd)
			.filter((f) => f !== null)

		return _features
	}
	return MultiPolygonToPolygons(prevFeatures)
}

/**
 *
 * Add single location to footprint state
 *
 * @param {object} state
 * @param {object} data
 *
 */
export const addHocResultHandler = (features, data) => {
	// Remove all object references
	const geoData = JSON.parse(JSON.stringify(data))
	const _geocodedDataRaw = [geoData].filter(
		(value) => Object.keys(value).length !== 0
	)
	let _features = [...features]
	let _buildings
	let _parcels = []
	if ("buildings" in geoData) {
		_buildings = [...JSON.parse(JSON.stringify(geoData.buildings))]
		_buildings = MultiPolygonToPolygons(_buildings)
		_buildings.map((building) => {
			const _building = { ...building }
			_building.properties.highlight = true
			return _building
		})
	}
	if ("parcels" in geoData) {
		_parcels = [...JSON.parse(JSON.stringify(geoData.parcels))]
		_parcels = MultiPolygonToPolygons(_parcels)
	}
	_features = _features.concat(_buildings, _parcels).filter((x) => x != null) // Removes empty feature
	_features = Array.from(new Set(_features.map(JSON.stringify))).map(
		JSON.parse
	)
	return {
		features: _features,
		geocodedDataRaw: _geocodedDataRaw,
		countyCodeISO3: geoData?.address?.country ?? ""
	}
}

/**
 *
 * Remove circle hint after loading more parcels
 *
 * @param {array}   features        Original features
 *
 */
export const removeCircleTargetHandler = (features) => {
	const _features = JSON.parse(JSON.stringify(features))
	return _features.filter((f) => f.properties.id !== "circle-target")
}

// POST to upload file endpoint @/upload_file
export const postBulkService = createAsyncThunk(
	`${SLICE_PREFIX}/postBulkService`,
	async (payload, { getState, fulfillWithValue, rejectWithValue }) => {
		if (!payload.bulkFile) {
			return rejectWithValue({
				code: "UPF_E001",
				description: "Upload file not found",
				details: []
			})
		}
		if (!payload.account_name) {
			return rejectWithValue({
				code: "UPF_E002",
				description: "'account_name' param not found",
				details: []
			})
		}
		if (!payload.account_reference) {
			return rejectWithValue({
				code: "UPF_E004",
				description: "'account_reference' param not found",
				details: []
			})
		}
		const formdata = new FormData()
		formdata.append(
			"file",
			payload.bulkFile.originFileObj,
			payload.bulkFile.originFileObj.name
		)
		formdata.append("account_name", payload.account_name)
		formdata.append("account_reference", payload.account_reference)

		const genericHeaders = generateHeadersWithAuthorization(getState())
		// For form-data request content-type has NOT to be set
		// https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
		delete genericHeaders["content-type"]

		const settings = {
			method: "POST",
			body: formdata,
			headers: genericHeaders
		}

		try {
			const response = await fetch(
				LocusEndpointURLBuilder(
					`${LOCUS_JOBS_ENDPOINT}/${LOCUS_BULK_API_ENDPOINT}`
				),
				settings
			)
			if (response.status === 200 || response.status === 201) {
				return fulfillWithValue(await response.json(), {
					status: response.status
				})
			}
			throw response
		} catch (error) {
			return rejectWithValue(await error.json(), { status: error.status })
		}
	}
)
// POST to request new footprint for a given mode @/footprint
export const postChangeFootprintMode = createAsyncThunk(
	`${SLICE_PREFIX}/changeFootprintMode`,
	async (values, { getState, fulfillWithValue, rejectWithValue }) => {
		if (!values) {
			return rejectWithValue({
				code: "BLD_E101",
				description: "All query parameters are missing",
				details: []
			})
		}
		if (
			!values.rawEMPAttributes &&
			values.action !== "buildings_around_point"
		) {
			return rejectWithValue({
				code: "BLD_E102",
				description: "RawEMPAttributes parameter is missing",
				details: []
			})
		}

		const { LATITUDE, LONGITUDE, ...rawEmpAttributesWithoutCoordinates } =
			values.rawEMPAttributes

		let body = {
			isTester: false,
			webui: true,
			geojson: false,
			input_data: {
				raw_emp_attributes: values?.savePanelMode
					? rawEmpAttributesWithoutCoordinates
					: values.rawEMPAttributes
			}
		}
		let currentMode = ""
		if (getState().jobsResults.footprintMode === "osm_building") {
			currentMode = "forward_footprint_building"
		} else if (getState().jobsResults.footprintMode === "osm_site") {
			currentMode = "forward_footprint_site"
		} else {
			currentMode = `forward_footprint_${
				getState().jobsResults.footprintMode
			}`
		}
		if (
			!values.triggerRetryMode &&
			values.action !== "buildings_around_point"
		) {
			body = {
				...body,
				mode: currentMode,
				// TODO Better sync between components (back/front/data) is needed to avoid prefixing on-the-fly
				geo_data: {
					backend_helper_mode:
						getState().geocoder.geocodedDataRaw[0]
							.backend_helper_mode,
					lat: getState().geocoder.geocodedDataRaw[0].lat,
					lon: getState().geocoder.geocodedDataRaw[0].lon,
					address: getState().geocoder.geocodedDataRaw[0].address,
					confidence:
						getState().geocoder.geocodedDataRaw[0].confidence,
					footprinter_confidence:
						getState().geocoder.geocodedDataRaw[0]
							.footprinter_confidence,
					footprinter_confidence_reasons:
						getState().geocoder.geocodedDataRaw[0]
							.footprinter_confidence_reasons,
					processing_mode:
						getState().geocoder.geocodedDataRaw[0].processing_mode,
					processing_mode_reason_key:
						getState().geocoder.geocodedDataRaw[0]
							.processing_mode_reason_key,
					processing_mode_reason_text:
						getState().geocoder.geocodedDataRaw[0]
							.processing_mode_reason_text,
					resolution:
						getState().geocoder.geocodedDataRaw[0].resolution,
					source: getState().geocoder.geocodedDataRaw[0].source,
					service: getState().geocoder.geocodedDataRaw[0].service,
					available_processing_modes:
						getState().geocoder.geocodedDataRaw[0]
							.available_processing_modes,
					...(getState().jobsResults.footprintMode === "osm_building"
						? {
								geometry: {
									type: "Point",
									coordinates: [
										getState().geocoder.geocodedDataRaw[0]
											.lat,
										getState().geocoder.geocodedDataRaw[0]
											.lon
									]
								}
						  }
						: {})
				}
			}
		} else if (values.action === "buildings_around_point") {
			body = {
				...body,
				mode: "buildings_around_point",
				max_buildings: 100,
				radius: 200,
				country: getState().geocoder.geocodedDataRaw[0].address.country,
				geo_data: {
					geometry: {
						type: "Point",
						coordinates: [values.lat, values.lon]
					}
				}
			}
		} else {
			body = {
				...body,
				mode: "forward",
				city: getState().jobsResults.selectedResults[
					getState().jobsResults.currentLocationIndex
				].input_data.city,
				street: getState().jobsResults.selectedResults[
					getState().jobsResults.currentLocationIndex
				].input_data.street_name,
				country:
					getState().jobsResults.selectedResults[
						getState().jobsResults.currentLocationIndex
					].input_data.country_code,
				postalCode:
					getState().jobsResults.selectedResults[
						getState().jobsResults.currentLocationIndex
					].input_data.postal_code,
				state: getState().jobsResults.selectedResults[
					getState().jobsResults.currentLocationIndex
				].input_data.state
			}
		}

		const settings = {
			method: "POST",
			body: JSON.stringify(body),
			headers: generateHeadersWithAuthorization(getState())
		}

		try {
			const response = await fetch(
				LocusEndpointURLBuilder(
					`${LOCUS_LOCATIONS_MANAGER}/${LOCUS_LOCATIONS_FOOTPRINT_ENDPOINT}`
				),
				settings
			)
			if (response.status === 200 || response.status === 201) {
				return fulfillWithValue(await response.json(), {
					status: response.status
				})
			}
			throw response
		} catch (error) {
			return rejectWithValue(await error.json(), { status: error.status })
		}
	}
)

// POST to add a parcel to an existing footprint @/footprint
export const postFootprintChecker = createAsyncThunk(
	`${SLICE_PREFIX}/footprintChecker`,
	async (values, { getState, fulfillWithValue, rejectWithValue }) => {
		let body = {
			isTester: false,
			webui: true,
			mode: FOOTPRINT_MODE.BUILDINGS,
			geojson: false
		}

		if (!values) {
			return rejectWithValue({
				code: "BLD_E001",
				description: "All query parameters are missing",
				details: []
			})
		}
		if (!values.geo_data) {
			return rejectWithValue({
				code: "BLD_E002",
				description: "Parcel parameter is missing",
				details: []
			})
		}
		if (!values.countyCodeISO3) {
			return rejectWithValue({
				code: "BLD_E003",
				description: "Country parameter is missing",
				details: []
			})
		}
		if (!values.parcelid) {
			return rejectWithValue({
				code: "BLD_E004",
				description: "parcelid parameter is missing",
				details: []
			})
		}

		body = {
			...body,
			geo_data: {
				geometry: values.geo_data,
				properties: {
					parcel_id: values.parcelid
				}
			},
			country: values.countyCodeISO3
		}

		const settings = {
			method: "POST",
			body: JSON.stringify(body),
			headers: generateHeadersWithAuthorization(getState())
		}

		try {
			const response = await fetch(
				LocusEndpointURLBuilder(
					`${LOCUS_LOCATIONS_MANAGER}/${LOCUS_LOCATIONS_FOOTPRINT_ENDPOINT}`
				),
				settings
			)
			if (response.status === 200 || response.status === 201) {
				return fulfillWithValue(await response.json(), {
					status: response.status
				})
			}
			throw response
		} catch (error) {
			return rejectWithValue(await error.json(), { status: error.status })
		}
	}
)

// PUT to save current footprint @/results
export const postEditResult = createAsyncThunk(
	`${SLICE_PREFIX}/postEditResult`,
	async (values, { getState, fulfillWithValue, rejectWithValue }) => {
		if (!values) {
			return rejectWithValue({
				code: "UFP_E001",
				description: "All query parameters are missing",
				details: []
			})
		}
		if (!values.parcels) {
			return rejectWithValue({
				code: "UFP_E002",
				description: "Parcel parameter is missing",
				details: []
			})
		}
		if (!values.coords) {
			return rejectWithValue({
				code: "UFP_E003",
				description: "Coordinates parameter is missing",
				details: []
			})
		}
		if (!values.id) {
			return rejectWithValue({
				code: "UFP_E004",
				description: "id parameter is missing",
				details: []
			})
		}
		if (!values.buildings) {
			return rejectWithValue({
				code: "UFP_E005",
				description: "Building parameter is missing",
				details: []
			})
		}
		if (!values.feedback) {
			return rejectWithValue({
				code: "UFP_E006",
				description: "feedback parameter is missing",
				details: []
			})
		}
		const currentLocation = getState().jobsResults.selectedResults.find(
			(location) =>
				location.id === getState().jobsResults.selectedResultId
		)
		const body = {
			id: values.id,
			validation_status: values.feedback,
			webui: true,
			geo_data: {
				buildings: [
					...new Set(
						values.buildings.map((f) => {
							const _f = JSON.parse(JSON.stringify(f))
							delete _f.properties.highlight
							delete _f.properties.isHovered
							return JSON.stringify(_f)
						})
					)
				].map((f) => JSON.parse(f)),
				lat: values.coords.lat,
				lon: values.coords.lon,
				parcels: [
					...new Set(
						values.parcels.map((f) => {
							const _f = JSON.parse(JSON.stringify(f))
							delete _f.properties.isHovered
							return JSON.stringify(_f)
						})
					)
				].map((f) => JSON.parse(f)),
				processing_mode: getState().jobsResults.footprintMode,
				processing_mode_reason_text:
					getState().jobsResults.footprintModeDescription,
				processing_mode_reason_key:
					getState().jobsResults.footprintModeReasonKey,
				confidence: currentLocation.geo_data.confidence,
				resolution: currentLocation.geo_data.resolution,
				service: currentLocation.geo_data.service,
				source: getState().geocoder.hasUserManuallyEditGeocode
					? "Manual"
					: currentLocation.geo_data.source,
				address: getState().geocoder.geocodedDataRaw[0].address,
				footprinter_confidence:
					getState().geocoder.geocodedDataRaw[0]
						.footprinter_confidence,
				footprinter_confidence_reasons:
					getState().geocoder.geocodedDataRaw[0]
						.footprinter_confidence_reasons,
				available_processing_modes:
					getState().geocoder.geocodedDataRaw[0]
						.available_processing_modes,
				backend_helper_mode:
					getState().geocoder.geocodedDataRaw[0].backend_helper_mode
			}
		}
		const settings = {
			method: "PUT",
			body: JSON.stringify(body),
			headers: generateHeadersWithAuthorization(getState())
		}

		try {
			const response = await fetch(
				LocusEndpointURLBuilder(LOCUS_RESULTS_ENDPOINT),
				settings
			)
			if (response.status === 200 || response.status === 201) {
				return fulfillWithValue(await response.json(), {
					status: response.status
				})
			}
			throw response
		} catch (error) {
			return rejectWithValue(await error.json(), { status: error.status })
		}
	}
)

// PATCH to edit footprint address @/results
export const patchAddressResult = createAsyncThunk(
	`${SLICE_PREFIX}/patchAddressResult`,
	async (values, { getState, fulfillWithValue, rejectWithValue }) => {
		if (!values) {
			return rejectWithValue({
				code: "UFP_E001",
				description: "All query parameters are missing",
				details: []
			})
		}
		if (!values.addressToUpdate) {
			return rejectWithValue({
				code: "UFP_E006",
				description: "addressToUpdate parameter is missing",
				details: []
			})
		}
		const body = {
			id: getState().jobsResults.selectedResultId,
			input_data: {
				street_name: values.addressToUpdate.streetAddress,
				district: "",
				city: values.addressToUpdate.city,
				postal_code: values.addressToUpdate.pstlCode,
				county: values.addressToUpdate.county,
				state: values.addressToUpdate.stateOrProvince,
				state_code: values.addressToUpdate.stateCode,
				country: getCountryNameByLocale(
					"en",
					values.addressToUpdate.country.label
				),
				country_code: values.addressToUpdate.country
			}
		}
		const settings = {
			method: "PATCH",
			body: JSON.stringify(body),
			headers: generateHeadersWithAuthorization(getState())
		}

		try {
			const response = await fetch(
				LocusEndpointURLBuilder(LOCUS_RESULTS_ENDPOINT),
				settings
			)
			if (response.status === 200) {
				return fulfillWithValue(await response.json(), {
					status: response.status
				})
			}
			throw response
		} catch (error) {
			return rejectWithValue(await error.json(), { status: error.status })
		}
	}
)

// GET additional parcels around coordinates @/footprint
export const getAdditionalParcels = createAsyncThunk(
	`${SLICE_PREFIX}/additionalParcels`,
	async (values, { getState, fulfillWithValue, rejectWithValue }) => {
		const settings = {
			method: "GET",
			headers: generateHeadersWithAuthorization(getState())
		}

		const url = new URL(
			LocusEndpointURLBuilder(
				`${LOCUS_LOCATIONS_MANAGER}/${LOCUS_LOCATIONS_FOOTPRINT_ENDPOINT}`
			)
		)

		if (!values) {
			return rejectWithValue({
				code: "PCL_E001",
				description: "All query parameters are missing",
				details: []
			})
		}
		if (!values.country) {
			return rejectWithValue({
				code: "PCL_E002",
				description: "Country parameter is missing",
				details: []
			})
		}
		if (!values.lat) {
			return rejectWithValue({
				code: "PCL_E003",
				description: "Latitude parameter is missing",
				details: []
			})
		}
		if (!values.lon) {
			return rejectWithValue({
				code: "PCL_E004",
				description: "Longitude parameter is missing",
				details: []
			})
		}

		const params = {
			...values,
			webui: true
		}
		Object.keys(params).forEach((key) =>
			url.searchParams.append(key, params[key])
		)

		try {
			const response = await fetch(url, settings)
			if (response.status === 200 || response.status === 201) {
				return fulfillWithValue(await response.json(), {
					status: response.status
				})
			}
			if (response.status === 204) {
				return rejectWithValue(
					{
						code: "PCL_E204",
						type: ALERTS_TYPE.INFO,
						isMapAlert: true,
						description:
							"No parcel data could be retrieved for this area from our data source",
						details: []
					},
					{ status: response.status }
				)
			}
			throw response
		} catch (error) {
			return rejectWithValue(await error.json(), { status: error.status })
		}
	}
)

// POST to request a footprint for given coordinates @/footprint
export const getFootprintByCoordinates = createAsyncThunk(
	`${SLICE_PREFIX}/getFootprintByCoordinates`,
	async (values, { getState, fulfillWithValue, rejectWithValue }) => {
		const url = new URL(
			LocusEndpointURLBuilder(
				`${LOCUS_LOCATIONS_MANAGER}/${LOCUS_LOCATIONS_FOOTPRINT_ENDPOINT}`
			)
		)

		if (!values) {
			return rejectWithValue({
				code: "PCL_E001",
				description: "All query parameters are missing",
				details: []
			})
		}
		if (!values.lat) {
			return rejectWithValue({
				code: "PCL_E003",
				description: "Latitude parameter is missing",
				details: []
			})
		}
		if (!values.lon) {
			return rejectWithValue({
				code: "PCL_E004",
				description: "Longitude parameter is missing",
				details: []
			})
		}

		const body = {
			point: {
				lat: values.lat,
				lon: values.lon
			},
			mode: "reverse",
			webui: true,
			geojson: false,
			input_data: {
				raw_emp_attributes:
					getState().jobsResults.selectedResults[
						getState().jobsResults.currentLocationIndex
					].input_data.raw_emp_attributes
			}
		}

		const settings = {
			method: "POST",
			body: JSON.stringify(body),
			headers: generateHeadersWithAuthorization(getState())
		}

		try {
			const response = await fetch(url, settings)
			if (response.status === 200 || response.status === 201) {
				return fulfillWithValue(await response.json(), {
					status: response.status
				})
			}
			if (response.status === 204) {
				return rejectWithValue(
					{
						code: "PCL_E204",
						type: ALERTS_TYPE.INFO,
						isMapAlert: true,
						description:
							"No parcel data could be retrieved for this area from our data source",
						details: []
					},
					{ status: response.status }
				)
			}
			throw response
		} catch (error) {
			return rejectWithValue(await error.json(), { status: error.status })
		}
	}
)

export const footprintSlice = createSlice({
	name: SLICE_PREFIX,
	initialState: INIT_STATE,
	reducers: {
		clearFootprintData: (state) => ({
			...INIT_STATE,
			isBulkSubDrawerVisible: state.isBulkSubDrawerVisible,
			toClearForm: state.toClearForm
		}),
		clearBulkFile: (state) => ({
			...state,
			form: {
				...state.form,
				bulkFile: []
			}
		}),
		clearBulkMetadata: (state) => ({
			...state,
			form: {
				account_name: "",
				account_reference: ""
			}
		}),
		unhighlightBuilding: (state, action) => {
			const oldFeatures = JSON.parse(
				JSON.stringify(state.parcelToTrace.features)
			)

			const updatedBuildings = unhighlightBuildingHandler(
				oldFeatures,
				action.payload
			)

			return {
				...state,
				parcelToTrace: {
					...state.parcelToTrace,
					features: updatedBuildings
				}
			}
		},
		highlightBuilding: (state, action) => {
			const oldFeatures = JSON.parse(
				JSON.stringify(state.parcelToTrace.features)
			)

			const updatedBuildings = highlightBuildingHandler(
				oldFeatures,
				action.payload
			)
			return {
				...state,
				parcelToTrace: {
					...state.parcelToTrace,
					features: updatedBuildings
				}
			}
		},
		highlightParcel: (state, action) => {
			if (action.payload && typeof action.payload === "string") {
				const newFeatures = setParcelSelection(
					state.parcelToTrace.features,
					JSON.parse(action.payload).properties.parcelid
				)
				return {
					...state,
					parcelToTrace: {
						...state.parcelToTrace,
						features: newFeatures
					}
				}
			}
			return state
		},
		setClickedParcel: (state, action) => ({
			...state,
			clickedParcelId:
				typeof action.payload === "string"
					? action.payload
					: state.clickedParcelId
		}),
		addHocResult: (state, action) => {
			const res = addHocResultHandler(
				state.parcelToTrace.features,
				action.payload
			)
			state.geocodedDataRaw = res.geocodedDataRaw
			state.countyCodeISO3 = res.countyCodeISO3
			state.parcelToTrace = {
				...state.parcelToTrace,
				features: res.features
			}
		},
		setIsGeocoding: (state, action) => {
			if (typeof action.payload !== "boolean") {
				return {
					...state,
					isGeocoded: state.isGeocoded
				}
			}
			return {
				...state,
				isGeocoded: action.payload
			}
		},
		unHoverAllParcels: (state) => ({
			...state,
			parcelToTrace: {
				...state.parcelToTrace,
				features: state.parcelToTrace.features.map((f) => ({
					...f,
					properties: {
						...f.properties,
						isHovered: false
					}
				}))
			}
		}),
		addCircleTarget: (state, action) => {
			let _features = JSON.parse(
				JSON.stringify(state.parcelToTrace.features)
			)
			const hasCircleTarget = _features.find(
				(f) => f.properties.id === "circle-target"
			)

			if (!hasCircleTarget) {
				const circleTarget = circle(
					[action.payload.lon, action.payload.lat],
					0.5,
					{
						steps: 50,
						units: "kilometers",
						properties: {
							id: "circle-target"
						}
					}
				)
				_features = [..._features, circleTarget]
			}
			return {
				...state,
				parcelToTrace: {
					...state.parcelToTrace,
					features: _features
				}
			}
		},
		removeCircleTarget: (state) => ({
			...state,
			parcelToTrace: {
				...state.parcelToTrace,
				features: removeCircleTargetHandler(
					state.parcelToTrace.features
				)
			}
		}),
		setShowLoadMoreParcelsCta: (state, action) => ({
			...state,
			showLoadMoreParcelsCta: action.payload
		}),
		setShowLoadMoreBuildingsCta: (state, action) => ({
			...state,
			showLoadMoreBuildingsCta: action.payload
		}),
		setShowLoadingModalForFootprintModeSwitch: (state, action) => ({
			...state,
			showLoadingModalForFootprintModeSwitch: action.payload
		}),
		setHasUserManuallyEditGeocode: (state, action) => ({
			...state,
			hasUserManuallyEditGeocode: action.payload
		}),
		setFootprintIsPristine: (state, action) => ({
			...state,
			footprintIsPristine: action.payload
		}),
		setIsLoadingAdditionalBuildings: (state, action) => ({
			...state,
			isLoadingAdditionalBuildings: action.payload
		})
	},
	extraReducers: (builder) => {
		builder
			.addCase(postBulkService.pending, (state) => {
				state.isSubmittingBulkFile = true
			})
			.addCase(postBulkService.fulfilled, (state) => {
				state.isSubmittingBulkFile = false
			})
			.addCase(postBulkService.rejected, (state) => {
				state.isSubmittingBulkFile = false
			})
			.addCase(postFootprintChecker.pending, (state) => {
				state.isSubmittingFootprintChecker = true
			})
			.addCase(postFootprintChecker.fulfilled, (state, action) => {
				const newFeatures = addNewBuildingFootprintHandler(
					state.parcelToTrace.features,
					action.payload,
					state.clickedParcelId
				)
				state.isSubmittingFootprintChecker = false
				state.parcelToTrace = {
					...state.parcelToTrace,
					features: newFeatures
				}
				state.clickedParcelId = ""
			})
			.addCase(postFootprintChecker.rejected, (state) => {
				state.isSubmittingFootprintChecker = false
			})
			.addCase(postEditResult.pending, (state) => {
				state.isPostingResultEdit = true
			})
			.addCase(postEditResult.fulfilled, (state) => {
				state.isPostingResultEdit = false
			})
			.addCase(postEditResult.rejected, (state) => {
				state.isPostingResultEdit = false
			})
			.addCase(patchAddressResult.pending, (state) => {
				state.isPostingResultEdit = true
			})
			.addCase(patchAddressResult.fulfilled, (state, action) => {
				state.isPostingResultEdit = false
				state.editedResultBuffer = action.payload
			})
			.addCase(patchAddressResult.rejected, (state) => {
				state.isPostingResultEdit = false
			})
			.addCase(getAdditionalParcels.pending, (state) => {
				state.isLoadingAdditionalParcels = true
				state.showLoadMoreParcelsCta =
					enableLoadMoreParcelsForSupportedCountries(
						state.countyCodeISO3
					)
			})
			.addCase(getAdditionalParcels.fulfilled, (state, action) => {
				state.isLoadingAdditionalParcels = false
				state.showLoadMoreParcelsCta = false
				state.parcelToTrace = {
					...state.parcelToTrace,
					features: removeCircleTargetHandler(
						addingNewParcelsHandler(
							state.parcelToTrace.features,
							action.payload
						)
					)
				}
			})
			.addCase(getAdditionalParcels.rejected, (state) => {
				state.isLoadingAdditionalParcels = false
				state.showLoadMoreParcelsCta = false
				state.parcelToTrace = {
					...state.parcelToTrace,
					features: removeCircleTargetHandler(
						state.parcelToTrace.features
					)
				}
			})
			.addCase(postChangeFootprintMode.pending, (state) => {
				if (state.isLoadingAdditionalBuildings) {
					state.isLoadingAdditionalParcels = true
					state.showLoadMoreBuildingsCta =
						state.geocodedDataRaw[0]?.backend_helper_mode === "osm"
				} else {
					state.isChangingFootprintMode = true
					state.showLoadingModalForFootprintModeSwitch = true
					state.showLoadMoreParcelsCta = false
				}
			})
			.addCase(postChangeFootprintMode.fulfilled, (state, action) => {
				if (action.payload && Array.isArray(action.payload)) {
					state.isLoadingAdditionalParcels = false
					state.parcelToTrace = {
						...state.parcelToTrace,
						features: removeCircleTargetHandler(
							addBuildingsAndParcels(
								state.parcelToTrace.features,
								action.payload,
								action.payload,
								true
							)
						)
					}
					state.isLoadingAdditionalBuildings = false
				} else {
					state.geocodedDataRaw = [action.payload]
					state.countyCodeISO3 = action.payload.address.country
					state.parcelToTrace = INIT_STATE.parcelToTrace
					state.parcelToTrace = {
						...state.parcelToTrace,
						features: addBuildingsAndParcels(
							state.parcelToTrace.features,
							action.payload.buildings,
							action.payload.parcels
						)
					}
				}
				state.showLoadMoreBuildingsCta = false
				state.isChangingFootprintMode = false
				state.showLoadingModalForFootprintModeSwitch = false
			})
			.addCase(postChangeFootprintMode.rejected, (state) => {
				state.isChangingFootprintMode = false
				state.showLoadingModalForFootprintModeSwitch = false
				state.isLoadingAdditionalParcels = false
				state.isLoadingAdditionalBuildings = false
			})
			.addCase(getFootprintByCoordinates.pending, (state) => {
				state.showLoadingModalForFootprintModeSwitch = true
			})
			.addCase(getFootprintByCoordinates.fulfilled, (state, action) => {
				state.showLoadingModalForFootprintModeSwitch = false
				state.geocodedDataRaw = [action.payload]
				state.countyCodeISO3 = action.payload.address.country
				state.parcelToTrace = INIT_STATE.parcelToTrace
				state.parcelToTrace = {
					...state.parcelToTrace,
					features: addBuildingsAndParcels(
						state.parcelToTrace.features,
						action.payload.buildings,
						action.payload.parcels
					)
				}
			})
			.addCase(getFootprintByCoordinates.rejected, (state) => {
				state.showLoadingModalForFootprintModeSwitch = false
			})
	}
})

export const {
	clearFootprintData,
	clearBulkFile,
	clearBulkMetadata,
	saveGroundtruth,
	highlightParcel,
	unhighlightBuilding,
	highlightBuilding,
	setClickedParcel,
	hoveredParcel,
	addHocResult,
	setIsGeocoding,
	unHoverAllParcels,
	addCircleTarget,
	removeCircleTarget,
	setShowLoadMoreParcelsCta,
	setShowLoadMoreBuildingsCta,
	setShowLoadingModalForFootprintModeSwitch,
	setHasUserManuallyEditGeocode,
	setFootprintIsPristine,
	setIsLoadingAdditionalBuildings
} = footprintSlice.actions

/* istanbul ignore next */
export const geocodedDataRaw = (state) => state.geocoder.geocodedDataRaw
/* istanbul ignore next */
export const currentMode = (state) =>
	state.geocoder.geocodedDataRaw[0]?.backend_helper_mode
/* istanbul ignore next */
export const geocodingFormValues = (state) => state.geocoder.form
/* istanbul ignore next */
export const geocodingIsFetching = (state) => state.geocoder.isFetching
/* istanbul ignore next */
export const geocodingIsSubmittingBulkFile = (state) =>
	state.geocoder.isSubmittingBulkFile
/* istanbul ignore next */
export const geocodingIsSubmittingFootprintChecker = (state) =>
	state.geocoder.isSubmittingFootprintChecker
/* istanbul ignore next */
export const parcelsToTrace = (state) => state.geocoder.parcelToTrace
/* istanbul ignore next */
export const isBulkSubDrawerVisible = (state) =>
	state.geocoder.isBulkSubDrawerVisible
/* istanbul ignore next */
export const countyCodeISO3 = (state) => state.geocoder.countyCodeISO3
/* istanbul ignore next */
export const clickedParcelId = (state) => state.geocoder.clickedParcel
/* istanbul ignore next */
export const isPostingResultEdit = (state) => state.geocoder.isPostingResultEdit
/* istanbul ignore next */
export const editedResultBuffer = (state) => state.geocoder.editedResultBuffer
/* istanbul ignore next */
export const isGeocoded = (state) => state.geocoder.isGeocoded
/* istanbul ignore next */
export const toClearForm = (state) => state.geocoder.toClearForm
/* istanbul ignore next */
export const isLoadingAdditionalParcels = (state) =>
	state.geocoder.isLoadingAdditionalParcels
/* istanbul ignore next */
export const showLoadMoreParcelsCta = (state) =>
	state.geocoder.showLoadMoreParcelsCta
/* istanbul ignore next */
export const showLoadMoreBuildingsCta = (state) =>
	state.geocoder.showLoadMoreBuildingsCta
/* istanbul ignore next */
export const showLoadingModalForFootprintModeSwitch = (state) =>
	state.geocoder.showLoadingModalForFootprintModeSwitch
/* istanbul ignore next */
export const footprintIsPristine = (state) => state.geocoder.footprintIsPristine

export default footprintSlice.reducer
