import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Dropdown, Typography, Button } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import {
	currentLocationIndex as currentLocationIndexSlice,
	selectedResults as selectedResultsSlice,
	footprintMode as footprintModeSlice,
	setCurrentFootprintMode
} from "features/jobsResultsSlice"

import {
	isPostingResultEdit as isPostingResultEditSlice,
	isLoadingAdditionalParcels as isLoadingAdditionalParcelsSlice,
	geocodingIsSubmittingFootprintChecker as geocodingIsSubmittingFootprintCheckerSlice,
	geocodedDataRaw as geocodedDataRawSlice,
	postChangeFootprintMode,
	setShowLoadingModalForFootprintModeSwitch,
	setFootprintIsPristine
} from "features/footprintSlice"

import { VALIDATION_STATUS_TYPE } from "helpers/constants"

import IconArrowDown from "assets/IconArrowDownSmall"
import IconArrowUp from "assets/IconArrowUpSmall"

import IconOpenInNew from "assets/IconOpenInNew"
import ModeAvailable from "./ModeAvailable"

const { Paragraph } = Typography

const TopbarDropdown = ({ openModal }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [isLoading, setLoading] = useState(true)
	const [isOpen, setIsOpen] = useState(false)

	const isPostingResultEdit = useSelector(isPostingResultEditSlice)
	const isLoadingAdditionalParcels = useSelector(
		isLoadingAdditionalParcelsSlice
	)
	const currentLocationIndex = useSelector(currentLocationIndexSlice)
	const selectedLocations = useSelector(selectedResultsSlice)
	const isSubmittingFootprintChecker = useSelector(
		geocodingIsSubmittingFootprintCheckerSlice
	)
	const currentFootprintMode = useSelector(footprintModeSlice)
	const currentGeocodedDataRaw = useSelector(geocodedDataRawSlice)

	const modeAvailables =
		(currentGeocodedDataRaw.length > 0 &&
			currentGeocodedDataRaw[0]?.available_processing_modes &&
			currentGeocodedDataRaw[0]?.available_processing_modes
				.map((mode) => ({
					key: mode,
					label: t(mode)
				}))
				.map((modeNode, index) => ({
					key: modeNode.key,
					"data-testid": `footprint-mode-${index}-testid`,
					label: (
						<ModeAvailable
							src={modeNode.key}
							title={modeNode.label}
							subtitle={t(`${modeNode.key}.subtitle`)}
						/>
					),
					onClick: ({ key: mode }) => {
						setIsOpen(false)
						dispatch(setCurrentFootprintMode(mode))
						dispatch(
							setShowLoadingModalForFootprintModeSwitch(true)
						)
						dispatch(
							postChangeFootprintMode({
								rawEMPAttributes:
									selectedLocations[currentLocationIndex]
										.input_data.raw_emp_attributes
							})
						).then(async (data) => {
							if (
								data.type ===
								postChangeFootprintMode.fulfilled.type
							) {
								dispatch(setFootprintIsPristine(false))
							}
						})
					}
				}))) ||
		[]
	const menuItems = [...modeAvailables].concat(
		{
			label: (
				<div className="dropdown_change_mode">
					<Paragraph>{t("change_mode")}</Paragraph>
				</div>
			),
			key: "2",
			type: "group"
		},
		{
			label: (
				// eslint-disable-next-line
				<div
					className="dropdown_more_mode"
					onClick={() => {
						openModal()
						setIsOpen(false)
					}}
				>
					<Button
						type="link"
						size="small"
						className="dropdown_button"
						style={{ fontWeight: "600" }}
					>
						{t("more_mode").toUpperCase()}
						<IconOpenInNew className="dropdown_button_icon" />
					</Button>
				</div>
			),
			key: "3"
		}
	)

	useEffect(() => {
		if (selectedLocations.length > 0) {
			setLoading(false)
		}
	}, [selectedLocations])

	if (isLoading) {
		return null
	}

	return (
		<Dropdown
			placement="bottom"
			onOpenChange={(open) => setIsOpen(open)}
			disabled={
				modeAvailables.length < 2 ||
				!currentFootprintMode ||
				isPostingResultEdit ||
				isLoadingAdditionalParcels ||
				isSubmittingFootprintChecker ||
				selectedLocations[currentLocationIndex]?.validation_status ===
					VALIDATION_STATUS_TYPE.ERROR
			}
			menu={{
				items: menuItems || <div />
			}}
		>
			<div
				className={`footprintMode__mode__label--selected${
					isPostingResultEdit ||
					isLoadingAdditionalParcels ||
					isSubmittingFootprintChecker ||
					selectedLocations[currentLocationIndex]
						?.validation_status === VALIDATION_STATUS_TYPE.ERROR
						? "--disabled"
						: ""
				} ${isOpen && "coloractive"}`}
				data-testid="footprintMode__mode__label--selected-testid"
			>
				<div className="dropdown-title">
					{t(currentFootprintMode) === ""
						? "N/A"
						: t(isOpen ? "MODE" : currentFootprintMode)}
				</div>
				<div className="dropdown-icon">
					{isOpen ? <IconArrowUp /> : <IconArrowDown />}
				</div>
			</div>
		</Dropdown>
	)
}

TopbarDropdown.propTypes = {
	openModal: PropTypes.func
}

TopbarDropdown.defaultProps = {
	openModal: () => {}
}

export default TopbarDropdown
