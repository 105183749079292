import PropTypes from "prop-types"
import React from "react"
import { Button } from "antd"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import parse from "html-react-parser"
import { useNavigate } from "react-router-dom"
import {
	CheckCircleOutlined,
	FlagOutlined,
	InfoCircleOutlined,
	WarningOutlined
} from "@ant-design/icons"

import { displayAlert, setAlertPayload } from "features/alertsSlice"
import { ALERTS_TYPE } from "helpers/constants"
import "./Alerts.css"
import { isNotToastableAlert, alertTypeByCode } from "helpers/rulesHandler"

/**
 * @async
 * Generate toast with a given message code for a given time
 *
 *
 * @param {dispatch}            pass redux dispatcher
 * @param {string}            	code of the message cf. common.json
 * @param {int}            		duration: how long should the toast be display in ms
 *
 *
 * @returns promise
 */
export const displayToast = async (
	dispatch,
	code,
	alertType,
	duration,
	message,
	isMapAlert = true
) => {
	dispatch(
		setAlertPayload({
			type: alertType,
			code,
			isMapAlert,
			message
		})
	)
	return new Promise((resolve) => {
		setTimeout(() => {
			dispatch(displayAlert(false))
			resolve()
		}, duration)
	})
}

/**
 *
 * Generate the right icon given an ALERTS_TYPE.*
 *
 *
 * @param string alerType            ALERTS_TYPE.*
 *
 *
 * @returns icon
 */

export const iconSelector = (
	alertType = ALERTS_TYPE.INFO,
	color = "#4976ba"
) => {
	const iconStyle = {
		color,
		fontSize: "20px"
	}
	switch (alertType.toLowerCase()) {
		case ALERTS_TYPE.ERROR:
			return (
				<WarningOutlined
					style={{
						...iconStyle,
						color: "#914146"
					}}
				/>
			)
		case ALERTS_TYPE.WARNING:
			return (
				<FlagOutlined
					style={{
						...iconStyle,
						color: "#da7200"
					}}
				/>
			)
		case ALERTS_TYPE.SUCCESS:
			return (
				<CheckCircleOutlined
					style={{
						...iconStyle,
						color: "#138636"
					}}
				/>
			)
		case ALERTS_TYPE.INFO:
			return (
				<InfoCircleOutlined
					style={{
						...iconStyle
					}}
				/>
			)

		default:
			return (
				<InfoCircleOutlined
					style={{
						...iconStyle
					}}
				/>
			)
	}
}

export const alertNamespaceSwitcher = (code) =>
	[
		"INFO_UPLOAD_NEW_FILE",
		"SUCCESS_DELETE_JOB",
		"SUCCESS_ADD_PARCELS",
		"DOWNLOADING_MESSAGE",
		"SUCCESS_DOWNLOADING_MESSAGE",
		"SUCCESS_VALIDATION_OR_NOT_LOCATION",
		"SUCCESS_MODIFIFY_GEOCODE",
		"FAILED_DELETE_JOB",
		"ERROR_DOWNLOADING_MESSAGE"
	].includes(code)

/**
 *
 * Creates an alert
 *
 *
 * @param string props.description      Human readable description text
 * @param string props.message          Human readable title
 * @param string props.type             ALERTS_TYPE.*
 *
 *
 * @returns Alert
 */

const Alerts = (props) => {
	// eslint-disable-next-line
	const { alertCode, isMapAlert, interpolation, message } = props
	const dispatch = useDispatch()
	const { t } = useTranslation(["errors"])
	const hasHumanDescription =
		t(`${alertCode}.description`) !== `${alertCode}.description`
	const navigate = useNavigate()

	return (
		<div>
			{alertCode ? (
				<div className={`${isMapAlert ? "alert__container--map" : ""}`}>
					<div
						className={`${
							isMapAlert
								? "alert__container__map"
								: "alert__container"
						} ${
							!isNotToastableAlert(alertCode)
								? "toast__container"
								: ""
						} alert__container--${alertTypeByCode(alertCode)} ${
							hasHumanDescription && !isMapAlert
								? "alert__container--withDescription"
								: ""
						}`}
					>
						<div className="alert__content">
							{isNotToastableAlert(alertCode) ? (
								<Button
									className="alert__closeButton"
									onClick={() =>
										dispatch(displayAlert(false))
									}
								>
									<svg
										width="18"
										height="18"
										viewBox="0 0 16 18"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M12.845 18.468 8 13.623l-4.845 4.845-1.623-1.623L6.377 12 1.532 7.155l1.623-1.623L8 10.377l4.845-4.845 1.623 1.623L9.623 12l4.845 4.845z"
											fill="#343c3d"
											fillRule="evenodd"
										/>
									</svg>
								</Button>
							) : (
								""
							)}
							<div className="alert__header">
								<div className="alert__content__icon__container">
									{iconSelector(alertTypeByCode(alertCode))}
								</div>
								<div className="alert__content__message__container">
									<span className="alert__content__message">
										{message ||
											(alertNamespaceSwitcher(alertCode)
												? t(`${alertCode}.message`, {
														ns: "common",
														recordName:
															interpolation?.recordName
												  })
												: t(
														`${alertCode}.message`,
														"Generic Error"
												  ))}
									</span>
								</div>
							</div>
							<div className="alert__content__wrapper">
								{hasHumanDescription ? (
									<span className="alert__content__description">
										{parse(
											t(`${alertCode}.description`, {
												ns: "errors"
											})
										)}
									</span>
								) : (
									""
								)}
								{isNotToastableAlert(alertCode) ? (
									<div className="alert__errorRef__container">
										{alertTypeByCode(alertCode) ===
										ALERTS_TYPE.ERROR ? (
											<span>Error ref: </span>
										) : (
											""
										)}
										<span className="alert__errorRef__code">
											{alertCode}
										</span>
									</div>
								) : null}
							</div>

							{alertCode === "AUT_E401" && (
								<div className="alert__button__container">
									<Button
										className="axa-button
					infoTooltip__action__container"
										type="primary"
										onClick={() => {
											navigate(0)
										}}
									>
										{t("AUTHENTICATE")}
									</Button>
								</div>
							)}
						</div>
					</div>
				</div>
			) : (
				""
			)}
		</div>
	)
}

Alerts.propTypes = {
	alertCode: PropTypes.string,
	interpolation: PropTypes.shape({
		recordName: PropTypes.string
	}),
	isMapAlert: PropTypes.bool
}

Alerts.defaultProps = {
	alertCode: "",
	interpolation: {},
	isMapAlert: false
}

export default Alerts
