import React from "react"
import PropTypes from "prop-types"

const DownloadFloodLE = ({ classname }) => (
	<svg
		width="16px"
		height="21px"
		viewBox="0 0 16 21"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		className={classname}
	>
		<path
			fill="#00008F"
			d="M14 0.5H6L0 6.5V18.5C0 19.6 0.9 20.5 2 20.5H14C15.1 20.5 16 19.6 16 18.5V2.5C16 1.4 15.1 0.5 14 0.5ZM8 15.5L4 11.5H7V7.52L9 7.5V11.5H12L8 15.5Z"
		/>
	</svg>
)

DownloadFloodLE.propTypes = {
	classname: PropTypes.string
}

DownloadFloodLE.defaultProps = {
	classname: ""
}

export default DownloadFloodLE
