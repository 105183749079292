/* eslint-disable no-underscore-dangle */
import React from "react"
import { useTranslation } from "react-i18next"
import { Typography, Table, Tooltip, Skeleton } from "antd"
import "./LocationsPage.css"

const { Text } = Typography

export const titleFragmentLeft = (title) => (
	<div className="table__title--toLeft" data-testid="column-header-id-testid">
		{title}
	</div>
)

const LocationGridSkeleton = () => {
	const { Column } = Table
	const { t } = useTranslation()
	return (
		<Table
			dataSource={[...Array(10).keys()]}
			rowKey="id"
			rowClassName={(record, index) =>
				index % 2 === 0
					? "locationRow pages__table__row--even"
					: "locationRow"
			}
		>
			<Column
				title={
					<Tooltip
						title={t(
							"The ID number attributed to the location in the source file"
						)}
					>
						{titleFragmentLeft("ID")}
					</Tooltip>
				}
				showSorterTooltip={false}
				align="left"
				width="8%"
				render={() => <Skeleton.Button />}
			/>
			<Column
				title={
					<Tooltip
						title={t(
							"The name provided by the client in the source file"
						)}
					>
						LOCATION NAME
					</Tooltip>
				}
				width="20%"
				render={() => <Skeleton.Input />}
			/>
			<Column
				title={
					<Tooltip
						title={t(
							"The address provided by the client in the source file"
						)}
					>
						ADDRESS
					</Tooltip>
				}
				width="30%"
				render={() => <Skeleton.Input />}
			/>
			<Column
				title={
					<Tooltip
						title={t("The country code provided by the geocoder")}
					>
						COUNTRY
					</Tooltip>
				}
				showSorterTooltip={false}
				ellipsis
				width="10%"
				render={() => <Skeleton.Button />}
			/>
			<Column
				title={
					<Tooltip
						title={t(
							"The resolution of the location provided by the geocoder"
						)}
					>
						RESOLUTION
					</Tooltip>
				}
				showSorterTooltip={false}
				ellipsis
				width="15%"
				render={() => <Skeleton.Input />}
			/>
			<Column
				title={
					<Tooltip
						title={t(
							"Our own confidence about the resolution based on the geocoder data"
						)}
					>
						CONFIDENCE
					</Tooltip>
				}
				showSorterTooltip={false}
				width="12%"
				render={() => <Skeleton.Button />}
			/>
			<Column
				title={
					<Tooltip title={t("The source of the geocode")}>
						SOURCE
					</Tooltip>
				}
				showSorterTooltip={false}
				width="12%"
				render={() => <Skeleton.Button />}
			/>
			<Column
				title={
					<Tooltip
						title={
							<Text style={{ color: "white" }}>
								It indicates if the location has a footprint.{" "}
							</Text>
						}
					>
						FOOTPRINT
					</Tooltip>
				}
				showSorterTooltip={false}
				ellipsis
				align="left"
				width="10%"
				render={() => <Skeleton.Button />}
			/>
			<Column
				title={
					<Tooltip title={t("Total insured value")}>
						<div data-testid="tiv-title-testid">TIV</div>
					</Tooltip>
				}
				showSorterTooltip={false}
				align="left"
				width="15%"
				render={() => <Skeleton.Button />}
			/>
		</Table>
	)
}

export default LocationGridSkeleton
